import { Button } from "@components/button/button"
import { Loader } from "@components/loader/loader"
import { useEffect, useState } from 'react'

import { getStatusReportComments, postStatusReportComments, putStatusReportComments } from '@api/reports'
import FieldCarForm from "@pages/car-form/car-form-components/components/FieldCarForm/FieldCarForm"
import clsx from 'clsx'
import { useAccordionButton } from 'react-bootstrap'


export const Comment = ({
	eventKey,
	id,
	isClosed
}) => {

	const [loading, setLoading] = useState(false)
	const [comment, setComment] = useState()
	const [commentId, setCommentId] = useState()

	const decoratedOnClick = useAccordionButton(eventKey)

	useEffect(() => {
		if (id) {
			getStatusReportComments(id).then(resp => {
				if (resp.data.length) {
					let x = resp.data.at(-1)
					setComment(x.body)
					setCommentId(x.id)
				}
			})
		}
	}, [id])

	const saveRequest = async () => {
		if (commentId) {
			return putStatusReportComments(id, commentId, { body: comment })
		}
		return postStatusReportComments(id, { body: comment })
	}

	const onClickSave = async () => {
		if (isClosed) return false
		setLoading(true)
		await saveRequest()
		setLoading(false)
		decoratedOnClick()
	}

	return (
		<div className="comment">
			<div className="all-info-wrapper">
				<FieldCarForm label="Дополнительный комментарий для аукциона">
					<textarea
						className={clsx('input-field textarea', isClosed && 'disabled')}
						id="body"
						name="body"
						defaultValue={comment}
						placeholder={"Оставьте комментарий"}
						onChange={(e) => setComment(e.target.value)}
					/>
				</FieldCarForm>
			</div>
			<div className="d-flex align-items-center gap-3 mt-3 comment-footer">
				{loading ? <Loader /> :
					<Button
						preset="primary"
						className="flex-grow-1"
						onClick={onClickSave}
					>
						Сохранить
					</Button>
				}
			</div>
		</div>
	)
}
