import has from 'lodash/has';
import some from 'lodash/some';
import { useForm } from 'react-hook-form';

const useFormWrapper = (schema, config) => {
  const form = useForm({
    ...config,
  });

  // eslint-disable-next-line no-shadow
  const setFormValues = (values, config = {}) => {
    Object.keys(values).forEach((formKey) => {
      form.setValue(formKey, values[formKey], config);
    });
  };

  const setFormErrors = (errors) => {
    Object.keys(errors).forEach((key) => {
      const errMessage = errors[key][0];

      form.setError(key, { message: errMessage });
    });
  };

  const renderFields = () => {
    return Object.keys(schema.fields).map((field) => ({
      name: field,
      errorMessage: form.errors[field].message,
    }));
  };

  const getFieldError = (fieldKey) => form.formState.errors[fieldKey]?.message;
  const getFieldValue = (fieldKey) => form.getValues()[fieldKey];

  const fieldsHaveError = (fields = []) => {
    return some(fields, (key) => has(form.formState.errors, key));
  };

  return {
    form,
    setFormValues,
    setFormErrors,
    renderFields,
    getFieldError,
    getFieldValue,
    fieldsHaveError,
  };
};

export default useFormWrapper;
