import { makeAutoObservable } from "mobx";

import { getSingleAuctionRequest } from "@api/auctions";

class AuctionStore {
	constructor() {
		this.auction = null;

		makeAutoObservable(this);
	}

	setAuction(auction) {
		if (auction && this.auction && auction.id === this.auction.id) {
			this.auction = { ...this.auction, ...auction };
		} else {
			this.auction = auction;
		}
	}

	fetch(id) {
		return getSingleAuctionRequest(id).then((resp) => {
			if (resp.message) {
				console.error(resp);

				return "error";
			}
			this.setAuction(resp);

			return "success";
		});
	}

	refetch() {
		this.fetch(this.auction.id);
	}
}

export const auctionStore = new AuctionStore();
