import { bytesToMegaBytes } from '@utils/format'
import classNames from 'classnames'
import styles from "./UploadedFile.module.scss"

const IMAGES_TYPE: any = {
	'pdf': '/img/icons/docs/pdf-doc.svg',
	'docx': '/img/icons/docs/doc-doc.svg',
	'doc': '/img/icons/docs/doc-doc.svg',
	'jpg': '/img/icons/docs/other-doc.svg',
	'jpeg': '/img/icons/docs/other-doc.svg',
	'png': '/img/icons/docs/other-doc.svg'
}

interface UploadedFileProps {
	className?: string
	name: string
	size: number
	type: any
	id: number
	url: string
	isDelete?: boolean
	onRemove?(id: number): void
}

const UploadedFile = (props: UploadedFileProps) => {
	const { className, name, size, type = 'jpg', id, url, isDelete, onRemove } = props

	const onRemoveHandler = () => {
		if (onRemove) onRemove(id)
	}

	return (
		<div className={classNames(styles.UploadedFile, className)}>
			<a href={url} className={styles.image}>
				<img src={IMAGES_TYPE[type]} alt="" />
			</a>
			<a href={url} className={styles.desc}>
				<div className={styles.name}>{name}</div>
				<div className={styles.size}>{bytesToMegaBytes(size)} MB</div>
			</a>
			{isDelete &&
				<div className={styles.delete} onClick={onRemoveHandler}>
					<img src="/img/icons/remove-icon.svg" alt="" />
				</div>
			}
		</div>
	)
}

export default UploadedFile