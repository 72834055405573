import { getAuctionsNotes, postAuctionsNotes, putAuctionsNotes } from "@api/auctions";
import clsx from "clsx";
import { useEffect, useState } from "react";
import Btn from "../../../components/btn/Btn/Btn";
import TextArea from "../../../components/form/TextArea/TextArea";
import cls from "./CarLotComment.module.scss";
import { meStore } from "../../../mobx/me";
import IconCheck from "../../../components/icons/IconCheck";
import { Loader } from "../../../components/loader/loader";
import IconPreloader from "../../../components/icons/IconPreloader";

interface CarLotCommentProps {
	className?: string;
	auctionId: string | number;
	lotId: string | number;
}

type MessageProps = {
	id: number;
	body: string;
	author: {
		full_name: string;
	};
};

const CarLotComment = (props: CarLotCommentProps) => {
	const { className, auctionId, lotId } = props;
	const [message, setMessage] = useState("");
	const [commentId, setCommentId] = useState<number>();
	const [pending, setPending] = useState(false);
	const [saved, setSaved] = useState(false);

	useEffect(() => {
		getAuctionsNotes(auctionId, lotId).then((data: MessageProps[]) => {
			if (data.length) {
				setSaved(true);
				setMessage(data[0]?.body ?? "");
				setCommentId(data[0].id);
			}
		});
	}, [meStore.data]);

	const onInput = (value: string) => {
		setMessage(value);
	};

	const onSubmit = async () => {
		setPending(true);
		setSaved(false);
		if (commentId) {
			await putAuctionsNotes({
				auctionId: auctionId,
				lotId: lotId,
				id: commentId,
				body: message
			});
		} else {
			const data = await postAuctionsNotes({
				auctionId: auctionId,
				lotId: lotId,
				body: message
			});
			setCommentId(data.id);
		}
		setPending(false);
		setSaved(true);
	};

	return (
		<div className={clsx(cls.CarLotComment, className)}>
			<div className={clsx(cls.header)}>
				<div className={cls.title}>
					Мой комментарий
					<span>Виден только вам</span>
				</div>
				<Btn
					size={"small"}
					type={"button"}
					color={"white"}
					onClick={onSubmit}
				>
					Сохранить
				</Btn>
			</div>
			<div className={cls.comment}>
				<div className={cls.icon}>
					{saved && <IconCheck />}
					{pending && <IconPreloader />}
				</div>
				<TextArea
					name={"msg"}
					className={cls.textarea}
					defaultValue={message}
					onInput={onInput}
				/>
			</div>
		</div>
	);
};

export default CarLotComment;
