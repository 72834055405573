import { DELETE, GET, POST, PUT } from './request'

export const getUserGroupsRequest = (params) => {
	return GET('user-groups?expand[0]=role', params)
}

export const createUserGroupRequest = (data) => {
	return POST('user-groups', data)
}

export const changeUserGroupRequest = (id, data) => {
	return PUT(`user-groups/${id}`, data)
}

export const getGroupUsersRequest = (id, params) => {
	return GET(`user-groups/${id}/users`, params)
}

export const addUsersToGroupRequest = (id, data) => {
	return POST(`user-groups/${id}/users`, data)
}

export const deleteUsersFromGroupRequest = (id, data) => {
	return DELETE(`user-groups/${id}/users`, data)
}

export const getUserGroupRequest = (id) => {
	return GET(`user-groups/${id}?expand[0]=role`)
}

export const getAllUsersFromGroup = (id) => {
	return GET(`user-groups/${id}/user-ids`)
}

export const addAllUsersToGroup = (id, params) => {
	return POST(`user-groups/${id}/assign-all`, params)
}
