import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"

import { getCarTradesHistory } from "@api/cars"
import { Table } from "@components/table/table"
import { managersStore } from "@mobx/managers"

import { citiesStore } from '../../../../mobx/cities'
import { setHistoryColumns } from "./table-templates"

const PER_PAGE = [10, 25, 50]

export const TradesHistory = observer(({ carSaleInfo }) => {
	const [history, setHistory] = useState()
	const { cities } = citiesStore

	useEffect(() => {
		if (!managersStore.managers.length) {
			managersStore.fetch()
		}
	}, [])

	const requestHistory = (params) => getCarTradesHistory(carSaleInfo.id, params).then(setHistory)

	useEffect(() => {
		if (!citiesStore.cities.length) {
			citiesStore.fetch()
		}
	}, [])
	useEffect(() => {
		requestHistory({ per_page: PER_PAGE[0] })
	}, [carSaleInfo])

	return (
		<div className="section" id="tradesHistory">
			<div className="section-header">
				<div className="title">История торгов</div>
			</div>
			{history?.data?.length > 0
				? (
					<Table
						data={history.data}
						columns={setHistoryColumns(cities)}
					/>
				)
				: <div>Пока что здесь нет изменений</div>}
		</div>
	)
})
