import {useState} from 'react';

const usePreloader = () => {

	const [preloader, setPreloader] = useState<boolean>(false)

	return {
		preloader,
		setPreloader
	}
}

export default usePreloader
