import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { meStore } from '@mobx/me';
import { NO_PERMISSION_URL } from '@utils/common';
import { changeExchangeOfferLotStatus, getExchangeOffer } from '@api/auctions';
import { Table } from '@components/table/table';
import { formatDateWithTime } from '@utils/date';

import { getColumns } from './utils';

export const ExchangeOffer = observer(() => {
  const { id } = useParams();
  const [exchangeOffer, setExchangeOffer] = useState();

  const getExchangeOfferRequest = () => {
    getExchangeOffer(id).then((resp) => setExchangeOffer(resp));
  };
  const changeStatusLot = (approve, lotId) => {
    changeExchangeOfferLotStatus(id, lotId, approve).then(() => getExchangeOfferRequest());
  };

  const columns = getColumns(changeStatusLot);

  useEffect(() => {
    getExchangeOfferRequest();
  }, []);

  if (meStore.noPermission('exchange-offers.view')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  return (
    <div className="single-auction-wrapper d-grid gap-20px">
      {exchangeOffer ? (
        <div>
          <div>
            <div className="d-flex justify-content-between mb-25px">
              <div className="auction-name">
                <span>
                  Предложение №{exchangeOffer.id}
                </span>
              </div>
              {meStore.hasPermission('exchange-offers.edit') && (
                <Link className="btn btn-primary" to={`/exchange-offer/${id}/edit`}>
                  Редактировать
                </Link>
              )}
            </div>
            <div className="auction-info">
              <div className="d-grid">
                <div className="d-flex justify-content-start align-items-center row-container gap-100px">
                  <div className="row-name">Продавец</div>
                  <Link to={`/${exchangeOffer.sellerable?.parent_id ? 'department' : 'company'}/${exchangeOffer.sellerable?.id}`}>
                    {exchangeOffer.sellerable?.display_name}
                  </Link>
                </div>
                <div className="d-flex justify-content-start align-items-center row-container gap-100px">
                  <div className="row-name">Время начала</div>
                  <div>{exchangeOffer.starts_at ? formatDateWithTime(exchangeOffer.starts_at) : '-'}</div>
                </div>
                <div className="d-flex justify-content-start align-items-center row-container gap-100px">
                  <div className="row-name">Время окончания</div>
                  <div>{exchangeOffer.expires_at ? formatDateWithTime(exchangeOffer.expires_at) : '-'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        'Нет данных`'
      )}
      {exchangeOffer && (
        <Table
          headerClasses="align-top"
          data={exchangeOffer.lots}
          columns={columns}
        />
      )}
    </div>
  );
});
