import React from "react";
import { IconType } from "./IconType";
import classNames from "classnames";
import styles from "./Icon.module.scss";

const IconCheck = ({ className, size = 16 }: IconType) => {
	return (
		<svg className={classNames(className, styles[`Icon${size}`])} xmlns="http://www.w3.org/2000/svg"
		     viewBox="0 0 16 16" fill="none">
			<g fill="currentColor">
				<path
					d="M13.8633 4.13642C14.1562 4.42931 14.1562 4.90418 13.8633 5.19708L7.19667 11.8637C6.90378 12.1566 6.4289 12.1566 6.13601 11.8637L2.80268 8.53041C2.50978 8.23752 2.50978 7.76264 2.80268 7.46975C3.09557 7.17686 3.57044 7.17686 3.86334 7.46975L6.66634 10.2728L12.8027 4.13642C13.0956 3.84352 13.5704 3.84352 13.8633 4.13642Z" />
			</g>
		</svg>
	);
};

export default IconCheck;
