export const outsideDamagePointsCoordinates = {
	car: {
		1: {
			top: "-0.4%",
			left: "48.6%",
		},
		4: {
			top: "2.6%",
			left: "48.6%",
		},
		7: {
			top: "6.4%",
			left: "37.6%",
		},
		11: {
			top: "6.4%",
			left: "59.599999999999994%",
		},
		15: {
			top: "5.6000000000000005%",
			left: "48.6%",
		},
		18: {
			top: "20.599999999999998%",
			left: "48.6%",
		},
		22: {
			top: "2.6%",
			left: "43.4%",
		},
		23: {
			top: "30.8%",
			left: "43.6%",
		},
		25: {
			top: "34.599999999999994%",
			left: "48.6%",
		},
		27: {
			top: "37.8%",
			left: "32%",
		},
		29: {
			top: "54%",
			left: "48.6%",
		},
		30: {
			top: "45%",
			left: "48.6%",
		},
		32: {
			top: "2.6%",
			left: "54%",
		},
		43: {
			top: "12.8%",
			left: "22.8%",
		},
		44: {
			top: "28.199999999999996%",
			left: "26.200000000000003%",
		},
		45: {
			top: "20.200000000000003%",
			left: "27.800000000000004%",
		},
		46: {
			top: "20.599999999999998%",
			left: "20%",
		},
		47: {
			top: "22.400000000000002%",
			left: "24.4%",
		},
		49: {
			top: "34.8%",
			left: "31.6%",
		},
		50: {
			top: "40.400000000000006%",
			left: "25.6%",
		},
		51: {
			top: "40.400000000000006%",
			left: "18.8%",
		},
		52: {
			top: "44.6%",
			left: "22.8%",
		},
		53: {
			top: "47.199999999999996%",
			left: "29.799999999999997%",
		},
		54: {
			top: "40.8%",
			left: "36%",
		},
		55: {
			top: "26.400000000000002%",
			left: "17.8%",
		},
		56: {
			top: "14.799999999999999%",
			left: "25.4%",
		},
		57: {
			top: "9.6%",
			left: "18.6%",
		},
		95: {
			top: "82.8%",
			left: "23.599999999999998%",
		},
		96: {
			top: "77%",
			left: "28.4%",
		},
		97: {
			top: "70.8%",
			left: "26.6%",
		},
		98: {
			top: "69%",
			left: "20.200000000000003%",
		},
		99: {
			top: "66.4%",
			left: "24%",
		},
		100: {
			top: "58.199999999999996%",
			left: "25.6%",
		},
		101: {
			top: "58.199999999999996%",
			left: "18.8%",
		},
		102: {
			top: "53.6%",
			left: "22.8%",
		},
		103: {
			top: "64.2%",
			left: "30.2%",
		},
		104: {
			top: "67.60000000000001%",
			left: "35.4%",
		},
		105: {
			top: "75.6%",
			left: "19%",
		},
		106: {
			top: "77%",
			left: "25.4%",
		},
		135: {
			top: "98.2%",
			left: "48.6%",
		},
		136: {
			top: "95.19999999999999%",
			left: "48.6%",
		},
		139: {
			top: "91.8%",
			left: "48.6%",
		},
		140: {
			top: "90.2%",
			left: "37.8%",
		},
		141: {
			top: "90.2%",
			left: "59.4%",
		},
		142: {
			top: "88.4%",
			left: "48.6%",
		},
		143: {
			top: "72.39999999999999%",
			left: "48.6%",
		},
		145: {
			top: "95.19999999999999%",
			left: "44.4%",
		},
		146: {
			top: "95.19999999999999%",
			left: "53%",
		},
		155: {
			top: "12.8%",
			left: "74.4%",
		},
		156: {
			top: "28.199999999999996%",
			left: "70.8%",
		},
		157: {
			top: "20.200000000000003%",
			left: "69.39999999999999%",
		},
		158: {
			top: "20.599999999999998%",
			left: "77%",
		},
		159: {
			top: "22.8%",
			left: "72.8%",
		},
		160: {
			top: "37.8%",
			left: "65.2%",
		},
		161: {
			top: "34.8%",
			left: "66.2%",
		},
		162: {
			top: "40.400000000000006%",
			left: "72.2%",
		},
		163: {
			top: "40.400000000000006%",
			left: "78.4%",
		},
		164: {
			top: "44.6%",
			left: "74.4%",
		},
		165: {
			top: "47.199999999999996%",
			left: "67.4%",
		},
		166: {
			top: "40.8%",
			left: "62.2%",
		},
		167: {
			top: "26.400000000000002%",
			left: "79.60000000000001%",
		},
		168: {
			top: "14.799999999999999%",
			left: "71.8%",
		},
		169: {
			top: "9.6%",
			left: "79%",
		},
		207: {
			top: "82.8%",
			left: "74.4%",
		},
		208: {
			top: "77%",
			left: "68.8%",
		},
		209: {
			top: "70.8%",
			left: "70.8%",
		},
		210: {
			top: "69%",
			left: "77%",
		},
		211: {
			top: "66.4%",
			left: "73.4%",
		},
		212: {
			top: "58.199999999999996%",
			left: "73%",
		},
		213: {
			top: "58.199999999999996%",
			left: "78.4%",
		},
		214: {
			top: "53.6%",
			left: "74.4%",
		},
		215: {
			top: "64.2%",
			left: "67.4%",
		},
		216: {
			top: "67.60000000000001%",
			left: "62%",
		},
		217: {
			top: "75.6%",
			left: "78.60000000000001%",
		},
		218: {
			top: "77%",
			left: "71.8%",
		},
		219: {
			top: "72.6%",
			left: "67.4%",
		},
	},
	truck: {
		5: {
			top: "3.22215941209723%",
			left: "49.7037037037037%",
		},
		8: {
			top: "4.69191633691351%",
			left: "41.03703703703704%",
		},
		12: {
			top: "4.804974561899378%",
			left: "58.74074074074074%",
		},
		16: {
			top: "9.101187111362352%",
			left: "48.44444444444444%",
		},
		19: {
			top: "9.044657998869418%",
			left: "51.33333333333333%",
		},
		26: {
			top: "17.58055398530243%",
			left: "48.44444444444444%",
		},
		485: {
			top: "29.11249293386094%",
			left: "49.111111111111114%",
		},
		36: {
			top: "17.524024872809495%",
			left: "51.11111111111111%",
		},
		38: {
			top: "35.387224420576594%",
			left: "49.333333333333336%",
		},
		59: {
			top: "39.174674957603166%",
			left: "8.962962962962964%",
		},
		60: {
			top: "38.94855850763143%",
			left: "11.407407407407408%",
		},
		61: {
			top: "35.16110797060486%",
			left: "7.629629629629629%",
		},
		62: {
			top: "33.01300169587337%",
			left: "7.629629629629629%",
		},
		63: {
			top: "17.410966647823628%",
			left: "36.888888888888886%",
		},
		65: {
			top: "28.942905596382136%",
			left: "15.777777777777777%",
		},
		66: {
			top: "28.5472018089316%",
			left: "7.037037037037037%",
		},
		69: {
			top: "24.98586772187677%",
			left: "39.55555555555556%",
		},
		70: {
			top: "37.08309779536461%",
			left: "11.407407407407408%",
		},
		107: {
			top: "50.59355568117581%",
			left: "6.666666666666667%",
		},
		108: {
			top: "49.7456189937818%",
			left: "11.555555555555555%",
		},
		109: {
			top: "67.49576031656302%",
			left: "8.222222222222223%",
		},
		110: {
			top: "63.59525155455059%",
			left: "7.629629629629629%",
		},
		111: {
			top: "61.447145279819104%",
			left: "7.481481481481482%",
		},
		112: {
			top: "67.4392312040701%",
			left: "10.74074074074074%",
		},
		113: {
			top: "48.33239118145845%",
			left: "6.592592592592593%",
		},
		114: {
			top: "46.127755794234034%",
			left: "6.518518518518518%",
		},
		140: {
			top: "96.09949123798756%",
			left: "41.77777777777778%",
		},
		141: {
			top: "95.98643301300169%",
			left: "58.074074074074076%",
		},
		150: {
			top: "94.57320520067834%",
			left: "49.77777777777778%",
		},
		152: {
			top: "79.64951950254381%",
			left: "49.925925925925924%",
		},
		171: {
			top: "39.2312040700961%",
			left: "91.55555555555556%",
		},
		172: {
			top: "39.2312040700961%",
			left: "88.81481481481481%",
		},
		173: {
			top: "35.27416619559073%",
			left: "93.18518518518518%",
		},
		174: {
			top: "33.23911814584511%",
			left: "93.11111111111111%",
		},
		175: {
			top: "16.676088185415487%",
			left: "63.40740740740741%",
		},
		177: {
			top: "28.3776144714528%",
			left: "84.14814814814815%",
		},
		178: {
			top: "28.5472018089316%",
			left: "93.55555555555556%",
		},
		181: {
			top: "24.929338609383834%",
			left: "60.14814814814815%",
		},
		182: {
			top: "37.42227247032221%",
			left: "88.81481481481481%",
		},
		220: {
			top: "51.15884680610514%",
			left: "93.33333333333333%",
		},
		221: {
			top: "53.70265686828717%",
			left: "88.5925925925926%",
		},
		222: {
			top: "67.38270209157716%",
			left: "89.85185185185185%",
		},
		223: {
			top: "63.48219332956473%",
			left: "93.03703703703704%",
		},
		224: {
			top: "61.051441492368575%",
			left: "92.96296296296296%",
		},
		225: {
			top: "67.49576031656302%",
			left: "92.88888888888889%",
		},
		226: {
			top: "48.67156585641605%",
			left: "93.18518518518518%",
		},
		227: {
			top: "46.184284906726965%",
			left: "93.18518518518518%",
		},
		228: {
			top: "54.43753533069531%",
			left: "75.1111111111111%",
		},
		484: {
			top: "54.32447710570945%",
			left: "49.925925925925924%",
		},
		488: {
			top: "87.28094968908988%",
			left: "49.925925925925924%",
		},
		486: {
			top: "26.681741096664783%",
			left: "6.962962962962963%",
		},
		490: {
			top: "26.568682871678917%",
			left: "93.48148148148148%",
		},
		487: {
			top: "35.2176370830978%",
			left: "20.444444444444446%",
		},
		365: {
			top: "35.36480686695279%",
			left: "79.86501687289089%",
		},
		489: {
			top: "96.3256076879593%",
			left: "49.77777777777778%",
		},
	},
	bus: {
		6: {
			top: "3.278688524590164%",
			left: "49.35579781962339%",
		},
		10: {
			top: "4.013566986998304%",
			left: "37.26461843409316%",
		},
		14: {
			top: "3.900508762012436%",
			left: "61.64519326065411%",
		},
		21: {
			top: "91.91633691351045%",
			left: "48.66204162537166%",
		},
		22: {
			top: "7.066139061616733%",
			left: "48.86025768087215%",
		},
		28: {
			top: "11.531938948558508%",
			left: "48.364717542120914%",
		},
		29: {
			top: "50.25438100621821%",
			left: "49.65312190287413%",
		},
		37: {
			top: "7.801017524024872%",
			left: "44.20218037661051%",
		},
		82: {
			top: "31.091011871113622%",
			left: "12.685827552031714%",
		},
		83: {
			top: "29.16902204635387%",
			left: "11.694747274529236%",
		},
		84: {
			top: "32.78688524590164%",
			left: "7.333994053518335%",
		},
		85: {
			top: "30.751837196156018%",
			left: "7.333994053518335%",
		},
		86: {
			top: "15.036743923120408%",
			left: "34.886025768087215%",
		},
		92: {
			top: "21.876766534765405%",
			left: "22.794846382556987%",
		},
		123: {
			top: "68.17410966647823%",
			left: "8.523290386521309%",
		},
		124: {
			top: "59.24250989259468%",
			left: "11.000991080277503%",
		},
		125: {
			top: "62.69078575466365%",
			left: "7.928642220019821%",
		},
		126: {
			top: "60.65573770491803%",
			left: "7.928642220019821%",
		},
		132: {
			top: "85.472018089316%",
			left: "36.37264618434094%",
		},
		134: {
			top: "37.81797625777275%",
			left: "10.901883052527255%",
		},
		138: {
			top: "97.39966082532504%",
			left: "47.571853320118926%",
		},
		139: {
			top: "97.39966082532504%",
			left: "50.842418235877105%",
		},
		140: {
			top: "94.12097230073488%",
			left: "36.868186323092175%",
		},
		141: {
			top: "94.17750141322782%",
			left: "62.43805748265609%",
		},
		194: {
			top: "31.091011871113622%",
			left: "87.90882061446978%",
		},
		195: {
			top: "29.338609383832676%",
			left: "88.800792864222%",
		},
		196: {
			top: "32.78688524590164%",
			left: "93.1615460852329%",
		},
		197: {
			top: "30.977953646127755%",
			left: "93.1615460852329%",
		},
		198: {
			top: "14.92368569813454%",
			left: "65.01486620416253%",
		},
		200: {
			top: "24.816280384397967%",
			left: "82.45787908820614%",
		},
		201: {
			top: "24.929338609383834%",
			left: "95.54013875123884%",
		},
		204: {
			top: "21.989824759751272%",
			left: "76.61050545094153%",
		},
		236: {
			top: "67.7784058790277%",
			left: "91.2784935579782%",
		},
		237: {
			top: "63.87789711701526%",
			left: "89.69276511397423%",
		},
		238: {
			top: "62.69078575466365%",
			left: "93.26065411298316%",
		},
		239: {
			top: "60.938383267382704%",
			left: "93.26065411298316%",
		},
		241: {
			top: "50.65008479366874%",
			left: "81.36769078295342%",
		},
		242: {
			top: "50.70661390616167%",
			left: "95.54013875123884%",
		},
		245: {
			top: "84.7936687394008%",
			left: "62.735381565906835%",
		},
		247: {
			top: "37.87450537026569%",
			left: "89.69276511397423%",
		},
		492: {
			top: "36.00904465799887%",
			left: "50.24777006937562%",
		},
		493: {
			top: "72.80949689089881%",
			left: "49.35579781962339%",
		},
		494: {
			top: "46.127755794234034%",
			left: "21.902874132804754%",
		},
		496: {
			top: "41.26625211984172%",
			left: "77.40336967294351%",
		},
		495: {
			top: "85.01978518937253%",
			left: "48.761149653121905%",
		},
	},
};

export const insideDamagePointsCoordinatesCar = {
	268: {
		top: "27.6%",
		left: "48.199999999999996%",
	},
	269: {
		top: "23.400000000000002%",
		left: "48.199999999999996%",
	},
	270: {
		top: "30.599999999999998%",
		left: "48.199999999999996%",
	},
	271: {
		top: "32%",
		left: "40.2%",
	},
	272: {
		top: "36.8%",
		left: "49.2%",
	},
	273: {
		top: "10.6%",
		left: "40.2%",
	},
	274: {
		top: "39%",
		left: "47.199999999999996%",
	},
	275: {
		top: "32%",
		left: "58.4%",
	},
	276: {
		top: "47.599999999999994%",
		left: "48.4%",
	},
	277: {
		top: "28.599999999999998%",
		left: "40.2%",
	},
	278: {
		top: "34.4%",
		left: "47.4%",
	},
	306: {
		top: "40.6%",
		left: "18.4%",
	},
	307: {
		top: "36.6%",
		left: "37%",
	},
	308: {
		top: "45.2%",
		left: "40.400000000000006%",
	},
	309: {
		top: "27.6%",
		left: "28.199999999999996%",
	},
	310: {
		top: "55.60000000000001%",
		left: "3.2%",
	},
	324: {
		top: "62.8%",
		left: "18%",
	},
	325: {
		top: "56.599999999999994%",
		left: "37.4%",
	},
	326: {
		top: "63%",
		left: "40.400000000000006%",
	},
	327: {
		top: "70.6%",
		left: "28.000000000000004%",
	},
	328: {
		top: "90.4%",
		left: "40.400000000000006%",
	},
	329: {
		top: "90.4%",
		left: "44.4%",
	},
	330: {
		top: "90.4%",
		left: "48.4%",
	},
	331: {
		top: "85.39999999999999%",
		left: "32%",
	},
	332: {
		top: "85.39999999999999%",
		left: "64.8%",
	},
	333: {
		top: "90.4%",
		left: "52.400000000000006%",
	},
	334: {
		top: "82.8%",
		left: "48.4%",
	},
	335: {
		top: "97.2%",
		left: "48.4%",
	},
	336: {
		top: "90.4%",
		left: "56.39999999999999%",
	},
	337: {
		top: "62.8%",
		left: "78.8%",
	},
	338: {
		top: "56.599999999999994%",
		left: "41.4%",
	},
	339: {
		top: "63%",
		left: "56.2%",
	},
	340: {
		top: "70.6%",
		left: "68.8%",
	},
	341: {
		top: "40.6%",
		left: "78.8%",
	},
	342: {
		top: "36.6%",
		left: "59.4%",
	},
	343: {
		top: "45.2%",
		left: "56.2%",
	},
	344: {
		top: "27.6%",
		left: "68.60000000000001%",
	},
	345: {
		top: "55.60000000000001%",
		left: "93.60000000000001%",
	},
};
