import React from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

import { formatValueWithSpaces } from "@utils/format";
import { getCurrencySymbol } from "@utils/common";
import { deleteBetAuctionRequest } from "@api/auctions";

import "./last-bet-delete-modal.scss";

export const LastBetDeleteModal = ({ auctionId, handleClose, bet }) => {
	return (
		<div className="position-relative">
			<Modal
				show={true}
				onHide={handleClose}
				className="d-flex align-items-center justify-content-center last-bet-delete-modal-wrapper"
			>
				<div className="title text-align-center">Удалить ставку из хода торгов?</div>
				<div className="d-flex gap-24px mt-25px">
					<div>
						<div className="row-name">Ставка</div>
						<div className="row-value">{formatValueWithSpaces(bet?.sum)} {getCurrencySymbol()}</div>
					</div>
					<div>
						<div className="row-name">Дата и время</div>
						<div className="row-value">{moment(bet.created_at).format("HH:mm DD.MM.YYYY")}</div>
					</div>
				</div>
				<div className="mt-12px">
					<div className="row-name">Пользователь</div>
					<div className="row-value">
						{bet.user?.full_name}{bet.department?.display_name && ","} {bet.department?.display_name}
					</div>
				</div>
				<div className="last-bet-delete-modal-wrapper__btns">
					<button
						type="button"
						className="btn btn-cancel"
						onClick={() => deleteBetAuctionRequest(auctionId, bet.lot_id).then(() => handleClose(true))}
					>
						<img src="/img/icons/red-close-icon.svg" alt="" />
						Да, удалить ставку
					</button>
					<button
						type="button"
						className="btn btn-close-modal"
						onClick={handleClose}
					>
						Нет, вернуться
					</button>
				</div>
			</Modal>
		</div>
	);
};
