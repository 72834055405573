import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Tabs } from '@pages/car/car-info/tabs';

import { AccessUserGroups } from './access-user-modal/access-user-groups';
import { AccessUsers } from './access-user-modal/access-users';

const options = [{
  value: 'users',
  label: 'Пользователи',
}, {
  value: 'groups',
  label: 'Группы',
}];

export const AccessUsersAndGroupsModal = ({ auctionId, show, onHide, onChange, selectedUsers, selectedGroups, isAccess }) => {
  const [activeTab, setActiveTab] = useState('users');

  return (
    <Modal show={show} onHide={onHide} className="access-users-modal-wrapper">
      <div className="head-text">{isAccess ? 'Выберите тех, кто будет иметь доступ к аукциону' : 'Выберите тех, для кого аукцион будет недоступен'}</div>
      <Tabs options={options} value={activeTab} onChange={setActiveTab} />
      {activeTab === 'users' && (
        <AccessUsers
          auctionId={auctionId}
          onChange={onChange}
          selected={selectedUsers}
          isAccess={isAccess}
          onHide={onHide}
        />
      )}
      {activeTab === 'groups' && (
        <AccessUserGroups
          auctionId={auctionId}
          onChange={onChange}
          selected={selectedGroups}
          isAccess={isAccess}
          onHide={onHide}
        />
      )}
    </Modal>
  );
};
