import React from 'react';

import { auctionRemoveFavoriteRequest, auctionToFavoriteRequest } from '@api/auctions';
import { auctionsStore } from '@mobx/auctions';

export const FavouriteBtn = ({ auctionId, lotId, isFavourite }) => {
  const addToFavorites = () => {
    auctionToFavoriteRequest(auctionId, lotId).then((res) => {
      if (!res.message) {
        auctionsStore.updateLot(auctionId, lotId, { isFavourite: true });
      }
    });
  };

  const removeFromFavorite = () => {
    auctionRemoveFavoriteRequest(auctionId, lotId).then((res) => {
      if (!res.message) {
        auctionsStore.updateLot(auctionId, lotId, { isFavourite: false });
      }
    });
  };

  return (
    <div className="favorite-icon">
      <img
        className="favorite-img"
        onClick={isFavourite ? removeFromFavorite : addToFavorites}
        src={isFavourite ? '/img/icons/selected-favorite.svg' : '/img/icons/favorite.svg'}
        alt=""
      />
    </div>
  );
};
