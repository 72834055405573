import classNames from "classnames";
import styles from "./Icon.module.scss";
import { IconType } from "./IconType";

const IconComment = ({ className, size = 24 }: IconType) => {
	return (
		<svg
			className={classNames(className, styles[`Icon${size}`])}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="none"
		>
			<g fill="currentColor">
				<path d="M4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H18C18.5304 5 19.0391 5.21071 19.4142 5.58579C19.7893 5.96086 20 6.46957 20 7V15C20 15.5304 19.7893 16.0391 19.4142 16.4142C19.0391 16.7893 18.5304 17 18 17H13C12.8188 17 12.6409 17.0493 12.4855 17.1425L9 19.2338V18C9 17.4477 8.55228 17 8 17H6C5.46957 17 4.96086 16.7893 4.58579 16.4142C4.21071 16.0391 4 15.5304 4 15V7C4 6.46957 4.21071 5.96086 4.58579 5.58579ZM6 3C4.93913 3 3.92172 3.42143 3.17157 4.17157C2.42143 4.92172 2 5.93913 2 7V15C2 16.0609 2.42143 17.0783 3.17157 17.8284C3.92172 18.5786 4.93913 19 6 19H7V21C7 21.3603 7.19379 21.6927 7.5073 21.8702C7.82081 22.0477 8.20556 22.0429 8.5145 21.8575L13.277 19H18C19.0609 19 20.0783 18.5786 20.8284 17.8284C21.5786 17.0783 22 16.0609 22 15V7C22 5.93913 21.5786 4.92172 20.8284 4.17157C20.0783 3.42143 19.0609 3 18 3H6Z" />
			</g>
		</svg>
	);
};

export default IconComment;
