import { FormField } from "@components/form/form-field";

export const EnumField = ({ field, defaultValue, required, isClearable }) => {
	const { values, name, id, type } = field;
	const options = values.map((value) => ({ value, label: value }));
	const isMultiple = type === "array";
	return (
		<FormField
			className="info-field"
			label={name}
			name={`${id}_`}
			as="select"
			options={options}
			isMulti={isMultiple}
			defaultValue={defaultValue}
			required={required}
			isClearable={isClearable}
		/>
	);
};
