import React, { useEffect, useState } from 'react';
import styles from './RoleViewAdmin.module.scss'
import Info from '../../../components/info/Info/Info';
import { Roles } from '../../../types/Roles';
import Title from '../../../components/titles/Title/Title';
import Btn from '../../../components/btn/Btn/Btn';
import IconPlus from '../../../components/icons/IconPlus';
import PermissionList from '../../../components/permissions/PermissionList/PermissionList';
import { PermissionsObject } from '../../../types/Permissions';
import TabHeader from '../../../components/tabs/TabHeader/TabHeader';
import TabButton from '../../../components/tabs/TabButton';
import { getRoles, removeRoles } from '@api/roles';
import { getPermissionsView } from '@api/users';
import { useParams } from 'react-router-dom';
import usePreloader from '../../../hooks/use-preloader';
import { Loader } from '../../../components/loader/loader';

type RoleViewAdminProps = {
	items: Roles[]
	url: string
	disabled?: boolean
	info?: string
	isUser?: boolean
	isCompany?: boolean
}

const RoleViewAdmin = ({ items, url, disabled, info = 'У пользователя пока нет доступов', isCompany, isUser }: RoleViewAdminProps) => {

	const { id } = useParams()
	const [itemsRoles, setItemsRoles] = useState(items)
	const [permissions, setPermissions] = useState<PermissionsObject>()
	const [active, setActive] = useState(items[0]?.id)
	const [roles, setRoles] = useState<Roles[]>([])
	const [currentRoles, setCurrentRoles] = useState<Roles>()
	const { preloader, setPreloader } = usePreloader()

	useEffect(() => {
		(async() => {
			await getRoles().then((data: Roles[]) => {
				if(data) {
					setRoles(data)
					setCurrentRoles(data.find(x => x.id === active))
				}
			}).catch(e => e)
			await getPermissionsView().then(setPermissions)
		})()
	}, []);

	const onSelectTab = (id: number) => {
		setActive(id)
		setCurrentRoles(roles.find(x => x.id === id))
	}

	const onDeleteRole = () => {
		setPreloader(true)
		let data = {}
		if(isUser) data = { users: [{ user_id: id, role_id: active }]}
		if(isCompany) data = { companies: [{ company_id: id, role_id: active }]}
		removeRoles(data).then(data => {
			const _itemsRoles = itemsRoles.filter(x => x.id !== active)
			const firstRole = roles.find(x => x.id === _itemsRoles[0]?.id)
			setItemsRoles(_itemsRoles)
			setActive(firstRole?.id)
			setCurrentRoles(firstRole)
			setPreloader(false)
		})
	}

	return (
		<div className={styles.RoleViewAdmin}>
			<div className={styles.header}>
				<Title label={'Доступы'} />
				{!disabled &&
					<div className={styles.btn}>
						<Btn href={`${url}/assign-role`}>
							<IconPlus />
							Добавить роль
						</Btn>
						{!!itemsRoles.length && <Btn onClick={onDeleteRole} color={'redBorder'}>Удалить роль</Btn>}
					</div>
				}
			</div>
			{!itemsRoles.length ?
				<Info
					title={info}
					text={'Выберите Кнопку “Добавить роль” для создания новой роли'}
				/> :
				<>
					<TabHeader>
						{itemsRoles.map(x =>
							<TabButton key={x.id} title={x.description} active={x.id === active} onClick={() => onSelectTab(x.id)} />
						)}
					</TabHeader>
					{permissions && <PermissionList permissions={permissions} selectedPermissions={currentRoles?.permissions} />}
				</>
			}
			{preloader && <Loader fixed />}
		</div>
	);
};

export default RoleViewAdmin;
