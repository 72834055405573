import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { DropdownButton } from '@components/dropdown/dropdown-button';
import { NO_PERMISSION_URL } from '@utils/common';
import { COLUMNS } from '@components/users-departments-modal/utils';
import { getRequisitesTemplates } from '@api/users';
import { meStore } from '@mobx/me';
import { UsersDepartmentModal } from '@components/users-departments-modal/users-department-modal';
import { Table } from '@components/table/table';
import { getCurrentDepartmentRequest, getDepartmentsUsersRequest } from '@api/companies';

import './department.scss';

export const Department = observer(() => {
  const { id } = useParams();

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'selection-row',
  };
  const navigate = useNavigate();
  const [department, setDepartment] = useState();
  const [users, setUsers] = useState();
  const [show, setShow] = useState(false);
  const [fields, setFields] = useState([]);

  const requestUsers = () => {
    getDepartmentsUsersRequest(id).then(setUsers);
  };

  const handleClose = (needRequest) => {
    if (needRequest) {
      requestUsers();
    }

    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    getCurrentDepartmentRequest(id).then(setDepartment);
    requestUsers();
  }, []);

  useEffect(() => {
    getRequisitesTemplates().then((resp) => {
      if (resp?.legal?.fields) {
        setFields(Object.values(resp.legal.fields));
      }
    });
  }, []);

  if (meStore.noPermission('admin.companies.view')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  if (meStore.data === null) {
    return null;
  }

  const renderDynamicField = (item) => {
    return (
      <div key={item.key} className="department-info__field-wrapper d-grid grid-columns-30-70">
        <div className="name">{item.name}</div>
        <div className="value">{department?.requisite?.[item.key] || '-'}</div>
      </div>
    );
  };

  return (
    <div>
      {department ? (
        <div className="department-wrapper">
          <div className="actions d-flex align-items-center justify-content-between">
            <div className="title">{department.title}</div>
            <Link to={`/department/${id}/edit`} className="btn btn-primary">
              Редактировать
            </Link>
          </div>

          <div className="department-info mt-4">
            <div className="d-grid grid-columns-30-70 department-info__field-wrapper">
              <div className="name">Название подразделения</div>
              <div className="value">{department.title}</div>
            </div>
            <div className="d-grid grid-columns-30-70 department-info__field-wrapper">
              <div className="name">Ответственный менеджер</div>
              <div className="value">{department.responsible?.full_name || '-'}</div>
            </div>
            <div className="d-grid grid-columns-30-70 department-info__field-wrapper">
              <div className="name">Тип компании</div>
              <div className="value">
                {department.type_id ? department.type?.name : '-'}
              </div>
            </div>
            <div className="d-flex justify-content-start gap-50px mt-3">
              <div className="d-flex form-check-inline checkbox">
                <input
                  type="checkbox"
                  name="roles"
                  value="buyer"
                  id="checkboxBuyer"
                  disabled={true}
                  checked={Boolean(department.roles?.find((item) => item.name === 'buyer'))}
                />
                <label className="form-check-label" htmlFor="checkboxBuyer">
                  Покупатель
                </label>
              </div>
              <div className="d-flex form-check-inline checkbox">
                <input
                  type="checkbox"
                  name="roles"
                  value="seller"
                  id="checkboxSeller"
                  disabled={true}
                  checked={Boolean(department.roles?.find((item) => item.name === 'seller'))}
                />
                <label className="form-check-label" htmlFor="checkboxSeller">
                  Продавец
                </label>
              </div>
            </div>
          </div>

          {fields.length > 0 && (
            <div className="mt-4">
              <div className="title">Реквизиты</div>
              <div className="department-info mt-3">
                {fields.map((item) => (item.key === 'agent_contract' ? (
                  <div key={item.key} className="d-grid grid-columns-30-70 company-info__field-wrapper">
                    <div className="name">Документ</div>
                    <div className="value">
                      {department?.requisite?.agent_contract?.url ? (
                        <a href={department.requisite.agent_contract.url} download={department.requisite.agent_contract.original_name}>
                          {department.requisite.agent_contract.original_name}
                        </a>
                      ) : '-'}
                    </div>
                  </div>
                ) : renderDynamicField(item)))}
              </div>
            </div>
          )}

          <div className="d-flex align-items-center justify-content-between mt-3">
            <div className="title">Пользователи</div>
            {meStore.hasPermission('admin.companies.edit') && (
              <DropdownButton
                btnText="Действия"
                options={[{
                  label: 'Добавить пользователя',
                  value: 'add',
                  onClick: handleShow,
                }, {
                  label: 'Создать пользователя',
                  value: 'create',
                  onClick: () => {
                    navigate(`/users/create?department_id=${id}`);
                  },
                },
                ]}
              />
            )}
          </div>
          <div className="mt-3">
            {users?.data?.length > 0 ? (
              <Table
                data={users.data}
                columns={COLUMNS}
                selectRow={selectRow}
              />
            ) : (
              <p>Нет пользователей</p>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
      {show && (
        <UsersDepartmentModal
          type="department"
          department_id={id}
          show={true}
          onHide={handleClose}
          selected={users?.data?.map((item) => item.id)}
        />
      )}
    </div>
  );
});
