import React from 'react';

import { Step } from './step';

import './steps.scss';

export const Steps = ({ steps = [], step: currentStep, onStepClick }) => {
  return (
    <div className="steps">
      <div className="steps-container">
        {steps.map((step, index) => (
          <Step
            step={step}
            index={index}
            isActive={currentStep >= index}
            key={`${step.text}-${index}`}
            onStepClick={onStepClick}
          />
        ))}
      </div>
    </div>
  );
};
