import { formToJSON } from "@utils/formToJSON"
import classNames from "classnames"
import clsx from "clsx"
import { useEffect, useRef, useState } from "react"
import useMediaQuery from "../../../hooks/use-media-query"
import { FilterType } from "../../../types/FilterType"
import Input from "../../Forms/Input/Input"
import Select from "../../Forms/Select/Select"
import { InputRequisitesSearch } from "../../Forms/input-requisites-search/input-requisites-search"
import Btn from "../../btn/Btn/Btn"
import IconArrowRound from "../../icons/IconArrowRound"
import IconClose from "../../icons/IconClose"
import IconFilterReset from "../../icons/IconFilterReset"
import styles from "./Filter.module.scss"

type SelectItemProps = {
	label: string | number
	value: string | number
}

interface FilterItemSaveProps {
	dataSaveFilter: FilterType
	dataSaveFilterData: any
	className?: string
}

type InputProps = {
	type:
	| "text"
	| "email"
	| "tel"
	| "number"
	| "date"
	| "password"
	| "hidden"
	| "search"
	| "select"
	name: string
	placeholder: string
	readonly?: boolean
	isMulti?: boolean
	options?: { label: string | number; value: string | number }[]
	value?: string | []
	id: string | number
	reset?: boolean
	min?: number
	max?: number
	typeId?: number
	disabled?: boolean
	className?: string
}

interface FilterEvents {
	onInitSelectField?(key: string, type: string, x: SelectItemProps[]): void

	onChangeSelectField?(key: string, type: string, x: SelectItemProps[]): void
}

interface FilterItemProps extends FilterEvents {
	data: InputProps[]
	reset?: boolean
}

interface FilterProps extends FilterItemProps {
	desktopShow?: boolean
	mobileShow?: boolean
	className?: string
	show?: boolean
	showAdd?: boolean
	title?: string
	titleAdditional?: string
	typeFilter:
	| "filter:users"
	| "filter:usersGroups"
	| "filter:companies"
	| "filter:cars"
	| "filter:auctions"
	| "filter:buy"
	| "filter:sell"
	| "filter:trades"
	typeFilterData:
	| "filter:users:data"
	| "filter:usersGroups:data"
	| "filter:companies:data"
	| "filter:cars:data"
	| "filter:auctions:data"
	| "filter:buy:data"
	| "filter:sell:data"
	| "filter:trades:data"
	additional?: InputProps[]
	indent?: boolean // FIXME: тут добавляет отрицательный марджин, надо фиксить отступы в проекте, через жопу сделано
	onSubmit?(data: {}): void

	onReset?(): void

	onChange?(): void

	onClose?(): void
}

const FilterItems = ({
	data,
	dataSaveFilter,
	dataSaveFilterData,
	reset,
	onChangeSelectField,
	className
}: FilterItemProps & FilterItemSaveProps) => {
	const onChangeSelectFieldHandler = (key: string, type: string, e: any) => {
		if (onChangeSelectField)
			onChangeSelectField(key, type, Array.isArray(e) ? e : [e])
	}

	return (
		<div className={clsx(styles.main, className && styles[className])}>
			{data.map((x: any) => {
				if (
					x.type === "text" ||
					x.type === "email" ||
					x.type === "tel" ||
					x.type === "date" ||
					x.type === "hidden" ||
					x.type === "number"
				) {
					return (
						<div className={styles.item} key={x.id}>
							<div
								className={clsx(
									styles.name,
									styles[x.className]
								)}
							>
								{x.placeholder}
							</div>
							<Input
								reset={reset}
								type={x.type}
								name={x.name}
								placeholder={x.placeholder}
								min={x.min}
								max={x.max}
								value={
									dataSaveFilter[x.name]
										? dataSaveFilter[x.name]
										: x.value
								}
							/>
						</div>
					)
				}
				if (x.type === "search") {
					let value = dataSaveFilterData[x.name]
						? dataSaveFilterData[x.name][0]
						: { label: "", value: "" }

					return (
						<div className={styles.item} key={x.id}>
							<div
								className={clsx(
									styles.name,
									styles[x.className]
								)}
							>
								{x.placeholder}
							</div>
							<InputRequisitesSearch
								name={x.name}
								reset={reset}
								resetValue={x.reset}
								placeholder={x.placeholder}
								typeId={x.typeId}
								defaultValue={value}
								onChange={(e: any) =>
									onChangeSelectFieldHandler(
										x.name,
										x.type,
										e
									)
								}
							/>
						</div>
					)
				} else {
					return (
						<div className={styles.item} key={x.id}>
							<div
								className={clsx(
									styles.name,
									x.disabled && styles.disabled,
									styles[x.className]
								)}
							>
								{x.placeholder}
							</div>
							<Select
								reset={reset}
								name={x.name}
								options={x.options}
								placeholder={x.placeholder}
								isMulty={x.isMulti}
								defaultValue={dataSaveFilterData[x.name]}
								onChange={(e) =>
									onChangeSelectFieldHandler(
										x.name,
										x.type,
										e
									)
								}
								resetValue={x.reset}
								сlearable={true}
								disabled={x.disabled}
							/>
						</div>
					)
				}
			})}
		</div>
	)
}

const Filter = ({
	title = "Фильтры",
	titleAdditional = "Дополнительные параметры",
	data,
	additional,
	typeFilter,
	typeFilterData,
	indent,
	onReset,
	onSubmit,
	onChange,
	onChangeSelectField,
	onInitSelectField,
	onClose,
	desktopShow = true,
	mobileShow
}: FilterProps) => {
	const formRef = useRef<HTMLFormElement>(null)
	const [showFilter, setShowFilter] = useState(desktopShow)
	const [showAddFilter, setShowAddFilter] = useState(false)
	const [resetForm, setResetForm] = useState<boolean>(false)
	const [filterSessionStorage, setFilterSessionStorage] = useState<any>({})
	const [filterSessionStorageData, setFilterSessionStorageData] =
		useState<FilterType>({})
	const [selected, setSelected] = useState<number>(0)

	const isMobile = useMediaQuery("screen and (max-width: 780px)")

	useEffect(() => {
		if (isMobile) setShowFilter(true)
	}, [desktopShow])


	const onToggleFilter = () => {
		if (!isMobile) setShowFilter(!showFilter)
	}

	const onToggleAdditionalFilter = () => {
		setShowAddFilter(!showAddFilter)
	}

	const onSubmitHandler = (e: any) => {
		if (e) e.preventDefault()
		let data
		if (formRef.current) {
			data = formToJSON(formRef.current)
			sessionStorage.setItem(typeFilter, JSON.stringify(data))
			sessionStorage.setItem(
				typeFilterData,
				JSON.stringify(filterSessionStorageData)
			)
			setFilterSessionStorage(data)
			setSelected(Object.keys(data).length)
			if (onSubmit) onSubmit(data)
		}
	}

	const onResetHandler = () => {
		sessionStorage.removeItem(typeFilter)
		sessionStorage.removeItem(typeFilterData)
		setResetForm(!resetForm)
		setFilterSessionStorage({})
		setFilterSessionStorageData({})
		setSelected(0)
		if (onReset) onReset()
		if (onSubmit) onSubmit({})
	}

	const initFilterStorage = (filter: any, items: InputProps[]) => {
		items.forEach((x) => {
			if (x.type === "select" && filter[x.name]) {
				if (onInitSelectField)
					onInitSelectField(x.name, x.type, filter[x.name])
			}
		})
	}

	const onChangeSelectFieldHandler = (key: string, type: string, e: any) => {
		filterSessionStorageData[key] = e
		if (e.length === 0) {
			delete filterSessionStorage[key]
			delete filterSessionStorageData[key]
		}
		setFilterSessionStorageData({ ...filterSessionStorageData })
		if (onChangeSelectField) onChangeSelectField(key, type, e)
	}

	useEffect(() => {
		const filter = sessionStorage.getItem(typeFilter)
		const filterData = sessionStorage.getItem(typeFilterData)

		if (filterData) {
			let _filterData = JSON.parse(filterData)
			setFilterSessionStorageData(_filterData)
			initFilterStorage(_filterData, data)
		}

		if (filter) {
			let _filter = JSON.parse(filter)
			setFilterSessionStorage(_filter)
			setShowFilter(true)
			setSelected(Object.keys(_filter).length)
			if (additional) {
				let arrayKeys = Object.keys(_filter).map((x) => x)
				let hasKeys = additional.some((x) =>
					arrayKeys.includes(x.name)
				)
				setShowAddFilter(hasKeys)
			}
		}

		if (formRef.current && onSubmit)
			onSubmit(filter ? JSON.parse(filter) : {})
	}, [formRef])

	return (
		<div
			className={clsx(
				styles.Filter,
				showFilter && styles.open,
				indent && styles.indent,
				mobileShow && styles.show
			)}
		>
			<div className={styles.header}>
				<div className={styles.title} onClick={onToggleFilter}>
					{title}
					{!!selected && <span>{selected}</span>}
					<IconArrowRound />
				</div>
				<div className={styles.close} onClick={onClose}>
					<IconClose />
				</div>
			</div>
			<form
				ref={formRef}
				onChange={onChange}
				onSubmit={onSubmitHandler}
				onReset={onResetHandler}
			>
				{showFilter && (
					<div className={styles.section}>
						<div className={styles.scroll}>
							<FilterItems
								data={data}
								reset={resetForm}
								dataSaveFilter={filterSessionStorage}
								dataSaveFilterData={filterSessionStorageData}
								onChangeSelectField={onChangeSelectFieldHandler}
							/>
							{!!additional?.length && (
								<>
									<div
										className={clsx(
											styles.titleSmall,
											showAddFilter && styles.openAdd
										)}
										onClick={onToggleAdditionalFilter}
									>
										{titleAdditional}
										<IconArrowRound />
									</div>
									{!!additional.length && (
										<FilterItems
											className={clsx(
												!showAddFilter && "hidden"
											)}
											data={additional}
											reset={resetForm}
											dataSaveFilter={
												filterSessionStorage
											}
											dataSaveFilterData={
												filterSessionStorageData
											}
											onChangeSelectField={
												onChangeSelectFieldHandler
											}
										/>
									)}
								</>
							)}
						</div>
						<div className={styles.footer}>
							<Btn type={"submit"} fontSize={"fontSmall"}>
								Применить фильтры
							</Btn>
							<Btn
								className={classNames(
									styles.reset,
									!!selected && styles.red
								)}
								type={"reset"}
								fontSize={"fontSmall"}
								color={"white"}
							>
								<span>Отменить все фильтры</span>
								<div>
									<IconFilterReset />
									Очистить
								</div>
							</Btn>
						</div>
					</div>
				)}
			</form>
		</div>
	)
}

export default Filter
