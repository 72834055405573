import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { logout } from '@components/protected-route/check-auth';
import { NO_AUTH_URL } from '@utils/common';

export const Out = () => {
  useEffect(() => {
    logout();
  }, []);

  return (
    <Navigate to={NO_AUTH_URL} />
  );
};
