import { observer } from "mobx-react-lite"
import { useEffect, useMemo, useState } from "react"

import { COUNTRY } from "@api/config"
import { Loader } from "@components/loader/loader"
import { dictionariesStore } from "@mobx/dictionaries"

import { DocumentField } from "./components/document-field"
import { DocumentFieldWithSelect } from "./components/document-field-with-select"

import { getStatusReportDocuments, postAddStatusReportDocuments, removeStatusReportDocuments } from "@api/reports"
import ErrorMessage from "@components/utils/ErrorMessage/ErrorMessage"
import { isKZ } from "@utils/common"
import clsx from "clsx"
import "./car-form-components.scss"

const staticDocumentNames = COUNTRY === "kz" ? [
	"Свидетельство о регистрации"
] : [
	"ПТС Лицевая сторона",
	"ПТС Оборотная сторона",
	"Фотография кузовной таблички с VIN"
]

export const Documents = observer(({ edit = true, id, isClosed, onClose }) => {

	const documentTypesList = dictionariesStore.dictionaries.sale_document_types


	// FIXME: костыль для казахского сайта
	const [documentTypesListFixed, setDocumentTypesListFixed] = useState([])
	const [required, setRequired] = useState(false)
	const [loading, setLoading] = useState(edit)
	const [documents, setDocuments] = useState()
	const [defaultValues, setDefaultValues] = useState({})
	const [error, setError] = useState()

	const requestDocuments = () => {
		getStatusReportDocuments(id).then(setDocuments)
	}

	useEffect(() => {
		requestDocuments()

		if (!documentTypesList) {
			dictionariesStore.request("sale_document_types", { filters: { car_type_id: "1" } })
		}

	}, [])

	useEffect(() => {
		if (documentTypesList?.length)
			setDocumentTypesListFixed(documentTypesList.map(x => Object.assign(x, isKZ && { is_required: x.document_type_id === 5 })))
	}, [documentTypesList])

	const { staticDocuments, defaultDocuments } = useMemo(
		() => (documentTypesListFixed || []).reduce(
			(acc, doc) => {
				const { document_type: { name, id } } = doc

				const isStaticDocument = staticDocumentNames.find((staticDoc) => staticDoc === name)
				if (isStaticDocument) {
					acc.staticDocuments.push({ ...doc, name: id, id, label: name, type: "file" })
				} else acc.defaultDocuments.push({ ...doc, name: id, label: name })

				return acc
			},
			{ staticDocuments: [], defaultDocuments: [] }
		),
		[documentTypesListFixed]
	)

	useEffect(() => {
		if (documents) {
			const newDefaultValues = {}

			documents.forEach((document) => {
				let value

				if (document.document_files.length) {
					value = document.document_files.map((item) => ({
						...item,
						size: item.file.size,
						name: item.file.original_name
					}))
				}

				const prevValue = newDefaultValues[document.document_type_id]

				newDefaultValues[document.document_type_id] = prevValue ? [...prevValue, ...value] : value
			})

			setDefaultValues(newDefaultValues)

			setLoading(false)
		}
	}, [documents, edit])

	const onDeleteDocument = (docId) => {
		removeStatusReportDocuments(id, [docId]).then(requestDocuments)
	}

	const onSaveRequest = async (file, document_type_id) => {
		try {
			const formData = new FormData()

			formData.append("documents[0][document_type_id]", document_type_id)
			formData.append("documents[0][files][0]", file)

			const response = await postAddStatusReportDocuments(id, formData)

			if (!response?.errors) {
				requestDocuments()
			} else {
				setError(response?.errors)

				setTimeout(() => {
					setError(null)
				}, 5000)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const onCloseHandler = () => {
		let isNextStep = staticDocuments.some(x => {
			let findedItem = documents.find(y => x.document_type_id === y.document_type_id)
			return x.is_required && findedItem?.document_files?.length
		})
		setRequired(!isNextStep)
		setTimeout(() => setRequired(false), 5000)
		if (isNextStep) onClose()
	}

	return (
		<div>
			{loading
				? <Loader />
				: (
					<>
						<div className="all-info-wrapper">
							{staticDocuments.map((doc) => {

								let defaultValue

								if (defaultValues[doc.document_type_id]?.[0]) {
									defaultValue = {
										...defaultValues[doc.document_type_id][0].file,
										id: defaultValues[doc.document_type_id][0].document_id
									}
								}

								return (
									<DocumentField
										name={doc.name}
										label={doc.label}
										key={doc.document_type_id}
										defaultValue={defaultValue}
										onDelete={onDeleteDocument}
										required={doc.is_required}
										onSave={onSaveRequest}
										isClosed={isClosed}
									/>
								)
							})}
							<DocumentFieldWithSelect
								onSaveRequest={onSaveRequest}
								options={defaultDocuments}
								defaultValues={defaultValues}
								onDelete={onDeleteDocument}
								isClosed={isClosed}
							/>
						</div>
						{error && (
							<div
								className="mt-3 mb-3 alert alert-danger break-word">{JSON.stringify(error, null, " ")}</div>
						)}
					</>
				)}

			{!isClosed &&
				<>
					<ErrorMessage show={required} text={"Необходимо загрузить обязательный документ"} />
					<div
						className="d-flex align-items-center justify-content-center gap-3 mt-3 mob-button-wrapper">
						<button
							onClick={onCloseHandler}
							className={clsx("btn btn-primary flex-grow-1")}
							type="button"
						>
							Сохранить
						</button>
					</div>
				</>
			}
		</div>
	)
})
