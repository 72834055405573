import React, { useEffect, useState } from 'react';
import styles from './AssignRole.module.scss'
import { useNavigate, useParams } from 'react-router-dom';
import { UserType } from '../../../types/UserType';
import { getPermissionsView, getUserById } from '@api/users';
import BreadCrumbs from '../../../components/breadcrumbs/BreadCrumbs';
import useLoaded from '../../../hooks/use-loaded';
import { Roles } from '../../../types/Roles';
import { PermissionsObject } from '../../../types/Permissions';
import { assignRoles, getRoles } from '@api/roles';
import Container from '../../../components/Containers/Container/Container';
import Title from '../../../components/titles/Title/Title';
import PermissionsSection from '../../../components/permissions/PermissionsSection/PermissionsSection';
import ComboBox from '../../../components/Forms/ComboBox/ComboBox';
import Btn from '../../../components/btn/Btn/Btn';
import PermissionList from '../../../components/permissions/PermissionList/PermissionList';
import usePreloader from '../../../hooks/use-preloader';
import { Loader } from '../../../components/loader/loader';
import { getSingleCompanyRequest } from '@api/companies';

type AssignRoleProps = {
	isUser: boolean
	isCompany: boolean
}

const AssignRole = ({ isUser, isCompany }: AssignRoleProps) => {

	const navigate = useNavigate()
	const { id } = useParams()
	const { preloader, setPreloader } = usePreloader()
	const [idRole, setIdRole] = useState<number | string>()
	const [breadCrumbs, setBreadCrumbs] = useState({
		url: '',
		name: ''
	})
	const [roleCurrent, setRoleCurrent] = useState<Roles>()
	const [rolesComboBox, setRolesComboBox] = useState<Roles[]>([])
	const [permissions, setPermissions] = useState<PermissionsObject>()
	const { loading, setLoaded } = useLoaded()

	useEffect(() => {
		(async () => {
			let _response
			let _responseData: any
			let _roles
			let _permissions
			let _rolesComboBox

			if(id) {
				if(isUser) {
					_response = await getUserById(id)
					let lastName = _response.last_name ?? ''
					let firstName = _response.first_name ?? ''
					let middleName = _response.middle_name ?? ''
					setBreadCrumbs({
						url: '/user/',
						name: lastName + ' ' + firstName + ' ' + middleName
					})
				}
				if(isCompany) {
					_response = await getSingleCompanyRequest(id)
					setBreadCrumbs({
						url: '/company/',
						name: _response.title
					})
				}
			}
			_responseData = _response.roles
			_roles = await getRoles()
			_permissions = await getPermissionsView()
			_rolesComboBox = _roles.filter((x: Roles) => {
				let s = _responseData.find((y: Roles) => y.id === x.id)
				if(s) return x.id !== s.id
				return x
			})
			setRolesComboBox(_rolesComboBox)
			setRoleCurrent(_rolesComboBox[0])
			setPermissions(_permissions)
			setIdRole(_rolesComboBox[0]?.id)
			setLoaded(true)
		})()
	}, []);

	const onChangeRole = (id: string) => {
		let _id = parseInt(id)
		setRoleCurrent(rolesComboBox.find(x => x.id === _id))
		setIdRole(_id)
	}

	const addRole = () => {
		setPreloader(true)
		let data = {}
		if(isUser) data = { users: [{ user_id: id, role_id: idRole }]}
		if(isCompany) data = { companies: [{ company_id: id, role_id: idRole }]}

		assignRoles(data).then(data => {
			if(isUser)
				navigate('/user/' + id)
			if(isCompany)
				navigate('/company/' + id)
			setPreloader(false)
		})
	}

	return (
		<div className={styles.AssignRole}>
			{loading &&
				<>
					<BreadCrumbs items={[{ id: 1, url: breadCrumbs.url + id, name: breadCrumbs.name }]} />
					<Title label={'Доступы'} className="mb-2" />
					{rolesComboBox?.length ?
						<>
							<Container>
								<PermissionsSection name={'Выбрать роль'} hasInput={true} info={'true'}>
									<ComboBox items={rolesComboBox} onChange={onChangeRole} />
								</PermissionsSection>
							</Container>
							<Title label={'Права'} className="mb-2" />
							{permissions && <PermissionList permissions={permissions} selectedPermissions={roleCurrent?.permissions} />}
							<Btn onClick={addRole}>
								Добавить роль
							</Btn>
						</> :
						<div>
							Доступных ролей нет
						</div>
					}

				</>
			}
			{preloader && <Loader fixed />}
		</div>
	);
};

export default AssignRole;
