import clsx from "clsx"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { AuctionIndicatorWithTimer } from "@components/auction-indicator/auction-indicator"
import { Tooltip } from "@components/tooltip/tooltip"
import { auctionsStore } from "@mobx/auctions"
import Popover from "react-bootstrap/Popover"

import { LotCard } from "./lot-card"

import { phoneNormalize } from "@utils/common"
import "./auction-item.scss"

const popover = (email, phone) => (
	<Popover>
		<Popover.Body>
			<div className="d-flex">
				<div className="w-70px">Почта:</div>
				<div>
					<a href={`mailto:${email}`}>{email}</a>
				</div>
			</div>
			<div className="d-flex">
				<div className="w-70px">Телефон:</div>
				<div><a href={`tel:+${phoneNormalize(phone)}`}>+{phoneNormalize(phone)}</a></div>
			</div>
		</Popover.Body>
	</Popover>
)

export const AuctionItem = observer(
	({ auction, view, hasViewPermission, fixedHeader, isMobile }) => {
		const lotStarted = auctionsStore.lotStarted[auction.id]
		const lotFinished = auctionsStore.lotFinished[auction.id]
		const [lotTimer, setLotTimer] = useState(0)

		const isDownAuction = auction?.auction_type === "sequential_decrease"
		const lastLotEnd = moment(auction.planned_finish_at)

		const lastLotPlannedEnd = moment(
			auction.status.code === 'auction_not_active' ? auction.start_at : auction?.lots?.length === 1
				? auction?.lots[0]?.expires_at
				: auction?.lots
					.filter((x) => x.is_active)
					.sort(
						(a, b) =>
							new Date(b?.expires_at).valueOf() -
							new Date(a?.expires_at).valueOf()
					)[0]?.expires_at
		) // берем дату окончания с первого лота, так как они на всех лотах одинаковые будут

		const expiresAt = lastLotEnd && lastLotEnd.toDate()

		useEffect(() => {
			if (lotStarted) {
				if (auction.status.code !== "auction_active") {
					auctionsStore.refetchAuction(auction.id)
				}
			}
		}, [lotStarted])

		useEffect(() => {
			if (lotFinished) {
				if (isDownAuction) {
					const findLotIndex = auction.lots.findIndex(
						(item) => item.id === lotFinished
					)

					setLotTimer({
						index: findLotIndex + 1,
						timer: Date.now() + 15000
					})

					setTimeout(() => {
						setLotTimer(null)
					}, 15000)
				}
			}
		}, [lotFinished])

		return (
			<div
				id={auction.id}
				className={clsx("card-auction mt-3", {
					"card-auction_fixed": fixedHeader
				})}
			>
				<div className="card-auction__header-wrap">
					<div className="card-auction__header d-flex align-items-center justify-content-between">
						<div className="card-auction__information card-auction__information-top-bar left-side">
							<div className="auction-id">
								№{" "}
								{hasViewPermission ? (
									<Link to={`/auction/${auction.id}`}>
										{auction.id}
									</Link>
								) : (
									auction.id
								)}
							</div>
							<div className="seller">
								<Tooltip
									text="Продавец"
									isDisabled={isMobile}
								>
									<b>
										{auction?.seller_title}
									</b>
								</Tooltip>
							</div>
							<Tooltip
								text="Ответственный менеджер"
								isDisabled={isMobile}
								overlay={(auction.responsible?.email || auction.responsible?.phone) && popover(auction.responsible?.email, auction.responsible?.phone)}
								trigger={(auction.responsible?.email || auction.responsible?.phone) && "click"}
							>
								<div className="manager">
									<img src="/img/user-icon.svg" alt="" />
									<span>
										{auction.responsible?.full_name ||
											"Менеджер не указан"}
									</span>
								</div>
							</Tooltip>
							<div className="d-flex date-from-to">
								<img src="/img/icons/date.svg" alt="" />
								<div className="date">
									<span>
										{auction.start_at
											? moment(auction.start_at).format(
												"DD.MM.YY HH:mm"
											)
											: "-"}
										{expiresAt ? (
											<span>
												<span> - </span>{" "}
												{auction.lots.length
													? moment(expiresAt).format(
														"DD.MM.YY HH:mm"
													)
													: "-"}
											</span>
										) : (
											""
										)}
									</span>
								</div>
							</div>
							<Tooltip
								text="Количество лотов в аукционе"
								isDisabled={isMobile}
							>
								<div className="user lots-number">
									<img
										src="/img/icons/hammer-icon.svg"
										alt=""
									/>
									<div className="name">
										{auction.lots.length || 0}
									</div>
								</div>
							</Tooltip>
							{auction?.auction_type === "buy_now" ? (
								<div className="type-auctions">
									<Tooltip
										text="Купить сейчас. Победителем становится участник, первым купивший лот"
										isDisabled={isMobile}
									>
										<img
											src="/img/icons/rocket-icon.svg"
											alt=""
										/>
									</Tooltip>
								</div>
							) : (
								<div className="type-auctions">
									<Tooltip
										isDisabled={isMobile}
										text={
											isDownAuction
												? "Аукцион на понижение. Стоимость автомобиля понижается с заданным интервалом времени. Победителем становится участник, который первый подтвердит текущую стоимость лота"
												: "Аукцион на повышение. Победителем становится участник с наивысшей ставкой по окончании аукциона"
										}
									>
										<img
											className={
												isDownAuction
													? "type-of-auction auction-type-icon_down"
													: "type-of-auction"
											}
											src="/img/icons/type-of-auction-icon.svg"
											alt=""
										/>
									</Tooltip>
									<Tooltip
										isDisabled={isMobile}
										text={
											isDownAuction
												? "Тип завершения: последовательный. Потоковое проведение торгов на лоты, собранные в данном Аукционе"
												: "Тип завершения: параллельный. Торги заканчиваются когда отсутствуют ставки на все лоты, собранные в данном Аукционе"
										}
									>
										<img
											src={
												isDownAuction
													? "/img/icons/auction-end-type.svg"
													: "/img/icons/end-of-type-icon.svg"
											}
											alt=""
											className="type-of-auction"
										/>
									</Tooltip>
								</div>
							)}
							{Boolean(auction.status_id) && (
								<AuctionIndicatorWithTimer
									auction={auction}
									expiresAt={lastLotPlannedEnd}
									isMobile={isMobile}
								/>
							)}
							{auction?.auction_type !== "buy_now" && (
								<Tooltip
									isDisabled={isMobile}
									text={
										isDownAuction
											? "Интервал понижения"
											: "Интервал продления"
									}
								>
									<div className="d-flex seconds-wrapper align-items-center">
										<img
											src="/img/icons/watch-icon.svg"
											alt=""
										/>
										<div className="name">
											{Math.ceil(auction.interval / 60)}{" "}
											мин.
										</div>
									</div>
								</Tooltip>
							)}
						</div>
					</div>
				</div>
				<div
					className={`d-flex flex-wrap mt-3 card-auction__lots-wrapper gap-10px ${view}`}
				>
					{auction.lots.map((lot, i) => {
						if (lot?.status?.code !== "lot_sold")
							return (
								<LotCard
									lot={lot}
									auctionId={auction.id}
									auctionType={auction?.auction_type}
									sellerable={auction?.sellerable}
									isSellerHidden={auction?.is_seller_hidden}
									lotTimer={
										i === lotTimer?.index && lotTimer?.timer
									}
									view={view}
									key={`lot-${lot.id}`}
									isMobile={isMobile}
								/>
							)
					})}
				</div>
			</div>
		)
	}
)
