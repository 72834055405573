import React from "react";
import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";

import "./tooltip.scss";

export const Tooltip = ({
							text, children, trigger = undefined, overlay = (props) => (
		<BootstrapTooltip id="button-tooltip" {...props}>
			{text}
		</BootstrapTooltip>
	), isDisabled
						}) => {
	return (
		!isDisabled ? (
			<OverlayTrigger
				placement="top"
				delay={{ show: 250, hide: 400 }}
				overlay={overlay}
				trigger={trigger}
				defaultShow={false}
				onToggle={() => {
				}}
				flip={false}
				target={null}
				onHide={null}
			>
				{children}
			</OverlayTrigger>
		) : children
	);
};
