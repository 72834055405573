import { deleteRole, getRoles } from '@api/roles'
import { formToJSON } from '@utils/formToJSON'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Btn from '../../../components/btn/Btn/Btn'
import IconPlus from '../../../components/icons/IconPlus'
import Info from '../../../components/info/Info/Info'
import InfoSelect from '../../../components/info/InfoSelect/InfoSelect'
import { Loader } from '../../../components/loader/loader'
import Table from '../../../components/tables/table/Table'
import Title from '../../../components/titles/Title/Title'
import useLoaded from '../../../hooks/use-loaded'
import usePreloader from '../../../hooks/use-preloader'
import { Roles } from '../../../types/Roles'
import styles from './RolesList.module.scss'

type RolesListProps = {
	url?: string
}

const RolesList = ({ url = 'users' }: RolesListProps) => {

	const columns = [
		{
			id: 1,
			title: 'Название роли',
		},
		{
			id: 2,
			title: 'Описание',
		},
	]

	const checkboxesRef = useRef<Array<HTMLInputElement | null>>([])
	const formRef = useRef<HTMLFormElement>(null)
	const [roles, setRoles] = useState<Roles[]>([])
	const { loading, setLoaded } = useLoaded()
	const { preloader, setPreloader } = usePreloader()
	const [isEmpty, setIsEmpty] = useState(false)
	const [select, setSelect] = useState<any>([])

	useEffect(() => {
		setPreloader(true)
		getRoles().then((data: Roles[]) => {
			if (Array.isArray(data)) {
				if (!data?.length) setIsEmpty(true)
				setRoles(data.map(x => Object.assign(x, { checked: false })))
				setLoaded(true)
			}
			setPreloader(false)
		}).catch(e => e)
	}, [])

	const onSelectRole = () => {
		let formData
		if (formRef.current) {
			formData = formToJSON(formRef.current)
			if (!Object.entries(formData)?.length) {
				setSelect([])
				return false
			}
			setSelect(formData.role.map((x: string) => parseInt(x)))
		}
	}

	const onCancel = () => {
		setSelect([])
		checkboxesRef.current.forEach(x => {
			if (x) x.checked = false
		})
	}

	const onDelete = async () => {
		setPreloader(true)
		for (const x of select) {
			await deleteRole(x)
		}
		setRoles(roles.filter(y => !select.includes(y.id)))
		setSelect([])
		setPreloader(false)
	}

	const onSelectAll = (y: boolean) => {
		checkboxesRef.current.forEach(x => {
			if (x) x.checked = y
		})
		onSelectRole()
	}

	return (
		<div className={styles.RolesList}>
			{loading &&
				<>
					<div className={styles.header}>
						<Title label={'Роли и доступы'} />
						<Btn href={`/${url}/roles/add`}>
							<IconPlus />
							Добавить роль
						</Btn>
					</div>
					{isEmpty ?
						<Info
							title={'Пока не создано ни одной роли'}
							text={'Выберите Кнопку “Добавить роль” для создания новой роли'}
						/> :
						<>
							{!!select?.length &&
								<InfoSelect
									count={select?.length}
									textArray={['роль', 'роли', 'ролей']}
									onCancel={onCancel}
									onDelete={onDelete}
								/>
							}
							<form ref={formRef}>
								<Table columns={columns} onSelectCheckbox={onSelectAll}>
									{roles.map((x, index) =>
										<tr key={x.id}>
											<td>
												<input
													ref={el => checkboxesRef.current[index] = el}
													name={'role'}
													value={x.id}
													onInput={onSelectRole}
													type="checkbox"
													className="selection-input-4"
												/>
											</td>
											<td>
												<Link to={`/${url}/roles/edit/${x.id}`}>
													<b>{x.name}</b>
												</Link>
											</td>
											<td>
												{x.description}
											</td>
										</tr>,
									)}
								</Table>
							</form>
						</>}
				</>
			}
			{preloader && <Loader fixed />}
		</div>
	)
}

export default RolesList
