import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Table } from '@components/table/table';
import { CarsModal } from '@pages/auction-form/cars-modal/cars-modal';

const LOT_SALES_COLUMNS = [
  {
    dataField: 'name',
    text: 'Название',
    formatter: (cell, row) => {
      if (typeof row !== 'number') {
        return (
          <div>
            <a href={`/car/${row.car_id}`} target="_blank" rel="noreferrer">{row.car?.brand?.name}</a>
          </div>
        );
      }
    },
  },
  {
    dataField: 'vin',
    text: 'VIN',
    formatter: (cell, row) => {
      if (typeof row !== 'number') {
        return <div>{row.car?.vin}</div>;
      }
    },
  },
  {
    dataField: 'city',
    text: 'Город',
    formatter: (cell, row) => {
      return <div>{row.city?.name}</div>;
    },
  },
];

export const ExchangeLotSalesTable = observer(({ sales, onChangeSales, withCreateSale, singleSelect }) => {
  const [show, setShow] = useState(false);

  const elTable = () => {
    if (withCreateSale) {
      return (
        <Table
          data={sales || []}
          columns={LOT_SALES_COLUMNS}
        />
      );
    }

    return (
      <table className="lot__table">
        <tbody>
          {sales?.map((sale) => {
            return (
              <tr key={`car-sale-${sale.id}`}>
                <td width="255px" className="lot__table-item-tr">
                  <a href={`/car/${sale.car_id}`} target="_blank" rel="noreferrer">{sale.car?.brand?.name}</a>
                </td>
                <td width="255px" className="lot__table-item-tr">{sale.car?.vin}</td>
                <td width="255px" className="lot__table-item-tr">{sale.city?.name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-10px mt-3">
        <div className="name w-30">Автомобили в лоте</div>
        <div className="d-flex align-items-center justify-content-end header w-70">
          {withCreateSale && (
            <button onClick={() => setShow(true)} className="btn btn-primary" type="button">
              Добавить автомобиль
            </button>
          )}
        </div>
        {withCreateSale && (
          <CarsModal
            show={show}
            onHide={() => setShow(false)}
            onChangeSales={onChangeSales}
            sales={sales}
            singleSelect={singleSelect}
          />
        )}
      </div>
      {sales?.length > 0 ? elTable() : <p>Нет автомобилей в лоте</p>}
    </>
  );
});
