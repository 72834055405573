export const formatPhoneMask = (phone) => {
  if (!phone) {
    return phone;
  }
  const phoneSplit = phone.split(/^([^\s]{1})([^\s]{3})([^\s]{3})([^\s]{2})([^\s]{2})$/);
  if (phoneSplit[5] === undefined) { return phone; }
  // если телефон не подпадает под маску, то он не обрабатывается, т.к результатом преобразования ниже будет пачка переменных со значением undefined
  return (`+${phoneSplit[1]}(${phoneSplit[2]}) ${phoneSplit[3]}-${phoneSplit[4]}-${phoneSplit[5]}`);
};

export const bytesToMegaBytes = (bytes) => {
  return (bytes / (1024 * 1024)).toFixed(2);
};

export const formatToNumberWithoutSpaces = (value) => {
  if (!value || typeof value !== 'string') return value;

  return Number(value.replace(/\s+|-/g, ''));
};

export const formatValueWithSpaces = (value) => {
  if (!value) return value;

  const withoutSpaces = String(value).replace(/ /g, '');

  let newValue = '';

  for (let i = 0; i < withoutSpaces.length; i++) {
    if ((/[^0-9]+/g).test(withoutSpaces[i])) {
      break;
    } else {
      newValue += withoutSpaces[i];
    }
  }

  return newValue.replace(/(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ');
};
