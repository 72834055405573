import classNames from 'classnames'
import clsx from 'clsx'
import { ReactNode } from 'react'
import styles from "./UploadField.module.scss"

interface UploadFieldProps {
	className?: string
	label?: string
	children?: ReactNode
	required?: boolean
	isPopup?: boolean
}

const UploadField = (props: UploadFieldProps) => {
	const { className, label, required = true, isPopup, children } = props
	return (
		<div className={classNames(styles.UploadField, className)}>
			<div className={clsx(styles.name, required && styles.required)}>{label} {required && label && '*'}</div>
			<div className={clsx(styles.field, isPopup && styles.fieldFull)}>
				{children}
			</div>
		</div>
	)
}

export default UploadField