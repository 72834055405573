import { useEffect, useState } from "react"

import { CarChecks } from "@components/car-checks/car-checks"
import { TermsOfCooperationModal } from "@components/car-checks/terms-of-cooperation-modal"
import { Tooltip } from "@components/tooltip/tooltip"
import { getCurrencySymbol, phoneNormalize } from "@utils/common"
import { formatValueWithSpaces } from "@utils/format"
import moment from "moment"

import { citiesStore } from "@mobx/cities"
import "./car-summary.scss"

export const CarSummary = ({
	carSaleInfo,
	isMobile,
	city_id,
	responsible,
	lot,
	isLotSale,
	id,
	elEditButton,
	currentStatusReport
}) => {
	const { car, owner } = carSaleInfo
	const { cities } = citiesStore

	const [showModal, setShowModal] = useState(false)
	const [city, setCity] = useState("")

	useEffect(() => {
		if (!cities.length) {
			citiesStore.fetch()
		}
	}, [])

	useEffect(() => {
		if (cities.length) {
			const findCity = cities.find(x => x.id === city_id)
			setCity(findCity?.name)
		}
	}, [cities, city_id])

	const getCommission = () => {
		const currentPrice = lot.lastBet?.sum || lot.starting_price
		const commission = currentPrice * (lot.commission / 100)

		if (commission > lot.minimal_commission) {
			return commission
		}

		return lot.minimal_commission
	}

	return (
		<div className="car-summary-wrapper">
			<div className="car-summary-wrapper__auction">
				{lot?.created_at && (
					<div className="car-summary-wrapper__auction-field">
						<div className="name">Начало торгов:</div>
						<div className="value">
							{moment(lot?.created_at).format("DD.MM.YY")}
						</div>
					</div>
				)}
				{lot?.auction?.planned_finish_at && (
					<div className="car-summary-wrapper__auction-field">
						<div className="name">Завершение торгов:</div>
						<div className="value">
							{moment(lot?.auction?.planned_finish_at).format("DD.MM.YY")}
						</div>
					</div>
				)}
				<div className="car-summary-wrapper__auction-field">
					<div className="name">Марка:</div>
					<div className="value">{car?.brand?.name}</div>
				</div>
				<div className="car-summary-wrapper__auction-field">
					<div className="name">Модель:</div>
					<div className="value">{car?.model?.name}</div>
				</div>
				<div className="car-summary-wrapper__auction-field">
					<div className="name">Город:</div>
					<div className="value">{city}</div>
				</div>
				{/* <div className="car-summary-wrapper__auction-field">
					<div className="name">Продавец:</div>
					<div className="value">{owner?.display_name}</div>
				</div> */}
				{lot && (
					<div className="car-summary-wrapper__auction-field">
						<div className="name">Комиссия:</div>
						<div className="value commission cursor-pointer">
							{formatValueWithSpaces(getCommission())}{" "}
							{getCurrencySymbol()}
							<Tooltip
								text="Условия и сборы"
								isDisabled={isMobile}
							>
								<img
									src="/img/icons/commission-info.svg"
									alt=""
									onClick={() => {
										setShowModal(true)
									}}
								/>
							</Tooltip>
						</div>
					</div>
				)}
				{responsible &&
					<div className="car-summary-wrapper__auction-field">
						<div className="name">Ответственный менеджер:</div>
						<div className="value">
							{responsible?.full_name}
							<div><a href={`mailto:${responsible?.email}`}>{responsible?.email}</a></div>
							<div><a
								href={`tel:+${phoneNormalize(responsible?.phone)}`}>+{phoneNormalize(responsible?.phone)}</a>
							</div>
						</div>
					</div>
				}
			</div>
			<CarChecks
				status={carSaleInfo.status?.code}
				vin={carSaleInfo.car?.vin}
				statusReportFile={currentStatusReport?.file}
				inspectionType={carSaleInfo.inspection_type}
				isEdit={carSaleInfo.metadata?.actions?.update}
				lot={lot}
				isLotSale={isLotSale}
				isLotSaleCarPage={true}
				isMobile={isMobile}
				id={id}
				elEditButton={elEditButton}
			/>
			{showModal && (
				<TermsOfCooperationModal
					handleClose={() => setShowModal(false)}
					lot={lot}
				/>
			)}
		</div>
	)
}
