import React, { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import './button.scss';

type ButtonProps = {
	preset?: any
	size?: any
	type?: any
	className?: any
	to?: any
	href?: any
	children?: ReactNode
	onClick?(): void
}

export const Button = forwardRef(({ preset, size, href, to, children, type, className, ...rest}: ButtonProps, ref: any) => {
	const classes = clsx('btn', `btn-${preset || 'primary'}`, `btn_${size || 'l'}`, className);

	if (to) {
		return <Link to={to} className={classes} ref={ref} {...rest}>{children}</Link>;
	}

	if (href) {
		return <a href={href} className={classes} ref={ref} {...rest}>{children}</a>;
	}

	return (
		// eslint-disable-next-line react/button-has-type
		<button type={type || 'button'} className={classes} ref={ref} {...rest}>{children}</button>
	);
});
