import { Cookies } from 'react-cookie';

import { GET, POST, request } from './request';

export const getXSRFTokenFromCookies = () => {
  const _cookies = new Cookies();
  return _cookies.get('XSRF-TOKEN'); // если в куках нет токена, возвращается undefined
};

export const getXCSRFfToken = () => {
  return GET('auth/csrf-cookie');
};

export const postLogout = () => {
  return POST('auth/logout');
};

export const spaAuth = (data: any) => {
  return request('auth/spa', 'post', { data }, { skipRedirect401: true });
};

export const postStopImpersonation = () => {
  return POST('auth/impersonation/stop');
};

export const postImpersonationCompany = (data: any) => {
  return POST('auth/impersonation/company', data);
};

export const getResentVerificationEmail = () => {
  return GET('auth/resend-verification-email');
};
