import clsx from "clsx"
import styles from "./ReportSelected.module.scss"

interface SelectedReportProps {
	sale_id: number
	status_report_id: number
	closed_report: string
	closed_at: string
}

interface ReportSelectedProps {
	className?: string
	report: SelectedReportProps
	isDeleting?: boolean
	onRemove(id: number): void
}

const ReportSelected = (props: ReportSelectedProps) => {
	const { className, report, isDeleting, onRemove } = props
	const onRemoveHandler = () => {
		onRemove(report.status_report_id)
	}

	return (
		<div className={clsx(styles.ReportSelected, className)}>
			<div>
				№{report.status_report_id}
			</div>
			<div>
				{report.closed_at}
			</div>
			<div>
				{report.closed_report}
			</div>
			<div>
				{isDeleting && <img src="/img/icons/remove-icon.svg" onClick={onRemoveHandler} />}
			</div>
		</div>
	)
}

export default ReportSelected
