import React, { useEffect, useState } from 'react';
import ContainerPage from '../../components/Containers/ContainerPage/ContainerPage';
import { Companies } from './companies';
import { meStore } from '../../mobx/me';
import RolesList from '../role/RolesList/RolesList';
import TabHeader from '../../components/tabs/TabHeader/TabHeader';
import TabButton from '../../components/tabs/TabButton';
import { useLocation, useNavigate } from 'react-router-dom';

const CompaniesPage = () => {

	const { state } = useLocation();
	const navigate = useNavigate()
	const [activeTabId, setActiveTabId] = useState(0)
	const tabHeadings = [
		{
			id: 0,
			title: 'Компании',
			content: <Companies />
		}
	];

	if(meStore.hasPermission('roles.view')) {
		tabHeadings.push({
			id: 1,
			title: 'Роли',
			content: <RolesList url={'companies'} />
		})
	}

	useEffect(() => {
		if(state && state?.key === 'roles') {
			setActiveTabId(1)
			navigate(window.location.pathname, { replace: true })
		}
	}, []);

	const onSelectTab = (id: number) => {
		setActiveTabId(id)
	}

	return (
		<ContainerPage>
			<TabHeader>
				{tabHeadings.map(x =>
					<TabButton
						key={x.id}
						title={x.title}
						onClick={() => onSelectTab(x.id)}
						active={x.id === activeTabId}
					/>
				)}
			</TabHeader>
			{tabHeadings[activeTabId].id === activeTabId && tabHeadings[activeTabId].content}
		</ContainerPage>
	);
};

export default CompaniesPage;
