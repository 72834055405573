import React, { useState } from 'react';

import { Button } from '@components/button/button';

import './confirm-popup.scss';

export const FindLots = ({ isConfirmLot, onStepChange, step, stepsNumber, handleClose, setInputSearch }) => {
  const [form, setForm] = useState('');

  const onChangeVin = (e) => {
    setForm({ ...form, vin: e.target.value });
  };

  const onSubmit = () => {
    const vin = { 'vin': form.vin.replaceAll('\n', ',').split(',') };
    setInputSearch(vin);
    onStepChange();
  };

  return (
    <div>
      <div className="lots-confirm-popup__header">
        <div>
          <div className="lots-confirm-popup__header-text">
            {isConfirmLot ? 'Подтверждение' : 'Отклонение'}  продажи по VIN...
          </div>
          <div className="lots-confirm-popup__header-text-step">Шаг {step} из {stepsNumber}</div>
        </div>
        <img src="/img/icons/close-icon.svg" alt="" onClick={() => handleClose()} className="cursor-pointer" />
      </div>
      <div className="lots-confirm-popup__form">
        <div className="w-50">
          <div className="name">VIN-номера</div>
          <div className="lots-confirm-popup__form-description">
            Скопируйте из файла или укажите номера в столбик
          </div>
        </div>
        <div className="w-50">
          <textarea
            name="vin"
            value={form.vin}
            onChange={(e) => onChangeVin(e)}
            className="lots-confirm-popup__form-textarea"
          />
          <Button onClick={onSubmit} preset="primary" className="w-100 mt-16px">
            Найти лоты
          </Button>
        </div>
      </div>
    </div>
  );
};
