import React from 'react';
import moment from 'moment';

import { getFileExtension } from '@utils/common';

export const SELLERS_COLUMNS = [
  {
    dataField: 'type',
    text: 'Тип отчета',
    formatter: (cell, row) => <div className="text-name-info">{row?.type}</div>,
  },
  {
    dataField: 'File',
    text: 'Продавец',
    formatter: (cell, row) => {
      return <div>{row?.seller?.title}</div>;
    },
  },
  {
    dataField: 'periodType',
    text: 'Период отчета',
    formatter: (cell, row) => (
      <>
        <div>{row.params?.from && moment(row.params?.from).format('DD.MM.YYYY')} - </div>
        <div>{row.params?.to && moment(row?.params?.to).format('DD.MM.YYYY')}</div>
      </>
    ),
  },
  {
    dataField: 'created_at',
    text: 'Создан',
    sort: true,
    formatter: (cell, row) => (
      <>
        <div>{row.created_at && moment(row.created_at).format('DD.MM.YYYY')}</div>
        <div className="text-extra-info">{row.created_at && moment(row.created_at).format('HH:mm')}</div>
      </>
    ),
  },
  {
    dataField: 'finished_at',
    text: 'Хранится до',
    formatter: (cell, row) => {
      const expDate = moment(row.created_at).add(30, 'days');

      return (
        <>
          <div>{expDate.format('DD.MM.YYYY')}</div>
          <div className="fs-12px text-secondary">{expDate.format('HH:mm')}</div>
        </>
      );
    },
  },
  {
    dataField: 'user',
    text: 'Пользователь',
    formatter: (cell, row) => <div>{row?.created_by?.full_name}</div>,
  },
  {
    dataField: 'file',
    text: 'Файл с отчётом',
    formatter: (cell, row) => (
      <div>
        {row?.file?.url ? (
          <>
            <a
              href={row.file.url}
              target="_blank"
              className="d-flex align-items-center text-name-info"
              rel="noreferrer"
              download={`${row.type} ${row.seller.display_name} ${row.params.from} - ${row.params.to}.${getFileExtension(row.file.url)}`}
            >
              <img src="/img/icons/download-file-icon.svg" alt="" /> Скачать XLS
            </a>
            <div className="text-extra-info">{Math.round(row.file.size / 1024)} Kb</div>
          </>
        ) : (
          'Нет'
        )}
      </div>
    ),
  },
];

export const CARS_COLUMNS = [
  {
    dataField: 'vin',
    text: 'VIN',
    formatter: (cell, row) => {
      const vinLength = row?.params?.vin?.length;
      return (
        <div>
          <div className="text-name-info">{row?.params?.vin[0]}</div>
          {vinLength > 1 && <div className="text-extra-info">и еще {vinLength - 1}</div>}
        </div>
      );
    },
  },
  {
    dataField: 'File',
    text: 'Продавец',
    formatter: (cell, row) => <div>{row?.seller?.title}</div>,
  },
  {
    dataField: 'created_at',
    text: 'Создан',
    sort: true,
    formatter: (cell, row) => (
      <div>
        <div>{row.created_at && moment(row.created_at).format('DD.MM.YYYY')}</div>
        <div className="text-extra-info">{row.created_at && moment(row.created_at).format('HH:mm')}</div>
      </div>
    ),
  },
  {
    dataField: 'finished_at',
    text: 'Хранится до',
    formatter: (cell, row) => {
      const expDate = moment(row.created_at).add(30, 'days');

      return (
        <>
          <div>{expDate.format('DD.MM.YYYY')}</div>
          <div className="fs-12px text-secondary">{expDate.format('HH:mm')}</div>
        </>
      );
    },
  },
  {
    dataField: 'user',
    text: 'Пользователь',
    formatter: (cell, row) => <div>{row?.created_by?.full_name}</div>,
  },
  {
    dataField: 'file',
    text: 'Файл с отчётом',
    formatter: (cell, row) => (
      <div>
        {row?.file?.url ? (
          <>
            <a
              href={row.file.url}
              target="_blank"
              className="d-flex align-items-center text-name-info"
              rel="noreferrer"
              download={`${row.type} ${row.seller.display_name}.${getFileExtension(row.file.url)}`}
            >
              <img src="/img/icons/download-file-icon.svg" alt="" /> Скачать XLS
            </a>
            <div className="text-extra-info">{Math.round(row.file.size / 1024)} Kb</div>
          </>
        ) : (
          'Нет'
        )}
      </div>
    ),
  },
];
