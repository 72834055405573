import React, { useState } from "react";
import Comment from "../../components/utils/Comment/Comment";
import { userUpdateRequest } from "@api/users";
import { postEditCompany } from "@api/companies";

type CommentEditProps = {
	id: string | undefined
	comment: string
	type: "company" | "user"
}

const LABELS = {
	company: "Комментарий о компании",
	user: "Комментарий о пользователе"
};

const CommentEdit = (props: CommentEditProps) => {
	const { id, type = "user", comment } = props;
	const [pending, setPending] = useState(false);
	const [saved, setSaved] = useState(false);

	const onSubmit = async (value: string) => {
		setPending(true);
		setSaved(false);

		if (id) {
			type === "user" ? await userUpdateRequest(id, {
				admin_comment: value
			}) : await postEditCompany(id, {
				admin_comment: value
			});
		}

		setPending(false);
		setSaved(true);
	};

	return (
		<Comment
			label={LABELS[type]}
			saved={saved}
			pending={pending}
			comment={comment}
			onSubmit={onSubmit}
		/>
	);
};

export default CommentEdit;
