import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import React from 'react';

export const UP_AUCTION = 'UP_AUCTION';
export const DOWN_AUCTION_DUTCH = 'DOWN_AUCTION_DUTCH';
export const BUY_NOW = 'BUY_NOW';

export const PARALLEL_INCREASE = 'PARALLEL_INCREASE';
export const SEQUENTIAL_DECREASE = 'SEQUENTIAL_DECREASE';

export const AUCTION_NAMES = {
  PARALLEL_INCREASE: 'На повышение',
  SEQUENTIAL_DECREASE: 'На понижение',
  BUY_NOW: 'Buy now',
};

const userFormatter = (cell, row) => {
  return (
    <div>
      <Link to={`/user/${row.id}`}>
        {row.owner_model.first_name} {row.owner_model.last_name} {row.owner_model.middle_name ? row.owner_model.middle_name : ''}
      </Link>
    </div>
  );
};

const dateFormatter = (cell, row) => {
  return <div>{moment(row.owner_model.created_at).format('DD.MM.YYYY')}</div>;
};

export const COLUMNS = [
  {
    dataField: 'first_name',
    text: 'ФИО',
    formatter: userFormatter,
  },
  {
    dataField: 'name',
    text: 'Название',
    formatter: (cell, row) => row.owner_model.name,
  },
  {
    dataField: 'phone',
    text: 'Телефон',
    formatter: (cell, row) => row.owner_model.phone,
  },
  {
    dataField: 'created_at',
    text: 'Дата создания',
    formatter: dateFormatter,
    headerClasses: 'id-custom-cell',
  },
];
