import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { observer } from 'mobx-react-lite';

import { Send } from '@api/request';
import { Input } from '@components/input/input';
import { NO_AUTH_URL } from '@utils/common';
import { dictionariesStore } from '@mobx/dictionaries';
import { citiesStore } from '@mobx/cities';
import { Select } from '@components/select/select';
import { Button } from '@components/button/button';

export const SignupIndividual = observer(() => {
  const {
    register, setValue, getValues, handleSubmit, watch, control, formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      company_title: '',
      city_id: '',
      conditions: '',
      roles: [],
      legal_form_id: '',
    },
  });
  const [typeFieldPassword, setTypeFieldPassword] = useState('password');
  const [classFieldPassword, setClassFieldPassword] = useState('hide-password-input');
  const [serverError, setServerError] = useState('');
  const [isShowFlash, setIsShowFlash] = useState(false);
  const [textFlash, setTextFlash] = useState('');
  const [valuesErrors, setValuesErrors] = useState({});
  const { cities } = citiesStore;
  const values = watch();

  useEffect(() => {
    if (!citiesStore.cities.length) {
      citiesStore.fetch();
    }

    if (!dictionariesStore.dictionaries.legal_forms) {
      dictionariesStore.request('legal_forms');
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setValuesErrors({});
    }, 5000);

    return () => clearTimeout(timer);
  }, [values]);

  const hidePassword = () => {
    if (typeFieldPassword === 'text') {
      setClassFieldPassword('hide-password-input');
      setTypeFieldPassword('password');
    } else {
      setClassFieldPassword('hide-password-input-active');
      setTypeFieldPassword('text');
    }
  };

  const onClassNameChange = (field, error, serverError) => {
    if (field === 'email') {
      if (valuesErrors?.email) {
        return 'border-red';
      }

      if (values.email === '') {
        return '';
      }

      if (!values.email?.includes('@') || !values.email?.includes('.')) {
        return 'border-red';
      } if (values.email?.includes('@') && values.email?.includes('.')) {
        return 'border-green';
      }
    }

    if (error || serverError) {
      return 'border-red';
    } if (values[field] !== '') {
      return 'border-green';
    }
    return '';
  };

  const elInputStatus = (name, error, serverError) => {
    if (serverError) {
      return <span className="form-no-validate" />;
    }

    if (name === 'roles') {
      if (serverError?.roles) {
        return <span className="form-no-validate" />;
      }
      return '';
    }

    if (name === 'email') {
      if (serverError?.email) {
        return <span className="form-no-validate" />;
      }

      if (values.email === '') {
        return '';
      } if (!values.email?.includes('@') || !values.email?.includes('.')) {
        return <span className="form-no-validate" />;
      } if (values.email?.includes('@') && values.email?.includes('.')) {
        return <span className="form-validate" />;
      }
    }

    if (values[name] !== '') {
      return <span className="form-validate" />;
    } if (error) {
      return <span className="form-no-validate" />;
    }
    return '';
  };

  const onFormSubmit = (data) => {
    const convert = data;
    convert.phone = convert.phone.replace(/\D/g, '');
    const urlQuery = 'auth/registration';

    Send(urlQuery, 'POST', convert).then((data) => {
      setServerError('');
      setValuesErrors({});
      if (data.errors) {
        setValuesErrors(data.errors);
        setServerError('Что-то пошло не так!');
      } else if (data.message === '') {
        setServerError('Что-то пошло не так!');
        setIsShowFlash(false);
      } else {
        setTextFlash('Для завершения регистрации необходимо подтвердить ваш Email. Ссылка для подтверждения отправлена на указанную при регистрации почту');
        setIsShowFlash(true);
      }
    });
  };

  return (
    <>
      {isShowFlash && <div className="alert alert-success">{textFlash}</div>}
      {serverError && <div className="alert alert-danger">{serverError}</div>}
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mb-3 position-relative">
          <Input
            className={onClassNameChange('last_name', errors.last_name, valuesErrors.last_name)}
            placeholder="Фамилия *"
            {...register('last_name', {
              required: true,
            })}
          />
          {elInputStatus('last_name', errors.last_name, valuesErrors.last_name)}
          {valuesErrors?.last_name && <span className="error-message">{valuesErrors.last_name}</span>}
          {errors?.last_name && <span className="error-message">Укажите фамилию</span>}
        </div>
        <div className="mb-3 position-relative">
          <Input
            className={onClassNameChange('first_name', errors.first_name, valuesErrors.first_name)}
            placeholder="Имя *"
            {...register('first_name', {
              required: true,
            })}
          />
          {elInputStatus('first_name', errors.first_name, valuesErrors.first_name)}
          {valuesErrors?.first_name && <span className="error-message">{valuesErrors.first_name}</span>}
          {errors?.first_name && <span className="error-message">Укажите имя</span>}
        </div>
        <div className="mb-3 position-relative">
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <InputMask
                  className={`input input_l ${onClassNameChange('phone', errors.phone, valuesErrors.phone)}`}
                  mask="+7(999) 999-99-99"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  placeholder="Телефон*"
                  refs={{ ...register('phone', {
                    required: true,
                  }) }}
                />
              );
            }}
          />
          {elInputStatus('phone', errors.phone, valuesErrors.first_name)}
          {valuesErrors?.phone && <span className="error-message">{valuesErrors.phone}</span>}
          {errors?.phone && <span className="error-message">Укажите номер телефона</span>}
        </div>
        <div className="mb-3 position-relative">
          <Input
            className={onClassNameChange('email', errors.email, valuesErrors.first_name)}
            placeholder="E-mail *"
            autoComplete="new-password"
            {...register('email', {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
              },
            })}
          />
          {elInputStatus('email', errors.email, valuesErrors.email)}
          {valuesErrors?.email && <span className="error-message">{valuesErrors.email}</span>}
          {errors?.email && <span className="error-message">Укажите корректный E-mail</span>}
        </div>
        <div className="mb-3 position-relative">
          <Input
            type={typeFieldPassword}
            name="password"
            className={onClassNameChange('password', errors.password, valuesErrors.password)}
            placeholder="Пароль *"
            autoComplete="new-password"
            {...register('password', {
              required: true,
            })}
          />
          {elInputStatus('password', errors.password, valuesErrors.password)}
          {valuesErrors?.password && <span className="error-message">{valuesErrors.password}</span>}
          {errors?.password && <span className="error-message">Укажите пароль</span>}
          <span onClick={hidePassword} className={classFieldPassword} />
        </div>
        <div className="mb-3 position-relative">
          <Input
            name="company_title"
            className={onClassNameChange('company_title', errors.company_title, valuesErrors.company_title)}
            placeholder="Компания"
            {...register('company_title', {
              required: true,
            })}
          />
          {elInputStatus('company_title', errors.company_title, valuesErrors.company_title)}
          {valuesErrors?.company_title && <span className="error-message">{valuesErrors?.company_title}</span>}
          {errors?.company_title && <span className="error-message">Укажите компанию</span>}
        </div>
        <div className="mb-3 position-relative">
          <Select
            options={cities?.map((city) => ({ value: city.id, label: city.name }))}
            valueId={getValues?.city_id}
            size="l"
            placeholder="Город"
            className={onClassNameChange('city_id', errors.city_id)}
            onChange={({ value }) => setValue('city_id', value)}
          />
          {elInputStatus('city_id', errors.city_id, valuesErrors.city_id)}
          {valuesErrors?.city_id && <span className="error-message">{valuesErrors?.city_id}</span>}
          {errors?.city_id && <span className="error-message">Укажите город</span>}
        </div>
        <div className="mb-3 position-relative">
          <Select
            options={dictionariesStore.dictionaries?.legal_forms?.map((item) => ({ value: item.value, label: item.label }))}
            valueId={getValues?.legal_form_id}
            size="l"
            placeholder="Правовая форма"
            className={onClassNameChange('legal_form_id', errors.legal_form_id)}
            onChange={({ value }) => setValue('legal_form_id', value)}
          />
          {elInputStatus('legal_form_id', errors.roles, valuesErrors.legal_form_id)}
        </div>
        <div className="mb-3 mt-3 position-relative d-flex roles-container">
          <div className="form-check-inline col-md-5 d-flex align-items-center">
            <input
              type="checkbox"
              value="buyer"
              id="checkboxBuyer"
              {...register('roles')}
            />
            <label className="form-check-label" htmlFor="checkboxBuyer">
              Покупатель
            </label>
          </div>
          <div className="form-check-inline d-flex align-items-center">
            <input
              type="checkbox"
              value="seller"
              id="checkboxSeller"
              {...register('roles')}
            />
            <label className="form-check-label" htmlFor="checkboxSeller">
              Продавец
            </label>
          </div>
          {elInputStatus('roles', errors.roles, valuesErrors.roles)}
          {valuesErrors?.roles && <span className="error-message">{valuesErrors?.roles}</span>}
        </div>
        <div className="line-hr" />
        <div className="mb-3 position-relative">
          <div className="d-flex conditions">
            <input
              type="checkbox"
              value="true"
              id="remember_me"
              {...register('conditions', {
                required: true,
              })}
            />
            <label className="form-check-label remember_me" htmlFor="remember_me">
              Согласие с{' '}
              <NavLink className="link-rules" to="#">
                правилами сервиса
              </NavLink>
            </label>
          </div>
          {errors?.conditions && <span style={{ top: '3px' }} className="form-no-validate" />}
          {errors?.conditions && <span className="error-message">Укажите согласны ли вы с правилами сервиса</span>}
        </div>
        <div className="mb-3">
          <Button preset="primary" type="submit" className="w-100" disabled={!isValid}>
            Зарегистрироваться
          </Button>
        </div>
        <div className="mb-3">
          <NavLink to={NO_AUTH_URL} className="link-dark link-style">
            Войти <img className="arrow" src="/img/vector.svg" alt="vector" />
          </NavLink>
          <NavLink
            style={{ float: 'right' }}
            to="/auth/recovery"
            className="link-dark link-style"
          >
            Забыли пароль?
          </NavLink>
        </div>
      </form>
    </>
  );
});
