import { GET } from './request';

export const getDictionaries = (key, params) => {
  return GET(`dictionaries/${key}/values`, params);
};

export const getSaleBrandsRequest = (car_type_key) => {
  return GET(`dictionaries/car_brands/values?filters[car_type_key]=${car_type_key}`);
};

export const getSaleModelsRequest = (car_brand_id = 1, car_type_key = 'car') => {
  return GET(`dictionaries/car_models/values?filters[car_brand_id]=${car_brand_id}&filters[car_type_key]=${car_type_key}`);
};
