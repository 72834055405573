import React, { useState } from "react";
import styles from "./Comment.module.scss";
import clsx from "clsx";
import Btn from "../../btn/Btn/Btn";
import IconCheck from "../../icons/IconCheck";
import IconPreloader from "../../icons/IconPreloader";
import TextArea from "../../form/TextArea/TextArea";

type CommentProps = {
	label: string
	saved: boolean
	pending: boolean
	comment: string
	onSubmit(m: string): void
}

const Comment = (props: CommentProps) => {
	const { label, saved, pending, comment, onSubmit } = props;
	const [message, setMessage] = useState(comment);

	const onInput = (value: string) => {
		setMessage(value);
	};

	const onSubmitHandler = () => {
		onSubmit(message);
	};

	return (
		<div className={clsx(styles.Comment)}>
			<div className={styles.label}>
				{label}
			</div>
			<div className={styles.section}>
				<div className={styles.block}>
					<div className={styles.icon}>
						{saved && <IconCheck />}
						{pending && <IconPreloader />}
					</div>
					<TextArea
						name={"msg"}
						className={styles.textarea}
						defaultValue={message}
						onInput={onInput}
					/>
				</div>
				<Btn
					size={"small"}
					type={"button"}
					color={"white"}
					onClick={onSubmitHandler}
				>
					Сохранить
				</Btn>
			</div>
		</div>
	);
};

export default Comment;
