export const tabsNames = {
  description: 'Спецификация',
  equipment: 'Комплектация',
  reports: 'Отчеты',
  documents: 'Документы',
  changes: 'История изменений',
  tradesHistory: 'История торгов',
  tires: 'Колеса',
  photos: 'Фотографии',
  damage: 'Повреждения',
  technicalCheck: 'Техпроверка',
};

export const getAllTabs = (fields) => {
  const newTabs = { ...tabsNames };

  if (!fields.includes(tabsNames.completeness)) {
    delete newTabs.completeness;
  }

  return Object.entries(newTabs).map((value) => ({ label: value[1], value: value[1], key: value[0] }));
};

export const getFilteredField = (fields, name) => {
  const filteredFields = fields?.filter((field) => {
    if ((field.type === 'bool' && field?.saved_value?.value)
      || field.name === 'ПТС'
      || field.name === 'Свидетельство о регистрации') {
      return field;
    }

    if (field.type === 'array' && field.saved_value?.value?.length) {
      return field;
    }

    if (field.type !== 'bool' && field.type !== 'array' && field.saved_value?.value) {
      return field;
    }
  });

  return { name, fields: filteredFields };
};

export const getFieldValue = (type, value, name) => {
  if (type === 'bool' && (name === 'ПТС' || name === 'Свидетельство о регистрации')) {
    return value ? 'Да' : 'Нет';
  }
  if (type === 'array' && value) {
    return `(${value?.join(', ')})`;
  }
  if (type === 'bool') {
    return;
  }

  return value && `(${value})`;
};

export const groupFields = () => {
  let groupIndex = 0;

  return (data, field, index) => {
    if (index === groupIndex) groupIndex += 2;
    // eslint-disable-next-line no-param-reassign
    if (data[groupIndex]) data[groupIndex] = [...data[groupIndex], field];
    // eslint-disable-next-line no-param-reassign
    else data[groupIndex] = [field];
    return data;
  };
};
