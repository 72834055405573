import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import './tooltip.scss';

const QuestionTooltip = ({ text }) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={(props) => (
        <Tooltip id="button-tooltip" {...props}>
          {text}
        </Tooltip>
      )}
      trigger={undefined}
      defaultShow={false}
      onToggle={() => {}}
      flip={false}
      target={null}
      onHide={null}
    >
      <div className="tooltip-wrapper">
        <img src="/img/question.svg" alt="" />
      </div>
    </OverlayTrigger>
  );
};

export default QuestionTooltip;
