import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export const ModalConfirm = (props) => {
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		if (!props.isDirty) {
			props.onClose();
		} else {
			setChecked(true);
		}
	}, []);

	if (!checked) return null;

	return (
		<Modal
			show={true}
			onHide={props.onClose}
			className="car__modal-confirm"
		>
			<div>Сохранить изменения?</div>
			<div className="mt-20px d-flex gap-20px">
				<button
					onClick={() => {
						props.onSave();
						props.onClose();
					}}
					className="btn btn-primary"
					type="button"
				>
					Сохранить
				</button>
				<button onClick={props.onClose} className="btn" type="button">
					Отменить
				</button>
			</div>
		</Modal>
	);
};
