import { getGroupUsersRequest } from "@api/user-groups";
import clsx from "clsx";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "../../../components/pagination/pagination";
import { Table } from "../../../components/table/table";
import cls from "./AuctionUsers.module.scss";

const PER_PAGE = [25, 50, 100, 250, 500];

const columns = [
	{
		dataField: "first_name",
		text: "ФИО",
		sort: true,
		formatter: (cell: any, row: any) => (
			<Link to={`/user/${row.id}`}>
				{row.first_name} {row.last_name}{" "}
				{row.middle_name ? row.middle_name : ""}
			</Link>
		),
	},
	{
		dataField: "phone",
		text: "Телефон",
		sort: true,
	},
	{
		dataField: "status",
		text: "Статус",
		sort: true,
		formatter: (cell: any, row: any) => (row.status ? row.status.name : ""),
	},
	{
		dataField: "created_at",
		text: "Дата создания",
		formatter: (cell: any, row: any) =>
			moment(row.created_at).format("DD.MM.YYYY"),
		sort: true,
	},
];

interface AuctionUsersProps {
	className?: string;
	id: number | null;
}

interface RequestUsersParams {
	page: number;
	per_page: number;
}

const AuctionUsers = (props: AuctionUsersProps) => {
	const { className, id, ...otherProps } = props;

	const [users, setUsers] = useState<any>(null);

	const requestUsers = (params?: RequestUsersParams) => {
		getGroupUsersRequest(id, params).then(setUsers);
	};

	useEffect(() => {
		if (id) requestUsers();
	}, [id]);

	return (
		<div className={clsx(cls.AuctionUsers)} {...otherProps}>
			{!!users?.data?.length ? (
				<>
					<Table data={users?.data} columns={columns} />
					<Pagination
						data={users}
						perPageList={PER_PAGE}
						onChange={requestUsers}
						onChangePage={() => console.log("onChangePage")}
						onChangePerPage={() => console.log("onChangePerPage")}
						className={""}
						hideInfo={false}
					/>
				</>
			) : (
				<div>В данной группе нет пользователей</div>
			)}
		</div>
	);
};

export default AuctionUsers;
