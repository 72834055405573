import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { Link, Navigate, useNavigate, useParams } from "react-router-dom"

import {
	getCompaniesUsersRequest,
	getSingleCompanyRequest
} from "@api/companies"
import { getRequisitesTemplates } from "@api/users"
import { DropdownButton } from "@components/dropdown/dropdown-button"
import { Loader } from "@components/loader/loader"
import { Table } from "@components/table/table"
import { UsersDepartmentModal } from "@components/users-departments-modal/users-department-modal"
import { COLUMNS } from "@components/users-departments-modal/utils"
import { meStore } from "@mobx/me"
import { NO_PERMISSION_URL, phoneNormalize } from "@utils/common"
import UploadDocument from '../../components/upload-documents/UploadDocument/UploadDocument'

import CommentEdit from "@components/CommentEdit/CommentEdit"
import { citiesStore } from "@mobx/cities"
import RoleViewAdmin from "@pages/role/RoleViewAdmin/RoleViewAdmin"
import "./company.scss"

export const Company = observer(() => {
	const navigate = useNavigate()
	const { id } = useParams()

	const [company, setCompany] = useState()
	const [city, setCity] = useState("-")
	const [users, setUsers] = useState()
	const [fields, setFields] = useState([])
	const [show, setShow] = useState(false)
	const [isRolesAdd, setIsRolesAdd] = useState(false)

	const requestUsers = () => {
		getCompaniesUsersRequest(id).then(setUsers)
	}

	const handleClose = (needRequest) => {
		if (needRequest) {
			requestUsers()
		}

		setShow(false)
	}
	const handleShow = () => setShow(true)

	useEffect(() => {
		(async () => {
			if (!citiesStore.cities.length) await citiesStore.fetch()

			getSingleCompanyRequest(id).then((data) => {
				setCompany(data)
				if (data.city_id)
					setCity(
						citiesStore.cities.find((x) => x.id === data.city_id)
							.name
					)
				if (meStore.hasPermission("roles.assign")) setIsRolesAdd(true)
			})
			requestUsers()

			getRequisitesTemplates().then((resp) => {
				if (resp?.legal?.fields) {
					setFields(Object.values(resp.legal.fields))
				}
			})
		})()
	}, [])

	if (meStore.noPermission("admin.companies.view")) {
		return <Navigate to={NO_PERMISSION_URL} />
	}

	if (meStore.data === null || !company) {
		return <Loader />
	}

	const renderDynamicField = (item) => {
		return (
			<div
				key={item.key}
				className="company-info__field-wrapper d-grid grid-columns-30-70"
			>
				<div className="name">{item.name}</div>
				<div className="value">
					{company?.requisite?.[item.key] || "-"}
				</div>
			</div>
		)
	}

	return (
		<div className="company-wrapper">
			<div className="actions d-flex align-items-center justify-content-between">
				<div className="title">{company.title}</div>
				<Link to={`/company/${id}/edit`} className="btn btn-primary">
					Редактировать
				</Link>
			</div>
			<div className="company-info mt-3">
				<div className="d-grid grid-columns-30-70 company-info__field-wrapper">
					<div className="name">Название организации</div>
					<div className="value">{company.title}</div>
				</div>
				<div className="d-grid grid-columns-30-70 company-info__field-wrapper">
					<div className="name">Тип компании</div>
					<div className="value">
						{company.type_id ? company.type?.name : "-"}
					</div>
				</div>
				<div className="d-grid grid-columns-30-70 company-info__field-wrapper">
					<div className="name">Ответственный менеджер</div>
					<div className="value">
						{company.requisite_id ? (
							<>
								{company.responsible?.full_name}
								{company?.responsible?.email && <div><a
									href={`mailto:${company?.responsible?.email}`}
								>
									{company?.responsible?.email}
								</a></div>}
								{company?.responsible?.phone && <div>
									<a
										href={`tel:+${phoneNormalize(
											company.responsible?.phone
										)}`}
									>
										+
										{phoneNormalize(
											company.responsible?.phone
										)}
									</a>
								</div>}
							</>
						) : "-"}
					</div>
				</div>
				<div className="d-grid grid-columns-30-70 company-info__field-wrapper">
					<div className="name">Логотип</div>
					<div className="value">
						{company?.logo?.url ? (
							<a
								href={company.logo.url}
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={company.logo.url}
									alt={company.logo.original_name}
									className="image"
								/>
							</a>
						) : (
							"-"
						)}
					</div>
				</div>
			</div>

			{fields.length > 0 && (
				<div className="mt-4">
					<div className="title">Реквизиты</div>
					<div className="company-info mt-3">
						<div className="d-grid grid-columns-30-70 company-info__field-wrapper">
							<div className="name">Город</div>
							<div className="value">{city}</div>
						</div>
						{fields.map((item) =>
							item.key === "agent_contract" ? (
								<div
									key={item.key}
									className="d-grid grid-columns-30-70 company-info__field-wrapper"
								>
									<div className="name">Документ</div>
									<div className="value">
										{company?.requisite?.agent_contract
											?.url ? (
											<a
												href={
													company.requisite
														.agent_contract.url
												}
												download={
													company.requisite
														.agent_contract
														.original_name
												}
											>
												{
													company.requisite
														.agent_contract
														.original_name
												}
											</a>
										) : (
											"-"
										)}
									</div>
								</div>
							) : (
								renderDynamicField(item)
							)
						)}
					</div>
				</div>
			)}

			{id && <UploadDocument apiPath='companies' id={parseInt(id)} />}

			{meStore.hasPermission("admin.companies.edit") &&
				<div className="mt-4">
					<div className="title">Комментарий</div>
					<div className="mt-3">
						<CommentEdit id={id} comment={company?.admin_comment} type={"company"} />
					</div>
				</div>
			}

			<div className="mt-4">
				<div className="d-flex align-items-center justify-content-between ">
					<div className="title">Пользователи</div>
					{meStore.hasPermission("admin.companies.edit") && (
						<DropdownButton
							btnText="Действия"
							options={[
								{
									label: "Изменить пользователей",
									value: "add",
									onClick: handleShow
								},
								{
									label: "Создать пользователя",
									value: "create",
									onClick: () => {
										navigate(
											`/users/create?department_id=${id}`
										)
									}
								}
							]}
						/>
					)}
				</div>
				{show && (
					<UsersDepartmentModal
						type="company"
						company_id={company?.id}
						show={true}
						onHide={handleClose}
						selected={users?.data?.map((item) => item.id)}
					/>
				)}
				<div className="mt-3">
					{users?.data?.length > 0 ? (
						<Table data={users.data} columns={COLUMNS} />
					) : (
						<p>Нет пользователей</p>
					)}
				</div>
				{isRolesAdd && (
					<RoleViewAdmin
						url={`/company/${company.id}`}
						items={company.roles}
						isCompany={true}
						info={"У компании пока нет доступов"}
					/>
				)}
			</div>
		</div>
	)
})
