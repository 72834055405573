import { makeAutoObservable, runInAction } from "mobx";

import { getDictionaries } from "@api/dictionaries";
import { getOption } from "@utils/common";

class DictionariesStore {
	constructor() {
		this.dictionaries = {};
		makeAutoObservable(this);
	}

	async request(dictionaryKey, config) {
		const { filters, params, saveKey } = config || {};
		const newParams = {};

		if (filters) {
			for (const [key, value] of Object.entries(filters)) {
				if (value) {
					newParams[`filters[${key}]`] = value;
				}
			}
		}

		if (params) {
			for (const [key, value] of Object.entries(params)) {
				if (value) {
					newParams[key] = value;
				}
			}
		}

		await getDictionaries(dictionaryKey, newParams).then((resp) => {
			runInAction(() => {
				this.dictionaries[saveKey || dictionaryKey] =
					resp.map(getOption);
			});
		});
	}

	async clear(dictionaryKey) {
		runInAction(() => {
			this.dictionaries[dictionaryKey] = null;
		});
	}
}

export const dictionariesStore = new DictionariesStore();
