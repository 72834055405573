import axios from 'axios';

import { SOCKET_URL } from './config';

export async function SocketRequest(action = '', method, data = {}, allowCredentials = true) {
  const config = {
    method,
    url: SOCKET_URL + action,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(data),
    withCredentials: allowCredentials,
  };
  const response = await axios(config)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return error.response.data;
    });

  return requestHandler(response);
}

export function requestHandler(response) {
  switch (response.status) {
    case 422:
      return response.json();

    case 401:
      return response;

    case 200:
      return response.data;

    default:
      return response;
  }
}
