import React, { Fragment } from 'react';

import './info-list.scss';

export const InfoList = ({ title, fields, actions }) => {
  return (
    <div className="info-list">
      <div className="d-flex align-items-center justify-content-between">
        <div className="info-list__title">
          {title}
        </div>
        {actions}
      </div>

      <div className="info-list__content mt-4">
        {fields?.map(({ label, value, custom }, index) => {
          return custom ? <Fragment key={`info-list-${label}-${index}`}>{custom}</Fragment> : (
            <div key={`info-list-${label}-${index}`} className="info-list__item">
              <div className="info-list__item-label">{label}</div>
              <div className="info-list__item-value">{value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
