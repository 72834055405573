import React from 'react';
import { IconType } from './IconType';
import classNames from 'classnames';
import styles from './Icon.module.scss'

const IconArrowBack = ({ className, size = 16 }: IconType) => {
	return (
		<svg className={classNames(className, styles[`Icon${size}`])} xmlns="http://www.w3.org/2000/svg"
		     viewBox="0 0 16 16" fill="none">
			<g fill="currentColor">
				<path d="M10.5303 3.46967C10.2374 3.17678 9.76256 3.17678 9.46967 3.46967L5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L9.46967 12.5303C9.76256 12.8232 10.2374 12.8232 10.5303 12.5303C10.8232 12.2374 10.8232 11.7626 10.5303 11.4697L7.06066 8L10.5303 4.53033C10.8232 4.23744 10.8232 3.76256 10.5303 3.46967Z" />
			</g>
		</svg>
	);
};

export default IconArrowBack;
