import clsx from "clsx";
import { ChangeEvent } from "react";
import styles from "./TextArea.module.scss";

type TextAreaProps = {
	name: string;
	className?: string;
	placeholder?: string;
	defaultValue?: string;
	onInput?(value: string): void;
};

const TextArea = (props: TextAreaProps) => {
	const { name, className, placeholder, defaultValue, onInput, ...rest } =
		props;

	const onInputHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
		if (onInput) onInput(e.target.value);
	};

	return (
		<div className={clsx(styles.TextArea, className)}>
			<textarea
				name={name}
				placeholder={placeholder}
				onChange={onInputHandler}
				defaultValue={defaultValue}
				{...rest}
			></textarea>
		</div>
	);
};

export default TextArea;
