import React, { ReactNode } from 'react';
import styles from './BtnDelete.module.scss'

type BtnDeleteProps = {
	icon: ReactNode
	children: ReactNode
	onClick?(): void
}

const BtnDelete = ({ icon, children, onClick }: BtnDeleteProps) => {
	return (
		<div className={styles.BtnDelete} onClick={onClick}>
			{icon}
			{children}
		</div>
	);
};

export default BtnDelete;
