import { Loader } from "@components/loader/loader"
import { dictionariesStore } from "@mobx/dictionaries"
import { compact } from "lodash"
import { observer } from "mobx-react-lite"
import { useEffect, useMemo, useState } from "react"

import { AdditionalPhoto } from "./components/additional-photo"
import { PhotoField } from "./components/photo-field"
import { mainPhotos } from "./utils"

import {
	getStatusReportPhotos,
	postStatusReportPhotos,
	removeStatusReportPhotos
} from "@api/reports"
import ErrorMessage from "@components/utils/ErrorMessage/ErrorMessage"
import clsx from "clsx"
import "./car-form-components.scss"

export const Photos = observer(
	(props) => {
		const { edit = true, id, isClosed, onClose } = props
		const [required, setRequired] = useState(false)
		const photoTypes = dictionariesStore.dictionaries.photo_types
		const [loading, setLoading] = useState(edit)
		const [defaultValues, setDefaultValues] = useState({})
		const [photos, setPhotos] = useState([])
		const initialPhotos = photos.find(
			(photo) => photo.name === "Дополнительно"
		)
		const [savedAdditionalPhotos, setSavedAdditionalPhotos] = useState(
			initialPhotos?.photos
		)
		const [error, setError] = useState()

		useEffect(() => {
			getStatusReportPhotos(id).then(setPhotos)
		}, [id])

		const defaultInputs = useMemo(
			() =>
				compact(
					(photoTypes || []).map((photo) => {
						const mainPhoto = mainPhotos.find(
							(mainPhoto) => mainPhoto.name === photo.label
						)

						return mainPhoto
							? { ...photo, ...mainPhoto, type: "file" }
							: null
					})
				),
			[photoTypes]
		)

		useEffect(() => {
			if (!photoTypes) {
				dictionariesStore.request("photo_types", {
					params: { "orders[order]": "asc" }
				})
			}
		}, [])

		useEffect(() => {
			if (photos.length) {
				const newDefaultValues = {}

				photos.forEach((photoObj) => {
					newDefaultValues[photoObj.id] = photoObj.photos.length
						? photoObj.photos[photoObj.photos.length - 1]
						: undefined
				})

				setDefaultValues(newDefaultValues)
				setLoading(false)

				setSavedAdditionalPhotos(initialPhotos?.photos)
			}
		}, [JSON.stringify(photos), edit])

		const handleChangeFile = (e) => {
			const files = Array.from(e.target.files)
			let successCounter = 0

			files.forEach((file, index) => {
				const reader = new FileReader()

				reader.onloadend = () => {
					++successCounter
					onSaveRequest(file, index, initialPhotos?.id)

					if (successCounter === files.length) {
						e.target.value = ""
					}
				}
				reader.readAsDataURL(file)
			})
		}

		const onDeletePhoto = (photoId) => {
			removeStatusReportPhotos(id, [photoId]).then(setPhotos)
		}

		const onDeleteSavedAdditionalPhoto = (photoId) => {
			removeStatusReportPhotos(id, [photoId]).then((resp) => {
				if (!resp?.message) {
					setSavedAdditionalPhotos((prev) =>
						prev.filter((item) => item.id !== photoId)
					)
				}
			})
		}

		const onSaveRequest = async (file, index, key) => {
			const formData = new FormData()

			if (!key || !file) return

			formData.append(`photos[${index}][type]`, key)
			formData.append(`photos[${index}][file]`, file)

			const response = await postStatusReportPhotos(id, formData)

			if (!response?.errors) {
				setPhotos(response)
			} else {
				setError(response.errors)

				setTimeout(() => {
					setError(null)
				}, 5000)
			}
		}

		const onCloseHandler = () => {
			const isNextStep = photos.some(x => x.is_required && x.photos?.length === 0)
			setRequired(isNextStep)
			setTimeout(() => setRequired(false), 5000)
			if (!isNextStep) onClose()
		}

		return (
			<div>
				{loading ? (
					<Loader />
				) : (
					<>
						<div className="all-info-wrapper mt-3">
							<div className="photo-report-grid">
								{defaultInputs.map((photo, index) => {
									return (
										<PhotoField
											photo={photo}
											name={photo.value}
											multiple={false}
											defaultValue={
												defaultValues[photo.value]
											}
											required={photos[index]?.is_required}
											onDelete={onDeletePhoto}
											onSaveRequest={onSaveRequest}
											index={index}
											isClosed={isClosed}
											key={index + '_PhotoField'}
										/>
									)
								})}
							</div>
							<div className="title mb-2 mt-20px">
								{isClosed ? "Дополнительные фотографии" : "Загрузите дополнительные фотографии"}
							</div>
							<div className="d-grid gap-20px">
								<div className="photo-report-grid">
									{savedAdditionalPhotos?.map(
										(fileObj, index) => {
											return (
												<AdditionalPhoto
													src={fileObj.file?.url}
													name={
														fileObj.file
															?.name_original
													}
													size={fileObj.file?.size}
													key={`${fileObj.name}-${index}`}
													id={fileObj.id}
													onDelete={
														onDeleteSavedAdditionalPhoto
													}
													isClosed={isClosed}
												/>
											)
										}
									)}
								</div>
								{!isClosed &&
									<div className="d-flex align-items-center position-relative">
										<div className="form-wrapper w-100">
											<div className="form-group mb-4">
												<label
													htmlFor="upload-photo"
													className="file-wrapper w-100 mb-1"
												>
													<div className="dashed-border d-flex align-items-center">
														<div
															className="d-flex flex-column justify-content-center align-items-center">
															<div className="plus">
																+
															</div>
															<div className="text">
																Перетащите файлы или{" "}
																<br /> выберите
																вручную
															</div>
														</div>
													</div>
												</label>
												<div className="input-wrapper">
													<input
														type="file"
														multiple={true}
														name="img"
														id="upload-photo"
														onChange={handleChangeFile}
														className="form-control"
														placeholder="Фотография"
													/>
												</div>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
						{!isClosed &&
							<>
								<ErrorMessage show={required} text={"Необходимо загрузить обязательные фотографии"} />
								<div
									className="d-flex align-items-center justify-content-center gap-3 mt-3 mob-button-wrapper">
									<button
										onClick={onCloseHandler}
										className={clsx("btn btn-primary flex-grow-1")}
										type="button"
									>
										Сохранить
									</button>
								</div>
							</>
						}
						{error && (
							<div className="mt-3 mb-3 alert alert-danger break-word">
								{JSON.stringify(error, null, " ")}
							</div>
						)}
						{/*<div className="mt-3 d-flex">*/}
						{/*	<button*/}
						{/*		className="btn btn-primary flex-grow-1"*/}
						{/*		type="button"*/}
						{/*		onClick={onSave}*/}
						{/*	>*/}
						{/*		Следующий шаг*/}
						{/*	</button>*/}
						{/*	<span className="flex-grow-1" />*/}
						{/*</div>*/}
					</>
				)}
			</div>
		)
	}
)
