import { deleteDocumentsUser, getDocumentsUser, postDocumentsUser } from '@api/documents'
import classNames from 'classnames'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import Input from '../../Forms/Input/Input'
import Upload from '../Upload/Upload'
import UploadField from '../UploadField/UploadField'
import UploadFileList, { UploadDocumentsProps } from '../UploadFileList/UploadFileList'
import styles from "./UploadDocument.module.scss"

interface UploadDocumentProps {
	className?: string
	edit?: boolean
	id: number
	apiPath?: 'companies' | 'users'
	isPopup?: boolean
	saveText?: string
	footerText?: string
	onClick?(): void
}

const UploadDocument = (props: UploadDocumentProps) => {
	const {
		className,
		edit,
		apiPath = 'users',
		isPopup,
		saveText = 'Сохранить документы',
		onClick,
		id,
		footerText
	} = props
	const [desciption, setDescription] = useState('')
	const [documents, setDocuments] = useState<UploadDocumentsProps[]>([])
	const [loading, setLoading] = useState(false)
	const [reset, setReset] = useState(false)

	const fetchDocuments = async () => {
		await getDocumentsUser(apiPath, id).then(setDocuments)
	}
	useEffect(() => {
		fetchDocuments()
	}, [])

	const onAddDocument = async (files: File[]) => {
		setLoading(true)
		await postDocumentsUser(apiPath, id, [
			{
				description: desciption,
				files: [...files]
			}
		])
		await fetchDocuments()
		setReset(prev => !prev)
		setLoading(false)
	}

	const onRemoveDocument = async (deleteId: number) => {
		await deleteDocumentsUser(apiPath, id, [deleteId])
		setDocuments(documents.filter(x => x.id !== deleteId))
	}

	const onInput = (e: any) => {
		setDescription(e.target.value)
	}

	return (
		<div className={classNames(styles.UploadDocument, className, isPopup && styles.isPopup)}>
			<div className={clsx(!isPopup && 'mt-4')}>
				<div className="title">Документы</div>
				<div className={clsx(styles.section, 'mt-2')}>
					<UploadFileList documents={documents} onRemoveFile={onRemoveDocument} isDelete={edit} />
					{edit &&
						<>
							<UploadField
								label={'Введите название документа'}
								isPopup={isPopup}
							>
								<Input
									placeholder={'Введите название документа'}
									value={desciption}
									onInput={onInput}
									reset={reset}
								/>
							</UploadField>
							<UploadField isPopup={isPopup}>
								<Upload onAddDocument={onAddDocument} loading={loading} />
							</UploadField>
						</>
					}
				</div>
				{isPopup &&
					<>
						{footerText && <div className={styles.footerText}>{footerText}</div>}
						<button
							onClick={onClick}
							className="btn btn-primary mt-4 w-100"
							type="submit"
						>
							{saveText}
						</button>
					</>
				}
			</div>
		</div>
	)
}

export default UploadDocument