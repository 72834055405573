import React, { useEffect, useState } from "react";
import moment from "moment/moment";

import { getServices, getTasksList, postImportTasks } from "@api/cars";
import { DropdownButton } from "@components/dropdown/dropdown-button";
import { Pagination } from "@components/pagination/pagination";
import { Table } from "@components/table/table";

import "./cars-tabs.scss";
import { useSearchParams } from "react-router-dom";

let filesCounter = 10000000000;

export const UploadHistoryTab = () => {
	const [tasks, setTasks] = useState();
	const [options, setOptions] = useState(null);
	const [pendingList, setPendingList] = useState([]);
	const [perPage, setPerPage] = useState(25);
	let [searchParams, setSearchParams] = useSearchParams();
	const [isInitLoading, setIsInitLoading] = useState(false);

	const getTasksListRequest = (params, page) => {
		getTasksList({
			page,
			per_page: perPage,
			...params
		}).then((resp) => setTasks(resp));
	};

	useEffect(() => {
		let page = 1;
		if (!isInitLoading && searchParams.get("page")) page = searchParams.get("page");
		getTasksListRequest(null, page);
		setIsInitLoading(true);
	}, [perPage]);

	const renderIcon = (statusCode) => {
		switch (statusCode) {
			case "import_task_in_queue":
				return <img src="/img/icons/time-status-icon.svg" alt="" />;
			// case 'import_task_in_progress':
			//   break;
			case "import_task_finished":
				return <img src="/img/icons/success-status-icon.svg" alt="" />;
			case "import_task_error":
				return <img src="/img/icons/error-status-icon.svg" alt="" />;
			default:
				return null;
		}
	};

	const COLUMNS = [
		{
			dataField: "ID",
			text: "ID",
			headerStyle: () => {
				return { width: "70px" };
			},
			formatter: (cell, row) => (!row?.loading ? <div className="table__file-id">{row?.id}</div> : undefined)
		},
		{
			dataField: "File",
			text: "Файл",
			headerStyle: () => {
				return { width: "340px" };
			},
			formatter: (cell, row) => (
				row?.import_file && (
					<div>
						<div className="table__file-name">{row.import_file.original_name}</div>
						<div className="table__file-extra-text">{Math.round(row.import_file.size / 1024)}Kb</div>
					</div>
				)
			)
		},
		{
			dataField: "Date",
			sort: true,
			onSort: (field, order) => {
				getTasksListRequest({ "orders[created_at]": order });
			},
			headerStyle: () => {
				return { width: "150px" };
			},
			text: "Дата загрузки",
			formatter: (cell, row) => (
				row?.created_at && (
					<div>
						<div>{moment(row.created_at).format("DD.MM.YYYY")}</div>
						<div className="table__file-extra-text">{moment(row.created_at).format("HH:mm")}</div>
					</div>
				)
			)
		},
		{
			dataField: "User",
			text: "Пользователь",
			formatter: (cell, row) => row?.created_by?.full_name
		},
		{
			dataField: "Status",
			text: "Статус",
			formatter: (cell, row) => ((row?.loading && row?.status?.code !== "import_task_error") ? (
				<div className="table-data-loading">
					<div className="table-data-loading-progress">
						<span style={{ width: `${row.percent}%` }} />
					</div>
					<div className="d-flex align-items-center justify-content-between">
						<span className="table-text-secondary">Загружаем...</span>
						<span className="table-text-secondary">{row?.percent}%</span>
					</div>
				</div>
			) : (
				<div className={`${row?.status?.code} status-text`}>
					{renderIcon(row?.status?.code)}
					{row?.status?.name}
				</div>
			))
		},
		{
			dataField: "Information",
			text: "Информация",
			formatter: (cell, row) => (row?.errors_file ? (
				<a href={row.errors_file.url} target="_blank" className="d-flex align-items-center error_link"
				   rel="noreferrer">
					<img src="/img/icons/download-file-icon.svg" alt="" /> Скачать ошибки
				</a>
			) : <div className="text-secondary">Нет</div>)
		}
	];

	const onUploadFile = (e) => {
		const newData = new FormData();
		const file = e.target.files[0];
		const id = filesCounter++;

		newData.append("type_key", e.target.id);
		newData.append("file", e.target.files[0]);

		setPendingList((prev) => {
			return [...prev, {
				id,
				loading: true,
				percent: 0,
				// token,
				import_file: {
					original_name: file.name,
					size: file.size
				}
			}];
		});

		const onUploadProgress = (progressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

			setPendingList((prev) => prev.map((item) => (item.id === id ? {
				...item,
				percent: percentCompleted
			} : item)));
		};

		postImportTasks({ data: newData, onUploadProgress }).then((resp) => {
			getTasksListRequest();

			if (resp.message) {
				setPendingList((prev) => prev.map((item) => (item.id === id ? {
					...item,
					status: {
						code: "import_task_error",
						name: resp.errors?.file?.[0]
					}
				} : item)));
			} else {
				setPendingList((prev) => prev.filter((item) => item.id !== id));
			}
		});

		e.target.value = "";
	};

	const changePage = (index) => {
		if (index > 0) {
			setSearchParams({ tab: "downloads", page: index });
			getTasksListRequest(null, index);
		}
	};

	const uploadButton = () => {
		const optionsList = [];
		options && Object.entries(options).map(([key, value], index) => {
			optionsList.push(({
				value: value.name,
				label: (
					<label key={`${key}-${index}`}>
						{value.name}
					</label>
				),
				onClick: (e) => {
					document.getElementById(key).click();
				}
			}));
		});

		return (
			<DropdownButton
				autoClose="outside"
				onClick={() => {
					if (options === null) {
						getServices().then((resp) => setOptions(resp));
					}
				}}
				btnIcon={<img src="/img/icons/upload-file-icon.svg" alt="" />}
				btnText="Загрузить..."
				options={optionsList}
			/>
		);
	};

	return (
		<div className="upload-history-wrapper">
			<div className="d-flex align-items-center justify-content-between mob-direction_row">
				<div className="title">История загрузок</div>
				{options && Object.keys(options).map((key, index) => {
					return <input type="file" id={key} key={key} className="d-none" onChange={onUploadFile} />;
				})}
				{uploadButton()}
			</div>
			{(tasks?.data || pendingList.length) ? (
				<div className="mt-3 d-flex align-items-stretch flex-wrap gap-10px">
					<Table
						data={[...pendingList, ...(tasks?.data || [])]}
						columns={COLUMNS}
					/>
				</div>
			) : (
				"Данных нет"
			)}
			<Pagination
				data={tasks}
				onChangePage={changePage}
				onChangePerPage={(e) => setPerPage(e.target.value)}
				perPageList={[25, 50, 100, 250]}
			/>
		</div>
	);
};
