import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import React from 'react';

import { getCurrencySymbol } from '@utils/common';
import { formatValueWithSpaces } from '@utils/format';

export const getColumns = (handleShowLastBetDeleteModal, isFinishedAuction) => {
  return [
    {
      dataField: 'lastBet',
      text: 'Текущая ставка',
      formatter: (cell, row) => (
        <div className="d-flex gap-10px">
          <div className="table-bet">{formatValueWithSpaces(row?.sum)} {getCurrencySymbol()}</div>
          {row.from_autobet && <img src="/img/icons/autobet-icon.svg" alt="" />}
        </div>
      ),
    },
    {
      dataField: 'date',
      text: 'Дата и время',
      formatter: (cell, row) => (
        row?.created_at && (
          <div>
            <div className="table__time">{moment(row.created_at).format('DD.MM.YYYY')}</div>
            <div className="table__time-hour">{moment(row.created_at).format('HH:mm')}</div>
          </div>
        )
      ),
    },
    {
      dataField: 'user',
      text: 'Пользователь',
      formatter: (cell, row) => (
        <Link to={`/user/${row.user?.id}`} className="table-link">
          {row.user?.full_name}
        </Link>
      ),
    },
    {
      dataField: 'company',
      text: 'Компания',
      formatter: (cell, row) => (
        <Link to={`/company/${row.company?.id}`} className="table-link">
          {row.company?.display_name}
        </Link>
      ),
    },
    {
      dataField: 'deleteBet',
      text: '',
      formatter: (cell, row, index) => {
        return index === 0 && !isFinishedAuction ? (
          <button
            className="btn-delete-bet"
            onClick={() => {
              handleShowLastBetDeleteModal(row);
            }}
            type="button"
          >
            <img src="/img/icons/delete-red-icon.svg" />
            Удалить
          </button>
        ) : null;
      },
    },
  ];
};
