import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';

import './suggestions.scss';

const BUTTON_CODE_ENTER = 13;
const BUTTON_CODE_ARROW_BOTTOM = 40;
const BUTTON_CODE_ARROW_TOP = 38;
const BUTTON_CODE_ESCAPE = 27;

export const SuggestionsList = (props) => {
  const [focusedItem, setFocusedItem] = useState(0);

  const onClickMatchedItem = (item) => {
    props.onSelectItem(item);
    props.onClose?.();
  };

  const onFocusMatchedItem = (currentIndex) => {
    if (focusedItem === currentIndex) {
      return;
    }

    setFocusedItem(currentIndex);
  };

  const handleKey = (e) => {
    switch (e.which) {
      // Enter
      case BUTTON_CODE_ENTER:
        e.preventDefault();
        e.stopPropagation();

        setFocusedItem((prev) => {
          props.onSelectItem?.(props.list[prev]);
          props.onClose?.();

          return prev;
        });

        break;
        // Стрелка вниз
      case BUTTON_CODE_ARROW_BOTTOM:
        e.preventDefault();
        e.stopPropagation();

        setFocusedItem((prev) => (props.list[prev + 1] ? prev + 1 : 0));
        break;
        // Стрелка вверх
      case BUTTON_CODE_ARROW_TOP:
        e.preventDefault();
        e.stopPropagation();

        setFocusedItem((prev) => (props.list[prev - 1] ? prev - 1 : props.list.length - 1));
        break;
        // ESC
      case BUTTON_CODE_ESCAPE:
        e.preventDefault();
        e.stopPropagation();

        props.onClose?.();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKey);

    return () => {
      document.removeEventListener('keydown', handleKey);
    };
  }, [props.list]);

  return (
    <OutsideClickHandler onOutsideClick={props.onClose}>
      <div className="suggestions__list">
        {props.list.map((item, key) => {
          const classes = clsx('suggestions__list-item', {
            'suggestions__list-item_hover': focusedItem === key,
          });

          return (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <div
              className={classes}
              onClick={() => onClickMatchedItem(item)}
              onMouseOver={() => onFocusMatchedItem(key)}
              key={`search-list-item-${key}`}
            >
              {item.label}
            </div>
          );
        })}
      </div>
    </OutsideClickHandler>
  );
};
