import React from 'react';

import { Button } from '@components/button/button';

import { Dropdown } from './dropdown';

import './dropdown.scss';

export const DropdownButton = (props) => {
  return (
    <Dropdown
      customToggle={(
        <Button
          preset="primary"
          className="dropdown__button"
          onClick={props.onClick}
        >
          {props.btnIcon}
          <div className="dropdown__button-text">{props.btnText}</div>
          <img className="dropdown__button-arrow" src="/img/icons/arrow-white-down-icon.svg" alt="" />
        </Button>
      )}
      {...props}
    />
  );
};
