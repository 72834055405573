import React, { useState } from "react";

import { Input } from "@components/input/input";
import { postSetNewWinning } from "@api/auctions";
import { formatToNumberWithoutSpaces, formatValueWithSpaces } from "@utils/format";
import { Button } from "@components/button/button";

export const EditTradesResult = ({ lot, handleClose, setIsAuctionEditTradesWinner }) => {
	const [form, setForm] = useState({
		department_id: lot?.winning_bet?.company_id || undefined,
		user_id: lot?.winning_bet?.user_id,
		sum: formatValueWithSpaces(lot?.lastBet?.sum)
	});

	const onSubmit = () => {
		postSetNewWinning(lot?.auction?.id, lot?.id, {
			...form,
			sum: formatToNumberWithoutSpaces(form.sum)
		}).then((resp) => {
			if (!resp.message) {
				handleClose(true);
			}
		});
	};

	return (
		<div className="mt-25px">
			<div className="edit-trades-result-wrapper__header-text">Укажите выигрышную ставку</div>
			<div className="edit-winning-bet-wrapper">
				<div className="edit-winning-bet-wrapper__field">
					<div className="edit-winning-bet-wrapper__field-name">Значение выигрышной ставки, ₽</div>
					<div className="d-flex align-items-center w-70">
						<Input
							name="sum"
							autoComplete="off"
							onChange={(e) => setForm((prev) => ({
								...prev,
								sum: formatValueWithSpaces(e.target.value)
							}))}
							value={form.sum}
						/>
					</div>
				</div>
				<div className="edit-winning-bet-wrapper__field">
					<div className="edit-winning-bet-wrapper__field-name">Победитель</div>
					<div className="edit-winning-bet-wrapper__field-value">
						<div>
							{lot?.winning_bet.user?.declared_company_name ? `${lot?.winning_bet.user?.declared_company_name},` : ""} {lot?.winning_bet.user?.full_name}
						</div>
						<div className="edit-winning-bet-wrapper__field-link"
							 onClick={() => setIsAuctionEditTradesWinner(true)}>
							<img
								src="/img/icons/edit-icon.svg"
								className="cursor-pointer"
								alt=""
							/>
							Изменить победителя
						</div>
					</div>
				</div>
			</div>
			<div className="edit-winning-bet-btns">
				<Button preset="secondary" onClick={handleClose}>Отмена</Button>
				<Button preset="primary" onClick={onSubmit}>Сохранить изменения</Button>
			</div>
		</div>
	);
};
