import clsx from "clsx"
import styles from "./ReportList.module.scss"
import { Table } from "../../../components/table/table"
import React, { useState } from "react"
import moment from "moment/moment"
import Btn from "../../../components/btn/Btn/Btn"

interface SelectedReportProps {
	sale_id: number
	status_report_id: number
	closed_report: string
	closed_at: string
	cityId: number
}

interface ReportListProps {
	className?: string
	items: any[]

	onSelect(obj: SelectedReportProps): void
}

export const COLUMNS = [
	{
		dataField: "id",
		text: "№",
		headerStyle: {
			width: 64
		},
		formatter: (cell: any, row: any) => {
			return row.id
		}
	},
	{
		dataField: "closed_at",
		text: "Дата закрытия",
		headerStyle: {
			width: 140
		},
		formatter: (cell: any, row: any) => {
			return moment(row.closed_at).format("DD.MM.YYYY HH:mm")
		}
	},
	{
		dataField: "closed_report",
		text: "Закрыл отчет",
		formatter: (cell: any, row: any) => {
			return row.closed_by?.display_name
		}
	}
]

const ReportList = (props: ReportListProps) => {
	const { className, onSelect, items } = props
	const [selectedReport, setSelectedReport] = useState<SelectedReportProps>()
	const selectRow = {
		mode: "radio",
		clickToSelect: true,
		onSelect: (row: any, isSelect: any) => {
			setSelectedReport({
				sale_id: row.sale_id,
				status_report_id: row.id,
				closed_at: moment(row.closed_at).format("DD.MM.YYYY HH:mm"),
				closed_report: row.closed_by?.display_name,
				cityId: row.city_id
			})
		}
	}

	const onClickButton = () => {
		if (selectedReport)
			onSelect(selectedReport)
	}

	return (
		<div className={clsx(styles.ReportList, className)}>
			<div className="title mb-3">Список отчетов</div>
			<div className={styles.main}>
				<Table
					data={items}
					selectRow={selectRow}
					columns={COLUMNS}
				/>
			</div>
			<Btn onClick={onClickButton}>Сохранить</Btn>
		</div>
	)
}

export default ReportList
