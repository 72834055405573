import { useEffect, useState } from 'react';

/**
 * Хук для обработки медиазапросов на стороне реакта.
 * Взято из этой либы с незначительными изменениями https://usehooks-ts.com/react-hook/use-media-query
 *
 * Что изменено: убрана устаревшая в js логика (addListener, removeListener), убраны излишние проверки (window === undefined) и тд
 * @param {string} customMediaQuery медиазапрос в виде строки, соответствующей входному параметру window.matchMedia
 * @returns {boolean} соответствие страницы медиазапросу
 */
const useMediaQuery = (customMediaQuery) => {
  const mediaQuery = customMediaQuery || 'screen and (max-width: 780px)';
  const getMatch = (mediaQuery) => window.matchMedia(mediaQuery); // в ориге есть проверка на среду выполнения кода, здесь она не нужна
  const [matches, setMatches] = useState(getMatch(mediaQuery).matches); // window.matchMedia возвращает структуру типа MediaQueryList, где matches обязательно есть
  const handleChange = () => setMatches(getMatch(mediaQuery).matches);

  useEffect(() => {
    const matchMedia = getMatch(mediaQuery);

    matchMedia.addEventListener('change', handleChange);
    return () => matchMedia.removeEventListener('change', handleChange);
  }, [mediaQuery]);

  return matches;
};

export default useMediaQuery;
