import { getPageAuctionsRequest } from "@api/auctions";
import { Pagination } from "@components/pagination/pagination";
import { Table } from "@components/table/table";
import { dictionariesStore } from "@mobx/dictionaries";
import { meStore } from "@mobx/me";
import { NO_PERMISSION_URL } from "@utils/common";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";

import { getColumns } from "./utils";

import { getUsersList } from "@api/users";
import Filter from "@components/filters/Filter/Filter";
import FilterButton from "@components/filters/FilterButton/FilterButton";
import "./auctions.scss";
import { Loader } from "@components/loader/loader";

export const Auctions = observer(() => {

	let [searchParams, setSearchParams] = useSearchParams();
	const [isInitLoading, setIsInitLoading] = useState(false);

	const [filterData, setFilterData] = useState([
		{
			id: 0,
			type: "text",
			name: "filters[ids][]",
			placeholder: "Номер аукциона"
		},
		{
			id: 1,
			type: "date",
			name: "filters[start_at_gt]",
			placeholder: "Даты проведения c"
		},
		{
			id: 2,
			type: "date",
			name: "filters[start_at_ls]",
			placeholder: "Даты проведения до"
		},
		{
			id: 3,
			type: "select",
			name: "filters[sellerable_type]",
			placeholder: "Тип продавца",
			options: [
				{ label: "Физическое лицо", value: "user" },
				{ label: "Компания", value: "company" }
			],
			value: []
		},
		{
			id: 4,
			type: "date",
			name: "filters[finished_at_gt]",
			placeholder: "Дата фактического окончания с"
		},
		{
			id: 5,
			type: "date",
			name: "filters[finished_at_ls]",
			placeholder: "Дата фактического окончания до"
		},
		{
			id: 6,
			name: "filters[responsible_ids][]",
			type: "select",
			isMulti: true,
			placeholder: "Ответственный менеджер",
			options: []
		},
		{
			id: 7,
			name: "filters[status_ids][]",
			type: "select",
			placeholder: "Статус",
			options: []
		},
		{
			id: 8,
			type: "search",
			name: "filters[company_ids][]",
			placeholder: "Компании",
			typeId: 2
		}
	]);

	const [loading, setLoading] = useState(false);
	const [isReadyFilter, setIsReadyFilter] = useState(false);
	const [formData, setFormData] = useState({});

	const [filterShow, setFilterShow] = useState(false);

	const { status } = dictionariesStore.dictionaries;

	const [auctions, setAuctions] = useState();
	const [sorting, setSorting] = useState({});

	const [perPage, setPerPage] = useState(25);
	const [page, setPage] = useState(1);

	useEffect(() => {
		(async () => {
			if (!status?.length)
				await dictionariesStore.request("status", {
					filters: { entity: "auction" }
				});
			let { data } = await getUsersList({
				"filters[roles][]": "manager"
			});

			setFilterData(
				filterData.map((x) => {
					if (x.name === "filters[status_ids][]")
						x.options = dictionariesStore.dictionaries.status;
					if (x.name === "filters[responsible_ids][]")
						x.options = data.map((x) => ({
							value: x.id,
							label: x.full_name
						}));
					return x;
				})
			);

			setIsReadyFilter(true);
		})();
	}, []);

	const requestAuctions = (data, page, per_page, sorting) => {
		const params = {
			page: page,
			per_page: per_page || perPage,
			...data,
			...sorting
		};
		setLoading(true);
		getPageAuctionsRequest(params).then(data => {
			setAuctions(data);
			setLoading(false);
		}).catch(e => setLoading(false));
	};

	const onSort = (field, order) => {
		setSorting({ field, order });
		requestAuctions(formData, page, perPage, { field, order });
	};

	const columns = getColumns(onSort);

	if (meStore.noPermission("admin.auctions.view")) {
		return <Navigate to={NO_PERMISSION_URL} />;
	}

	const onChangeSelectField = async (key, type, data) => {
		let array = filterData.map((x) => x);

		if (key === "filters[sellerable_type]") {
			array = array.map((x) => {
				if (
					x.name === "filters[company_ids][]" &&
					data[0]?.value === "user"
				) {
					x.name = "filters[sellerable_id]";
					x.placeholder = "ФИО";
					x.typeId = 1;
				} else if (
					x.name === "filters[sellerable_id]" &&
					data[0]?.value === "company"
				) {
					x.name = "filters[company_ids][]";
					x.placeholder = "Компании";
					x.typeId = 2;
				}
				return x;
			});
		}

		setFilterData(array);
	};

	const onSubmit = (data) => {
		setFormData(data);
		if (Object.keys(data).length === 0) {
			setSorting({});
			setPage(1);
		}
		isInitLoading && setSearchParams({});
		requestAuctions(data, !isInitLoading ? searchParams.get("page") : 1, perPage, {});
		setFilterShow(false);
		setIsInitLoading(true);
	};

	const onPagination = ({ page, per_page }) => {
		setPage(page);
		setPerPage(per_page);
		setSearchParams({ page: page });
		requestAuctions(formData, page, per_page, sorting);
	};

	const onResetFilter = () => {
		setFilterData(
			filterData.map((x) => {
				if (x.type === "search") {
					x.name = "filters[company_ids][]";
					x.typeId = 2;
					x.placeholder = "Компании";
				}
				return x;
			})
		);
		setFilterShow(false);
	};

	const onCloseFilter = () => {
		setFilterShow(false);
	};

	return (
		<div className="auction-page-wrapper">
			{isReadyFilter && (
				<Filter
					data={filterData}
					typeFilter={"filter:auctions"}
					typeFilterData={"filter:auctions:data"}
					mobileShow={filterShow}
					onSubmit={onSubmit}
					onChangeSelectField={onChangeSelectField}
					onReset={onResetFilter}
					indent
					onClose={onCloseFilter}
				/>
			)}

			<div className="d-flex align-items-center justify-content-end header mb-3 auction-page-wrapper__header">
				<FilterButton onClick={() => setFilterShow(true)} />
				{meStore.hasPermission("admin.auctions.edit") && (
					<Link to="/add-auction" className="btn btn-primary">
						Добавить
					</Link>
				)}
			</div>

			{auctions?.data?.length > 0 ? (
				<Table
					headerClasses="align-top"
					data={auctions.data}
					columns={columns}
				/>
			) : (
				<p>Нет аукционов</p>
			)}
			<Pagination
				data={auctions}
				onChange={onPagination}
				perPageList={[25, 50, 100, 250]}
			/>
			{loading && <Loader fixed />}
		</div>
	);
});
