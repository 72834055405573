import { makeAutoObservable } from 'mobx';

class NotificationsStore {
  constructor() {
    this.counter = 0;
    this.notifications = [];

    makeAutoObservable(this);
  }

  addNotification(data) {
    const id = this.counter++;

    this.notifications.push({ id, ...data });

    if (data.autoClose) {
      setTimeout(() => {
        this.notifications = this.notifications.filter((item) => item.id !== id);
      }, data.autoClose);
    }
  }

  closeNotification = (id) => {
    this.notifications = this.notifications.filter((item) => item.id !== id);
  };
}

export const notificationsStore = new NotificationsStore();
