import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import clsx from 'clsx';

import './select.scss';

export const Select = (props) => {
	const {
		className,
		valueId,
		options,
		disabled,
		size = 'l',
		isMulty,
		color,
		placeholder = 'Выберите значение',
		...rest
	} = props;
	const [value, setValue] = useState();

	useEffect(() => {
		if (valueId) {
			setValue(options?.find((item) => item.value === valueId));
		} else if (rest.value) {
			setValue(rest.value);
		} else if (value) {
			setValue(null);
		}
	}, [rest.value, valueId, JSON.stringify(options)]);

	return (
		<ReactSelect
			className={clsx('react-select', `react-select_${size}`, color && `react-select_${color}`, className, { 'react-select_multi': props.isMulti })}
			options={options}
			placeholder={placeholder}
			styles={customStyles}
			value={value}
			isMulti={isMulty}
			isDisabled={disabled}
			{...rest}
		/>
	);
};

const customStyles = {
	container: (provided) => ({
		...provided,
		width: '100%',
	}),
	option: (provided) => ({
		...provided,
		color: 'black',
	}),
	singleValue: (provided) => ({
		...provided,
		marginLeft: 0,
		marginRight: 0,
	}),
	menu: (provided) => ({
		...provided,
		'z-index': 7,
	}),
	placeholder: (provided) => ({
		...provided,
		color: '#9CA3B0',
	}),
	control: (provided) => ({
		...provided,
		border: '0 !important',
		boxShadow: 'none',
		backgroundColor: 'transparent',
		minHeight: 'auto',
		height: '100%',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: '0 16px',
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		height: '100%',
	}),
};
