import { useState } from "react"

import { FormSelect } from "@components/form/form-select"

import { bytesToMegaBytes } from "../utils"
import FieldCarForm from "@pages/car-form/car-form-components/components/FieldCarForm/FieldCarForm"
import Select from "@components/Forms/Select/Select"
import { dictionariesStore } from "@mobx/dictionaries"

export const DocumentFieldWithSelect = (props) => {

	const { isClosed } = props
	const [docType, setDocType] = useState(null)

	const onSelectChange = (value) => {
		setDocType(value)
	}

	const onAddDocument = (e) => {
		if (!docType) return null

		const field = docType.name

		props.onSaveRequest(e.target.files[0], field, 0)
	}

	const removeFile = (field, documentId) => () => {
		if (documentId) {
			props.onDelete(documentId)
		}
	}

	return (
		<div className="info-field-wrapper">
			{props.options.map((option) => {
				const files = props.defaultValues[option.name] || []

				if (!files.length) return null

				return (
					<div
						className="mt-20px mob-document-wrapper document"
						key={option.name}
					>
						<div className="label">{option.label}</div>
						<div className="d-flex flex-column gap-10px">
							{files.map((file, index) => {
								return (
									<div
										key={`${file.name}_${index}`}
										className="loaded-file d-flex align-items-center"
									>
										<div className="name">{file.name}</div>
										<div className="size">
											{bytesToMegaBytes(file.size)} MB
										</div>
										{!isClosed &&
											<img
												className="cursor-pointer"
												onClick={removeFile(
													option.name,
													file.document_id
												)}
												src="/img/icons/remove-icon.svg"
												alt=""
											/>
										}
									</div>
								)
							})}
						</div>
					</div>
				)
			})}
			{!isClosed &&
				<>
					<FieldCarForm
						label="Тип документа"
						indentBottom
						className={"document-field__select"}
					>
						<Select
							options={props.options}
							placeholder={"Выберите значение"}
							onChange={onSelectChange}
							сlearable
						/>
					</FieldCarForm>
					<div className="d-grid grid-columns-30-70 mt-20px mob-document-wrapper">
						<div />
						<label htmlFor="other-doc" className="upload-document">
							<input
								type="file"
								id="other-doc"
								onChange={onAddDocument}
								className="document-field__upload-input"
							/>
							<div
								className="dashed-border d-flex justify-content-center align-items-center upload-document">
								<div className="upload-text">
									<div className="upload-plus">+</div>
									<span>Выберите файл</span>
								</div>
							</div>
						</label>
					</div>
				</>
			}
		</div>
	)
}
