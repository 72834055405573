import { makeObservable, observable, computed, action, runInAction } from 'mobx';

import { getCitiesList } from '@api/cities';

class CitiesStore {
	constructor() {
		this.items = new Map();

		makeObservable(this, {
			items: observable,
			cities: computed,
			fetch: action,
		});
	}

	async fetch() {
		return await getCitiesList().then((resp) => {
			runInAction(() => {
				resp.forEach((item) => {
					this.items.set(item.id, item);
				});
			});
		});
	}

	get cities() {
		return Array.from(this.items.values());
	}
}

export const citiesStore = new CitiesStore();
