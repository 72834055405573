import React, { useEffect, useState } from 'react';

import QuestionTooltip from '@components/tooltip/tooltip-question';
import { postAddExchangeLot } from '@api/auctions';
import { formatToNumberWithoutSpaces, formatValueWithSpaces } from '@utils/format';
import { Input } from '@components/input/input';

import { ExchangeLotSalesTable } from './exchange-lot-sales-table';

export const ExchangeLotForm = (props) => {
  const { isEdit } = props;
  const [sale, setSale] = useState(props.lot?.sale ? [props.lot?.sale] : []);
  const [lot, setLot] = useState(isEdit ? props.lot : {
    order: props.order + 1,
    price: '',
    sale: [],
  });
  const [lotError, setLotError] = useState('');

  useEffect(() => {
    if (isEdit && props.lot) {
      setSale(props.lot?.sale ? [props.lot?.sale] : []);
      setLot(props.lot);
    }
  }, [props.lot]);

  const requestSaveLot = () => {
    const exchangeData = {
      order: lot.order,
      price: formatToNumberWithoutSpaces(lot.price),
      sale_id: sale[0]?.id,
    };

    if (isEdit) {
      exchangeData.id = lot.id;
    }

    postAddExchangeLot(props.exchangeId, { lots: [exchangeData] }).then((resp) => {
      if (resp.message) {
        setLotError(resp.errors || resp.message);

        setTimeout(() => setLotError(''), 5000);
      } else {
        props.onChange(resp);
      }
    });
  };

  return (
    <div className="mt-4">
      <div className="name mb-2">{isEdit ? 'Изменение лота' : 'Добавление лота'}</div>
      <div className="lots-wrapper">
        <div className="lot__buttons-top">
          {!props.hideClose && (
            <button
              onClick={() => props.onClose()}
              className="lot__button-top lot__button-top_last"
              type="button"
            >
              X
            </button>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between mt-2">
          <div className="name w-30">Стоимость</div>
          <div className="d-flex align-items-center position-relative w-70">
            <Input
              name="starting_price"
              onChange={(e) => setLot((prevLot) => ({ ...prevLot, price: formatValueWithSpaces(e.target.value) }))}
              value={lot?.price}
              autoComplete="off"
              placeholder="В рублях"
            />
            <QuestionTooltip text="Укажите стоимость лота" />
          </div>
        </div>
        <div className="line-hr" />
        <ExchangeLotSalesTable
          sales={sale}
          onChangeSales={(sale) => setSale(sale)}
          withCreateSale={true}
          singleSelect={true}
        />
      </div>
      {Boolean(lotError) && (
        <div className="mt-2 mb-2 alert alert-danger">{JSON.stringify(lotError, null, ' ')}</div>
      )}
      <button
        onClick={requestSaveLot}
        className="btn btn-primary w-100 mt-3"
        type="button"
      >
        {isEdit ? 'Изменить лот' : 'Добавить лот'}
      </button>
    </div>
  );
};
