import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { meStore } from '@mobx/me';
import { managersStore } from '@mobx/managers';
import { NO_PERMISSION_URL } from '@utils/common';
import { Input } from '@components/input/input';
import { getRequisitesTemplates } from '@api/users';
import { createDepartmentRequest, editDepartmentRequest, getCurrentDepartmentRequest } from '@api/companies';
import { dictionariesStore } from '@mobx/dictionaries';
import { Loader } from '@components/loader/loader';
import { Select } from '@components/select/select';
import { DocumentField } from '@pages/car-form/car-form-components/components/document-field';

import './department-form.scss';

export const DepartmentForm = observer(({ isEdit }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState();
  const [department, setDepartment] = useState();
  const [fields, setFields] = useState([]);
  const [roles, setRoles] = useState([]);
  const [form, setForm] = useState({
    title: '',
    type_id: '',
    lead_id: 5,
    responsible_id: '',
    parent_id: id ? Number(id) : '',
    requisite: {},
  });

  useEffect(() => {
    if (!managersStore.managers.length) {
      managersStore.fetch();
    }

    getRequisitesTemplates().then((resp) => {
      if (resp?.legal?.fields) {
        const fields = [];
        const requisite = {};

        Object.values(resp.legal.fields).forEach((field) => {
          fields.push(field);
          requisite[field.key] = '';
        });

        setForm((prev) => ({ ...prev, requisite }));
        setFields(fields);

        if (isEdit) {
          getCurrentDepartmentRequest(id).then(setDepartment);
        } else {
          setLoading(false);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (department && isEdit) {
      const values = {
        title: department.title,
        lead_id: department.lead_id || undefined,
        responsible_id: department.responsible_id || undefined,
        parent_id: id ? Number(id) : '',
        requisite: {},
      };

      Object.keys(form?.requisite).forEach((key) => {
        if (key !== 'agent_contract') {
          values.requisite[key] = department.requisite?.[key] || '';
        }
      });

      const newRoles = [];

      department.roles?.forEach((item) => {
        if (item.name !== 'admin') {
          newRoles.push(item.name);
        }
      });

      setRoles(newRoles);
      setForm(values);
      setLoading(false);
    }
  }, [department]);

  if (meStore.noPermission('admin.companies.edit')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  if (meStore.data === null || loading) {
    return <Loader />;
  }

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      setRoles((prev) => [...prev, e.target.name]);
    } else {
      setRoles((prev) => prev.filter((item) => item !== e.target.name));
    }
  };

  const handleInputChange = (e) => {
    const { name } = e.target;
    if (name.includes('requisite.')) {
      const newName = name.replace('requisite.', '');

      setForm({
        ...form,
        requisite: {
          ...form.requisite,
          [newName]: e.target.value,
        },
      });
    } else {
      setForm({
        ...form,
        [name]: e.target.value,
      });
    }
  };

  const addDepartment = () => {
    const data = { ...form };

    if (isEdit || roles?.length > 0) {
      data.roles = roles;
    }

    const request = isEdit ? editDepartmentRequest(id, data) : createDepartmentRequest(id, data);

    request.then((resp) => {
      if (resp.message) {
        setErrors(resp.errors);

        setTimeout(() => {
          setErrors(null);
        }, 5000);
      } else {
        navigate(`/department/${resp.id}`);
      }
    });
  };

  const renderDynamicInput = (item) => {
    return (
      <div key={item.key} className="d-flex align-items-center mt-2">
        <div className="name w-30">{item.name}</div>
        <Input
          type={item.type === 'string' ? 'text' : item.type}
          name={`requisite.${item.key}`}
          onChange={handleInputChange}
          value={form.requisite?.[item.key]}
          placeholder={item.name}
        />
      </div>
    );
  };

  return (
    <div className="create-department-wrapper">
      <div className="head-text">{isEdit ? 'Редактирование подразделения' : 'Добавление подразделения'}</div>
      <div className="info-wrapper mt-4">
        <div className="d-flex align-items-center mt-2">
          <div className="name w-30">Название подразделения</div>
          <Input
            name="title"
            onChange={handleInputChange}
            value={form.title}
            placeholder="Название подразделения *"
          />
        </div>
        <div className="d-flex align-items-center mt-2">
          <div className="name w-30">Тип компании</div>
          <Select
            options={dictionariesStore.dictionaries.company_types?.map((type) => ({ value: type.value, label: type.label }))}
            valueId={form.type_id}
            size="m"
            placeholder="Тип компании"
            onChange={({ value }) => handleInputChange({ target: { name: 'type_id', value } })}
          />
        </div>
        <div className="d-flex align-items-center mt-2">
          <div className="name w-30">Ответственный менеджер</div>
          <Select
            options={managersStore.managers?.map((user) => ({ value: user.id, label: user.full_name }))}
            valueId={form.responsible_id}
            size="m"
            placeholder="Ответственный менеджер"
            onChange={({ value }) => handleInputChange({ target: { name: 'responsible_id', value } })}
          />
        </div>
        {fields.length > 0 && fields.map((item) => {
          return (item.key === 'agent_contract' && item.type === 'file') ? (
            <div key={item.key} className="mt-2 document-field__wrapper">
              <DocumentField
                label="Договор"
                name="requisite.agent_contract"
                defaultValue={department?.requisite?.agent_contract}
                onSave={(file, name) => handleInputChange({ target: { name, value: file } })}
                onDelete={() => {
                  handleInputChange({ target: { name: 'requisite.agent_contract', value: '' } });
                }}
              />
            </div>
          ) : renderDynamicInput(item);
        })}
        <div className="d-flex align-items-center mt-2 h-44px">
          <div className="name w-30">Роли</div>
          <div className="d-flex justify-content-start gap-50px">
            <div className="d-flex form-check-inline checkbox">
              <input
                type="checkbox"
                name="buyer"
                onChange={handleChangeCheckbox}
                id="checkboxBuyer"
                checked={roles.includes('buyer')}
              />
              <label className="form-check-label" htmlFor="checkboxBuyer">
                Покупаю
              </label>
            </div>
            <div className="d-flex form-check-inline checkbox">
              <input
                type="checkbox"
                name="seller"
                onChange={handleChangeCheckbox}
                id="checkboxSeller"
                checked={roles.includes('seller')}
              />
              <label className="form-check-label" htmlFor="checkboxSeller">
                Продаю
              </label>
            </div>
          </div>
        </div>
      </div>
      {errors && Object.values(errors).map((error) => (
        <Alert key={error} variant="danger">
          {error}
        </Alert>
      ))}
      <button onClick={addDepartment} className="btn btn-primary mt-4 w-100" type="button">
        Сохранить подразделение
      </button>
    </div>
  );
});
