import { observer } from "mobx-react-lite"
import moment from "moment"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { getUserGroupsRequest } from "@api/user-groups"
import { Pagination } from "@components/pagination/pagination"
import { Table } from "@components/table/table"
import { meStore } from "@mobx/me"

import Filter from "@components/filters/Filter/Filter"
import FilterButton from "@components/filters/FilterButton/FilterButton"
import "./user-tabs.scss"

const COLUMNS = [
	{
		dataField: "name",
		text: "Имя",
		sort: true,
		formatter: (cell, row) => <Link to={`/user-group/${row.id}`}>{row.name}</Link>
	},
	{
		dataField: "created_at",
		text: "Дата создания",
		formatter: (cell, row) => moment(row.created_at).format("DD-MM-YYYY"),
		sort: true
	},
	{
		dataField: "role",
		text: "Роль",
		formatter: (cell, row) => row.role?.description ?? '-',
		sort: true
	}
]

export const GroupsTab = observer(() => {

	const [filterData, setFilterData] = useState([
		{
			id: 0,
			name: "filters[name]",
			type: "text",
			placeholder: "Имя"
		}
	])

	const [isReadyFilter, setIsReadyFilter] = useState(false)
	const [filterShow, setFilterShow] = useState(false)

	const [userGroups, setUserGroups] = useState()
	const [filters, setFilters] = useState({
		name: ""
	})

	useEffect(() => {
		setIsReadyFilter(true)
	}, [])

	const onPagination = (params) => {
		const newParams = { ...params }

		if (filters.name) {
			newParams["filters[name]"] = filters.name
		}

		getUserGroupsRequest(newParams).then(setUserGroups)
	}

	const onSubmit = (data) => {
		setFilterShow(false)
		if (Object.keys(data).length === 0) {
			getUserGroupsRequest({}).then(setUserGroups)
			return false
		}
		getUserGroupsRequest({ page: 1, per_page: userGroups?.per_page, ...data }).then(setUserGroups)
	}

	const onCloseFilter = () => {
		setFilterShow(false)
	}

	return (
		<div className="group-tab-wrapper">

			{isReadyFilter &&
				<Filter
					data={filterData}
					typeFilter={"filter:usersGroups"}
					typeFilterData={"filter:usersGroups:data"}
					mobileShow={filterShow}
					onSubmit={onSubmit}
					onClose={onCloseFilter}
				/>
			}

			<div className="users-list">
				<div className="d-flex align-items-center justify-content-between mt-4 mb-4">
					<div className="title">Группы пользователей</div>
					{meStore.hasPermission("groups.edit") && (
						<Link to="/user-group/create" className="btn btn-primary">
							Добавить
						</Link>
					)}
				</div>
				<FilterButton indentBottom onClick={() => setFilterShow(true)} />
				{userGroups?.data?.length > 0 ? (
					<>
						<Table data={userGroups.data} columns={COLUMNS} />
						<Pagination
							data={userGroups}
							onChange={onPagination}
							perPageList={[25, 50, 100, 250, 500]}
						/>
					</>
				) : (
					<p>Нет данных</p>
				)}
			</div>
		</div>
	)
})
