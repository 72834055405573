import React from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { LotCard } from '@components/auction-item/lot-card';
import { Tooltip } from '@components/tooltip/tooltip';
import { layoutStore } from '@mobx/layout';
import { VIEW_HORIZONTAL } from '@pages/current-trades/current-trades-filters/utils';

import './car.scss';

const getCarText = (count) => {
  let end = 'ей';

  if (count === 1) {
    end = 'ь';
  } else if (count >= 2 && count <= 4) {
    end = 'я';
  }

  return `+${count} автомобил${end}`;
};

export const CarLotInfo = observer(({ auction, lot, isMobile }) => {
  return (auction && lot) ? (
    <div className={clsx('car-lot-info card-auction wrapper', { 'wrapper-full': !layoutStore.isSidebarOpened })}>
      <LotCard
        lot={lot}
        auctionId={auction.id}
        auctionType={auction?.auction_type}
        hideCars={true}
        view={VIEW_HORIZONTAL}
        showBtn={lot.sales.length > 1}
        isMobile={isMobile}
      />
      {lot.sales.length > 1 && (
        <Tooltip
          text="Лот содержит больше 1 автомобиля. Ставка ставится на все автомобили в лоте"
          isDisabled={isMobile}
        >
          <div className="car-lot-info__button">
            {getCarText(lot.sales.length - 1)}
          </div>
        </Tooltip>
      )}
    </div>
  ) : null;
});
