import React, { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { getReportsCategories } from "@api/reporting";
import { meStore } from "@mobx/me";
import { NO_PERMISSION_URL } from "@utils/common";

import { ReportMakeSellers } from "./report-make-tabs/report-make-sellers";
import { ReportMakeCars } from "./report-make-tabs/report-make-cars";

import "./report-make.scss";

const SELLER = "seller";
const SALE = "sale";

const TABS = [
	{ id: SELLER, title: "По продавцам" },
	{ id: SALE, title: "По автомобилям" }
];

export const ReportMake = () => {
	const [categories, setCategories] = useState();
	const [searchParams, setSearchParams] = useSearchParams();
	const [activeTab, setActiveTab] = useState(searchParams.get("tab") || TABS[0].id);

	useEffect(() => {
		getReportsCategories().then((resp) => setCategories(resp));
	}, []);

	if (meStore.noPermission("reports.use")) {
		return <Navigate to={NO_PERMISSION_URL} />;
	}

	if (meStore.data === null) {
		return null;
	}

	return (
		<div className="reporting-wrapper">
			<div className="reporting-wrapper__head-text">Отчетность</div>
			<div className="tabs-wrapper">
				<div className="tabs d-flex">
					{TABS.map((tab) => {
						return (
							<div key={tab.id}>
								<button
									onClick={() => {
										setActiveTab(tab.id);
										setSearchParams({ tab: tab.id });
									}}
									className={`tab-btn ${tab.id === activeTab ? "tab-btn-active" : ""}`}
									type="button"
								>
									{tab.title}
								</button>
							</div>
						);
					})}
				</div>
			</div>
			<div className="mt-20px">
				{activeTab === SELLER && <ReportMakeSellers seller={categories?.seller} />}
				{activeTab === SALE && <ReportMakeCars sales={categories?.sales} />}
			</div>
		</div>
	);
};
