import React, { ReactNode } from "react";
import styles from "./Btn.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";

type BtnProps = {
	href?: string
	className?: string
	children: ReactNode
	color?: "blue" | "white" | "redBorder"
	type?: "submit" | "reset" | "button" | undefined
	size?: "normal" | "small"
	fontSize?: "fontSmall" | "fontNormal"
	state?: object
	download?: boolean
	onClick?(): void
}

const Btn = ({
	             href,
	             type,
	             color = "blue",
	             className,
	             children,
	             size = "normal",
	             fontSize = "fontNormal",
	             state,
	             onClick,
	             download
             }: BtnProps) => {

	if (type) {
		return (
			<button onClick={onClick} type={type}
			        className={classNames(styles.Btn, styles[color], styles[size], styles[fontSize], className)}>
				{children}
			</button>
		);
	}

	return (
		<div onClick={onClick}
		     className={classNames(styles.Btn, styles[color], styles[size], styles[fontSize], className)}>
			{children}
			{href ? download ? <a href={href} download></a> : <Link to={href} state={state} /> : null}
		</div>
	);
};

export default Btn;
