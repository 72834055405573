import { observer } from "mobx-react-lite"
import { useEffect, useMemo, useState } from "react"
import { useFieldArray } from "react-hook-form"
import { useParams } from "react-router-dom"

import { deleteWheels, getWheels, postSetWheels } from "@api/reports"
import { Input } from "@components/input/input"
import { Select } from "@components/select/select"
import { Tooltip } from "@components/tooltip/tooltip"
import useFormWrapper from "@hooks/use-form-wrapper"
import useMediaQuery from "@hooks/use-media-query"
import { dictionariesStore } from "@mobx/dictionaries"
import { useAccordionButton } from "react-bootstrap/AccordionButton"

import { DEFAULT_TIRES } from "./utils"
import { WheelRow } from "./wheel-row"
import { WheelRowAll } from "./wheel-row-all"

import "./state-accordion.scss"

const SEASONS = [
	{ id: 1, name: "1 - Летняя" },
	{ id: 2, name: "2 - Зимняя" },
	{ id: 3, name: "3 - Всесезонная" }
];

export const WheelsAccordion = observer(({ eventKey, isClosed }) => {
	const isMobile = useMediaQuery();

	const decoratedOnClick = useAccordionButton(eventKey);

	const { reportId } = useParams();
	const { form } = useFormWrapper(null, {
		defaultValues: {
			wheels: [
				{
					description: "",
					is_installed: true,
					season: "1",
					tires: DEFAULT_TIRES
				}
			]
		}
	});
	const { remove } = useFieldArray({
		control: form.control,
		name: "wheels",
		defaultValues: {}
	});

	const handleRemoveSet = (index, wheelsId) => {
		remove(index);

		if (wheelsId) {
			deleteWheels(reportId, wheelsId);
		}
	};

	const [formIsValid, setFormIsValid] = useState(false);

	useEffect(() => {
		if (!dictionariesStore.dictionaries.tire_brands) {
			dictionariesStore.request("tire_brands");
		}

		form.watch();
	}, []);

	useEffect(() => {
		if (reportId) {
			getWheels(reportId).then((resp) => {
				if (resp?.length) {
					form.reset({ wheels: resp });
				}
			});
		}
	}, [reportId]);

	const addWheels = async (e) => {
		const formData = new FormData();

		form.getValues().wheels.map((value, complectIdx) => {
			for (const [key, val] of Object.entries(value)) {
				if (Array.isArray(val)) {
					val.map((wheel, fieldId) => {
						for (const [nestedKey, nestedVal] of Object.entries(
							wheel
						)) {
							if (nestedKey === "photos") {
								if (nestedVal.length) {
									nestedVal.map((img) => {
										// Добавляем в форму новые файлы, старые же файлы являются объектами с ключом file, их игнорируем.
										if (!img?.file) {
											formData.append(
												`${complectIdx}[tires][${fieldId}][photos][photos]`,
												img
											);
										}
									});
								}
							} else {
								formData.append(
									`${complectIdx}[tires][${fieldId}][${nestedKey}]`,
									nestedVal
								);
							}
						}
					});
				} else if (key === "is_installed") {
					formData.append(
						`${complectIdx}[is_installed]`,
						val ? 1 : 0
					);
				} else {
					formData.append(`${complectIdx}[${key}]`, val);
				}
			}
		});

		await postSetWheels(reportId, formData);

		getWheels(reportId).then((resp) => {
			if (resp?.data?.length) {
				// temp hack, у нас нет какого-то индекатора состояния UI/UX
				setTimeout(() => {
					// https://github.com/react-hook-form/react-hook-form/discussions/5769#discussioncomment-936557
					// If you have a group input, it's always better to register the input and let hook form know it's a group input instead of multiple inputs.
					form.register("wheels");
					form.setValue("wheels", resp);
				}, 0);
			}
		});

		decoratedOnClick(e);
	};

	const defaultButtonWrapper = (
		<Tooltip text="Заполните недостающие данные" isDisabled={isMobile}>
			<div className="save-btn btn-disabled">Сохранить</div>
		</Tooltip>
	);
	const buttonWrapperOnFormValid = (
		<button
			className="save-btn background"
			type="button"
			onClick={addWheels}
		>
			Сохранить
		</button>
	);
	/**
	 * Так как свойство disabled отключает эвенты, включая hover, для контекстной подсказки здесь используется имитация кнопки дивом с аналогичными стилями
	 */
	const buttonWrapper = useMemo(
		() => (formIsValid ? buttonWrapperOnFormValid : defaultButtonWrapper),
		[formIsValid]
	);

	const formValues = form.getValues();
	useEffect(() => {
		const isValid = formValues.wheels.reduce((acc, cur) => {
			for (let i = 0; i < cur.tires.length; i += 1) {
				if (!cur.tires[i].dimensions || !cur.tires[i].tire_brand_id) {
					return false;
				}
			}
			return acc;
		}, true);
		setFormIsValid(isValid);
	}, [formValues]);

	return (
		<div className="wheels-wrapper">
			{!isClosed &&
				<>
					<hr />
					<div className="actions">
						<button
							onClick={() => {
								form.setValue("wheels", [
									...form.getValues().wheels,
									{ tires: DEFAULT_TIRES }
								]);
							}}
							className="btn btn-primary"
							type="button"
						>
							Добавить комплект
						</button>
						{buttonWrapper}
					</div>
				</>
			}
			{form.getValues().wheels.map((table, complectIdx) => {
				return (
					<div
						key={`wheelsComplect-${complectIdx}`}
						className="table-wrapper mt-4 position-relative"
					>
						<div
							onClick={() =>
								handleRemoveSet(complectIdx, table.id)
							}
							className="close-btn position-absolute"
						>
							X
						</div>
						<div className="header d-flex align-items-center justify-content-between mob-tab-header">
							<label className="cursor-pointer d-flex gap-2 align-items-center">
								<input
									type="checkbox"
									checked={table.is_installed}
									onClick={() => {
										setTimeout(() => {
											const newWheels =
												formValues.wheels.map(
													(
														wheels,
														newWheelsIndex
													) => {
														if (
															wheels.is_installed &&
															newWheelsIndex !==
															complectIdx
														) {
															return {
																...wheels,
																is_installed: false
															};
														}

														return wheels;
													}
												);

											form.setValue("wheels", newWheels);
										}, 100);
									}}
									{...form.register(
										`wheels[${complectIdx}].is_installed`
									)}
								/>
								<span>Установлены в автомобиле</span>
							</label>

							<div className="d-flex align-items-center">
								<label htmlFor="season">Сезонность</label>
								<Select
									options={SEASONS?.map((item) => ({
										value: item.id,
										label: item.name
									}))}
									valueId={
										formValues.wheels?.[complectIdx]?.season
									}
									size="l"
									placeholder="Состояние"
									className="react-select_white"
									onChange={({ value }) =>
										form.setValue(
											`wheels[${complectIdx}].season`,
											value
										)
									}
									disabled={isClosed}
								/>
							</div>

							<div className="d-flex align-items-center">
								<label htmlFor="description">Описание</label>
								<Input
									{...form.register(
										`wheels[${complectIdx}].description`
									)}
									className="description"
									placeholder="Описание"
									color="white"
									disabled={isClosed}
								/>
							</div>
						</div>
						{!isMobile && (
							<table className="table table-bordered mt-3">
								<thead>
								<tr>
									<th>Ось</th>
									<th>Сторона</th>
									<th>Марка</th>
									<th>Размерность</th>
									<th>Фото</th>
								</tr>
								</thead>
								<tbody>
								<WheelRowAll
									form={form}
									complectIdx={complectIdx}
									tireBrands={
										dictionariesStore.dictionaries
											.tire_brands
									}
									isClosed={isClosed}
								/>
								{table.tires.map((field, fieldIdx) => (
									<WheelRow
										complectForm={form}
										complectIdx={complectIdx}
										field={field}
										fieldId={fieldIdx}
										tireBrands={
											dictionariesStore.dictionaries
												.tire_brands
										}
										key={fieldIdx + 10}
										isClosed={isClosed}
									/>
								))}
								</tbody>
							</table>
						)}
						{isMobile && (
							<WheelRowAll
								form={form}
								complectIdx={complectIdx}
								tireBrands={
									dictionariesStore.dictionaries.tire_brands
								}
								isMobile={isMobile}
								isClosed={isClosed}
							/>
						)}
						{isMobile &&
							table.tires.map((field, fieldIdx) => (
								<WheelRow
									complectForm={form}
									complectIdx={complectIdx}
									field={field}
									fieldId={fieldIdx}
									tireBrands={
										dictionariesStore.dictionaries
											.tire_brands
									}
									key={fieldIdx + 10}
									isMobile={isMobile}
									isClosed={isClosed}
								/>
							))}
					</div>
				);
			})}
		</div>
	);
});
