import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Container.module.scss'

type ContainerProps = {
	children: ReactNode
	className?: string
}

const Container = ({ children, className }: ContainerProps) => {
	return (
		<div className={classNames(className, styles.Container)}>
			{children}
		</div>
	);
};

export default Container;
