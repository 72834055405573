import React from 'react';
import ReactFormInputValidation from 'react-form-input-validation';

import { POST } from '@api/request';
import { Input } from '@components/input/input';

export class Recovery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        email: '',
      },
      errors: {},
      pageRequest: 'first',
      disabledBtn: true,
    };

    this.handleChange = this.handleChange.bind(this);

    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
      email: 'present',
    });

    this.form.onformsubmit = () => {
      POST('auth/password/reset_code', this.state.fields)
        .then((data) => {
          if (data.errors) {
            this.setState({ disabledBtn: false });
            this.setState({
              errors: data.errors,
            });
          } else {
            this.setState({
              pageRequest: 'two',
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    };
  }

  componentDidMount() {
    document.title = 'Восстановление пароля';

    this.setState({ errors: 'error' });
  }

  handleChange = (e) => {
    this.setState({
      fields: {
        email: e.target.value,
      },
    });
    this.setState({ disabledBtn: false });
  };

  typeRender = () => {
    const { pageRequest } = this.state;
    const { email } = this.state.fields;
    if (pageRequest === 'first') {
      return (
        <>
          <p className="reset-text">
            Введите ваш E-mail и мы вышлем инструкцию по восстановлению пароля
          </p>
          <form onSubmit={this.form.handleSubmit}>
            <div className="mb-3 position-relative">
              <Input
                type="email"
                name="email"
                onChange={this.handleChange}
                value={this.state.fields.email}
                className={this.state.fields.email?.trim() !== ''
                  ? 'border-green'
                  : this.state.errors.email ? 'border-red' : ''}
                placeholder="Почта"
              />

              {this.state.errors.email ? <label className="form-no-validate">{}</label> : ''}
              {this.state.errors.email === {} && this.state.fields.email.trim() !== '' ? (
                <label className="form-validate">{}</label>
              ) : (
                ''
              )}
              {this.state.errors.email ? (
                <span className="error-message">{this.state.errors.email}</span>
              ) : (
                ''
              )}
            </div>

            <div className="mb-3">
              <button
                disabled={Object.keys(this.state.errors).length && this.state.disabledBtn}
                type="submit"
                className="btn btn-primary btn-submit"
              >
                Восстановить пароль
              </button>
            </div>
          </form>
        </>
      );
    }

    if (pageRequest === 'two') {
      return (
        <>
          <div className="alert alert-success">
            Если учетная запись «{email}» существует, на данный E-mail будет отправлено письмо с
            дальнейшими инструкциями.
          </div>
          <p>Перейти в почтовый ящик</p>

          <div className="d-flex flex-wrap mail-list">
            <div className="item">
              <a href="https://mail.yandex.ru" target="_blank" rel="noreferrer">
                <img src="/img/yandex.svg" alt="" />
                <span>Яндекс</span>
              </a>
            </div>

            <div className="item">
              <a href="https://mail.google.com" target="_blank" rel="noreferrer">
                <img src="/img/gmail.svg" alt="" />
                <span>Gmail</span>
              </a>
            </div>

            <div className="item">
              <a href="https://mail.ru" target="_blank" rel="noreferrer">
                <img src="/img/mail.svg" alt="" />
                <span>Mail.ru</span>
              </a>
            </div>
          </div>
        </>
      );
    }
  };

  render() {
    return (
      <div className="form-lk-wrapper form-reset">
        <div className="title mb-2">Восстановления пароля</div>

        {this.typeRender()}
      </div>
    );
  }
}
