import React from "react";

import useMediaQuery from "@hooks/use-media-query";

import "./input-upload-photo.scss";

export const InputUploadPhoto = ({ name, handleChangeFile, selectedState }) => {
	const isMobile = useMediaQuery();

	return (
		<div className="d-flex w-100 justify-content-center">
			<div className="d-flex w-50 align-items-center position-relative">
				<div className="input-upload-photo-wrapper">
					<div className="mb-4">
						<label htmlFor="upload-photo" className="cursor-pointer w-100">
							<div className="input-upload-photo-wrapper__dashed-border">
								<div className="d-flex flex-column justify-content-center align-items-center">
									<div className="input-upload-photo-wrapper__plus">+</div>
									<div className="input-upload-photo-wrapper__text">
										{isMobile ? "Выберите файл" : "Перетащите файлы или выберите вручную"}
									</div>
								</div>
							</div>
						</label>
						<input
							type="file"
							multiple
							name={name}
							id="upload-photo"
							onChange={(e) => handleChangeFile(e, selectedState?.isDiagnostic)}
							className="input-upload-photo-wrapper__form-control"
							placeholder="Фотография"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
