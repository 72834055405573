import React, { useEffect } from 'react';
import ReactImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';

import './gallery.scss';

export const Gallery = (props) => {
  const handleKey = (e) => {
    if (e.which === 27) {
      props.onClose?.();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKey);

    return () => {
      document.removeEventListener('keydown', handleKey);
    };
  }, []);

  return props.isWithOverlay ? (
    <div className="gallery__overlay">
      <div className="gallery__wrapper">
        <span className="gallery__close" onClick={props.onClose}>
          <svg width="14" height="14" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.967.71a.996.996 0 0 0-1.41 0l-4.89 4.88L1.777.7a.996.996 0 1 0-1.41 1.41L5.257 7l-4.89 4.89a.996.996 0 1 0 1.41 1.41l4.89-4.89 4.89 4.89a.996.996 0 1 0 1.41-1.41L8.077 7l4.89-4.89c.38-.38.38-1.02 0-1.4Z"
            />
          </svg>
        </span>
        <ReactImageGallery {...props} />
      </div>
    </div>
  ) : (
    <ReactImageGallery {...props} />
  );
};
