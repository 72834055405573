import classNames from 'classnames'
import UploadedFile from '../UploadedFile/UploadedFile'
import styles from "./UploadFileList.module.scss"

export interface UploadFile {
	id: number
	original_name: string
	mime_type: string
	size: number
	path: string
	created_at: string
	updated_at: string
	url: string
}

export interface UploadDocumentFile {
	id: number
	document_id: number
	file_id: number
	file: UploadFile
}

export interface UploadDocumentsProps {
	id: number
	documentable_type: string
	documentable_id: number
	document_type_id: number
	description: string
	created_at: string
	updated_at: string
	document_files: UploadDocumentFile[]
}

interface UploadFileListProps {
	className?: string
	documents: UploadDocumentsProps[]
	isDelete?: boolean
	onRemoveFile(id: number): void
}

const UploadFileList = (props: UploadFileListProps) => {
	const { className, documents, isDelete, onRemoveFile } = props

	return (
		<>
			{documents?.length ?
				<div className={classNames(styles.UploadFileList, className, isDelete && styles.edit)}>
					{documents.map(x =>
						<UploadedFile
							key={x.id}
							name={x.description}
							size={x.document_files[0]?.file?.size}
							type={x.document_files[0]?.file?.url?.split('.').pop()}
							id={x.id}
							url={x.document_files[0]?.file?.url}
							isDelete={isDelete}
							onRemove={onRemoveFile}
						/>
					)}
				</div> :
				<div>Документы отсутствуют</div>
			}
		</>
	)
}

export default UploadFileList