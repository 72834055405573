import moment from "moment/moment"
import Countdown from "react-countdown"
import { Link } from "react-router-dom"

import { putApproveLot } from "@api/auctions"
import { Button } from "@components/button/button"
import {
	LOT_GREEN_STATUSES,
	LOT_GREY_STATUSES,
	LOT_ORANGE_STATUSES,
	LOT_RED_STATUSES
} from "@utils/common"
import { formatValueWithSpaces } from "@utils/format"

import { setDateLocale } from "../car/car-info/components/table-templates"

/**
 * @param targetDate {string}
 * @returns {number|string}
 */
export const countTimeDifference = (targetDate) => {
	const currentDate = moment()
	const timeDiff = moment(targetDate).diff(currentDate)
	return timeDiff > 0 ? timeDiff : "-" // проверяем, что дата еще не наступила
}

/**
 * @param timer {number|string}
 * @returns {JSX.Element|string}
 */
export const resolveTimerType = (timer) => {
	return timer && typeof timer === "number" ? (
		<Countdown date={moment() + timer} />
	) : (
		timer
	) // Компонент не нужен, т.к. после возвращения заглушка будет обернута
}

/**
 * Функция создает таймер по входящему условию
 * @param targetDate {string} timestamp либо строковая заглушка
 * @returns {JSX.Element|string}
 */
export const generateTimerFromDate = (targetDate) => {
	if (!(targetDate instanceof Date)) return targetDate
	return resolveTimerType(countTimeDifference(targetDate))
}

export const getClassName = (status) => {
	if (LOT_RED_STATUSES.includes(status)) {
		return "lot-status red"
	}
	if (LOT_GREEN_STATUSES.includes(status)) {
		return "lot-status green"
	}
	if (LOT_GREY_STATUSES.includes(status)) {
		return "lot-status grey"
	}
	if (LOT_ORANGE_STATUSES.includes(status)) {
		return "lot-status orange"
	}
}

const elIcon = (
	lot,
	setIsAuctionEditTradesWinner,
	setIsShowAuctionEditTradesModal
) => {
	return (
		lot.auction?.is_finished &&
		lot.status?.code !== "lot_declined_seller" &&
		lot.status?.code !== "lot_new" &&
		lot.status?.code !== "lot_no_bets" && (
			<img
				src="/img/icons/edit-icon.svg"
				className="edit-icon"
				alt=""
				onClick={() => {
					setIsAuctionEditTradesWinner(false)
					setIsShowAuctionEditTradesModal(lot)
				}}
			/>
		)
	)
}

const lotsActionFormatter = (
	id,
	row,
	cell,
	requestCallback,
	setIsShowAuctionEditTradesModal,
	setIsAuctionEditTradesWinner,
	auctionStatus
) => {

	return auctionStatus === "auction_finished" &&
		row.status?.code === "lot_waiting_approval" &&
		row.actions?.approve ? (
		<div className="d-flex flex-wrap gap-5px">
			<Button
				size="xs"
				className="d-flex gap-5px align-items-center"
				onClick={() => {
					putApproveLot(id, row.id, true)
					requestCallback(id)
				}}
			>
				<img src="/img/icons/tick-icon.svg" alt="" />
				Подтвердить
			</Button>
			<Button
				size="xs"
				preset="secondary"
				className="d-flex gap-5px align-items-center"
				onClick={() => {
					putApproveLot(id, row.id, false)
					requestCallback(id)
				}}
			>
				<img src="/img/icons/cancel-black-icon.svg" alt="" />
				Отклонить
			</Button>
		</div>
	) : (
		<div className="d-flex align-items-center gap-10px">
			{formatValueWithSpaces(row?.winning_bet?.sum)}
			{elIcon(
				row,
				setIsAuctionEditTradesWinner,
				setIsShowAuctionEditTradesModal
			)}
		</div>
	)
}

export const getLotsColumns = (
	auctionId,
	requestCallback,
	setLotId,
	setShowTradesHistoryModal,
	setIsShowAuctionEditTradesModal,
	setIsAuctionEditTradesWinner,
	elStatusLot,
	auctionStatus
) => {
	return [
		{
			dataField: "lot",
			text: "Лот",
			formatter: (cell, row) => {
				let relatedStatusReports = row.relatedStatusReport
				return (
					<div className="d-grid gap-5px w-200">
						{row.sales?.map((sale, index) => {
							let currentStatusReport = relatedStatusReports.find(x => x.sale_id === sale.id)
							let urlStatusReport = currentStatusReport?.status_report?.id ? `&statusReportId=${currentStatusReport?.status_report?.id}` : ""
							return (
								<Link
									to={`/car/${sale.id}?auctionId=${row.auction_id}&lotId=${row.id}&lotSale=true${urlStatusReport}`}
									key={`sale-${index}`}
									className="table__file-blue-text mh-60px"
								>
									{sale.car?.brand?.name}{" "}
									{sale.car?.model?.name} {sale.car?.year}{" "}
									г.в. {sale.car?.engine_volume}{" "}
									{sale.car?.transmission?.name}{" "}
									{sale.car?.vin}
								</Link>
							)
						})}
					</div>
				)
			}
		},
		{
			dataField: "winning_bet",
			text: "Лидер",
			formatter: (cell, row) => {
				let field = row.bets?.length && row.bets[row.bets.length - 1]
				return (
					<div className="w-250">
						{field ? (
							<div>
								<Link
									to={`/user/${field.user?.id}`}
									className="table__file-blue-text"
								>
									{field.user?.display_name}
								</Link>
								{elIcon(
									row,
									setIsAuctionEditTradesWinner,
									setIsShowAuctionEditTradesModal
								)}
							</div>
						) : (
							"-"
						)}
					</div>
				)
			}
		},
		{
			dataField: "company_id",
			text: "Компания",
			formatter: (cell, row) => {
				let field = row.bets?.length && row.bets[row.bets.length - 1]
				return (
					<div className="w-200">
						{field ? (
							<div>
								<Link
									to={`/company/${field.company?.id}`}
									className="table__file-blue-text"
								>
									{field.company?.display_name}
								</Link>
							</div>
						) : (
							"-"
						)}
					</div>
				)
			}
		},
		{
			dataField: "city_id",
			text: "Город",
			formatter: (cell, row) => (
				<div className="d-grid gap-5px w-100">
					{row.sales.length
						? row.sales.map((sale, index) => (
							<div
								key={`${sale.city?.name}-${index}`}
								className="mh-60px"
							>
								{sale.city?.name}
							</div>
						))
						: "-"}
				</div>
			)
		},
		{
			dataField: "status",
			text: "Статус",
			formatter: (cell, row) => (
				<div onClick={() => setLotId(row.id)} className="w-150">
					{elStatusLot(
						row.auction.id,
						row.id,
						row?.status?.code,
						row.status?.name
					)}
				</div>
			)
		},
		{
			dataField: "starting_price",
			text: "Стартовая стоимость",
			formatter: (cell, row) => (
				<div className="mh-60px w-100">
					<div>{formatValueWithSpaces(row.starting_price)}</div>
				</div>
			)
		},
		{
			dataField: "minimal_price",
			text: "Минимальная цена",
			formatter: (cell, row) => (
				<div className="mh-60px w-100">
					<div>{formatValueWithSpaces(row.minimal_price)}</div>
				</div>
			)
		},
		{
			dataField: "minimal_step",
			text: "Шаг повышения/понижения",
			formatter: (cell, row) => (
				<div className="mh-60px w-100">
					<div>{formatValueWithSpaces(row.minimal_step)}</div>
				</div>
			)
		},
		{
			dataField: "blitz_price",
			text: "Блиц цена",
			formatter: (cell, row) => (
				<div className="mh-60px w-100">
					<div>
						{row.blitz_price
							? formatValueWithSpaces(row.blitz_price)
							: "-"}
					</div>
				</div>
			)
		},
		{
			dataField: "lastBet",
			text: "Текущая ставка",
			formatter: (cell, row) => (
				<div className="mh-60px w-100">
					<div>{formatValueWithSpaces(row?.lastBet?.sum)}</div>
					<div
						className="table__trades-history"
						onClick={() => {
							setLotId(row.id)
							setShowTradesHistoryModal(true)
						}}
					>
						<img src="/img/icons/trades-history-icon.svg" alt="" />
						Ход торгов
					</div>
				</div>
			)
		},
		{
			dataField: "action",
			text: "Подтвержденная",
			formatter: (cell, row) =>
				lotsActionFormatter(
					auctionId,
					row,
					cell,
					requestCallback,
					setIsShowAuctionEditTradesModal,
					setIsAuctionEditTradesWinner,
					auctionStatus
				)
		}
	]
}

export const getBetsColumns = () => {
	return [
		{
			dataField: "lots",
			text: "Лот",
			formatter: (cell, row) => (
				<div>
					{row.sales.length
						? row.sales.map((sale, index) => (
							<Link
								to={`/car/${sale.car.id}`}
								key={`${sale.car?.name}-${index}`}
							>
								{sale.car.brand?.name}{" "}
								{sale.car.model?.name}{" "}
								{sale.car.year
									? `${sale.car.year} г.в.`
									: ""}{" "}
								{sale.car.engine_volume}{" "}
								{sale.car.transmission?.name}
								<br />
							</Link>
						))
						: "-"}
				</div>
			)
		},
		{
			dataField: "auto_bets",
			text: "Автоставка",
			formatter: (cell, row) => (
				<div>
					{row.auto_bets.length
						? row.auto_bets.map((bet, index) => (
							<div key={`${bet.id}-${index}`}>
								{formatValueWithSpaces(bet.max_sum)}
							</div>
						))
						: "-"}
				</div>
			)
		},
		{
			dataField: "updated_at",
			text: "Дата",
			formatter: (cell, row) => (
				<div>
					{row.auto_bets
						? row.auto_bets.map((bet, index) => (
							<div key={`${bet.id}-dates-${index}`}>
								{bet?.updated_at &&
									setDateLocale(bet.updated_at)}
							</div>
						))
						: "-"}
				</div>
			)
		},
		{
			dataField: "sales",
			text: "Покупатель",
			formatter: (cell, row) => (
				<div>
					{row.auto_bets.length
						? row.auto_bets.map((bet, index) => (
							<Link
								to={`/user/${bet?.user.id}`}
								key={`${bet?.user.full_name}-${index}`}
							>
								{bet.user.full_name}
								<br />
							</Link>
						))
						: "-"}
				</div>
			)
		},
		{
			dataField: "was_extended",
			text: "Компания",
			formatter: (cell, row) => (
				<div>
					{row.auto_bets.map((bet, index) => {
						return (
							<div key={`${bet?.company?.id}-${index}`}>
								{bet?.company?.display_name ?? "-"}
							</div>
						)
					})}
				</div>
			)
		}
	]
}
