import React from 'react';
import moment from 'moment/moment';

const userFormatter = (cell, row) => {
  return <a href={`/user/${row.id}`} target="_blank">{row.full_name}</a>;
};

const dateFormatter = (cell, row) => {
  return moment(row.created_at).format('DD.MM.YYYY');
};

export const COLUMNS = [
  {
    dataField: 'first_name',
    text: 'ФИО',
    sort: true,
    checked: true,
    formatter: userFormatter,
  },
  {
    dataField: 'phone',
    text: 'Телефон',
    sort: true,
    checked: true,
  },
  {
    dataField: 'status',
    text: 'Статус',
    sort: true,
    checked: true,
    formatter: (cell, row) => row.status?.name || '',
  },
  {
    dataField: 'created_at',
    text: 'Дата создания',
    checked: true,
    formatter: dateFormatter,
    sort: true,
  },
];

export const GROUP_COLUMNS = [
  {
    dataField: 'name',
    text: 'Название',
    sort: true,
  },
  {
    dataField: 'created_at',
    text: 'Дата создания',
    formatter: dateFormatter,
    sort: true,
  },
];
