import moment from "moment/moment"
import { Link } from "react-router-dom"

export const getColumns = (setSorting, sortFunc) => [
	{
		dataField: "first_name",
		text: "ФИО",
		sort: true,
		formatter: (cell, row) => <Link
			to={`/user/${row.id}`}>{row.last_name} {row.first_name} {row.middle_name ? row.middle_name : ""}</Link>,
		sortFunc: () => {
		},
		onSort: (field, order) => {
			setSorting({ field, order })
			if (sortFunc) sortFunc({ field, order })
		}
	},
	{
		dataField: "phone",
		text: "Телефон"
	},
	{
		dataField: "status",
		text: "Статус",
		formatter: (cell, row) => (row.status.code === "user_new" ?
			<span className="green">{row.status.name}</span> : row.status?.name)
	},
	{
		dataField: "created_at",
		text: "Создан",
		formatter: (cell, row) => moment(row.created_at).format("DD.MM.YYYY"),
		sort: true,
		sortFunc: () => {
		},
		onSort: (field, order) => {
			setSorting({ field, order })
			if (sortFunc) sortFunc({ field, order })
		}
	},
	{
		dataField: "city",
		text: "Город",
		formatter: (cell, row) => <div>{row.city ? row.city.name : "-"}</div>
	},
	{
		dataField: "last_active_at",
		text: "Последняя активность",
		formatter: (cell, row) => (row.last_active_at ? moment(row.last_active_at).format("DD.MM.YYYY") : "-"),
		sort: true,
		sortFunc: () => {
		},
		onSort: (field, order) => {
			setSorting({ field, order })
			if (sortFunc) sortFunc({ field, order })
		}
	},
	{
		dataField: "roles",
		text: "Роль",
		formatter: (cell, row) => <div className="role-wrapper">
			{row?.roles.map(x => <div className="role" key={x.id}>{x.description}</div>)}
		</div>
	},
	{
		dataField: "email",
		text: "E-mail",
		formatter: (cell, row) => {
			return (
				<div className="break-word">{row.email || "-"}</div>
			)
		}
	},
	{
		dataField: "email_verified",
		text: "E-mail подтвержден",
		formatter: (cell, row) => {
			return (
				<div>{row.email_verified_at ? "Да" : "Нет"}</div>
			)
		}
	},
	{
		dataField: "companies",
		text: "Название компании",
		headerStyle: () => {
			return { width: '200px' }
		},
		formatter: (cell, row) => {
			return (
				<div>{row.companies?.length ?
					row.companies.map(x =>
						<div key={x.id} className='mb-2'>
							<Link to={`/company/${x.id}`} title={x.display_name} className='users-company-name'>{x.display_name}</Link>
						</div>
					)
					: '-'}
				</div>
			)
		}
	},
	{
		dataField: "admin_comment",
		text: "Комментарий",
		formatter: (cell, row) => {
			return (
				<div className="line-clamp-2">{row.admin_comment ?? "-"}</div>
			)
		}
	}
]
