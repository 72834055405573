import { makeAutoObservable } from 'mobx';

import { getSaleBrandsRequest } from '@api/dictionaries';
import { getOption } from '@utils/common';

class CarBrandsStore {
  constructor() {
    this.brands = [];

    makeAutoObservable(this);
  }

  fetch() {
    getSaleBrandsRequest('car').then((resp) => {
      this.brands = resp.map(getOption);
    });
  }
}

export const carBrandsStore = new CarBrandsStore();
