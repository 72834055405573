import React from 'react';
import moment from 'moment/moment';

import { bytesToMegaBytes } from '@utils/format';
import { getFileExtension } from '@utils/common';

export const COLUMNS = [
  {
    dataField: 'type',
    text: 'Тип отчета',
  },
  {
    dataField: 'created_at',
    text: 'Создан',
    formatter: (cell, row) => (
      <>
        <div>{moment(row.created_at).format('DD.MM.YYYY')}</div>
        <div className="fs-12px text-secondary">{moment(row.created_at).format('HH:mm')}</div>
      </>
    ),
  },
  {
    dataField: 'expiration_at',
    text: 'Хранится до',
    formatter: (cell, row) => {
      const expDate = moment(row.created_at).add(30, 'days');

      return (
        <>
          <div>{expDate.format('DD.MM.YYYY')}</div>
          <div className="fs-12px text-secondary">{expDate.format('HH:mm')}</div>
        </>
      );
    },
  },
  {
    dataField: 'user',
    text: 'Пользователь',
    formatter: (cell, row) => row?.created_by?.full_name || '-',
  },
  {
    dataField: 'file',
    text: 'Файл с отчетом',
    formatter: (cell, row) => (row?.file ? (
      <>
        <a
          href={row.file.url}
          target="_blank"
          className="d-flex align-items-center report_link"
          rel="noreferrer"
          download={`${row.type} Аукцион#${row.params.auction_id}.${getFileExtension(row.file.url)}`}
        >
          <img src="/img/icons/download-file-icon.svg" alt="" /> Скачать отчет
        </a>
        <div className="text-secondary fs-12px">{bytesToMegaBytes(row.file.size)} Мб</div>
      </>
    ) : <div className="text-secondary">Нет</div>),
  },
];
