import React, { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './ContainerPage.module.scss'

type ContainerPageProps = {
	children: ReactNode
	className?: string

}

const ContainerPage = ({ children, className }: ContainerPageProps) => {
	return (
		<div className={clsx(styles.ContainerPage, className)}>
			{children}
		</div>
	);
};

export default ContainerPage;
