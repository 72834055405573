import React from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";

import "./loader.scss";

const propTypes = {
	fixed: PropTypes.bool,
	noPadding: PropTypes.bool,
	padding: PropTypes.string
};

export const Loader = (props) => {
	return (
		<div className={clsx("loader-wrapper", {
			"loader-wrapper_fixed": props.fixed,
			"loader-wrapper-no-padding": props.noPadding
		})}>
			<Spinner variant="primary" animation="border"
					 style={props.padding ? { padding: props.padding } : undefined} />
		</div>
	);
};

Loader.propTypes = propTypes;
