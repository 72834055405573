type FormToJsonPropsAny = any;
export type FormToJsonProps = FormToJsonPropsAny;

const isCheckbox = (element: any) => element.type === "checkbox";

const isRadio = (element: any) => element.type === "radio";

const isMultiSelect = (element: any) => element.options && element.multiple;

const isValidElement = (element: any) =>
	element.name && element.value && !element.disabled;

const isValidValue = (element: any) => {
	return !["checkbox", "radio"].includes(element.type) || element.checked;
};

const getSelectValues = (options: any) =>
	[].reduce.call(
		options,
		(values: any, option: any) => {
			return option.selected ? values.concat(option.value) : values;
		},
		[]
	);

export const formToJSON = (elements: any) =>
	Array.from(elements).reduce<FormToJsonProps>(
		(data: any, element: any) => {
			if (isValidElement(element) && isValidValue(element)) {
				if (
					element.type === "hidden" &&
					element.name.indexOf("[]") !== -1
				) {
					data[element.name] = (data[element.name] || []).concat(
						element.value
					);
				} else if (isCheckbox(element)) {
					data[element.name] = (data[element.name] || []).concat(
						element.value
					);
				} else if (isRadio(element)) {
					data[element.name] = element.checked && element.value;
				} else if (isMultiSelect(element)) {
					data[element.name] = getSelectValues(element);
				} else {
					data[element.name] = element.value;
				}
			}

			return data || {};
		},

		{}
	);

// FIXME: убрал,  && element.value чтобы пустые поля тоже отсылались
const isValidEmptyElement = (element: any) =>
	element.name && !element.disabled;

export const formToJSONWithEmpryString = (elements: any) =>
	Array.from(elements).reduce<FormToJsonProps>(
		(data: any, element: any) => {
			if (isValidEmptyElement(element) && isValidValue(element)) {
				if (
					element.type === "hidden" &&
					element.name.indexOf("[]") !== -1
				) {
					data[element.name] = (data[element.name] || []).concat(
						element.value
					);
				} else if (element.type === "hidden") {
					if (element.value) data[element.name] = element.value;
				} else if (isCheckbox(element)) {
					data[element.name] = (data[element.name] || []).concat(
						element.value
					);
				} else if (isRadio(element)) {
					data[element.name] = element.checked && element.value;
				} else if (isMultiSelect(element)) {
					data[element.name] = getSelectValues(element);
				} else {
					data[element.name] = element.value;
				}
			}

			return data || {};
		},

		{}
	);
