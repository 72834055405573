import classNames from 'classnames'
import { Loader } from '../../loader/loader'
import styles from "./Upload.module.scss"

interface UploadProps {
	className?: string
	accept?: string
	loading?: boolean
	onAddDocument(e: File[]): void
}

const Upload = (props: UploadProps) => {
	const { className, accept = '.jpg,.jpeg,.png,.doc,.docx,.pdf', loading, onAddDocument } = props

	const onAddDocumentHandler = (e: any) => {
		onAddDocument(e.target.files)
	}

	return (
		<div className={classNames(styles.Upload, className)}>
			{loading ? <Loader /> :
				<label className={styles.document}>
					<input
						type="file"
						onChange={onAddDocumentHandler}
						className="document-field__upload-input"
						accept={accept}
					/>
					<div
						className="dashed-border d-flex justify-content-center align-items-center upload-document">
						<div className="upload-text">
							<div className="upload-plus">+</div>
							<span>Выберите файл</span>
						</div>
					</div>
				</label>
			}
		</div>
	)
}

export default Upload