import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { POST } from '@api/request';
import { Input } from '@components/input/input';
import { getLogoSrc } from '@utils/common';

export const ResetConfirm = () => {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [passwordConfirmType, setPasswordConfirmType] = useState('password');
  const [error, setError] = useState({});
  const [isSuccessReset, setIsSuccessReset] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    if (password === passwordConfirm) {
      POST('auth/password/reset', { password, token: searchParams.get('token') })
        .then((data) => {
          if (data.message) {
            setError(data.errors || { error: data.message });

            setTimeout(() => {
              setError({});
            }, 5000);
          } else {
            setIsSuccessReset(true);
          }
        })
        .catch(console.log);
    }
  };

  return (
    <div className="login-wrapper">
      <img className="logo" src={getLogoSrc()} alt="logo" />
      <div className="form-lk-wrapper form-signup">
        <div className="title">Восстановления пароля</div>
        {isSuccessReset ? (
          <div className="alert alert-success">Пароль успешно изменен!</div>
        ) : (
          <form onSubmit={onSubmit}>
            <p className="reset-text">
              Создайте новый пароль, который вы не использовали на других сайтах
            </p>
            <div className="mb-3 position-relative">
              <Input
                type={passwordType}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                className={error.password ? 'border-red' : (password.trim() !== '' ? 'border-green' : '')}
                placeholder="Новый пароль"
              />
              {error.password && <span className="form-no-validate" />}
              {password.trim() !== '' ? <span className="form-validate" /> : ''}
              {error.password && <span className="error-message">{error.password}</span>}
              <span
                onClick={() => setPasswordType((prev) => (prev === 'text' ? 'password' : 'text'))}
                className={passwordType === 'password' ? 'hide-password-input' : 'hide-password-input-active'}
              />
            </div>
            <div className="mb-3 position-relative">
              <Input
                type={passwordConfirmType}
                name="password_confirmation"
                onChange={(e) => setPasswordConfirm(e.target.value)}
                value={passwordConfirm}
                className={(passwordConfirm !== '' && (error.password_confirmation || password !== passwordConfirm)) ? 'border-red' : (
                  passwordConfirm.trim() !== '' ? 'border-green' : '')}
                placeholder="Новый пароль ещё раз"
              />
              {error.password_confirmation && <span className="form-no-validate" />}
              {(passwordConfirm.trim() !== '' && password === passwordConfirm) && <span className="form-validate" />}
              {(passwordConfirm !== '' && password !== passwordConfirm) && <span className="error-message">Пароли не совпадают</span>}
              {error?.password_confirmation && <span className="error-message">{error.password_confirmation}</span>}
              <span
                onClick={() => setPasswordConfirmType((prev) => (prev === 'text' ? 'password' : 'text'))}
                className={passwordConfirmType === 'password' ? 'hide-password-input' : 'hide-password-input-active'}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-10px w-100"
            >
              Сохранить новый пароль
            </button>
            {Object.keys(error).length > 0 && (
            <div className="mt-20px mb-2 alert alert-danger">{JSON.stringify(error, null, ' ')}</div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};
