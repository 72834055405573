import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { postActivatePromo } from '@api/users';
import { meStore } from '@mobx/me';
import { DEFAULT_APP_URL, getLogoSrc } from '@utils/common';

export const CodeAuth = () => {
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    meStore.fetch({ skipRedirect401: true }).then((resp) => {
      if (!resp.message) {
        code && postActivatePromo(code);
        navigate(DEFAULT_APP_URL);
      }
    });
  }, []);

  return (
    <div className="login-wrapper">
      <img className="logo" src={getLogoSrc()} alt="logo" />
      <div className="form-lk-wrapper form-auth">
        <div className="mb-3">
          <Link to={`/auth/login/${code}`} className="btn btn-primary btn-submit">
            Войти
          </Link>
        </div>
        <div className="mb-3">
          <Link to={`/auth/simple-register/${code}`} className="btn btn-secondary btn-submit">
            Регистрация
          </Link>
        </div>
      </div>
    </div>
  );
};
