import React from 'react';
import { observer } from 'mobx-react-lite';

import { notificationsStore } from '@mobx/notifications';
import { AUTO_BET_SUCCESS, BET_ERROR, BET_SUCCESS } from '@components/notifications/utils';

import './notifications.scss';

export const Notifications = observer((props) => {
  if (!notificationsStore.notifications.length) {
    return null;
  }

  const closeNotification = (id) => {
    notificationsStore.closeNotification(id);
  };

  return (
    <div className="notifications">
      {notificationsStore.notifications.map((item, index) => {
        if (item.type === AUTO_BET_SUCCESS) {
          return (
            <div className="notifications__auto-bet" key={`${index}-${item.auctionType}`}>
              <img src="/img/icons/success-status-icon.svg" />
              <div className="notifications__auto-bet-text">Автоставка принята</div>
              <span className="notifications__auto-bet-close" onClick={() => closeNotification(item.id)}>
                <img src="/img/icons/close.svg" />
              </span>
            </div>
          );
        }
        if (item.type === BET_SUCCESS) {
          return (
            <div className="notifications__auto-bet" key={`${index}-${item.auctionType}`}>
              <img src="/img/icons/success-status-icon.svg" />
              <div className="notifications__auto-bet-text">
                {item.auctionType === 'parallel_increase' ? 'Ставка принята' : 'Лот куплен'}
              </div>
              <span className="notifications__auto-bet-close" onClick={() => closeNotification(item.id)}>
                <img src="/img/icons/close.svg" />
              </span>
            </div>
          );
        }
        if (item.type === BET_ERROR) {
          return (
            <div className="notifications__auto-bet" key={`${index}-${item.auctionType}`}>
              <img src="/img/icons/error-status-icon.svg" />
              <div className="notifications__auto-bet-text">{item.text}</div>
              <span className="notifications__auto-bet-close" onClick={() => closeNotification(item.id)}>
                <img src="/img/icons/close.svg" />
              </span>
            </div>
          );
        }
      })}
    </div>
  );
});
