import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { postChangeProfilePassword, postChangeUsersPassword } from '@api/users';
import { meStore } from '@mobx/me';
import { NO_PERMISSION_URL } from '@utils/common';
import { Input } from '@components/input/input';
import { IconTick } from '@components/icons/tick';

import './change-password.scss';

export const ChangePassword = observer(({ isProfile }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState({
    password: false,
    repeatedPassword: false,
  });

  const {
    register,
    handleSubmit,
  } = useForm({ mode: 'onChange', defaultValues: { password: '', repeatedPassword: '' }, reValidateMode: 'onChange' });

  if (!isProfile && meStore.noPermission('admin.users.edit')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  if (meStore.data === null) {
    return null;
  }

  const toggleShowPassword = (name) => {
    setShowPassword((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const onFormSubmit = (data) => {
    if (data.password === data.repeatedPassword) {
      if (isProfile) {
        postChangeProfilePassword(data.password).then((resp) => {
          if (resp.message) {
            setError(resp.message);

            setTimeout(() => setError(null), 5000);
          } else {
            setSuccess('Пароль успешно изменен');
            navigate('/profile');
          }
        });
      } else {
        postChangeUsersPassword(id, data.password).then((resp) => {
          if (resp.message) {
            setError(resp.message);

            setTimeout(() => setError(null), 5000);
          } else {
            setSuccess('Пароль успешно изменен');
            navigate(`/user/${id}`);
          }
        });
      }
    } else {
      setError('Пароли не совпадают');
      setTimeout(() => setError(null), 5000);
    }
  };

  return (
    <div className="user-profile">
      <div className="title">Сменить пароль</div>

      <div className="change-password mt-4">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="change-password__field-wrapper">
            <div className="change-password__field-wrapper-name">Новый пароль</div>
            <div className="change-password__field-wrapper-input">
              <Input
                type={showPassword.password ? 'text' : 'password'}
                placeholder="Пароль"
                {...register('password', {
                  required: true,
                })}
              />
              <span
                onClick={() => toggleShowPassword('password')}
                className={showPassword.password ? 'hide-password-input' : 'hide-password-input-active'}
              />
            </div>
          </div>
          <div className="change-password__field-wrapper">
            <div className="change-password__field-wrapper-name">Повторите новый пароль</div>
            <div className="change-password__field-wrapper-input">
              <Input
                type={showPassword.repeatedPassword ? 'text' : 'password'}
                placeholder="Повторите пароль"
                {...register('repeatedPassword', {
                  required: true,
                })}
              />
              <span
                onClick={() => toggleShowPassword('repeatedPassword')}
                className={showPassword.password ? 'hide-password-input' : 'hide-password-input-active'}
              />
            </div>
          </div>
          <div className="change-password__field-wrapper-btns">
            <button type="submit" className="btn btn-primary">
              <IconTick />
              Сохранить
            </button>
            <Link to={isProfile ? '/profile' : `/user/${id}`} className="btn btn-secondary">Отменить</Link>
          </div>
        </form>
      </div>
      {success && <div className="alert alert-success">{success}</div>}
      {error && <div className="mt-2 mb-2 alert alert-danger">{error}</div>}
    </div>
  );
});
