import axios from 'axios';

import { NO_AUTH_URL } from '@utils/common';

import { API_URL_V1 } from './config';

axios.defaults.withXSRFToken = true;
axios.defaults.withCredentials = true;

type AdditionalOptionsProps = {
	skipRedirect401: any
}

const axiosInstance = <T>(config: object, skipRedirect401?: object) => {
	return axios<T>(config)
		.catch((error) => {
			if (!skipRedirect401 && error.response?.status === 401) {
				window.location.href = NO_AUTH_URL;
			}

			return error.response?.data ? { ...error.response?.data, status: error.response?.status, error } : error;
		});
};

export async function getFileLink(action = '', fileType = '') {
	return axiosInstance({ url: API_URL_V1 + action, responseType: 'blob', withCredentials: true, method: 'GET' })
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const fileName = response.headers['content-disposition'].split('filename="')[1].split('.')[0];

			return { url, download: `${fileName}.${fileType}` };
		});
}

export const Send = <T>(url = '', method = '', data = {}, isFile = false, params = {}, allowCredentials = true, headers?: object) => {
	const config = {
		method,
		url: API_URL_V1 + url,
		params,
		headers: headers || (isFile
			? {}
			: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}),
		data: isFile ? data : JSON.stringify(data),
		withCredentials: allowCredentials, // разрешить передавать заголовки в запросе, включая куки
	};

	return axiosInstance<T>(config)
		.then((resp) => {
			return resp.data || resp;
		});
};

export const request = <T>(url = '', method = '', requestConfig?: object, additionalOptions?: AdditionalOptionsProps) => {
	const config = {
		method,
		url: API_URL_V1 + url,
		...requestConfig,
	};

	return axiosInstance<T>(config, additionalOptions?.skipRedirect401)
		.then((resp) => {
			return resp.data || resp;
		})
};

export const GET = <T>(url: string, params?: object) => {
	return Send<T>(url, 'get', undefined, false, params);
};

export const POST = <T>(url: string, data?: object, params?: object, config?: object) => {
	return request<T>(url, 'post', { ...config, data, params });
};
export const PUT = <T>(url: string, data: object, params?: object) => {
	return Send<T>(url, 'put', data, false, params);
};

export const DELETE = <T>(url: string, data?: object, params?: object) => {
	return Send<T>(url, 'delete', data, false, params);
};
