import React from 'react';

import { getResentVerificationEmail } from '@api/auth';
import { LayoutMain } from '@components/layout/layout';

export const NoVerified = () => {
  return (
    <LayoutMain noMenu={true}>
      <div className="form-lk-wrapper form-auth d-flex text-align-center justify-content-center flex-column gap-20px">
        <div className="fw-bold">Для получения доступа к платформе необходимо подтвердить Email, перейдя по специальной ссылке</div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => getResentVerificationEmail()}
        >
          Выслать ссылку повторно
        </button>
      </div>
    </LayoutMain>
  );
};
