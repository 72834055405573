import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { getAuctionsList } from "@api/auctions";
import { Pagination } from "@components/pagination/pagination";
import { Table } from "@components/table/table";
import useMediaQuery from "@hooks/use-media-query";

import { getFinishedColumns } from "./utils";

import Filter from "@components/filters/Filter/Filter";
import FilterButton from "@components/filters/FilterButton/FilterButton";
import useBuyFilter from "@hooks/filters/auctions/useBuyFilter";
import "./buy.scss";
import { citiesStore } from "@mobx/cities";

const PER_PAGE = [25, 50, 100, 250];

export const Buy = observer(() => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitLoading, setIsInitLoading] = useState(false);

	const { ready, items, itemsAdditional, setDefaultItems, loadCarData } =
		useBuyFilter();

	const [isReadyFilter, setIsReadyFilter] = useState(false);
	const [formData, setFormData] = useState({});
	const [filterShow, setFilterShow] = useState(false);

	const [perPage, setPerPage] = useState(25);

	const [auctions, setAuctions] = useState();

	const isMobile = useMediaQuery("screen and (max-width: 780px)");

	const { cities } = citiesStore;

	useEffect(() => {
		if (!citiesStore.cities.length) {
			citiesStore.fetch();
		}
	}, []);

	const auctionRequest = (params, filters = {}) => {
		getAuctionsList(
			{
				...params,
				...filters
			},
			"auctions/buying"
		).then(setAuctions);
	};

	useEffect(() => {
		if (ready) {
			let auctionId = searchParams.get("auctionId");
			if (auctionId) {
				sessionStorage.removeItem("filter:buy");
				sessionStorage.removeItem("filter:buy:data");
				sessionStorage.setItem(
					"filter:buy",
					JSON.stringify({ "filters[ids][]": auctionId })
				);
				sessionStorage.setItem("filter:buy:data", "{}");
			}
			setIsReadyFilter(true);
		}
	}, [ready]);

	const changeSelectFilter = async (key, type, data, reset) => {
		loadCarData(key, type, data, "filter:buy:data", reset);
	};

	const onInitSelectField = async (key, type, data) => {
		changeSelectFilter(key, type, data);
	};

	const onChangeSelectField = async (key, type, data) => {
		changeSelectFilter(key, type, data, true);
	};

	const onSubmit = (data) => {
		setFormData(data);
		isInitLoading && setSearchParams({});
		auctionRequest({ page: !isInitLoading ? searchParams.get("page") : 1, per_page: perPage }, data);
		setFilterShow(false);
		setIsInitLoading(true);
	};

	const onPagination = ({ page, per_page }) => {
		setPerPage(per_page);
		setSearchParams({ page: page });
		auctionRequest({ page: page, per_page: per_page }, formData);
	};

	const onResetFilter = () => {
		setDefaultItems();
		setFilterShow(false);
	};

	const onCloseFilter = () => {
		setFilterShow(false);
	};

	return (
		<div className="buy-wrapper">
			{isReadyFilter && (
				<Filter
					data={items}
					additional={itemsAdditional}
					typeFilter={"filter:buy"}
					typeFilterData={"filter:buy:data"}
					onSubmit={onSubmit}
					indent
					mobileShow={filterShow}
					onInitSelectField={onInitSelectField}
					onChangeSelectField={onChangeSelectField}
					onReset={onResetFilter}
					onClose={onCloseFilter}
				/>
			)}

			<div className="buy-wrapper__name-title">Покупаю</div>
			<div className="title">Аукционы</div>
			<FilterButton indent onClick={() => setFilterShow(true)} />
			{auctions && auctions.data?.length ? (
				<div className="mt-3 d-flex align-items-stretch flex-wrap gap-10px">
					<Table
						data={auctions?.data}
						columns={getFinishedColumns(cities)}
					/>
				</div>
			) : (
				"Лотов нет"
			)}
			<Pagination
				data={auctions}
				onChange={onPagination}
				perPageList={PER_PAGE}
				hideInfo={isMobile}
			/>
		</div>
	);
});
