import React from "react";
import { Modal } from "react-bootstrap";

import { getPhotoSrc } from "@utils/photo";
import { Button } from "@components/button/button";
import { Input } from "@components/input/input";

export const CarDefectModal = ({
								   selectedReport,
								   handleClose,
								   selectedReportRoot,
								   removeDefect,
								   isReport,
								   isClosed
							   }) => {
	return (
		<div className="defect-modal-wrapper">
			<Modal show={true} onHide={handleClose} className="defect-modal-wrapper">
				<div className="d-flex justify-content-end">
					<img
						src="/img/icons/close-icon.svg"
						alt=""
						onClick={() => handleClose()}
						className="cursor-pointer"
					/>
				</div>
				<div className="defect-modal-wrapper__header">
					<div className="title">{selectedReport.defect.name}</div>
					<div className="defect-modal-wrapper__header-text">
						{selectedReportRoot.rootName} {"> "}
						{selectedReportRoot.name}
					</div>
				</div>
				{selectedReport?.photos.map((photo) => (
					<img
						key={photo.file.url}
						className="report-img w-25 p-2"
						src={getPhotoSrc(photo.thumbnails_urls?.middle || photo.file.url)}
						alt=""
					/>
				))}
				<div className="defect-modal-wrapper__form-field mb-10px">
					<div className="defect-modal-wrapper__form-field-name name">Описание</div>
					<div className="defect-modal-wrapper__form-field-value">
						<Input
							placeholder="Описание"
							size="m"
							disabled
							value={selectedReport.description ?? ""}
						/>
					</div>
				</div>
				<div className="defect-modal-wrapper__buttons">
					{isReport && !isClosed && (
						<Button preset="primary" onClick={removeDefect}>
							Удалить повреждение
						</Button>
					)}
					<Button preset="secondary" onClick={() => handleClose()}>
						Закрыть
					</Button>
				</div>
			</Modal>
		</div>
	);
};
