import React, { useMemo } from 'react';

import { getFieldValue, getFilteredField } from '../utils';

const getNewSection = (child, result) => {
  const newSection = getFilteredField(child.fields, child.name);
  if (newSection.fields?.length > 0) {
    result.push(newSection);
  }
};

export const CarSection = ({ sections }) => {
  const elSection = useMemo(() => {
    const result = [];

    sections.forEach((section) => {
      if (section?.key === 'sale_equipment' && section?.childs?.length > 0) {
        section.childs.forEach((child) => getNewSection(child, result));
      }

      if (section?.key === 'sale_completeness' && section?.fields?.length > 0) {
        getNewSection(section, result);
      }
    });

    return result;
  }, [sections]);

  return (
    <div className="section" id="equipment">
      <div className="title">Комплектация</div>
      <div className="info-car-wrapper-completeness">
        <div className="info-car-wrapper__columns">
          {elSection.map((section, index) => {
            return (
              <div key={`${section.id}-${index}`} className="info-car-wrapper__columns-column">
                <div className="info-car-wrapper__columns-title">{section.name}</div>
                {section.fields.map((field, index) => {
                  return (
                    <div className="info-car__field" key={`${field.id}-${index}`}>
                      <span className="value">{field.name}</span>
                      <span className="name">
                        {getFieldValue(field.type, field.saved_value?.value, field.name)}
                      </span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
