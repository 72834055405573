import { Link } from "react-router-dom";
import moment from "moment/moment";
import React from "react";

export const COLUMNS = [
	{
		dataField: "title",
		text: "Название",
		sort: true,
		formatter: (cell, row) => <Link to={`/company/${row.id}`}>{row.title}</Link>
	},
	{
		dataField: "name",
		text: "Юр. название",
		formatter: (cell, row) => row.requisite?.company_name || "-"
	},
	{
		dataField: "inn",
		text: "ИНН",
		formatter: (cell, row) => row.requisite?.inn || "-"
	},
	{
		dataField: "manager",
		text: "Менеджер",
		formatter: (cell, row) => (row.responsible ? row.responsible?.full_name : "-")
	},
	{
		dataField: "roles",
		text: "Роли",
		formatter: (cell, row) => <div className="role-wrapper">
			{row?.roles.map(x => <div className="role" key={x.id}>{x.description}</div>)}
		</div>
	},
	{
		dataField: "type",
		text: "Тип клиента",
		formatter: (cell, row) => (row.type ? row.type.name : "-")
	},
	{
		dataField: "created_at",
		text: "Создана",
		formatter: (cell, row) => (row.created_at ? moment(row.created_at).format("DD.MM.YYYY") : "-"),
		sort: true
	},
	{
		dataField: "admin_comment",
		text: "Комментарий",
		formatter: (cell, row) => <div className="line-clamp-2">{row.admin_comment ?? "-"}</div>
	}
];
