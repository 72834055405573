import clsx from "clsx"
import { useEffect, useState } from "react"

import { postBlitzBet } from "@api/auctions"
import { Button } from "@components/button/button"
import { Input } from "@components/input/input"
import { Tooltip } from "@components/tooltip/tooltip"
import QuestionTooltip from "@components/tooltip/tooltip-question"
import { auctionsStore } from "@mobx/auctions"
import { VIEW_HORIZONTAL } from "@pages/current-trades/current-trades-filters/utils"
import { getCurrencySymbol } from "@utils/common"
import {
	formatToNumberWithoutSpaces,
	formatValueWithSpaces
} from "@utils/format"
import { Modal } from "react-bootstrap"

import { AutoBetModal } from "./auto-bet-modal"
import { LotCars } from "./lot-cars/lot-cars"
import { TradesHistoryModal } from "./trades-history-modal"

import "./auction-item.scss"

export const LotCardUpAuction = ({
	lot,
	auctionId,
	sellerable,
	isSellerHidden,
	view,
	hideCars,
	showBtn,
	currentBet,
	isLost,
	isWinner,
	classes,
	isMobile,
	elFavouriteBtn,
	relatedStatusReport
}) => {
	const [inputValue, setInputValue] = useState(
		formatValueWithSpaces(
			lot.lastBet
				? lot.minimal_step + lot.lastBet.sum
				: lot.starting_price
		)
	)

	const [showProgress, setShowProgress] = useState(false)
	const [showAutoBet, setShowAutoBet] = useState(false)

	const [showModal, setShowModal] = useState(false)

	const [showAutoBetPoint, setShowAutoBetPoint] = useState(
		Boolean(lot.user_auto_bet?.max_sum)
	)

	useEffect(() => {
		if (currentBet) {
			setInputValue(
				formatValueWithSpaces(currentBet.bet_sum + lot.minimal_step)
			)
		}
	}, [currentBet, lot.id, lot.minimal_step])

	const onToggleModal = () => {
		setShowModal((prev) => !prev)
	}

	const handleBet = async (auctionId, lotId) => {
		await auctionsStore.betAuction(
			auctionId,
			lotId,
			formatToNumberWithoutSpaces(inputValue),
			true
		)
	}

	const handleChangeBetInput = (e) => {
		setInputValue(formatValueWithSpaces(e.target.value))
	}

	const decrement = () => {
		setInputValue((prev) =>
			formatValueWithSpaces(
				formatToNumberWithoutSpaces(prev) - lot.minimal_step
			)
		)
	}

	const increment = () => {
		setInputValue((prev) =>
			formatValueWithSpaces(
				formatToNumberWithoutSpaces(prev) + lot.minimal_step
			)
		)
	}

	const onBuyBlitzPrice = () => {
		postBlitzBet(auctionId, lot.id)
	}

	const modal = () => {
		return (
			<Modal
				show={showModal}
				onHide={onToggleModal}
				className={clsx(
					"d-flex align-items-center justify-content-center modal-size-small"
				)}
			>
				<div className="head-text text-center mb-3">
					{lot?.sales[0]?.display_name}
				</div>
				<div className={"d-flex justify-content-center gap-2 mb-3"}>
					<b>Цена:</b>
					<b>{formatValueWithSpaces(lot.blitz_price)} ₽</b>
				</div>
				<Button
					onClick={onBuyBlitzPrice}
					preset="primary"
					className={"mb-3"}
				>
					Купить
				</Button>
				<Button preset="secondary" onClick={onToggleModal}>
					Закрыть
				</Button>
			</Modal>
		)
	}

	const elBetInput = () => {
		return (
			<div className="input-group position-relative">
				<button
					onClick={() => decrement(lot.id)}
					className="decrement"
					type="button"
				>
					-
				</button>
				<Input
					min={lot.minimal_step}
					onChange={handleChangeBetInput}
					name="starting_price"
					value={inputValue}
					placeholder="Ставка"
					color="white"
				/>
				<button
					onClick={() => increment(lot.id)}
					className="increment"
					type="button"
				>
					+
				</button>
			</div>
		)
	}

	const elBetBtn = () => {
		let text = "Сделать ставку"

		if (isWinner) {
			text = "Лот куплен"
		} else if (isLost) {
			text = "Торги закончились"
		}

		return (
			<button
				onClick={() => handleBet(auctionId, lot.id)}
				className="btn btn-primary d-flex align-items-center justify-content-center auction__bet-btn"
				type="button"
			>
				{text}
			</button>
		)
	}

	const elStep = () => {
		return (
			<Tooltip text="Шаг торгов" isDisabled={isMobile}>
				<div className="el-step d-flex align-items-center">
					<img src="/img/icons/step.svg" alt="" />
					<div>
						{lot.minimal_step
							.toString()
							.replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}{" "}
						{getCurrencySymbol()}
					</div>
				</div>
			</Tooltip>
		)
	}

	const elBidProgressBtn = () => (
		<div className="d-flex bid-up bid-up-progress">
			<Tooltip text="Ход торгов" isDisabled={isMobile}>
				<img
					onClick={() => setShowProgress(true)}
					className="cart-icon cursor-pointer"
					src="/img/icons/cart-icon.svg"
					alt=""
				/>
			</Tooltip>
		</div>
	)

	const elAutoBetBtn = () => (
		<Tooltip text="Автоставки" isDisabled={isMobile}>
			<div
				className={clsx("d-flex bid-up position-relative", {
					"bid-up_active": lot?.auction?.status_id === 7
				})}
			>
				<img
					onClick={() => setShowAutoBet(true)}
					className="watch-to-down-icon cursor-pointer"
					src="/img/icons/watch-to-down-icon.svg"
					alt=""
				/>
				{showAutoBetPoint && <span className="lot-card__point" />}
			</div>
		</Tooltip>
	)

	const elModals = () => {
		return (
			<>
				{showProgress && (
					<TradesHistoryModal
						lot={lot}
						auctionId={auctionId}
						handleClose={() => setShowProgress(false)}
						handleBet={handleBet}
						isWinner={isWinner}
						isLost={isLost}
						elBetInput={elBetInput}
						isMobile={isMobile}
					/>
				)}
				{showAutoBet && (
					<AutoBetModal
						lot={lot}
						auctionId={auctionId}
						defaultValue={inputValue}
						handleClose={() => setShowAutoBet(false)}
						setShowAutoBetPoint={setShowAutoBetPoint}
					/>
				)}
			</>
		)
	}

	const elCurrentBet = () => {
		return (
			<div className="current-bet d-flex align-items-center">
				<Tooltip text="Текущая ставка" isDisabled={isMobile}>
					<div className="d-flex align-items-center">
						<img
							src="/img/icons/arrow-up-bordered-icon.svg"
							alt=""
						/>
						<div className="starting-price d-flex align-items-center">
							{formatValueWithSpaces(
								currentBet?.bet_sum ||
								lot.lastBet?.sum ||
								lot.starting_price
							)}{" "}
							{getCurrencySymbol()}
						</div>
					</div>
				</Tooltip>
				{view === VIEW_HORIZONTAL ? (
					<div className="ml-5px">
						{lot.is_vat_included ? "С НДС" : "Без НДС"}
					</div>
				) : (
					<QuestionTooltip
						text={
							lot.is_vat_included ? "Цена с НДС" : "Цена без НДС"
						}
					/>
				)}
			</div>
		)
	}

	const elBlitzPrice = (isSingle) => {
		if (
			!lot.is_blitz_price_enabled ||
			(currentBet?.bet_sum || lot.lastBet?.sum || 0) + lot.minimal_step >=
			lot.blitz_price
		)
			return

		return (
			<Tooltip text="Купить лот по блиц-цене" isDisabled={isMobile}>
				<Button
					preset="ghost"
					className={clsx("card-auction__lot-blitz", {
						"card-auction__lot-blitz_single": isSingle
					})}
					onClick={onToggleModal}
				>
					<img src="/img/icons/bolt.svg" alt="" />
					{formatValueWithSpaces(lot.blitz_price)}{" "}
					{getCurrencySymbol()} <span>·</span> Купить
				</Button>
			</Tooltip>
		)
	}

	const getClassName = (lotLength) => {
		if (view === VIEW_HORIZONTAL) {
			return "lots-actions-wrapper-horizontal"
		}

		if (lotLength === 2) {
			return "lots-actions-wrapper-two"
		}

		if (lotLength === 3 && lot.is_blitz_price_enabled) {
			return "lots-actions-wrapper-three"
		}

		return "lots-actions-wrapper"
	}

	const elActions = () => {
		if (!isMobile && (view === VIEW_HORIZONTAL || lot.sales.length > 1)) {
			return (
				<div className="actions lots-actions">
					<div className={getClassName(lot.sales.length)}>
						{elCurrentBet()}
						{elStep()}
						{elBetInput()}
						{elBetBtn()}
						<div className="lots-actions__btns">
							{elAutoBetBtn()}
							{elBidProgressBtn()}
						</div>
						{elBlitzPrice()}
						{elFavouriteBtn}
					</div>
				</div>
			)
		}

		if (isMobile && hideCars) {
			return (
				<div className="actions actions-mobile">
					<div className="d-flex align-items-center justify-content-between gap-10px">
						<div>
							{elCurrentBet()}
							{elStep()}
						</div>
						<div className="d-flex gap-5px">
							{elBidProgressBtn()}
							{elAutoBetBtn()}
						</div>
						{elFavouriteBtn}
					</div>
					<div className="actions-mobile__btn-wrapper">
						{elBetInput()}
						{elBetBtn()}
					</div>
					{elBlitzPrice(true)}
				</div>
			)
		}

		return (
			<div className="actions">
				<div className="d-flex align-items-center justify-content-between gap-10px">
					{elBetInput()}
					{elAutoBetBtn()}
				</div>
				<div className="d-flex justify-content-between gap-10px mt-2">
					{elBetBtn()}
					{elBidProgressBtn()}
				</div>
				<div className="d-flex align-items-center justify-content-between mt-3">
					{elCurrentBet()}
					{elStep()}
					{elFavouriteBtn}
				</div>
				{elBlitzPrice(true)}
			</div>
		)
	}

	return (
		<div
			className={`lot-car-wrapper position-relative ${classes.join(
				" "
			)} ${showBtn ? "lot-car-wrapper_show-btn" : ""}`}
		>
			{(lot.finished_at || !lot.is_active) && (
				<div className="disabled-auctions" />
			)}
			{!hideCars && (
				<LotCars
					lot={lot}
					view={view}
					auctionId={auctionId}
					sellerable={sellerable}
					isSellerHidden={isSellerHidden}
					relatedStatusReport={relatedStatusReport}
				/>
			)}
			{elActions()}
			{elModals()}
			{modal()}
		</div>
	)
}
