import React, { useEffect, useRef, useState } from 'react';
import styles from './AddRole.module.scss';
import Title from '../../../components/titles/Title/Title';
import BreadCrumbs from '../../../components/breadcrumbs/BreadCrumbs';
import Container from '../../../components/Containers/Container/Container';
import { getPermissionsView } from '@api/users';
import { PermissionsGroup, PermissionsObject } from '../../../types/Permissions';
import { uuid } from '@utils/uuid';
import PermissionsSection from '../../../components/permissions/PermissionsSection/PermissionsSection';
import Checkbox from '../../../components/form/Checkbox/Checkbox';
import classNames from 'classnames';
import Input from '../../../components/Forms/Input/Input';
import Btn from '../../../components/btn/Btn/Btn';
import { formToJSON } from '@utils/formToJSON';
import { deleteRole, getRoles, setRoles, updateRoles } from '@api/roles';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { meStore } from '../../../mobx/me';
import { NO_PERMISSION_URL } from '@utils/common';
import useLoaded from '../../../hooks/use-loaded';
import { Loader } from '../../../components/loader/loader';
import { Roles } from '../../../types/Roles';
import usePreloader from '../../../hooks/use-preloader';

type AddRoleProps = {
	isEdit: boolean
	url?: string
}

const AddRole = ({ isEdit, url = '/users' }: AddRoleProps) => {

	const { id } = useParams()
	const { loading, setLoaded } = useLoaded();
	const { preloader, setPreloader } = usePreloader()
	const navigate = useNavigate();
	const formRef = useRef<HTMLFormElement>(null)
	const [permissions, setPermissions] = useState<PermissionsGroup[]>([]);
	const [currentPermissions, setCurrentPermissions] = useState<number[]>([])
	const [fields, setFields] = useState([
		{
			id: 1,
			name: 'Название роли',
			nameInput: 'name',
			placeholder: 'Введите название роли',
			value: '',
			required: true,
			disabled: false
		},
		{
			id: 2,
			name: 'Краткое описание роли',
			nameInput: 'description',
			placeholder: 'Введите описание',
			value: '',
			required: false,
			disabled: false
		}
	])

	useEffect(() => {
		setPreloader(true)
		getPermissionsView().then((data: PermissionsObject) => {

			const array = Object.values(data).map(x => x);

			const uniqueKeys = [...new Set(array.map(x => x.group))];

			const grouped = uniqueKeys.map(key => ({
				id: uuid(),
				name: key,
				data: array.filter(item => item.group === key),
			}));

			setPermissions(grouped);

			if(isEdit) {
				getRoles().then((data: Roles[]) => {
					if(id) {
						let role = data.find(x => x.id === parseInt(id))
						if(role) {
							setFields([
								{
									id: 1,
									name: 'Название роли',
									nameInput: 'name',
									placeholder: 'Введите название роли',
									value: role.name,
									required: true,
									disabled: true
								},
								{
									id: 2,
									name: 'Краткое описание роли',
									nameInput: 'description',
									placeholder: 'Введите описание',
									value: role.description,
									required: true,
									disabled: false
								}
							])
							setCurrentPermissions(role.permissions)
						}
						setLoaded(true)
						setPreloader(false)
					}
				})
			} else {
				setLoaded(true)
				setPreloader(false)
			}
		});
	}, []);

	const onSubmit = (e: any) => {
		e.preventDefault()
		setPreloader(true)
		let formData
		if(formRef.current) {
			formData = formToJSON(formRef.current)
			if(isEdit) {
				if(id) {
					updateRoles(formData, id).then(data => {
						navigate(url, { state: { key: "roles" } })
						setPreloader(false)
					})
				}
			} else {
				setRoles(formData).then(({ data, status, error }) => {
					setPreloader(false)
					if(error) {
						// FIXME: вывод ошибки
						alert(JSON.stringify(error, null, 6))
						return false
					}
					navigate(url, { state: { key: "roles" } })
				})
			}
		}
	}

	const onDelete = () => {
		setPreloader(true)
		deleteRole(id).then(data => {
			navigate(url, { state: { key: "roles" } })
			setPreloader(false)
		})
	}

	if(meStore.noPermission('roles.edit')) {
		return <Navigate to={NO_PERMISSION_URL} />;
	}

	return (
		<div className={styles.AddRole}>
			{loading &&
				<>
					<BreadCrumbs items={[{ id: 1, url: url, name: 'Список ролей', state: { key: 'roles' } }]} />
					<Title label={isEdit ? 'Редактирование роли' : 'Создание роли'} className={'mb-2'} />
					<form encType="multipart/form-data" ref={formRef} onSubmit={onSubmit}>
						<Container>
							{fields.map(x =>
								<PermissionsSection name={x.name} key={x.id} hasInput={true}>
									<Input name={x.nameInput} placeholder={x.placeholder} required={x.required} disabled={x.disabled} value={x.value} />
								</PermissionsSection>
							)}
						</Container>
						<Title label={'Права'} className={'mb-2'} />
						<Container className={'mb-4'}>
							{permissions.map(x =>
								<PermissionsSection key={x.id} name={x.name} info={'true'}>
									{x.data.map((x, index) =>
										<div key={x.id} className={classNames(index > 0 && 'mt-2')}>
											<Checkbox
												name={'permissions'}
												label={x.description}
												value={x.id}
												defaultChecked={currentPermissions.includes(x.id)}
											/>
										</div>
									)}
								</PermissionsSection>
							)}
						</Container>
						<div className={styles.footer}>
							<div>
								<Btn type={'submit'} className={styles.submit}>
									{isEdit ? 'Сохранить изменения' : 'Создать роль'}
								</Btn>
								<Btn href={url} color={'white'} state={{ key: 'roles' }}>
									Отмена
								</Btn>
							</div>
							{isEdit && <Btn onClick={onDelete} color={'redBorder'}>Удалить роль</Btn>}
						</div>
					</form>
				</>
			}
			{preloader && <Loader fixed />}
		</div>
	);
};

export default AddRole;
