import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Timeline from 'react-timelines';
import moment from 'moment';

import 'react-timelines/lib/css/style.css';

import { Loader } from '@components/loader/loader';
import { GET } from '@api/request';
import { meStore } from '@mobx/me';
import { NO_PERMISSION_URL } from '@utils/common';

import { buildTimebar, buildTrack } from './utils';

import './auctions-calendar.scss';

const timebar = buildTimebar(new Date());

export const AuctionsCalendar = observer(() => {
  const [loading, setLoading] = useState(true);
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams([
      ['filters[is_increasing]', 1],
      ['filters[status_codes][]', 'auction_active'], ['filters[status_codes][]', 'auction_not_active'],
      ['orders[start_at]', 'asc'],
      ['expand[1]', 'status'], ['expand[2]', 'lots'], ['expand[0]', 'sellerable'],
    ]);

    return GET('auctions', params)
      .then((resp) => {
        const newTracks = resp.data
          .filter((item) => item.lots.length > 0)
          .map((item, i) => {
            const { id, name, lots, start_at, planned_finish_at } = item;
            const toolTipInfo = {
              lots: lots.length,
              seller: item.sellerable?.display_name ?? item.sellerable?.full_name,
            };

            return buildTrack(id, name, start_at, planned_finish_at, toolTipInfo);
          });

        setTracks(newTracks);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // Хак-костыль, чтобы тултип при наведении на интервал аукциона не заезжал левее, чем таймлайн
    const intervalId = setInterval(() => {
      const elements = document.querySelectorAll('.rt-track__element');

      if (elements.length) {
        elements.forEach((element) => {
          const parsedLeft = parseInt(element.style.left, 10);

          if (parsedLeft < 0) {
            // eslint-disable-next-line no-param-reassign
            element.querySelector('.rt-element__tooltip').style.marginLeft = `${Math.abs(parsedLeft)}px`;
          }
        });

        clearInterval(intervalId);
      }
    }, 200);
  }, []);

  if (loading || !meStore.data) {
    return <Loader />;
  }

  if (meStore.noPermission('auctions.view') && meStore.noPermission('admin.auctions.view')) {
    return <Navigate to={NO_PERMISSION_URL} />;
  }

  return (
    <div className="auctions-calendar">
      <Timeline
        scale={{
          start: moment().startOf('day').toDate(),
          end: moment().endOf('day').add('days', 30).toDate(),
          zoom: 10,
          minWidth: 1600, // этот параметр отвечает как за ширину всего блока, так и за ширину отдельных ячеек
        }}
        isOpen={true}
        timebar={timebar}
        tracks={tracks}
        enableSticky
        scrollToNow
        clickElement={(track) => { window.location.replace(`/current-trades?auctionId=${track.trackId}`); }} // обработка скролла происходит в компоненте CurrentTradesTotal
      />
    </div>
  );
});
