import React from 'react';
import { IconType } from './IconType';
import classNames from 'classnames';
import styles from './Icon.module.scss'

const IconQuestion = ({ className, size = 24 }: IconType) => {
	return (
		<svg className={classNames(className, styles[`Icon${size}`])} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
			<path
				d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
				fill="#F4F6FB" />
			<path fillRule="evenodd" clipRule="evenodd"
			      d="M11.7827 7.25001C11.3219 7.24873 10.8671 7.35444 10.454 7.5588C10.041 7.76317 9.68108 8.06062 9.40255 8.42773C9.15218 8.75771 9.21673 9.22818 9.54672 9.47854C9.87671 9.72891 10.3472 9.66436 10.5975 9.33437C10.7357 9.15222 10.9143 9.00463 11.1193 8.90322C11.3242 8.80182 11.5499 8.74937 11.7785 8.75001C12.0072 8.75064 12.2325 8.80434 12.4369 8.90688C12.6413 9.00942 12.8191 9.15799 12.9562 9.34091C13.0934 9.52383 13.1863 9.7361 13.2275 9.961C13.2687 10.1859 13.2572 10.4173 13.1938 10.637C13.1303 10.8567 13.0168 11.0586 12.8621 11.227C12.7091 11.3933 12.5201 11.5224 12.3095 11.6042C11.8984 11.7516 11.5454 12.0274 11.303 12.391C11.0584 12.7581 10.9396 13.1947 10.9645 13.6351C10.988 14.0486 11.3423 14.3649 11.7558 14.3414C12.1694 14.3179 12.4856 13.9637 12.4621 13.5501C12.4556 13.4344 12.4868 13.3196 12.5511 13.2231C12.6154 13.1266 12.7094 13.0536 12.8188 13.0152L12.8379 13.0082C13.2683 12.8436 13.6546 12.5813 13.9665 12.242C14.2783 11.9028 14.5071 11.4957 14.6349 11.053C14.7627 10.6103 14.786 10.1439 14.7029 9.69063C14.6199 9.23736 14.4327 8.80956 14.1562 8.44091C13.8798 8.07226 13.5215 7.77282 13.1096 7.56617C12.6977 7.35952 12.2435 7.25129 11.7827 7.25001ZM12.4633 16.5909C12.4633 16.1767 12.1275 15.8409 11.7133 15.8409C11.2991 15.8409 10.9633 16.1767 10.9633 16.5909V16.6004C10.9633 17.0147 11.2991 17.3504 11.7133 17.3504C12.1275 17.3504 12.4633 17.0147 12.4633 16.6004V16.5909Z"
			      fill="#8A92A2" />
		</svg>
	);
};

export default IconQuestion;
