import cls from "./LogoSeller.module.scss";
import clsx from "clsx";

interface LogoSellerProps {
	className?: string;
	image: string;
	background?: boolean;
	small?: boolean;
}

const LogoSeller = (props: LogoSellerProps) => {
	const { className, image, background, small } = props;
	return (
		<div className={clsx(cls.LogoSeller, background && cls.white, small && cls.small, className)}>
			<img src={image} loading={"lazy"} />
		</div>
	);
};

export default LogoSeller;
