import React from 'react';
import moment from 'moment';

import { formatPhoneMask } from '@utils/format';

const getClassName = (status) => {
  if (status === 'user_inactive') {
    return 'user-status red';
  } if (status === 'user_submitted') {
    return 'user-status green';
  } if (status === 'user_new') {
    return 'user-status grey';
  }
};

export const USERS_COLUMNS = [
  {
    dataField: 'first_name',
    text: 'ФИО',
    sort: true,
    checked: true,
    headerStyle: () => {
      return { width: '350px' };
    },
    formatter: (cell, row) => row.full_name,
  },
  {
    dataField: 'phone',
    text: 'Телефон',
    sort: true,
    checked: true,
    formatter: (cell, row) => formatPhoneMask(row.phone),
  },
  {
    dataField: 'status',
    text: 'Статус',
    sort: true,
    checked: true,
    formatter: (cell, row) => <div className={getClassName(row.status?.code)}>{row.status?.name || ''}</div>,
  },
  {
    dataField: 'created_at',
    text: 'Создан',
    checked: true,
    formatter: (cell, row) => moment(row.created_at).format('DD.MM.YYYY'),
    sort: true,
  },
];

export const COMPANIES_COLUMNS = [
  {
    dataField: 'name',
    text: 'Название компании',
    sort: true,
    formatter: (cell, row) => row?.display_name,
  },
  {
    dataField: 'created_at',
    text: 'Юридическое название',
    formatter: (cell, row) => row.requisite?.company_name,
    sort: true,
  },
  {
    dataField: 'inn',
    text: 'ИНН',
    formatter: (cell, row) => row?.requisite?.inn,
    sort: true,
  },
];
