import React from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";

export const COLUMNS = [
	{
		dataField: "first_name",
		text: "ФИО",
		sort: true,
		formatter: (cell, row) => <Link to={`/user/${row.id}`}>{row.full_name}</Link>
	},
	{
		dataField: "phone",
		text: "Телефон",
		sort: true
	},
	{
		dataField: "created_at",
		text: "Дата создания",
		formatter: (cell, row) => moment(row.created_at).format("DD.MM.YYYY"),
		sort: true
	},
	{
		dataField: "admin_comment",
		text: "Комментарий",
		formatter: (cell, row) => {
			return (
				<div className="line-clamp-2">{row.admin_comment ?? "-"}</div>
			);
		}
	}
];
