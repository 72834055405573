import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Tabs } from '@pages/car/car-info/tabs';
import { CompaniesTable } from '@components/users-companies-table/compaies-table';
import { UsersTable } from '@components/users-companies-table/users-table';

import './input-requisites-search.scss';

const options = [
  {
    value: 'companies',
    label: 'Компании',
  }, {
    value: 'users',
    label: 'Пользователи',
  },
];

export const ChooseSellerModal = ({ handleClose, onSelectSeller }) => {
  const [activeTab, setActiveTab] = useState('companies');

  return (
    <Modal
      show={true}
      onHide={handleClose}
      className="choose-seller-modal-wrapper"
    >
      <>
        <Tabs options={options} value={activeTab} onChange={setActiveTab} />
        {activeTab === 'companies' && (
          <CompaniesTable onSelectCompany={onSelectSeller} />
        )}
        {activeTab === 'users' && (
          <UsersTable onSelectUser={onSelectSeller} />
        )}
      </>
    </Modal>
  );
};
