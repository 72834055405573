import clsx from "clsx";
import { useState } from "react";

import { Tooltip } from "@components/tooltip/tooltip";
import { isKZ } from "@utils/common";

import { TermsOfCooperationModal } from "./terms-of-cooperation-modal";

import IconComment from "../icons/IconComment";
import "./car-checks.scss";

export const CarChecks = ({
	vin,
	statusReportFile,
	inspectionType,
	lot,
	isLotSale,
	isLotSaleCarPage,
	isMobile,
	isEdit,
	elEditButton,
}) => {
	const [showModal, setShowModal] = useState(false);

	return (
		<div>
			{!isLotSale || isLotSaleCarPage ? (
				<div className="car-checks">
					{isMobile && isEdit && elEditButton()}
					<a
						href={`https://гибдд.рф/check/auto#${vin}`}
						target="_blank"
						rel="noopener noreferrer"
						className="car-checks__btn"
					>
						<img src="/img/icons/gibdd-icon.svg" alt="" />
						Проверить в ГИБДД
					</a>
					{statusReportFile && (
						<a
							href={`${statusReportFile?.url}`}
							target="_blank"
							rel="noopener noreferrer"
							className="car-checks__btn"
						>
							<img src="/img/icons/download-icon.svg" alt="" />
							Отчет о состоянии
						</a>
					)}
				</div>
			) : (
				<>
					{inspectionType?.name && (
						<div
							className={clsx(
								"car-checks__automart",
								`car-checks__automart_${inspectionType.id}`
							)}
						>
							<img
								className="car-checked-icon"
								src={
									inspectionType.id === "seller"
										? "/img/icons/reviewed-seller.svg"
										: "/img/icons/reviewed-automart.svg"
								}
								alt=""
							/>
							{inspectionType?.name}
						</div>
					)}
					<div className="car-checks__check-icons">
						{!isKZ && (
							<Tooltip
								text="Проверка в ГИБДД"
								isDisabled={isMobile}
							>
								<a
									href={`https://гибдд.рф/check/auto#${vin}`}
									target="_blank"
									rel="noopener noreferrer"
									className="car-checks__shield"
								>
									<img src="/img/shield.svg" alt="" />
								</a>
							</Tooltip>
						)}
						{isLotSale && (
							<Tooltip
								text="Условия и сборы"
								isDisabled={isMobile}
							>
								<img
									src="/img/home.svg"
									className="cursor-pointer"
									alt=""
									onClick={() => setShowModal(true)}
								/>
							</Tooltip>
						)}
						{statusReportFile && (
							<Tooltip
								text="Отчет о состоянии"
								isDisabled={isMobile}
							>
								<a
									href={`${statusReportFile.url}`}
									target="_blank"
									rel="noopener noreferrer"
									className="car-checks__report"
								>
									<img src="/img/pdf.svg" alt="" />
								</a>
							</Tooltip>
						)}
						<Tooltip
							text={
								lot?.hasComments
									? "В данном лоте есть ваш комментарий"
									: "В данном лоте нет вашего комментария"
							}
							isDisabled={isMobile}
						>
							<div
								className={clsx(
									"car-checks__comment",
									lot?.hasComments &&
										"car-checks__comment-has"
								)}
							>
								<IconComment />
							</div>
						</Tooltip>
					</div>
					{showModal && (
						<TermsOfCooperationModal
							handleClose={() => setShowModal(false)}
							lot={lot}
						/>
					)}
				</>
			)}
		</div>
	);
};
