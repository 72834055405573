import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { RadioButton } from "@components/radio-button/radio-button";
import { Button } from "@components/button/button";

import "./choose-profile-popup.scss";

export const ChooseProfilePopup = ({ handleClose, data, onChangeImpersonation }) => {
	const [checkboxValue, setCheckboxValue] = useState();

	const submit = () => {
		if (checkboxValue === "user") {
			handleClose();
		} else {
			onChangeImpersonation(checkboxValue);
		}
	};

	return (
		<Modal
			show={true}
			onHide={handleClose}
			className="choose-profile-popup"
		>
			<div className="choose-profile-popup__title">Выберите профиль</div>
			<div className="choose-profile-popup__list">
				<RadioButton
					label="Физлицо"
					id="user"
					name="profile"
					value="user"
					onChange={(e) => setCheckboxValue(e.target.value)}
				/>
				{data?.available_impersonation?.map((item, index) => {
					return (
						<div key={`${item.title}-${index}`}>
							<RadioButton
								label={item.title}
								id={item.id}
								name="profile"
								value={item.id}
								onChange={(e) => setCheckboxValue(e.target.value)}
							/>
						</div>
					);
				})}
			</div>
			<Button preset="primary" onClick={submit}>
				Продолжить
				<img src="/img/icons/arrow-right-icon.svg" alt="" />
			</Button>
		</Modal>
	);
};
