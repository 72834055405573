
export const COLUMNS = [
	{
		dataField: "name",
		text: "Название",
		sort: true,
		checked: true,
		formatter: (cell, row) => {
			return (
				<a href={`/car/${row.id}`} target="_blank" rel="noreferrer">
					{row.car.brand.name} {row.car.year ? `${row.car.year} г.в.` : ""}{" "}
					{row.car.engine_volume} {row.car.transmission?.name}
				</a>
			)
		},
	},
	{
		dataField: "seller",
		text: "Продавец",
		sort: true,
		checked: true,
		formatter: (cell, row) => {
			return (
				<a
					href={`/${row.short_owner_type}/${row.owner?.id}`}
					target="_blank"
					rel="noreferrer"
				>
					{row.owner?.display_name}
				</a>
			)
		},
	},
	{
		dataField: "vin",
		text: "VIN",
		sort: true,
		checked: true,
		formatter: (cell, row) => {
			return <div>{row.car.vin}</div>
		},
	}
]
