import React, { useEffect, useState } from 'react';

import { Table } from '@components/table/table';
import { getCurrentCarChanges } from '@api/cars';

import { changesColumns } from './table-templates';

const PER_PAGE = [10, 25, 50];

export const Changes = ({ carSaleInfo }) => {
  const [changeLog, setChangeLog] = useState();

  const requestChangeLog = (params) => {
    getCurrentCarChanges(carSaleInfo.id, params).then(setChangeLog);
  };

  useEffect(() => {
    requestChangeLog({ 'per_page': PER_PAGE[0] });
  }, []);

  return (
    <div className="section" id="changes">
      <div className="section-header">
        <div className="title">История изменений</div>
      </div>
      {changeLog?.data?.length > 0
        ? (
          <Table
            data={changeLog.data}
            columns={changesColumns}
          />
        )
        : <div>Пока что здесь нет изменений</div>}
    </div>
  );
};
