import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"

import { blockUsersRequest, filteredUsers, unlockUsers } from "@api/users"
import { Button } from "@components/button/button"
import { Pagination } from "@components/pagination/pagination"
import { Table } from "@components/table/table"
import { citiesStore } from "@mobx/cities"
import { dictionariesStore } from "@mobx/dictionaries"
import { meStore } from "@mobx/me"

import { getColumns } from "./utils"

import { getRoles } from "@api/roles"
import Btn from "@components/btn/Btn/Btn"
import Filter from "@components/filters/Filter/Filter"
import FilterButton from "@components/filters/FilterButton/FilterButton"
import IconDownload from "@components/icons/IconDownload"
import { useSearchParams } from "react-router-dom"
import "./user-tabs.scss"

const PER_PAGE = [25, 50, 100, 250, 500]

export const UsersTab = observer(() => {

	let [searchParams, setSearchParams] = useSearchParams()
	const [isInitLoading, setIsInitLoading] = useState(false)

	const [refresh, setRefresh] = useState(0)
	const [filterData, setFilterData] = useState([
		{
			id: 0,
			name: "filters[last_name]",
			type: "text",
			placeholder: "Фамилия"
		},
		{
			id: 1,
			name: "filters[first_name]",
			type: "text",
			placeholder: "Имя"
		},
		{
			id: 2,
			name: "filters[middle_name]",
			type: "text",
			placeholder: "Отчество"
		},
		{
			id: 3,
			name: "filters[phone]",
			type: "tel",
			placeholder: "Телефон"
		},
		{
			id: 4,
			name: "filters[email]",
			type: "text",
			placeholder: "Почта"
		},
		{
			id: 5,
			name: "filters[status_id]",
			type: "select",
			placeholder: "Cтатус Активности",
			options: []
		},
		{
			id: 6,
			name: "filters[>created_at]",
			type: "date",
			placeholder: "Дата регистрации пользователя от"
		},
		{
			id: 7,
			name: "filters[<created_at]",
			type: "date",
			placeholder: "Дата регистрации пользователя до"
		},
		{
			id: 8,
			name: "filters[roles][]",
			type: "select",
			placeholder: "Роль",
			options: []
		},
		{
			id: 9,
			name: "filters[city_ids][]",
			type: "select",
			isMulti: true,
			placeholder: "Город",
			options: []
		},
		{
			id: 10,
			name: "filters[gt_last_active_at]",
			type: "date",
			placeholder: "Дата последней активности от"
		},
		{
			id: 11,
			name: "filters[ls_last_active_at]",
			type: "date",
			placeholder: "Дата последней активности до"
		},
		{
			id: 12,
			name: "filters[email_verified]",
			type: "select",
			placeholder: "Cтатус подтверждения почты/тел",
			options: [{ value: "1", label: "Да" }, { value: "0", label: "Нет" }]
		}
	])

	// для фильтров Статус активности и города
	const { user_status } = dictionariesStore.dictionaries


	const [isReadyFilter, setIsReadyFilter] = useState(false)
	const [filterShow, setFilterShow] = useState(false)

	const [formData, setFormData] = useState({})
	const [users, setUsers] = useState()
	const [selectedRow, setSelectedRow] = useState([])
	const [sorting, setSorting] = useState({ field: "created_at", order: "desc" })

	const sortFunc = ({ field, order }) => {
		const newParams = {}
		newParams[`orders[${field}]`] = order

		getFilteredUsersList({
			page: searchParams.get("page") ?? 1,
			per_page: users?.per_page, ...newParams
		}, formData)
	}

	const columns = getColumns(setSorting, sortFunc)

	const selectRow = {
		mode: "checkbox",
		clickToSelect: true,
		classes: "selection-row",
		onSelectAll: (isSelect, rows) => {
			const filteredRows = []
			rows.some((item) => {
				if (rows.indexOf(item.id) === -1) {
					filteredRows.push(item)
				}
			})
			if (isSelect) {
				setSelectedRow(filteredRows)
			} else {
				setSelectedRow([])
			}
		},
		onSelect: (row, isSelect, rowIndex) => {
			const rows = []
			rows.push(row)
			if (isSelect) {
				setSelectedRow((prev) => [...prev, ...rows])
			} else {
				selectedRow.splice(rowIndex, 1)
				setSelectedRow(selectedRow)
			}
		}
	}

	const getFilteredUsersList = (params, newFilters) => {
		const newParams = params || { page: searchParams.get("page") ?? 1, per_page: users?.per_page ?? PER_PAGE[0] }

		if (sorting?.field) {
			newParams[`orders[${sorting.field}]`] = sorting?.order
		}

		filteredUsers(newFilters, newParams).then(setUsers)
	}

	useEffect(() => {

		(async () => {
			if (!user_status?.length) await dictionariesStore.request("status", {
				filters: { entity: "user" },
				saveKey: "user_status"
			})
			let roles = await getRoles()
			if (!citiesStore.cities.length) await citiesStore.fetch()

			setFilterData(filterData.map(x => {
				if (x.name === "filters[status_id]") x.options = dictionariesStore.dictionaries?.user_status
				if (x.name === "filters[roles][]") x.options = roles.map(x => ({
					value: x.name,
					label: x.description
				}))
				if (x.name === "filters[city_ids][]") x.options = citiesStore.cities.map(x => ({
					value: x.id,
					label: x.name
				}))
				return x
			}))

			setIsReadyFilter(true)
		})()

	}, [])

	const fetchData = async (fn) => {
		const ids = selectedRow.map((user) => user.id)
		await fn(ids)
		getFilteredUsersList()
	}

	const handleBlock = async () => {
		await fetchData(blockUsersRequest)
	}

	const handleUnBlock = async () => {
		await fetchData(unlockUsers)
	}

	const onPagination = (params) => {
		setSearchParams({ page: params.page })
		getFilteredUsersList(params, formData)
	}

	const onSubmit = (data) => {
		setFormData(data)
		setFilterShow(false)
		if (isInitLoading) setSearchParams({})
		if (Object.keys(data).length === 0) {
			setSorting({ field: "", order: "" })
			getFilteredUsersList({ page: !isInitLoading ? searchParams.get("page") : 1 }, data)
			setIsInitLoading(true)
			return false
		}
		getFilteredUsersList({ page: !isInitLoading ? searchParams.get("page") : 1, per_page: users?.per_page }, data)
		setIsInitLoading(true)
	}

	const onCloseFilter = () => {
		setFilterShow(false)
	}

	return (
		<div className="users-tab-wrapper">

			{isReadyFilter &&
				<Filter
					data={filterData}
					typeFilter={"filter:users"}
					typeFilterData={"filter:users:data"}
					mobileShow={filterShow}
					onSubmit={onSubmit}
					onClose={onCloseFilter}
				/>
			}

			<div className="users-list">
				<div className="users-table-wrapper">
					<div className="d-flex align-items-center justify-content-between header mt-4 mb-4">
						<div className="title">Пользователи</div>
						{meStore.hasPermission("admin.users.edit") && (
							<div className="d-flex gap-2">
								<Btn href={process.env.REACT_APP_API_URL + "/v1/users/report"} color={"white"} download>
									<IconDownload />
									Экспортировать в XLS
								</Btn>
								<Button to="/users/create" preset="primary">
									Добавить
								</Button>
							</div>
						)}
					</div>
					<FilterButton indentBottom onClick={() => setFilterShow(true)} />
					{users?.data?.length > 0 ? (
						<>
							<Table
								data={users.data}
								columns={columns}
								selectRow={selectRow}
								key={refresh}
								classes="users-table-wrapper__table"
							/>
							<div className="mt-2 d-flex gap-2">
								<Btn onClick={handleUnBlock}>Разблокировать</Btn>
								<Btn onClick={handleBlock} color={"redBorder"}>Заблокировать</Btn>
							</div>
							<Pagination
								data={users}
								onChange={onPagination}
								perPageList={PER_PAGE}
							/>
						</>
					) : (
						<p>Нет пользователей</p>
					)}
				</div>
			</div>
		</div>
	)
})
