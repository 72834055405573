import { useEffect, useMemo, useState } from 'react'

import { getCurrentTireSets } from '@api/cars'
import { Gallery } from '@components/gallery/gallery'
import { Slider } from '@components/slider/slider'
import { dictionariesStore } from '@mobx/dictionaries'
import { getPhotoSrc } from '@utils/photo'

import './tires.scss'

export const Tires = ({ id, statusReportId }) => {

	const [tires, setTires] = useState([])
	const [error, setError] = useState('')
	const [startIndex, setStartIndex] = useState()
	const [showGallery, setShowGallery] = useState(false)

	useEffect(() => {
		if (statusReportId) {
			getCurrentTireSets(id, statusReportId)
				.then((resp) => {
					if (resp.message) {
						setError(resp.errors || resp.message)

						setTimeout(() => setError(''), 5000)
					} else {
						setTires(resp)
					}
				})
		}
	}, [statusReportId])

	useEffect(() => {
		if (!dictionariesStore.dictionaries.tire_brands) {
			dictionariesStore.request('tire_brands')
		}
	}, [])

	const tireBrands = useMemo(() => {
		if (dictionariesStore.dictionaries.tire_brands?.length) {
			return dictionariesStore.dictionaries.tire_brands.reduce((acc, item) => {
				acc[item.value] = item.label
				return acc
			}, {})
		}
		return {}
	}, [dictionariesStore.dictionaries.tire_brands?.length])

	const tiresPhotos = useMemo(() => {
		const result = []
		tires?.forEach((tire) => {
			tire?.tires?.forEach((item) => {
				item.photos?.forEach((photo) => {
					result.push({
						original: photo.thumbnails_urls?.middle || photo.file.url,
						thumbnail: photo.thumbnails_urls?.small,
						description: `Колесо ${item.dimensions}`,
					})
				})
			})
		})

		return result
	}, [tires])

	const getSeason = (num) => {
		if (num === 1) {
			return 'Летняя'
		} if (num === 2) {
			return 'Зимняя'
		} if (num === 3) {
			return 'Демисезонная'
		}
	}

	const getTireSide = (side) => {
		if (side === 1) {
			return 'Левое'
		} if (side === 2) {
			return 'Правое'
		}
	}

	const getTireAxis = (axis) => {
		if (axis === 1) {
			return 'переднее'
		} if (axis === 2) {
			return 'заднее'
		}
	}

	const elPhotos = (tire) => {
		if (!tire.photos.length) {
			return (
				<img
					src={getPhotoSrc(null, true)}
					alt="Шина"
					className="tire-photo"
				/>
			)
		}

		return tire.photos.length > 1
			? (
				<Slider photos={tire.photos.map((photo) => {
					return (
						getPhotoSrc(photo.thumbnails_urls?.small || photo?.file?.url)
					)
				})}
				/>
			)
			: tire.photos.map((photo, index) => {
				return (
					<img
						src={getPhotoSrc(photo.thumbnails_urls?.small || photo.file?.url)}
						alt="Шина"
						className="tire-photo"
						key={`tire-photo-${photo?.id}`}
						onClick={() => {
							setStartIndex(index)
							setShowGallery(true)
						}}
					/>
				)
			})
	}

	return (
		<div className="section tires-wrapper" id="tires">
			<div className="title">Колеса</div>
			<div className="tires-wrapper__group">
				{tires.length ? tires.map((tiresObj, index) => {
					return (
						<div key={`tires-group-${tiresObj.id}-${index}`}>
							<div className="title-season">
								{getSeason(tiresObj?.season)} резина {tiresObj?.is_installed && '(установлены в автомобиле)'}
							</div>
							<div className="tires-wrapper__group-set">
								{tiresObj.tires.map((tire, indexTire) => {
									return (
										<div className="tire" key={`tire-${tire.id}-${indexTire}`}>
											<div className="image_container">{elPhotos(tire)}</div>
											<div className="tire-side">{getTireSide(tire.side)} {getTireAxis(tire.axis)}</div>
											<div className="tire-dimensions">{tireBrands[tire.tire_brand_id]} {tire.dimensions}</div>
										</div>
									)
								})}
							</div>
						</div>
					)
				}) : <div className="mt-2 mb-2">Данные о колесах отсутствуют</div>}
			</div>
			{error && (
				<div className="mt-2 mb-2 alert alert-danger">{JSON.stringify(error, null, ' ')}</div>
			)}
			{showGallery && (
				<Gallery
					startIndex={startIndex}
					showPlayButton={false}
					items={tiresPhotos}
					onClose={() => setShowGallery(false)}
					isWithOverlay={true}
					showFullscreenButton={true}
					showThumbnails={true}
				/>
			)}
		</div>
	)
}
