import React from 'react';
import Drop from 'react-bootstrap/Dropdown';

const getCustomToogle = (customToggle) => {
  return React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {customToggle}
    </div>
  ));
};

export const Dropdown = (props) => {
  return (
    <Drop autoClose={props.autoClose}>
      <Drop.Toggle as={props.customToggle ? getCustomToogle(props.customToggle) : undefined} />

      {props.options?.length > 0 && (
        <Drop.Menu>
          {props.options?.map((item) => {
            return (
              <Drop.Item
                disabled={item.disabled}
                active={props.value === item.value}
                key={`drop-item-${item.label}`}
                href={item.href}
                onClick={item.onClick}
              >
                {item.label}
              </Drop.Item>
            );
          })}
        </Drop.Menu>
      )}
    </Drop>
  );
};
