import React from 'react';
import styles from './BreadCrumbs.module.scss'
import { Link } from 'react-router-dom';
import IconArrowBack from '../icons/IconArrowBack';

type BreadCrumbsItemProps = {
	id: number
	url: string
	name?: string
	state?: object
}

type BreadCrumbsProps = {
	items: BreadCrumbsItemProps[]
}

const BreadCrumbs = ({ items }: BreadCrumbsProps) => {
	return (
		<div className={styles.BreadCrumbs}>
			<IconArrowBack />
			{items.map(x =>
				<Link key={x.id} to={x.url} state={x.state}>{x.name}</Link>
			)}
		</div>
	);
};

export default BreadCrumbs;
