import React from 'react';
import { IconType } from './IconType';
import classNames from 'classnames';
import styles from './Icon.module.scss'

const IconFilterReset = ({ className, size = 16 }: IconType) => {
	return (
		<svg className={classNames(className, styles[`Icon${size}`])} xmlns="http://www.w3.org/2000/svg"
		     viewBox="0 0 16 16" fill="none">
			<g fill="currentColor">
				<path
					d="M2.53033 1.46967C2.23744 1.17678 1.76256 1.17678 1.46967 1.46967C1.17678 1.76256 1.17678 2.23744 1.46967 2.53033L2.52221 3.58287C2.5052 3.64407 2.49224 3.70666 2.48354 3.77024C2.43655 4.11385 2.51722 4.46276 2.71028 4.75086C2.73081 4.78149 2.75357 4.81056 2.77837 4.83785L5.91666 8.28997V10.6667C5.91666 10.9027 6.02781 11.125 6.21666 11.2667L8.88333 13.2667C9.11059 13.4371 9.41465 13.4645 9.66874 13.3375C9.92282 13.2105 10.0833 12.9508 10.0833 12.6667V11.144L13.4697 14.5303C13.7626 14.8232 14.2374 14.8232 14.5303 14.5303C14.8232 14.2374 14.8232 13.7626 14.5303 13.4697L2.53033 1.46967ZM8.58333 9.64399L7.41666 8.47732V10.2917L8.58333 11.1667V9.64399ZM5.99999 2.58334C5.58578 2.58334 5.24999 2.91913 5.24999 3.33334C5.24999 3.74756 5.58578 4.08334 5.99999 4.08334H11.8803L9.41237 6.79818C9.13374 7.10467 9.15633 7.57901 9.46283 7.85764C9.76933 8.13626 10.2437 8.11367 10.5223 7.80718L13.2216 4.83784C13.2465 4.81049 13.2693 4.78133 13.2899 4.75061C13.4102 4.57095 13.4877 4.36614 13.5166 4.15188C13.5456 3.9376 13.525 3.71956 13.4567 3.51445C13.3883 3.30934 13.2739 3.1226 13.1222 2.96853C12.9705 2.81446 12.7856 2.69716 12.5815 2.62561C12.5018 2.59763 12.4179 2.58334 12.3333 2.58334H5.99999Z" />
			</g>
		</svg>
	);
};

export default IconFilterReset;
