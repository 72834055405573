import { bytesToMegaBytes } from "../utils";

export const AdditionalPhoto = ({ src, name, size, id, onDelete, isClosed }) => {
	const removeFile = (e) => {
		e.stopPropagation();
		e.preventDefault();

		onDelete(id);
	};

	return (
		<div className="img-upload mt-3 d-flex flex-column justify-content-center">
			<img className="img-photo" src={src} alt="" />
			<div className="img-name mt-2">Дополнительно</div>
			{src && (
				<div className="d-flex align-items-center justify-content-between loaded-file mt-2">
					<div className="name">{name}</div>
					<div className="size">{bytesToMegaBytes(size)} MB</div>
					{!isClosed &&
						<div>
							<img
								className="cursor-pointer"
								onClick={removeFile}
								src="/img/icons/remove-icon.svg"
								alt=""
							/>
						</div>
					}
				</div>
			)}
		</div>
	);
};
