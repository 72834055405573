import React from 'react';
import { Link } from 'react-router-dom';

export const getColumns = (changeStatusLot) => [
  {
    dataField: 'reservedBy',
    text: 'Зарезервировал',
    formatter: (cell, row) => <Link to={`/user/${row.reserved_by?.id}`}>{row.reserved_by?.full_name}</Link>,
  },
  {
    dataField: 'city',
    text: 'Город',
    formatter: (cell, row) => row.sale?.city?.name,
  },
  {
    dataField: 'status',
    text: 'Статус',
    formatter: (cell, row) => row.sale?.status?.name,
  },
  {
    dataField: 'vin',
    text: 'VIN',
    formatter: (cell, row) => <Link to={`/car/${row?.sale.id}`}>{row.sale?.car?.vin}</Link>,
  },
  {
    dataField: 'brand',
    text: 'Марка',
    formatter: (cell, row) => row.sale?.car?.brand?.name,
  },
  {
    dataField: 'model',
    text: 'Модель',
    formatter: (cell, row) => row.sale?.car?.model?.name,
  },
  {
    dataField: 'type',
    text: 'Тип авто',
    formatter: (cell, row) => row.sale?.car?.type?.name,
  },
  {
    dataField: 'price',
    text: 'Стоимость',
  },
  {
    dataField: 'do',
    text: 'Действие',
    formatter: (cell, row) => {
      return row?.sale?.status?.code === 'sale_waiting_approval'
        ? (
          <div>
            <a
              className="link-primary cursor-pointer mt-4 mb-2"
              onClick={() => changeStatusLot(true, row.id)}
            >
              Подтвердить
            </a> /
            <a
              className="link-primary cursor-pointer mt-4 mb-2"
              onClick={() => changeStatusLot(false, row.id)}
            >
              Отклонить
            </a>
          </div>
        ) : '';
    },
  },
];
