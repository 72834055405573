import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { getAuctionsList } from '@api/auctions'
import { Pagination } from '@components/pagination/pagination'
import { Table } from '@components/table/table'
import useMediaQuery from '@hooks/use-media-query'
import { ConfirmPopup } from '@pages/sell/confirm-popup/confirm-popup'

import { getFinishedColumns } from './utils'

import Filter from '@components/filters/Filter/Filter'
import FilterButton from '@components/filters/FilterButton/FilterButton'
import useBuyFilter from '@hooks/filters/auctions/useBuyFilter'
import { pusherStore } from "@mobx/pusher"
import { AuctionTradesHistoryModal } from "@pages/auction/auction-trades-history-modal/auction-trades-history-modal"
import { useSearchParams } from "react-router-dom"
import { meStore } from '../../mobx/me'
import './sell.scss'

const PER_PAGE = [25, 50, 100, 250]

export const Sell = observer(() => {

	const [searchParams, setSearchParams] = useSearchParams()
	const [isInitLoading, setIsInitLoading] = useState(false)

	const { ready, items, itemsAdditional, setDefaultItems, loadCarData } = useBuyFilter()

	const [isReadyFilter, setIsReadyFilter] = useState(false)
	const [formData, setFormData] = useState({})
	const [filterShow, setFilterShow] = useState(false)

	const [lotId, setLotId] = useState()
	const [showTradesHistoryModal, setShowTradesHistoryModal] = useState(false)

	const [perPage, setPerPage] = useState(25)

	const [auctions, setAuctions] = useState()
	const [isShowConfirmPopup, setIsShowConfirmPopup] = useState(false)
	const [isConfirmLot, SetIsConfirmLot] = useState(false)

	const isMobile = useMediaQuery('screen and (max-width: 780px)')

	const isBetViewExtend = meStore.hasPermission('bet.view.extend')

	const [auctionId, setAuctionId] = useState()
	const [auctionFinished, setAuctionFinished] = useState(false)

	const auctionRequest = (params, filters = {}, activeTab) => {
		getAuctionsList({
			...params,
			...filters
		}, 'auctions/selling').then(setAuctions)
	}

	const onAfterChangeStatusAuction = () => {
		auctionRequest({ page: auctions?.page, per_page: auctions?.per_page })
	}

	useEffect(() => {
		setIsReadyFilter(true)
	}, [ready])

	const [updateHistory, setUpdateHistory] = useState(false)

	useEffect(() => {
		pusherStore.subscribeAuctions({
			onBetPlaced: () => {
				onSubmit()
				setUpdateHistory(prev => !prev)
			}
		})

		return () => {
			pusherStore.disconnect()
		}
	}, [])

	const handleShowLastBetDeleteModal = (data) => {
		setShowTradesHistoryModal(false)
	}

	const changeSelectFilter = async (key, type, data, reset) => {
		loadCarData(key, type, data, 'filter:sell:data', reset)
	}

	const onInitSelectField = async (key, type, data) => {
		changeSelectFilter(key, type, data)
	}

	const onChangeSelectField = async (key, type, data) => {
		changeSelectFilter(key, type, data, true)
	}

	const onSubmit = (data) => {
		setFormData(data)
		setFilterShow(false)
		isInitLoading && setSearchParams({})
		auctionRequest({ page: !isInitLoading ? searchParams.get("page") : 1, per_page: perPage }, data)
		setIsInitLoading(true)
	}

	const onPagination = ({ page, per_page }) => {
		setPerPage(per_page)
		setSearchParams({ page: page })
		auctionRequest({ page: page, per_page: per_page }, formData)
	}

	const onResetFilter = () => {
		setDefaultItems()
		setFilterShow(false)
	}

	const onCloseFilter = () => {
		setFilterShow(false)
	}

	return (
		<div className="buy-wrapper">
			{isReadyFilter &&
				<Filter
					data={items}
					additional={itemsAdditional}
					typeFilter={'filter:sell'}
					typeFilterData={'filter:sell:data'}
					onSubmit={onSubmit}
					indent
					mobileShow={filterShow}
					onInitSelectField={onInitSelectField}
					onChangeSelectField={onChangeSelectField}
					onReset={onResetFilter}
					onClose={onCloseFilter}
				/>
			}
			<div className="buy-wrapper__name-title">Продаю</div>
			<div className="title">Аукционы</div>
			<FilterButton indent onClick={() => setFilterShow(true)} />
			{/*{(activeTab === 'finished' && !isMobile) && (*/}
			{/*	<div className="tabs d-flex">*/}
			{/*		<Button*/}
			{/*			preset="secondary"*/}
			{/*			onClick={() => {*/}
			{/*				setIsShowConfirmPopup(true);*/}
			{/*				SetIsConfirmLot(false);*/}
			{/*			}}*/}
			{/*		>*/}
			{/*			Отклонить по VIN...*/}
			{/*		</Button>*/}
			{/*		<Button*/}
			{/*			preset="primary"*/}
			{/*			onClick={() => {*/}
			{/*				setIsShowConfirmPopup(true);*/}
			{/*				SetIsConfirmLot(true);*/}
			{/*			}}*/}
			{/*		>*/}
			{/*			Подтвердить по VIN...*/}
			{/*		</Button>*/}
			{/*	</div>*/}
			{/*)}*/}
			{auctions && auctions.data?.length ? (
				<div className="mt-3 d-flex align-items-stretch flex-wrap gap-10px">
					<Table
						data={auctions?.data}
						columns={getFinishedColumns(
							auctionRequest,
							isBetViewExtend,
							setLotId,
							setShowTradesHistoryModal,
							setAuctionId,
							setAuctionFinished
						)}
					/>
				</div>
			) : (
				'Лотов нет'
			)}
			<Pagination
				data={auctions}
				onChange={onPagination}
				perPageList={PER_PAGE}
				hideInfo={isMobile}
			/>
			{isShowConfirmPopup &&
				<ConfirmPopup
					isConfirmLot={isConfirmLot}
					handleClose={() => setIsShowConfirmPopup(false)}
					auctionRequest={auctionRequest}
				/>
			}
			{showTradesHistoryModal && (
				<AuctionTradesHistoryModal
					isFinishedAuction={auctionFinished}
					auctionId={auctionId}
					lotId={lotId}
					updateHistory={updateHistory}
					handleClose={() => setShowTradesHistoryModal(false)}
					handleShowLastBetDeleteModal={handleShowLastBetDeleteModal}
				/>
			)}
		</div>
	)
})
