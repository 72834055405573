import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './Table.module.scss'

type TableProps = {
	columns: {
		id: number
		title: string
	}[]
	children: ReactNode
	onSelectCheckbox?(x: boolean): void
}

const Table = ({ columns, children, onSelectCheckbox }: TableProps) => {

	const [selectedProps, setSelectedProps] = useState(false)
	const onSelect = () => {
		setSelectedProps(!selectedProps)
		if(onSelectCheckbox) onSelectCheckbox(!selectedProps)
	}

	return (
		<div className={styles.table}>
			<table>
				<thead>
					<tr>
						<td>
							<input type="checkbox" className="selection-input-4" onInput={onSelect} />
						</td>
						{columns.map(x =>
							<td key={x.id}>
								<b>{x.title}</b>
							</td>
						)}
					</tr>
				</thead>
				<tbody>
					{children}
				</tbody>
			</table>
		</div>
	);
};

export default Table;
