import clsx from "clsx";
import styles from "./ErrorMessage.module.scss";

interface ErrorMessageProps {
	className?: string;
	show: boolean;
	text: string;
}

const ErrorMessage = (props: ErrorMessageProps) => {
	const { className, show, text } = props;
	return (
		<>
			{show && <div className={clsx(styles.ErrorMessage, className)}>
				{text}
			</div>}
		</>
	);
};

export default ErrorMessage;
