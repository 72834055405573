import React, { useEffect, useState } from "react";
import styles from "./RequiredFieldsVehicle.module.scss";
import clsx from "clsx";
import { getStatusReportFillableFields, putStatusReportId } from "@api/reports";
import { GetStatusReportFillableFieldsGroupsType } from "../../../../types/GetStatusReportFillableFieldsType";
import FieldCarForm from "../../../car-form/car-form-components/components/FieldCarForm/FieldCarForm";
import Input from "../../../../components/Forms/Input/Input";
import Select from "../../../../components/Forms/Select/Select";
import { dictionariesStore } from "../../../../mobx/dictionaries";
import { formToJSONWithEmpryString } from "@utils/formToJSON";
import { getReportDefaultValues } from "../../utils";
import { Loader } from "../../../../components/loader/loader";

type RequiredFieldsVehicleProps = {
	className?: string
	keyType: string
	edit?: boolean
	report: any
	isClosed: boolean
	onClose(s: string): void
}

const FieldType: Record<string, "text" | "number"> = {
	string: "text",
	year: "number",
	integer: "number",
	float: "number"
};

const DICTIONARIES = ["car_locations", "cities", "areas", "inspection_types"];

const RequiredFieldsVehicle = (props: RequiredFieldsVehicleProps) => {
		const { className, keyType, report, isClosed, onClose } = props;
		const [response, setResponse] = useState<GetStatusReportFillableFieldsGroupsType>();
		const dictionaries: any = dictionariesStore.dictionaries;
		const [defaultValue, setDefaultValue] = useState<any>();
		const [area, setArea] = useState<any[]>([]);
		const [loading, setLoading] = useState(false);

		const fetch = async () => {
			for (let i in DICTIONARIES) {
				await dictionariesStore.request(DICTIONARIES[i], {
					filters: {
						car_type_key: keyType
					},
					params: {
						"orders[name]": "asc"
					}
				});
			}
			await getStatusReportFillableFields(keyType).then(data => {
				setResponse(data.groups[0]);
			});
		};

		useEffect(() => {
			fetch();
			setDefaultValue(getReportDefaultValues(report));
		}, []);

		useEffect(() => {
			if (dictionaries?.areas?.length)
				setArea(dictionaries?.areas.find((x: any) => x.value === report.area_id));
		}, [dictionaries?.areas]);

		const onChangeDictionaryHandler = (type: any, value: any) => {
			if (type === "cities") {
				setArea(dictionaries.areas.find((x: any) => x.value === value.area_id));
			}
		};

		const onSubmit = async (e: any) => {
			e.preventDefault();
			setLoading(true);
			let data = formToJSONWithEmpryString(e.target);
			await putStatusReportId(report.id, data);
			setLoading(false);
			onClose("1");
		};

		return (
			<form className={clsx(styles.RequiredFieldsVehicle, className)} onSubmit={onSubmit}>
				{response?.fields.map(x => {

					const {
						key,
						name,
						is_required,
						type,
						dictionary
					} = x;

					if (dictionary) {
						return (
							<FieldCarForm
								label={name}
								key={key}
								indentBottom
								required={
									is_required
								}
							>
								<Select
									name={key}
									options={
										dictionaries[dictionary] || []
									}
									placeholder={
										"Выберите значение"
									}
									defaultValue={key === "area_id" ? area : defaultValue[key]}
									onChange={(e) =>
										onChangeDictionaryHandler(
											dictionary,
											e
										)
									}
									required={
										is_required
									}
									disabled={isClosed}
									сlearable={!is_required}
								/>
							</FieldCarForm>
						);
					}

					return (
						<FieldCarForm
							label={name}
							key={key}
							indentBottom
							required={
								is_required
							}
						>
							<Input
								type={FieldType[type]}
								placeholder={name}
								name={key}
								required={
									is_required
								}
								value={report[key]}
								disabled={isClosed}
							/>
						</FieldCarForm>
					);
				})}
				{!isClosed &&
					<div
						className={clsx("d-flex align-items-center justify-content-center gap-3 mt-3 mob-button-wrapper", styles.footer)}>
						{loading ? <Loader noPadding /> :
							<button
								className={clsx("btn btn-primary flex-grow-1")}
								type="submit"
							>
								Сохранить
							</button>
						}
					</div>
				}
			</form>
		);
	}
;

export default RequiredFieldsVehicle;
