import React from 'react';
import { Link } from 'react-router-dom';

import { getPhotoSrc } from '@utils/photo';
import { formatValueWithSpaces } from '@utils/format';

import './cars.scss';

export const CarsItem = ({ car }) => {
  return (
    <div className="car-sales__car" key={car.id}>
      <Link to={`/car/${car.id}`}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="car-sales__car-head-text">№ {car.id}</div>
          <div className="car-sales__car-head-text">{car?.department?.display_name}</div>
        </div>
        <div className="car-sales__car-content mt-2">
          <img className="car-sales__car-image" width="228px" height="128" src={getPhotoSrc(car?.preview?.thumbnails_urls?.small || car?.preview?.file?.url)} alt="car" />
          <div className="car-sales__car-name">{car.car.brand?.name} {car.car.model?.name}</div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="w-55">
              <div className="d-flex align-items-center">
                <img src="/img/icons/date-icon.svg" alt="" className="year car-sales__car-info-icon" />
                <div className="car-sales__car-info-value">{car.car.year} г</div>
              </div>
              <div className="d-flex align-items-center">
                <img src="/img/icons/speed-icon.svg" alt="" className="mileage car-sales__car-info-icon" />
                <div className="car-sales__car-info-value">{car?.mileage ? formatValueWithSpaces(car?.mileage) : '-'} км</div>
              </div>
              <div className="d-flex align-items-center">
                <img src="/img/icons/gearbox.svg" alt="" className="reg_number car-sales__car-info-icon" />
                <div className="car-sales__car-info-value">{car.car.transmission?.name}</div>
              </div>
            </div>
            <div className="w-45 d-flex flex-column">
              <div className="d-flex align-items-center">
                <img src="/img/icons/map-marker-icon.svg" alt="" className="city car-sales__car-info-icon" />
                <div className="car-sales__car-info-value" title={car.city?.name}>{car.city?.name}</div>
              </div>
              <div className="d-flex align-items-center">
                <img src="/img/icons/engine-icon.svg" alt="" className="engine car-sales__car-info-icon" />
                <div className="car-sales__car-info-value">{car.car.engine_volume} см3</div>
              </div>
              <div className="d-flex align-items-center">
                <img src="/img/icons/power-icon.svg" alt="" className="engine car-sales__car-info-icon" />
                <div className="car-sales__car-info-value">{car.car.power} л.с.</div>
              </div>
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="car-sales__car-info-vin-title">VIN:</div>
            <div className="car-sales__car-info-vin">
              <span>{car.car.vin}</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
