import { makeObservable, observable, action } from 'mobx';

import { request } from '@api/request';

class MeStore {
	constructor() {
		this.data = null;
		this.isLogined = false;

		makeObservable(this, {
			data: observable,
			isLogined: observable,
			fetch: action,
			setData: action,
			setLogined: action,
		});
	}

	fetch(config) {
		return request('auth/me', 'get', undefined, { skipRedirect401: config?.skipRedirect401 }).then((resp) => {
			if (!resp.message) {
				this.setData(resp);
			}
			return resp;
		});
	}

	setData(data) {
		this.data = data;
	}

	setLogined() {
		this.isLogined = true;
	}

	hasPermission(permission) {
		return this.data?.impersonate_as?.permissions?.includes(permission);
	}

	noPermission(permission) {
		if (this.data) {
			return this.data.impersonate_as?.permissions ? !this.data.impersonate_as.permissions.includes(permission) : true;
		}

		return null;
	}
}

export const meStore = new MeStore();
