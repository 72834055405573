import React, { ChangeEvent } from 'react';
import styles from './ComboBox.module.scss'

type ComboxItemProps = {
	id: number
	name: string
	description: string
}

type ComboxProps = {
	items: ComboxItemProps[]
	name?: string
	onChange?(value: string): void
}
const ComboBox = ({ items, name, onChange }:ComboxProps) => {

	const onChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
		if(onChange) onChange(e.target.value)
	}

	return (
		<div className={styles.Combox}>
			<select name={name} onChange={onChangeHandler}>
				{items.map(x =>
					<option key={x.id} value={x.id}>{x.description}</option>
				)}
			</select>
		</div>
	);
};

export default ComboBox;
