import classNames from "classnames"
import { ChangeEvent, useEffect, useState } from "react"
import styles from "./Input.module.scss"

type InputProps = {
	name?: string
	placeholder?: string
	type?: "text" | "email" | "tel" | "number" | "date" | "password" | "hidden"
	className?: string
	required?: boolean
	disabled?: boolean
	value?: string | number | undefined
	reset?: boolean
	readonly?: boolean
	min?: number
	max?: number
	onInput?(e: ChangeEvent): void
}

const Input = ({
	name,
	type = "text",
	placeholder,
	className,
	required,
	disabled,
	value,
	reset,
	readonly,
	max,
	min,
	onInput,
}: InputProps) => {
	const [defaultValue, setDefaultValue] = useState<string | number>("")

	const onInputHandler = (e: any) => {
		if (onInput) onInput(e)
	}

	useEffect(() => {
		setDefaultValue("")
	}, [reset])

	useEffect(() => {
		if (value) setDefaultValue(value)
	}, [value])

	return (
		<input
			className={classNames("input input_l", className, styles.Input)}
			type={type}
			name={name}
			placeholder={placeholder}
			required={required}
			disabled={disabled}
			value={defaultValue}
			readOnly={readonly}
			min={min}
			max={max}
			onInput={onInputHandler}
			onChange={(e) => setDefaultValue(e.target.value)}
		/>
	)
}

export default Input
