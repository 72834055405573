import clsx from "clsx";
import { ReactNode } from "react";
import styles from "./FieldCarForm.module.scss";

type FieldCarFormProps = {
	label?: string;
	children?: ReactNode;
	className?: string;
	indentBottom?: boolean;
	required?: boolean
};

const FieldCarForm = (props: FieldCarFormProps) => {
	const { children, label, className, indentBottom, required } = props;

	return (
		<div
			className={clsx(
				styles.FieldCarForm,
				className,
				indentBottom && styles.indent
			)}
		>
			<div className={styles.name}>
				{label}
				{required && <span>*</span>}
			</div>
			<div className={styles.section}>{children}</div>
		</div>
	);
};

export default FieldCarForm;
