import React, { useState } from 'react';

import { DEFECTS_SHORTHAND } from '@pages/report/accordion-components/utils';
import { IconTick } from '@components/icons/tick';
import { CarDefectModal } from '@components/car-defect-modal/car-defect-modal';

const renderDefect = (defects, handleShowReport, rootName) => {
  let hasOk = false;
  let hasDefects = false;

  defects?.status_report_values?.forEach((defect) => {
    if (defect?.defect?.name === 'ОК') {
      hasOk = true;
    } else {
      hasDefects = true;
    }
  });

  return defects?.status_report_values.map((defect) => {
    if (hasOk && hasDefects && defect?.defect?.name === 'ОК') return;

    let shortHand = null;

    if (defect?.defect?.name) {
      if (defect.defect.name === 'Не ОК' && defect.description) {
        shortHand = defect.description;
      } else {
        shortHand = DEFECTS_SHORTHAND[defect.defect.name];
      }
    }

    return (
      <div className="technical-check-wrapper__columns-item-field" key={defect.defect.name + defect.defect.id}>
        <div className="technical-check-wrapper__columns-item-field-name">
          {shortHand === 'ОК' ? defects.name
            : (
              <span
                onClick={() => handleShowReport(defect, defects, rootName)}
                className="technical-check-wrapper__columns-item-field-name-link"
              >
                {defects.name}
              </span>
            )}
        </div>
        <div className="defect-short align-items-center gap-2px">
          <span className={shortHand === 'ОК' ? 'green-text' : ''}>
            {shortHand === 'ОК' ? <>ОК<IconTick /></> : <img src="/img/icons/alert.svg" alt="" className="alert-icon" />}
          </span>
        </div>
      </div>
    );
  });
};

export const TechnicalCheck = ({ lastReport }) => {
  const [showReport, setShowReport] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedReportRoot, setSelectedReportRoot] = useState(null);

  const handleShowReport = (report, rootReport, rootName) => {
    setSelectedReport(report);
    setSelectedReportRoot({ ...rootReport, rootName });
    setShowReport(true);
  };

  let showSection = false;
  const showColumns = {};

  lastReport?.full_childs?.forEach((column, index) => {
    column?.status_report_elements?.forEach((item) => {
      if (item.status_report_values?.length) {
        showSection = true;
        showColumns[index] = true;
      }
    });
  });

  return (
    <div className="section technical-check-wrapper" id="technicalCheck">
      <div className="title">Техпроверка</div>

      {showSection ? (
        <>
          <div className="technical-check-wrapper__columns">
            {lastReport?.full_childs?.map((el, indexChild) => {
              if (!showColumns[indexChild]) return null;

              return (
                <div className="technical-check-wrapper__columns-item" key={`report-child-${el.id}-${indexChild}`}>
                  <div className="technical-check-wrapper__columns-item-title">{el.name}</div>
                  {el?.status_report_elements?.map((item, indexElem) => {
                    if (!item?.status_report_values?.length) return null;

                    return (
                      <div key={`report-child-${item.id}-${indexElem}`}>
                        {renderDefect(item, handleShowReport, el.name)}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {selectedReport && showReport ? (
            <CarDefectModal
              handleClose={() => setShowReport(false)}
              selectedReport={selectedReport}
              selectedReportRoot={selectedReportRoot}
            />
          ) : null}
        </>
      ) : <div>Нет данных</div>}
    </div>
  );
};
