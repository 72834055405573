import React, { useMemo } from 'react';

export const CarImportedSection = ({ sections }) => {
  const getElemEquipment = useMemo(() => {
    let arr = [];
    sections?.forEach((section) => {
      if (section.key === 'sale_equipment') {
        arr = [...arr, ...section.imported_fields];
      }
    });

    return arr;
  }, [sections]);

  const getElemCompleteness = useMemo(() => {
    let arr = [];
    sections?.forEach((section) => {
      if (section.key === 'sale_completeness') {
        arr = [...arr, ...section.imported_fields];
      }
    });

    return arr;
  }, [sections]);

  return (
    <div className="section" id="equipment">
      <div className="title">Комплектация</div>
      <div className="info-car-wrapper-completeness">
        <div className="info-car-wrapper__columns">
          {getElemEquipment?.map((item) => {
            return (
              <div className="value" key={`equipment-${item.id}`}>{item.name}</div>
            );
          })}
          <div className="info-car-wrapper__columns-title">Комплектность</div>
          {getElemCompleteness.map((item, index) => {
            return (
              <div key={`completeness-${index}`}>
                <span className="value">{item?.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
