import React, { useEffect, useState } from 'react';
import styles from './RoleViewUser.module.scss'
import { Roles } from '../../../types/Roles';
import Title from '../../../components/titles/Title/Title';
import Info from '../../../components/info/Info/Info';
import TabHeader from '../../../components/tabs/TabHeader/TabHeader';
import TabButton from '../../../components/tabs/TabButton';
import PermissionListUser from '../../../components/permissions/PermissionListUser/PermissionListUser';

type RoleViewUserProps = {
	items: Roles[]
	info?: string
}

const RoleViewUser = ({ items, info = 'У пользователя пока нет доступов' }: RoleViewUserProps) => {

	const [active, setActive] = useState(items[0]?.id)
	const [permissions, setPermissions] = useState(items[0]?.permissions)

	const onSelectTab = (id: number) => {
		setActive(id)
		const role = items.find(x => x.id === id);
		if(role)
			setPermissions(role?.permissions)
	}

	useEffect(() => {
		setActive(items[0]?.id)
		setPermissions(items[0]?.permissions)
	}, [items]);

	return (
		<div className={styles.RoleViewUser}>
			<div className={styles.header}>
				<Title label={'Доступы'} />
			</div>
			{!items.length ?
				<Info
					title={info}
					text={'Выберите Кнопку “Добавить роль” для создания новой роли'}
				/> :
				<>
					<TabHeader>
						{items.map(x =>
							<TabButton key={x.id} title={x.description} active={x.id === active} onClick={() => onSelectTab(x.id)} />
						)}
					</TabHeader>
					{permissions && <PermissionListUser items={permissions} />}
				</>
			}
		</div>
	);
};

export default RoleViewUser;
