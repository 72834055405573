import clsx from "clsx";
import { ReactNode } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import styles from "./ReportButton.module.scss";

type ReportButtonProps = {
	children: ReactNode;
	eventKey: string;
};

const ReportButton = (props: ReportButtonProps) => {
	const { children, eventKey } = props;

	const decoratedOnClick = useAccordionButton(eventKey);

	return (
		<button
			onClick={decoratedOnClick}
			className={clsx("accordion-button", styles.button)}
		>
			{children}
		</button>
	);
};

export default ReportButton;
