import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"

import { CarsModal } from "../../../cars-modal/cars-modal"

import moment from "moment/moment"
import "./lots.scss"
import LotSelectedCar from './LotSelectedCar'

export const LotSalesTable = observer(
	({
		statusAuction,
		lot,
		sales,
		onChangeSales,
		onChangeReports,
		withCreateSale,
		singleSelect,
		isSavedLot,
		isEdit,
		isNewLot,
		isUpAuction,
		isAuctionBuyNow,
		lots
	}) => {
		const [show, setShow] = useState(!sales?.length)
		const [selectedReports, setSelectedReports] = useState([])

		const [isAddingCarButton, setIsAddingCarButton] = useState(false)
		const [isAdditionalCar, setIsAdditionalCar] = useState(false)

		const onRemove = (id) => {
			setSelectedReports(selectedReports.filter(x => x.sale_id !== id))
			onChangeSales(sales.filter((item) => item.id !== id))
		}

		const onSelectReport = (obj) => {
			setSelectedReports(prev => [...prev, obj])
		}

		const onRemoveStatusReport = (id) => {
			setSelectedReports(selectedReports.filter(x => x.status_report_id !== id))
		}

		const onChangeSalesHandler = (addSales) => {
			onChangeSales([...sales, addSales])
		}

		useEffect(() => {
			if (onChangeReports) onChangeReports(selectedReports)
		}, [selectedReports])

		useEffect(() => {
			if (sales.length) {
				let relatedReports = []

				if (lot?.relatedStatusReport?.length) {
					let relatedStatusReport = lot?.relatedStatusReport.map(x =>
						Object.assign({
							sale_id: x.sale_id,
							status_report_id: x.status_report?.id,
							closed_report: x.status_report?.closed_by?.display_name,
							closed_at: moment(x.status_report?.closed_at).format("DD.MM.YYYY HH:mm"),
							cityId: x.status_report?.city_id
						}))

					relatedReports = [...relatedReports, ...relatedStatusReport]
				} else {
					sales.forEach(x => {
						let statusReports = x.status_reports.filter(x => x.lot_id === null)
						if (statusReports.length === 1) {
							let report = statusReports[0]
							let object = {
								sale_id: report.sale_id,
								status_report_id: report.id,
								closed_report: report.closed_by?.display_name,
								closed_at: moment(report?.closed_at).format("DD.MM.YYYY HH:mm"),
								cityId: report.city_id
							}

							if (relatedReports?.length)
								relatedReports = [...relatedReports, object]
							else {
								relatedReports.push(object)
							}
						}
					})
				}
				setSelectedReports(relatedReports)
			}
		}, [])

		const elTable = () => {
			return (
				<div className="lot__table">
					{sales?.map((sale) =>
						<LotSelectedCar
							key={sale.id}
							selectedReports={selectedReports}
							lotId={lot?.id}
							id={sale.id}
							name={sale.car?.brand?.name}
							vin={sale.car?.vin}
							withCreateSale={withCreateSale}
							isNewLot={isNewLot}
							isSavedLot={isSavedLot}
							statusReports={sale.status_reports}
							hasReports={!!lot?.relatedStatusReport?.length}
							isEnableDelectingReport={!(lot?.status?.code === 'lot_active' || lot?.status?.code === 'lot_sold' || statusAuction === 'auction_not_active')}
							onSelectReport={onSelectReport}
							onRemove={onRemove}
							onRemoveStatusReport={onRemoveStatusReport}
						/>
					)}
				</div>
			)
		}

		return (
			<>
				<div className="d-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center justify-content-between w-100">
						{withCreateSale && !sales?.length && (
							<>
								<button
									onClick={() => setShow(true)}
									className="btn btn-primary"
									type="button"
								>
									<img
										src="/img/icons/plus-icon.svg"
										alt=""
									/>
									Добавить автомобиль
								</button>
								<div className="text-info">
									<img
										src="/img/icons/info-icon.svg"
										alt=""
									/>
									Для создания лота добавьте минимум один
									автомобиль
								</div>
							</>
						)}
					</div>
					{withCreateSale && show && (
						<CarsModal
							show={true}
							onHide={() => setShow(false)}
							onChangeSales={onChangeSales}
							sales={sales}
							singleSelect={singleSelect}
							isUpAuction={isUpAuction}
							isNewLot={isNewLot}
							isEdit={isEdit}
						/>
					)}
				</div>
				{sales?.length > 0 && elTable()}
				{isAddingCarButton && (
					<button
						onClick={() => setShow(true)}
						className="btn-add-car mt-12px"
						type="button"
					>
						<img src="/img/icons/icon-left-blue.svg" alt="" />
						Добавить автомобиль
					</button>
				)}
			</>
		)
	}
)
