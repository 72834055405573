import React, { useEffect, useState } from 'react';
import styles from './InfoSelect.module.scss'
import { declOfNum } from '@utils/declOfNum';
import BtnDelete from '../../btn/BtnDelete/BtnDelete';
import IconDelete from '../../icons/IconDelete';
import Btn from '../../btn/Btn/Btn';
import IconTrash from '../../icons/IconTrash';

type InfoSelectProps = {
	textArray: string[]
	count: number
	onCancel?(): void
	onDelete?(): void
}

const InfoSelect = ({ textArray, count, onCancel, onDelete }: InfoSelectProps) => {

	const [text, setText] = useState('Выбрано: 1 роль')

	useEffect(() => {
		setText('Выбрано: ' + count + ' ' + declOfNum(count, textArray))
	}, [count])

	return (
		<div className={styles.InfoSelect}>
			<BtnDelete icon={<IconDelete />} onClick={onCancel}>
				{text}
			</BtnDelete>
			<Btn color={'redBorder'} size={'small'} onClick={onDelete}>
				<IconTrash />
				Удалить
			</Btn>
		</div>
	);
};

export default InfoSelect;
