import React, { useEffect, useState } from 'react';

import { getReportsCategoriesTypes, postMakeReport } from '@api/reporting';
import { DropdownButton } from '@components/dropdown/dropdown-button';
import { getAuctionReports } from '@api/auctions';
import { Table } from '@components/table/table';

import { COLUMNS } from './utils';

import './auction-reports.scss';

export const AuctionReports = ({ auctionId }) => {
  const [reports, setReports] = useState();
  const [auctionTypesList, setAuctionTypesList] = useState([]);

  const requestAuctionReports = () => {
    getAuctionReports(auctionId).then((resp) => {
      setReports(resp);
    });
  };

  useEffect(() => {
    getReportsCategoriesTypes('auction').then((resp) => {
      setAuctionTypesList(resp);
    });

    requestAuctionReports();
  }, []);

  const onClickMakeReport = (reportParams) => {
    postMakeReport({ category: 'auction', type: reportParams.key, params: { auction_id: Number(auctionId) } }).then(requestAuctionReports);
  };

  const elCreateButton = () => {
    return (
      <DropdownButton
        btnText="Создать новый отчет"
        options={auctionTypesList.map((item) => ({
          value: item.key,
          label: item.key,
          onClick: () => onClickMakeReport(item) }))}
      />
    );
  };

  return (
    <div className="auction-reports">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <div className="title">Отчеты по аукциону</div>
          <div className="text-secondary fs-12px">Хранятся 30 календарных дней после создания</div>
        </div>
        {reports?.data?.length > 0 && elCreateButton()}
      </div>
      {reports?.data?.length ? (
        <div className="auction-reports__list">
          <Table
            data={reports.data}
            columns={COLUMNS}
          />
        </div>
      ) : (
        <div className="auction-reports__list auction-reports__no-reports">
          <div className="auction-reports__no-reports-title text-secondary">Отчётов по этому аукциону пока нет</div>
          {elCreateButton()}
        </div>
      )}
    </div>
  );
};
