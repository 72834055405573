import { isObject } from "lodash";

import { getOption } from "@utils/common";

export const fieldType = {
	enum: "select",
	boolean: "radioGroup",
	string: "string",
	int: "number"
};

export const booleanOptions = [
	{ label: "Да", value: "true" },
	{ label: "Нет", value: "false" }
];

export const booleanOptionsRadio = [
	{ label: "Да", value: 1 },
	{ label: "Нет", value: 0 }
];

const getValue = (value, parseBoolean = false) => {
	if (Array.isArray(value)) return value.map(({ value: v }) => v);
	if (isObject(value)) return value.value;

	if (!Number.isNaN(Number(value)) && value !== null) return Number(value);
	if (value === "true" || value === "false") {
		if (parseBoolean) return value === "true" ? 1 : 0;
		return value === "true";
	}
	return value;
};

export const getSubmitValues = (values) => {
	return Object.entries(values).reduce((acc, [key, value]) => {
		if (key === "owner") {
			acc.owner_id = value?.id || value;
			acc.owner_type = "company";
		} else {
			acc[key] = getValue(value, true);
		}
		return acc;
	}, {});
};

export const getArraySubmitValues = (values) => {
	return Object.entries(values).reduce((acc, [key, value]) => {
		if (
			(value &&
				value !== "undefined" &&
				// eslint-disable-next-line no-prototype-builtins
				(value === "true" ||
					value === "false" ||
					typeof value === "boolean" ||
					typeof value === "number" ||
					typeof value === "string" ||
					Array.isArray(value) ||
					(value?.hasOwnProperty("value") && value.value))) ||
			value === null
		) {
			acc.push({
				id: Number(key.replace(/[^a-zA-Z0-9]/g, "")),
				value: getValue(value)
			});
		}
		return acc;
	}, []);
};

export const bytesToMegaBytes = (bytes) => {
	return (bytes / (1024 * 1024)).toFixed(2);
};

export const transformVin = (e) => {
	return { target: { value: e.target.value?.toUpperCase() } };
};

export const getGeneralInformationDefaultValues = (carData) => {
	return {
		car_type_key: getOption(carData.car?.type?.key),
		vin: carData.car?.vin,
		car_brand_id: getOption(carData.car?.brand),
		car_model_id: getOption(carData.car?.model),
		year: carData.car?.year,
		body_id: getOption(carData.car?.body),
		reg_number: carData.reg_number,
		color_id: getOption(carData.color),
		city_id: getOption(carData.city),
		area_id: getOption(carData.area),
		location_id: getOption(carData.location),
		is_left_handed: String(carData.car?.is_left_handed),
		mileage: carData.mileage,
		engine_id: getOption(carData.car?.engine),
		transmission_id: getOption(carData.car?.transmission),
		car_drive_id: getOption(carData.car?.drive),
		engine_volume: carData.car?.engine_volume,
		power: carData.car?.power,
		owner: carData?.owner,
		inspection_type_id: getOption(carData.inspection_type),
		engine_hours: carData.engine_hours
	};
};

export const mainPhotos = [
	{
		placeholder: "/img/car-images/car-front-left.svg",
		name: "Фронтальная часть и левый борт"
	},
	{
		placeholder: "/img/car-images/car-front.svg",
		name: "Фронтальная часть"
	},
	{
		placeholder: "/img/car-images/car-back.svg",
		name: "Задняя часть"
	},
	{
		placeholder: "/img/car-images/car-back-right.svg",
		name: "Задняя часть и правый борт"
	},
	{
		placeholder: "/img/car-images/car-left-side.svg",
		name: "Левый борт"
	},
	{
		placeholder: "/img/car-images/car-right-side.svg",
		name: "Правый борт"
	},
	{
		placeholder: "/img/car-images/car-panel.svg",
		name: "Панель приборов"
	},
	{
		placeholder: "/img/car-images/car-inner1.svg",
		name: "Передние сиденья"
	},
	{
		placeholder: "/img/car-images/car-inner2.svg",
		name: "Задние сиденья"
	},
	{
		placeholder: "/img/car-images/car-trunk.svg",
		name: "Багажник"
	},
	{
		placeholder: "/img/car-images/car-hood.svg",
		name: "Подкапотное пространство"
	},
	{
		placeholder: "/img/car-images/car-front3.svg",
		name: "Передний правый колесный диск"
	}
];

export const inputStringTypeField = {
	string: "text",
	year: "number",
	integer: "number",
	float: "number"
};
