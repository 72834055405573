import React from 'react';

import { getFileType } from '@utils/get-file-type';
import { getPhotoSrc } from '@utils/photo';
import { bytesToMegaBytes } from '@pages/car-form/car-form-components/utils';

import './file-preview.scss';

export const FilePreview = ({ file: { file, document_type } }) => {
  const { mime_type, url } = file;
  const { name } = document_type;

  const fileType = getFileType(mime_type);

  return (
    <a href={getPhotoSrc(url)} target="_blank" rel="noreferrer noopener" className="download-file">
      <img src="/img/icons/download-file-icon.svg" alt="" className="download-file-icon" />
      <div>
        <div className="fileName">{name}</div>
        <div className="fileInfo">
          {fileType}・{bytesToMegaBytes(file.size)}Mb
        </div>
      </div>
    </a>
  );
};
