import { DELETE, GET, POST, PUT, Send } from "@api/request"

export const deleteStatusReportDefect = (id, data) => {
	return DELETE(`status_report/${id}/defects`, data)
}

export const getDefects = (groupId) => {
	const params = {}

	if (groupId) params.group_id = groupId

	return GET("status_report/defects", params)
}

export const getStatusReportFillableFields = (car_type_key) => {
	return GET(`status_report/fillable_fields?car_type_key=${car_type_key}`)
}

export const putStatusReportId = (id, data) => {
	return PUT(`status_report/${id}`, data)
}

export const getStatusReportDocuments = (id) => {
	return Send(`status_report/${id}/documents`)
}

export const removeStatusReportDocuments = (id, document_ids) => {
	return DELETE(`status_report/${id}/documents`, { document_ids })
}

export const postAddStatusReportDocuments = (id, data) => {
	return POST(`status_report/${id}/documents`, data, undefined, {
		headers: { "content-type": "multipart/form-data" }
	})
}

export const getStatusReportPhotos = (id) => {
	return GET(`status_report/${id}/photos`)
}

export const postStatusReportPhotos = (id, data) => {
	return POST(`status_report/${id}/photos`, data, undefined, {
		headers: { "content-type": "multipart/form-data" }
	})
}

export const removeStatusReportPhotos = (id, photo_ids) => {
	return DELETE(`status_report/${id}/photos`, { photo_ids })
}

export const getWheels = (id) => {
	return GET(`status_report/${id}/tire_sets`)
}

export const postSetWheels = (id, data) => {
	return POST(`status_report/${id}/tire_sets/mass`, data, null, { headers: { "content-type": "multipart/form-data" } })
}

export const deleteWheels = (reportId, wheelsId) => {
	return DELETE(`status_report/${reportId}/tire_sets/${wheelsId}`)
}

export const getStatusReportsGroups = () => {
	return GET("status_report/groups")
}

export const getStatusReportDefects = (statusReportId, groupId) => {
	return GET(`status_report/${statusReportId}/defects?group_id=${groupId}`)
}

export const postCreateReport = (sale_id) => {
	return POST("status_report", { sale_id })
}

export const postCreateSaleDefect = (reportId, data) => {
	return POST(`status_report/${reportId}/defects`, data)
}

export const getStatusReportComments = (reportId) => {
	return GET(`status_report/${reportId}/comments`)
}

export const postStatusReportComments = (reportId, data) => {
	return POST(`status_report/${reportId}/comments`, data)
}

export const putStatusReportComments = (reportId, commentId, data) => {
	return PUT(`status_report/${reportId}/comments/${commentId}`, data)
}

export const deleteStatusReportComments = (reportId, commentId) => {
	return DELETE(`status_report/${reportId}/comments/${commentId}`)
}