import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@components/button/button';
import { postAuctionsLotsApprove, postAuctionsLotsByVin } from '@api/auctions';
import { Loader } from '@components/loader/loader';
import { Table } from '@components/table/table';
import { getTypeAuctionText } from '@utils/common';

const COLUMNS = [
  {
    dataField: 'id',
    text: '№ ',
    headerStyle: () => {
      return { width: '115px' };
    },
    formatter: (cell, row) => (
      <div>
        <Link to={`auction/${row?.auction?.id}`} className="table-link cursor-pointer">{row?.auction?.id}</Link>
        <div className="table__text-extra">
          {getTypeAuctionText(row?.auction?.auction_type)}
        </div>
      </div>
    ),
  },
  {
    dataField: 'lots',
    text: 'Лоты',
    sort: true,
    headerStyle: () => {
      return { width: '300px' };
    },
    formatter: (cell, row) => {
      return (
        <div className="d-grid gap-5px">
          {row.sales?.map((sale, index) => {
            return (
              <Link to={`/car/${sale.id}`} key={`sale-${index}`} className="table-link min-height">
                {sale.car?.brand?.name} {sale.car?.model?.name} {sale.car?.year} г.в. {sale.car?.engine_volume} {sale.car?.transmission?.name}  {sale.car?.vin}
              </Link>
            );
          })}
        </div>
      );
    },
  },
  {
    dataField: 'last-bet',
    text: 'Макс. ставка',
    formatter: (cell, row) => {
      return (
        <div className="d-grid gap-5px">
          {row.lastBet?.sum?.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
        </div>
      );
    },
  },
];

export const ChooseLots = ({ isConfirmLot, onStepChange, step, stepsNumber, handleClose, vinList, auctionRequest }) => {
  const [lots, setLots] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'selection-row',
    selected: selectedRows,
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelectedRows(rows.map((item) => item.id));
      } else {
        setSelectedRows([]);
      }
    },
    onSelect: (row, isSelect, rowIndex) => {
      if (isSelect) {
        setSelectedRows((prev) => [...prev, row.id]);
      } else {
        setSelectedRows((prev) => prev.filter((item) => item !== row.id));
      }
    },
  };

  useEffect(() => {
    postAuctionsLotsByVin(vinList)
      .then((resp) => {
        if (!resp.message) {
          setLots(resp);
          setLoading(false);
        }
      });
  }, []);

  const onSubmit = () => {
    postAuctionsLotsApprove({ 'ids': selectedRows, 'approve': isConfirmLot }).then(() => {
      handleClose();
      auctionRequest();
    });
  };

  return (
    <div>
      <div className="lots-confirm-popup__header">
        <div>
          <div className="lots-confirm-popup__header-text">
            {isConfirmLot ? 'Подтверждение' : 'Отклонение'}  продажи по VIN...
          </div>
          <div className="lots-confirm-popup__header-text-step">Шаг {step} из {stepsNumber}</div>
        </div>
        <img src="/img/icons/close-icon.svg" alt="" onClick={() => handleClose()} className="cursor-pointer" />
      </div>
      {loading
        ? <Loader />
        : (
          <div className="lots-confirm-popup__table">
            <Table
              data={lots}
              columns={COLUMNS}
              selectRow={selectRow}
            />
          </div>
        )}
      <div className="lots-confirm-popup__buttons">
        <Button
          preset="secondary"
          onClick={() => onStepChange(true)}
        >
          <img src="/img/icons/arrow-left-icon.svg" alt="" />
          Назад
        </Button>
        <Button
          preset="primary"
          onClick={onSubmit}
        >
          <img src="/img/icons/tick-icon.svg" alt="" />
          {isConfirmLot ? 'Подтвердить' : 'Отклонить'} продажу выбранных VIN
        </Button>
      </div>
    </div>
  );
};
