/**
 * Префиксы для полей, использующих вложенные фильтрации из экспанда
 */
export const getFilterPrefixes = (isPlural) => [
	`lots][sale${(isPlural) ? 's' : ''}][car][`,
	`lots][sale${(isPlural) ? 's' : ''}][`,
	'',
];
/**
 * Значения, к которым применяются префиксы
 * @type {string[][]}
 */
export const listOfPrefixedKeys = [
	['ids', 'vin', 'brand_id', 'model_id', 'year_gt', 'year_ls'],
	['mileage_gt', 'mileage_ls', 'city_id', 'location_id', 'reg_number'],
	['status_codes', 'start_at', 'starts_at_gt', 'auction_type'],
];

/**
 * Лист статусов для аукционов и текущих торгов
 * @param tabModifiers {[]} значение по умолчанию - вызов из точки "Текущие торги"
 * @return {[Object]}
 */

/**
 * Вспомогательная функция для очистки значений формы с фильтрами, которые были задействованы в последнем запросе.
 * Функция может быть универсальной для каждого компонента фильтра, так как очищаемые значения берутся из стейта.
 * Фильтры, не затронутые пользователем, просто не обрабатываются.
 * @param filters объект фильтра, хранящий текущее состояние фильтрации
 * @param filtersToIgnore {[*]} фильтры, значение которых не будет сброшено
 * @returns {{}} массив ключей фильтра, содержащий задействованные поля с нулевым значением.
 */
export const clearFilterValues = (filters, filtersToIgnore = []) => {
	const formattedFiltersList = {};
	for (const [key, value] of Object.entries(filters)) {
		if (value !== '') {
			// если значение было сброшено при прошлой итерации, в текущей его уже не будет
			formattedFiltersList[key] = filtersToIgnore.includes(key) ? value : '';
		}
	}
	return formattedFiltersList;
};

/**
 * Обертка для добавления префикса конкретной формы фильтрации к части вложенных фильтров
 * @param filters объект с фильтрами
 * @param listOfPrefixedKeys {object|string} ключи, для которых нужно применить префикс. Если ввести строковый ключ all, то все ключи будут дополнены префиксом
 * @param prefix префикс для вложенной фильтрации
 * @param ignoreOthers не возвращать значения, остающиеся без префикса
 * @returns {{}} список отформатированных объектов
 */
export const addPrefixes = (filters, listOfPrefixedKeys, prefix, ignoreOthers = false) => {
	const formattedFiltersList = {};
	// eslint-disable-next-line prefer-const
	for (let [key, value] of Object.entries(filters)) {
		if (listOfPrefixedKeys === 'all' || listOfPrefixedKeys.includes(key)) {
			if (key === 'ids') {
				formattedFiltersList[key] = (key === 'vin' && value !== '') ? `%${value}` : [value];
			} else {
				formattedFiltersList[prefix + key] = (key === 'vin' && value !== '') ? `%${value}` : value; // при отправке запроса нужен префикс %, маркирующий поиск по соответствию
			}
		} else if (!ignoreOthers) formattedFiltersList[key] = value;
	}
	return formattedFiltersList;
};

export const formatFilterKeys = (filters, isPlural) => {
	return getFilterPrefixes(isPlural).map((filter, i) => addPrefixes(filters, listOfPrefixedKeys[i], filter, true));
};

export const makeFiltersList = (filters, defaultParams) => {
	let params = defaultParams;
	for (const [key, value] of Object.entries(filters)) {
		if (value) params = { ...params, ...{ [`filters[${(key === 'start_at') ? `>${key}` : key}]`]: value } };
	}
	return params;
};

export const setLocalStorageFilters = (filters, localStorageName) => {
	const newFilters = {};

	if (filters) {
		Object.entries(filters)?.forEach(([key, value]) => {
			if (value) {
				newFilters[key] = value;
			}
		});
	}

	localStorage.setItem(localStorageName, JSON.stringify(newFilters));
};

export const filteredFilters = (filters) => {
	if (filters) {
		const formattedFilters = formatFilterKeys(filters, true);

		return formattedFilters.reduce((result, current) => Object.assign(result, current));
	}
};
