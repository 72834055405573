import React, { useEffect, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { CurrentTradesFilters } from "@pages/current-trades/current-trades-filters/current-trades-filters";
import { getExchangeRequest } from "@api/auctions";
import { meStore } from "@mobx/me";
import { NO_PERMISSION_URL } from "@utils/common";
import { pusherStore } from "@mobx/pusher";
import { formatFilterKeys } from "@utils/filters/filter-constants";
import { VIEW_HORIZONTAL, VIEW_TILE } from "@pages/current-trades/current-trades-filters/utils";
import { LotChangeView } from "@components/lot-change-view/lot-change-view";
import { Pagination } from "@components/pagination/pagination";
import useMediaQuery from "@hooks/use-media-query";

import { ExchangeItem } from "./exchange-item";

export const Exchange = observer(() => {
	let [searchParams, setSearchParams] = useSearchParams();

	const [lots, setLots] = useState(null);
	const [perPage, setPerPage] = useState(25);
	const [page, setPage] = useState(1);
	const isMobile = useMediaQuery();
	const [view, setView] = useState();

	useEffect(() => {
		setView(isMobile ? VIEW_TILE : (localStorage.getItem("exchange_view") || VIEW_HORIZONTAL));
	}, [isMobile]);

	const onChangeFilters = (filters, isReset) => {
		setSearchParams({});
		if (isReset) {
			requestExchanges(page, perPage);
		} else {
			const formattedFilters = formatFilterKeys(filters, false);
			requestExchanges(page, perPage, formattedFilters.reduce((result, current) => Object.assign(result, current)));
		}
	};

	useEffect(() => {
		pusherStore.subscribeExchange();

		return () => {
			pusherStore.disconnect();
		};
	}, []);

	const requestExchanges = (page, per_page, filters = null) => getExchangeRequest(
		{ page, per_page },
		filters
	).then((resp) => setLots(resp));

	useEffect(() => {
		let page = searchParams.get("page");
		requestExchanges(page, perPage);
	}, []);

	const onPagination = (params) => {
		setPage(params.page);
		setPerPage(params.per_page);
		setSearchParams({ page: params.page });
		requestExchanges(params.page, params.per_page);
	};

	if (meStore.noPermission("exchange-offers.view")) {
		return <Navigate to={NO_PERMISSION_URL} />;
	}

	if (meStore.data === null) {
		return null;
	}

	const elViewSettings = () => {
		return (
			<div className="auctions-wrapper">
				<div className="d-flex align-items-center justify-content-between">
					<div className="title">Лоты</div>
					<div className="d-flex">
						<LotChangeView nameView="exchange_view" setView={setView} />
						{meStore.hasPermission("exchange-offers.edit") && (
							<Link to="/exchange-offer/add" className="btn btn-primary">
								Добавить
							</Link>
						)}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="total-wrapper">
			<CurrentTradesFilters
				onChange={onChangeFilters}
				fieldsToHide={["mileage", "managers", "auction_type", "start_at"]}
				fieldsToAdd={["starts_at_gt"]}
			/>
			{elViewSettings()}
			{lots?.data?.length > 0
				? lots.data?.map((item) => (
					<ExchangeItem
						key={item.id}
						lot={item}
						view={view}
						hasViewPermission={meStore.hasPermission("exchange-offers.edit")}
					/>
				))
				: "Нет активных лотов"}
			<Pagination
				data={lots}
				onChange={onPagination}
				perPageList={[25, 50, 100, 250]}
			/>
		</div>
	);
});
