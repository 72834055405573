export const ExtentionsEnum = {
  Jpeg: 'image/jpeg',
  Jpg: 'image/jpeg',
  Png: 'image/png',
  Doc: 'application/msword',
  Pdf: 'application/pdf',
};

export const getFileType = (mime) => {
  switch (mime) {
    case ExtentionsEnum.Jpeg:
    case ExtentionsEnum.Jpg:
    case ExtentionsEnum.Png:
      return 'image';
    case ExtentionsEnum.Doc:
      return 'doc';
    case ExtentionsEnum.Pdf:
      return 'pdf';
    default:
      return 'unknown';
  }
};
