import clsx from "clsx";
import styles from "./ReportModal.module.scss";
import { Modal } from "react-bootstrap";
import { ReactNode } from "react";

interface ReportModalProps {
	className?: string;
	show: boolean;
	children: ReactNode;

	onHide(): void;
}

const ReportModal = (props: ReportModalProps) => {
	const { className, show, onHide, children } = props;

	return (
		<Modal show={show} onHide={onHide} className={clsx(styles.ReportModal, className)}>
			{children}
		</Modal>
	);
};

export default ReportModal;
