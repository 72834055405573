import React from "react";
import { IconType } from "./IconType";
import classNames from "classnames";
import styles from "./Icon.module.scss";

const IconDownload = ({ className, size = 16 }: IconType) => {
	return (
		<svg className={classNames(className, styles[`Icon${size}`])} xmlns="http://www.w3.org/2000/svg"
		     viewBox="0 0 16 16" fill="none">
			<g fill="currentColor">
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M4.25423 2.92085C4.36362 2.81146 4.512 2.75 4.66671 2.75H8.62605C8.59841 2.82819 8.58337 2.91234 8.58337 3V4.66667C8.58337 5.04239 8.73263 5.40273 8.99831 5.6684C9.26398 5.93408 9.62432 6.08333 10 6.08333H11.9167V12.6667C11.9167 12.8214 11.8552 12.9697 11.7459 13.0791C11.6365 13.1885 11.4881 13.25 11.3334 13.25H4.66671C4.512 13.25 4.36362 13.1885 4.25423 13.0791C4.14483 12.9697 4.08337 12.8214 4.08337 12.6667V3.33333C4.08337 3.17862 4.14483 3.03025 4.25423 2.92085ZM13.1963 4.80223L9.8637 1.46967C9.72305 1.32902 9.53229 1.25 9.33337 1.25H4.66671C4.11417 1.25 3.58427 1.46949 3.19357 1.86019C2.80287 2.2509 2.58337 2.7808 2.58337 3.33333V12.6667C2.58337 13.2192 2.80287 13.7491 3.19357 14.1398C3.58427 14.5305 4.11417 14.75 4.66671 14.75H11.3334C11.8859 14.75 12.4158 14.5305 12.8065 14.1398C13.1972 13.7491 13.4167 13.2192 13.4167 12.6667V5.33454V5.33333C13.4167 5.12583 13.3324 4.93802 13.1963 4.80223ZM10.856 4.58333H10.0834V3.81066L10.856 4.58333ZM8.00003 6.25C8.41424 6.25 8.75003 6.58579 8.75003 7V9.52268L9.4697 8.80301C9.76259 8.51012 10.2375 8.51012 10.5304 8.80301C10.8233 9.09591 10.8233 9.57078 10.5304 9.86367L8.53036 11.8637C8.23747 12.1566 7.76259 12.1566 7.4697 11.8637L5.4697 9.86367C5.17681 9.57078 5.17681 9.09591 5.4697 8.80301C5.76259 8.51012 6.23747 8.51012 6.53036 8.80301L7.25003 9.52268V7C7.25003 6.58579 7.58582 6.25 8.00003 6.25Z"
				/>
			</g>
		</svg>
	);
};

export default IconDownload;
