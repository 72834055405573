import { useState } from "react";

import { getPhotoSrc } from "@utils/photo";

import { bytesToMegaBytes } from "../utils";
import clsx from "clsx";

export const PhotoField = ({ photo, name, index, defaultValue, ...props }) => {
	const { label, placeholder } = photo;
	const { isClosed } = props;
	const [previewImage, setPreviewImage] = useState(
		defaultValue
			? {
				src: getPhotoSrc(defaultValue?.file?.url),
				size: defaultValue?.file?.size,
				name: defaultValue?.file?.original_name
			}
			: undefined
	);

	const handleChange = (e) => {
		const file = e.target.files[0];

		if (file) {
			const reader = new FileReader();

			reader.onloadend = () => {
				setPreviewImage({
					src: reader.result,
					size: file.size,
					name: file.name
				});
				props.onSaveRequest?.(file, index, e.target.name);
				e.target.value = "";
			};
			reader.readAsDataURL(file);
		}
	};

	const removeFile = (e) => {
		e.stopPropagation();
		e.preventDefault();

		if (defaultValue?.id) {
			props.onDelete?.(defaultValue?.id);
		}

		setPreviewImage(null);
	};

	const resetClick = (e) => {
		e.preventDefault();
	};

	return (
		<label
			className={clsx("img-upload mt-3 d-flex flex-column justify-content-center", props.required && !previewImage && "required", props.className)}
			htmlFor={name}
			onClick={previewImage ? resetClick : undefined}
		>
			{!isClosed && !previewImage &&
				<input
					className="file-input"
					type="file"
					id={name}
					name={name}
					onChange={handleChange}
				/>
			}
			<img
				className="img-photo"
				src={previewImage?.src || placeholder}
				alt=""
			/>
			<div className="img-name mt-2">
				{label}
				{props.required && <span>*</span>}
			</div>
			{previewImage ? (
				<div className="d-flex align-items-center justify-content-between loaded-file mt-2">
					<div className="name">
						{previewImage?.name}
					</div>
					<div className="size">
						{bytesToMegaBytes(previewImage?.size)} MB
					</div>
					{!isClosed &&
						<div>
							<img
								className="cursor-pointer"
								onClick={(e) => removeFile(e)}
								src="/img/icons/remove-icon.svg"
								alt=""
							/>
						</div>
					}
				</div>
			) : (
				<span className={clsx("bt btn-primary mt-2", isClosed && "opacity-25")}>Добавить фото</span>
			)}
		</label>
	);
};
