import React from "react"

import { CarItem } from "@components/car-item/car-item"

import "./lot-cars.scss"

export const LotCars = ({ lot, sellerable, isSellerHidden, view, auctionId, relatedStatusReport }) => {
	return (
		<div
			className={lot?.sales?.length > 1 ? `lot-cars__wrapper flex-wrap d-flex mt-3 gap-10px ${view}` : `lot-cars__wrapper d-flex mt-3 ${view}`}>
			{lot?.sales?.map((sale) => {
				return (
					<CarItem
						sale={sale}
						view={view}
						sellerable={sellerable}
						isSellerHidden={isSellerHidden}
						params={`auctionId=${auctionId}&lotId=${lot.id}&lotSale=true`}
						key={`lot-car-${sale.id}`}
						lot={lot}
						relatedStatusReport={relatedStatusReport}
					/>
				)
			})}
		</div>
	)
}
