import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import QuestionTooltip from '@components/tooltip/tooltip-question';
import { getExchangeOffer, putEditExchangeOffer, saveLotExchangeRequest } from '@api/auctions';
import { InputRequisitesSearch } from '@components/input-requisites-search/input-requisites-search';
import { Input } from '@components/input/input';

import { ExchangeLots } from './lots/exchange-lots';

import './exchange.scss';

export const AddLot = ({ isEdit }) => {
  const { id } = useParams();

  const [dateTypeStart, setDateTypeStart] = useState('text');
  const [dateTypeEnd, setDateTypeEnd] = useState('text');
  const [lotError, setLotError] = useState('');
  const [lotSuccess, setLotSuccess] = useState('');
  const [isExchangeCreated, setIsExchangeCreated] = useState(false);
  const [exchange, setExchange] = useState();

  const { register, handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: {
      sellerable_id: '',
      starts_at: '',
      expires_at: '',
      sellerable_type: 'department',
    },
    mode: 'onChange',
  });

  const values = watch();

  useEffect(() => {
    if (isEdit && id) {
      getExchangeOffer(id).then((resp) => setExchange(resp));
    }
  }, [isEdit, id]);

  useEffect(() => {
    if (exchange) {
      reset({
        sellerable_id: exchange.sellerable_id,
        starts_at: moment(exchange.starts_at).format('DD.MM.YYYY HH:mm'),
        expires_at: moment(exchange.expires_at).format('DD.MM.YYYY HH:mm'),
        sellerable_type: 'department',
      });
    }
  }, [exchange]);

  const onFormSubmit = (data) => {
    const request = isEdit
      ? putEditExchangeOffer(exchange.id, { starts_at: data.starts_at, expires_at: data.expires_at })
      : saveLotExchangeRequest(data);

    request.then((resp) => {
      if (resp.message) {
        setLotError(JSON.stringify(resp.errors || resp.message, null, ' '));

        setTimeout(() => setLotError(null), 5000);
      } else {
        setLotSuccess(`Лот успешно ${isEdit ? 'изменен' : 'добавлен'}`);
        setExchange(resp);
        setIsExchangeCreated(true);

        setTimeout(() => setLotSuccess(null), 5000);
      }
    });
  };

  return (
    <div className="exchange">
      <div className="head-text mb-3">
        <span>{isEdit ? 'Изменение' : 'Добавление'} лота</span>
      </div>
      <div className="info-wrapper mt-2">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <div className="name w-30">Дата начала</div>
            <div className="d-flex align-items-center w-70">
              <Input
                {...register('starts_at')}
                name="starts_at"
                type={dateTypeStart}
                onFocus={() => setDateTypeStart('datetime-local')}
                onBlur={() => setDateTypeStart('text')}
                max="24:00"
                placeholder="Дата начала"
                autoComplete="off"
                value={values.starts_at}
              />
              <QuestionTooltip text="Укажите название аукциона" />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <div className="name w-30">Дата окончания</div>
            <div className="d-flex align-items-center w-70">
              <Input
                {...register('expires_at')}
                name="expires_at"
                placeholder="Дата окончания"
                autoComplete="off"
                type={dateTypeEnd}
                onFocus={() => setDateTypeEnd('datetime-local')}
                onBlur={() => setDateTypeEnd('text')}
                max="24:00"
                value={values.expires_at}
              />
              <QuestionTooltip text="Укажите название аукциона" />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <div className="name w-30">Продавец</div>
            <div className="d-flex align-items-center position-relative w-70">
              {isEdit ? (
                <Input
                  name="sellerable_id"
                  placeholder="Продавец"
                  disabled={true}
                  value={exchange?.sellerable?.display_name || ''}
                />
              ) : (
                <InputRequisitesSearch
                  name="sellerable_id"
                  placeholder="Продавец"
                  onChange={(value) => setValue('sellerable_id', value)}
                />
              )}
              <QuestionTooltip text="Укажите продавца" />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary w-100 mt-5"
          >
            {isEdit ? 'Изменить' : 'Создать'}
          </button>
        </form>
      </div>
      {lotError && <div className="mt-2 mb-2 alert alert-danger">{lotError}</div>}
      {lotSuccess && <div className="mt-2 mb-2 alert alert-success">{lotSuccess}</div>}
      {(isExchangeCreated || (isEdit && exchange)) && (
        <ExchangeLots
          exchangeId={exchange?.id}
          isExchangeLot={true}
          lots={exchange?.lots}
          isEdit={isEdit}
        />
      )}
    </div>
  );
};
