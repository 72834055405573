import { makeObservable, observable, action } from 'mobx';

class ExchangeStore {
  constructor() {
    this.reservedList = {};

    makeObservable(this, {
      reservedList: observable,
      setLotReserved: action,
    });
  }

  setLotReserved(lotId) {
    this.reservedList[lotId] = true;
  }
}

export const exchangeStore = new ExchangeStore();
