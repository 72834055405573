import React from "react";
import styles from "./PhotoReport.module.scss";
import clsx from "clsx";
import { Photos } from "../../../car-form/car-form-components/photos";

type PhotoReportProps = {
	className?: string
	id: number
	isClosed: boolean
	onClose(): void
}

const PhotoReport = (props: PhotoReportProps) => {
	const { className, id, isClosed } = props;

	return (
		<div className={clsx(styles.PhotoReport, className)}>
			<Photos {...props} />
		</div>
	);
};

export default PhotoReport;
