import React from 'react';
import moment from 'moment';

const MONTH_NAMES = ['ЯНВ', 'ФЕВ', 'МАР', 'АПР', 'МАЯ', 'ИЮН', 'ИЮЛ', 'АВГ', 'СЕН', 'ОКТ', 'НОЯ', 'ДЕК'];

const buildTooltip = (dates, toolTipInfo) => {
  const getDate = (date) => { return new Date(date).toLocaleDateString('fr-CH'); }; // используем французскую локализацию как наиболее приближенную по формату

  return (
    `<div><div class="dates"><b>Даты:</b> ${getDate(dates.start)} - ${getDate(dates.end)}</div><div class="seller"><b>Продавец:</b> ${toolTipInfo.seller}</div><div class="lots"><b>Лоты:</b> ${toolTipInfo.lots}</div></div>`
  );
};

const addDaysToDate = (prevDate, daysToAdd) => {
  return prevDate.setDate(prevDate.getDate() + daysToAdd);
};

export const buildCells = (now) => {
  const [arrayOfDates, arrayOfCells] = [[], []];
  const _date = (date = new Date()) => new Date(date.valueOf());
  const [firstDayInCell, lastDayInCell] = [
    _date(now),
    _date(addDaysToDate(now, +32)),
  ];

  for (let i = 0; i < 32; i++) {
    arrayOfDates.push(
      _date(addDaysToDate(firstDayInCell, +((i === 0) ? 0 : 1))),
    );
  }
  for (let i = 0; i < arrayOfDates.length - 1; i++) {
    arrayOfCells.push({
      id: `date-${i}`,
      title: `${arrayOfDates[i].getDate()} ${MONTH_NAMES[arrayOfDates[i].getMonth()]}`,
      start: arrayOfDates[i],
      end: arrayOfDates[i + 1] ?? lastDayInCell,
    });
  }

  return arrayOfCells;
};

export const buildTimebar = (now) => [
  {
    id: 'quarters',
    title: '№',
    cells: buildCells(moment().startOf('day').toDate()),
    style: {},
    useAsGrid: true,
    pointerVisible: false,
  },
];

export const buildElement = ({ trackId, start, end, toolTipInfo, name }) => {
  return {
    id: `track-${trackId}`,
    title: name ?? 'err_noname',
    tooltip: buildTooltip({ start, end }, toolTipInfo),
    trackId,
    start,
    end,
    style: {
      backgroundColor: '#3C83ED',
      boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
    },
  };
};

export const buildElements = (trackId, name = null, start_at, planned_finish_at, toolTipInfo) => {
  const element = [];

  element.push(buildElement({
    trackId,
    start: new Date(start_at),
    end: new Date(planned_finish_at),
    toolTipInfo,
    name,
  }));

  return element;
};

export const buildTrack = (trackId, name = 'task', start_at, planned_finish_at, toolTipInfo) => {
  return {
    id: `auction-${trackId}`,
    title: (
      <div
        className="rt-track-key__link"
        onClick={() => { window.open(`/current-trades?auctionId=${trackId}`); }}
      >
        {`${trackId}${toolTipInfo?.seller ? `, ${toolTipInfo?.seller}` : ''}`}
      </div>
    ),
    elements: buildElements(trackId, name || `Аукцион№ ${trackId}`, start_at, planned_finish_at, toolTipInfo),
  };
};
