import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { getXSRFTokenFromCookies } from '@api/auth';
import { NO_AUTH_URL, NO_PERMISSION_URL } from '@utils/common';
import { meStore } from '@mobx/me';

import { logout, checkAuth } from './check-auth';

const ProtectedRoute = observer(({ Component, props }) => {
	const navigate = useNavigate();
	const user = meStore.data;

	useEffect(() => {
		checkAuth({
			onSuccess: (resp) => {
				if (!resp?.user?.email_verified_at || (!resp?.impersonate_as?.entity?.roles?.length && window.location.pathname !== '/profile')) {
					navigate(NO_PERMISSION_URL);
				}
			},
			onError: () => {
				navigate(NO_AUTH_URL);
			},
		});
	}, []);

	if (!user) {
		return null;
	}

	const logoutAndNavigate = () => {
		logout();

		navigate(NO_AUTH_URL);
		return null;
	};

	return (user && getXSRFTokenFromCookies()) ? <Component {...props} /> : logoutAndNavigate();
});

export default ProtectedRoute;
