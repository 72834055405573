import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Input } from '@components/input/input';
import { Select } from '@components/select/select';
import { getSaleModelsRequest } from '@api/dictionaries';

import './current-trades-filters-modal.scss';

const carType = 'car';

export const CurrentTradesFiltersModal = ({ handleClose, clearAllFilters, handleFilterChange, filters, fieldsToAdd, fieldsToHide, brands, auctionTypes, cities, car_locations, applyFilters }) => {
  const [models, setModels] = useState([]);

  useEffect(() => {
    if (filters?.brand_id) {
      getSaleModelsRequest(filters.brand_id, carType).then(setModels);
    }
  }, []);

  return (
    <Modal
      show={true}
      onHide={handleClose}
      className="current-trades-filters-wrapper"
    >
      <div className="current-trades-filters-wrapper__header">
        <div className="d-flex align-items-center gap-10px">
          <div className="current-trades-filters-wrapper__header-text">Фильтры</div>
          <div
            onClick={() => {
              clearAllFilters();
              handleClose();
            }}
            className="btn-clear"
          >
            <img src="/img/icons/filters-off-icon.svg" />
            Очистить
          </div>
        </div>
        <img src="/img/icons/close-icon.svg" alt="" onClick={() => handleClose()} className="cursor-pointer" />
      </div>
      <div className="filters-wrapper">
        <div className="d-flex align-items-center flex-wrap gap-10px">
          {fieldsToAdd?.includes('starts_at_gt') && (
            <div className="filter">
              <div className="filter-name">Дата начала от</div>
              <Input
                onChange={handleFilterChange}
                name="starts_at_gt"
                size="m"
                value={filters?.starts_at_gt}
                type="date"
                placeholder="ДД.ММ.ГГГГ"
              />
            </div>
          )}
          {!fieldsToHide?.includes('start_at') && (
            <div className="filter">
              <div className="filter-name">Дата начала от</div>
              <Input
                onChange={handleFilterChange}
                name="start_at"
                size="m"
                value={filters?.start_at}
                type="date"
                placeholder="ДД.ММ.ГГГГ"
              />
            </div>
          )}
          {!fieldsToHide?.includes('auction_type') && (
            <div className="filter">
              <div className="filter-name">Тип аукциона</div>
              <Select
                options={auctionTypes?.map((auctionType) => ({ value: auctionType.key, label: auctionType.title }))}
                valueId={filters?.auction_type}
                size="m"
                placeholder="Все"
                onChange={({ value }) => handleFilterChange({ target: { name: 'auction_type', value } })}
              />
            </div>
          )}
          {!fieldsToHide?.includes('vin') && (
            <div className="filter">
              <div className="filter-name">VIN</div>
              <Input
                size="m"
                onChange={handleFilterChange}
                value={filters?.vin || ''}
                name="vin"
              />
            </div>
          )}
          {!fieldsToHide?.includes('brand') && (
            <div className="filter">
              <div className="filter-name">Бренд</div>
              <Select
                options={brands}
                valueId={filters?.brand_id}
                size="m"
                placeholder="Все"
                onChange={({ value }) => {
                  handleFilterChange({ target: { name: 'brand_id', value } });
                  getSaleModelsRequest(value, carType).then(setModels);
                }}
              />
            </div>
          )}
          {!fieldsToHide?.includes('model') && (
            <div className="filter">
              <div className="filter-name">Модель</div>
              <Select
                options={models?.map((model) => ({ value: model.id, label: model.name }))}
                valueId={filters?.model_id}
                size="m"
                placeholder="Все"
                onChange={({ value }) => {
                  handleFilterChange({ target: { name: 'model_id', value } });
                }}
              />
            </div>
          )}
          <div className="d-flex justify-content-between w-100 gap-10px">
            {!fieldsToHide?.includes('year') && (
              <div className="filter">
                <div className="filter-name">Год выпуска от</div>
                <Input
                  size="m"
                  onChange={handleFilterChange}
                  name="year_gt"
                  value={filters?.year_gt ?? ''}
                  placeholder="ДД.ММ.ГГГГ"
                />
              </div>
            )}
            {!fieldsToHide?.includes('year') && (
              <div className="filter">
                <div className="filter-name">До</div>
                <Input
                  size="m"
                  onChange={handleFilterChange}
                  name="year_ls"
                  value={filters?.year_ls ?? ''}
                  placeholder="ДД.ММ.ГГГГ"
                />
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between w-100 gap-10px">
            {!fieldsToHide?.includes('mileage') && (
              <div className="filter">
                <div className="filter-name">Пробег (км)</div>
                <Input
                  size="m"
                  onChange={handleFilterChange}
                  name="mileage_gt"
                  value={filters?.mileage_gt ?? ''}
                />
              </div>
            )}
            {!fieldsToHide?.includes('mileage') && (
              <div className="filter">
                <div className="filter-name">До</div>
                <Input
                  size="m"
                  onChange={handleFilterChange}
                  name="mileage_ls"
                  value={filters?.mileage_ls ?? ''}
                />
              </div>
            )}
          </div>
          {!fieldsToHide?.includes('city_id') && (
            <div className="filter">
              <div className="filter-name">Город</div>
              <Select
                options={cities?.map((city) => ({ value: city.id, label: city.name }))}
                valueId={filters?.city_id}
                size="m"
                placeholder="Все"
                onChange={({ value }) => handleFilterChange({ target: { name: 'city_id', value } })}
              />
            </div>
          )}
          {!fieldsToHide?.includes('location_id') && (
            <div className="filter">
              <div className="filter-name">Местонахождение</div>
              <Select
                options={car_locations}
                valueId={filters?.location_id}
                size="m"
                placeholder="Все"
                onChange={({ value }) => handleFilterChange({ target: { name: 'location_id', value } })}
              />
            </div>
          )}
        </div>
      </div>
      <div className="current-trades-filters-wrapper__footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            applyFilters();
            handleClose();
          }}
        >
          Применить
        </button>
      </div>
    </Modal>
  );
};
