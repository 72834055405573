import clsx from "clsx";

import "./radio-button.scss";

export const RadioButton = (props) => {
	const { label, checked, defaultChecked, name, onChange, id, ...rest } =
		props;

	return (
		<label
			className={clsx("radio-container", {
				"radio-container_disabled": rest.disabled,
			})}
			htmlFor={rest.name}
		>
			<span className="radio-btn-label">{label}</span>
			<input
				id={id || name}
				checked={checked}
				name={name}
				type="radio"
				onChange={onChange}
				defaultChecked={defaultChecked}
				{...rest}
			/>
			<span className="radio-btn d-flex align-items-center justify-content-center" />
		</label>
	);
};
