import React, { useEffect, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { AuctionItem } from "@components/auction-item/auction-item";
import { pusherStore } from "@mobx/pusher";
import { meStore } from "@mobx/me";
import { NO_PERMISSION_URL } from "@utils/common";
import { VIEW_HORIZONTAL, VIEW_TILE } from "@pages/current-trades/current-trades-filters/utils";
import { LotChangeView } from "@components/lot-change-view/lot-change-view";
import { Pagination } from "@components/pagination/pagination";
import { Loader } from "@components/loader/loader";
import useMediaQuery from "@hooks/use-media-query";
import { auctionsStore } from "@mobx/auctions";

export const FavouriteLots = observer(() => {

	const [searchParams, setSearchParams] = useSearchParams();
	const isMobile = useMediaQuery();
	const [view, setView] = useState();
	const user = meStore.data;
	const { auctions, loading } = auctionsStore;

	useEffect(() => {
		setView(isMobile ? VIEW_TILE : (localStorage.getItem("auction_view") || VIEW_HORIZONTAL));
	}, [isMobile]);

	const requestLots = (params) => {
		auctionsStore.getAuctions(params, "lots/favourites");
	};

	useEffect(() => {
		let page = searchParams.get("page");
		requestLots({ page: page ?? 1 });
	}, []);

	useEffect(() => {
		if (user.user.id) {
			pusherStore.subscribeAuctions({ userId: user.user.id });
		}

		return () => {
			pusherStore.disconnect();
		};
	}, [user.user.id]);

	if (meStore.noPermission("auctions.view") && meStore.noPermission("admin.auctions.view")) {
		return <Navigate to={NO_PERMISSION_URL} />;
	}

	if (!meStore.data || loading) {
		return <Loader />;
	}

	const elViewSettings = () => {
		return (
			<div className="auctions-wrapper">
				<div className="d-flex align-items-center justify-content-between">
					<div className="title">Аукционы</div>
					{!isMobile && (
						<div className="d-flex">
							<LotChangeView nameView="auction_view" setView={setView} />
							{meStore.hasPermission("admin.auctions.edit") && (
								<Link to="/add-auction" className="btn btn-primary">
									Добавить
								</Link>
							)}
						</div>
					)}
				</div>
			</div>
		);
	};

	const onPagination = (params) => {
		setSearchParams({ page: params.page });
		requestLots(params);
	};

	return (
		<div className="total-wrapper">
			{elViewSettings()}
			{auctions.data?.length ? (
				<>
					<div>
						{auctions.data.map((item) => (
							<AuctionItem
								isMobile={isMobile}
								key={item.id}
								auction={item}
								hasViewPermission={isMobile ? VIEW_TILE : meStore.hasPermission("admin.auctions.view")}
								view={view}
							/>
						))}
					</div>
					<Pagination
						data={auctions}
						onChange={onPagination}
						perPageList={[25, 50, 100, 250]}
						hideInfo={isMobile}
					/>
				</>
			) : "Нет данных"}
		</div>
	);
});
