import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { CarItem } from '@components/car-item/car-item';
import { postReserveLot } from '@api/auctions';
import { getCurrencySymbol } from '@utils/common';
import { Tooltip } from '@components/tooltip/tooltip';
import { formatValueWithSpaces } from '@utils/format';
import { exchangeStore } from '@mobx/exchange';
import { VIEW_HORIZONTAL } from '@pages/current-trades/current-trades-filters/utils';
import useMediaQuery from '@hooks/use-media-query';
import { formatDateWithTime } from '@utils/date';

export const ExchangeItem = observer(({ lot, hasViewPermission, view }) => {
  const isMobile = useMediaQuery();
  const reserveLot = (id) => {
    postReserveLot(lot.id, id).then((resp) => exchangeStore.setLotReserved(resp.id));
  };

  const elActions = () => {
    if (view === VIEW_HORIZONTAL || lot?.sales?.length > 1) {
      return (
        <div className={lot?.sales?.length > 1 ? `lot-cars__wrapper flex-wrap d-flex mt-3 gap-10px ${view}` : `lot-cars__wrapper d-flex mt-3 gap-10px ${view}`}>
          {lot?.lots?.map((item) => {
            const isReserved = item.reserved || exchangeStore.reservedList[item.id];

            return (
              <div className="lot-car-wrapper position-relative" key={item.id}>
                <div className={`lot-cars__wrapper d-flex mt-3 ${view}`} key={item.id}>
                  <CarItem sale={item.sale} view={view} />
                </div>
                <div className="actions">
                  <div className="d-flex align-items-center justify-content-end gap-10px">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="price">
                          {formatValueWithSpaces(item.price)} {getCurrencySymbol()}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-10px">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={!isReserved ? () => reserveLot(item.id) : undefined}
                        disabled={Boolean(isReserved)}
                      >
                        {isReserved ? 'Зарезервировано' : 'Зарезервировать'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div className={`d-flex flex-wrap mt-3 card-auction__lots-wrapper gap-10px ${view}`}>
        {lot?.lots?.map((item) => {
          const isReserved = item.reserved || exchangeStore.reservedList[item.id];

          return (
            <div className="lot-car-wrapper position-relative" key={item.id}>
              <div className="lot-cars__wrapper d-flex mt-3">
                <CarItem sale={item.sale} view={view} />
              </div>
              <div className="actions">
                <div className="w-100 position-relative d-flex flex-column">
                  <div className="d-flex align-items-center gap-10px">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={!isReserved ? () => reserveLot(item.id) : undefined}
                      disabled={Boolean(isReserved)}
                    >
                      {isReserved ? 'Зарезервировано' : 'Зарезервировать'}
                    </button>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-15px">
                    <div className="d-flex align-items-center">
                      <div className="price">
                        {formatValueWithSpaces(item.price)} {getCurrencySymbol()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="card-auction mt-3">
      <div className="d-flex flex-grow-1 align-items-center justify-content-between">
        <div className="left-side d-flex flex-grow-1 align-items-center justify-content-between w-100">
          <div className="d-flex flex-grow-1 align-items-center">
            <div className="number">
              <span>
                № {hasViewPermission ? <Link to={`/exchange-offer/${lot.id}`}>{lot.id}</Link> : lot.id}
              </span>
            </div>
          </div>
          <div className="ooo-name d-flex flex-grow-1 align-items-center">
            <Tooltip text="Продавец" isDisabled={isMobile}>
              <b>{lot?.sellerable?.display_name || lot?.sellerable?.full_name}</b>
            </Tooltip>
          </div>
          <div className="d-flex flex-grow-1 align-items-center">
            <div className="d-flex flex-grow-1 date-from-to">
              <img src="/img/icons/date.svg" alt="" />
              <div className="date">
                <span>
                  {lot.starts_at ? moment(lot.starts_at).format('DD.MM.YYYY HH:mm') : '-'}
                  {lot.expires_at ? (
                    <span>
                      <span> - </span>{' '}
                      {formatDateWithTime(lot.expires_at)}
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {elActions()}
    </div>
  );
});
