import {useState} from 'react';

const useLoaded = () => {

	const [loading, setLoaded] = useState<boolean>(false)

	return {
		loading,
		setLoaded
	}
}

export default useLoaded
