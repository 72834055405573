import clsx from "clsx"
import { useMemo, useState } from "react"
import { Modal } from "react-bootstrap"

import { Gallery } from "@components/gallery/gallery"
import { Slider } from "@components/slider/slider"

import {
	insideDamagePointsCoordinatesCar,
	outsideDamagePointsCoordinates,
} from "./points"

import "./car-damage.scss"

const IMAGE_CAR_OUTSIDE = {
	car: "/img/damage/car-damage-outside.svg",
	bus: "/img/damage/bus-damage-outside.png",
	truck: "/img/damage/truck-damage-outside.png",
}

const IMAGE_CAR_INSIDE = {
	car: "/img/damage/car-damage-inside.svg",
}

export const CarDamage = ({ defects, isImported, keyVechicle }) => {
	const [activePoint, setActivePoint] = useState()
	const [activeFilter, setActiveFilter] = useState(null)
	const [startIndex, setStartIndex] = useState()
	const [showGallery, setShowGallery] = useState(false)
	const [galleryPhotos, setGalleryPhotos] = useState([])

	const newDefects = useMemo(() => {
		let result = []

		if (defects?.imported_children?.length) {
			result = defects?.imported_children.map((child) => {
				const newChild = { ...child }

				newChild.imported_children = newChild.imported_children.map(
					(child1) => {
						return { ...child1 }
					}
				)

				newChild.imported_children = newChild.imported_children?.filter(
					(item) => item.imported_defects?.length > 0
				)

				return newChild
			})

			result = result?.filter(
				(item) => item.imported_children.length > 0
			)
		}
		return result
	}, [defects])

	const allPoints = useMemo(() => {
		const inside = []
		const outside = []

		const findDefects = (childs) => {
			childs.forEach((child) => {
				if (child.status_report_elements?.length) {
					child.status_report_elements.forEach((element) => {
						if (element.status_report_values?.length) {
							element.status_report_values.forEach(
								(damageItem) => {
									if (damageItem?.defect_id !== 1) { // тут имеется ввиду что id = 1 = OK
										const newItem = {
											photos: damageItem.photos?.length ? damageItem.photos : [{
												thumbnails_urls: {
													small: '/img/damage/no-photo.svg'
												}
											}],
											id: damageItem.id,
											defect_name:
												damageItem.defect?.name,
											defect_place: element.name,
											place_id:
												damageItem.status_report_element_id,
										}

										if (
											outsideDamagePointsCoordinates[
											keyVechicle
											][
											damageItem
												.status_report_element_id
											]
										) {
											outside.push({
												...newItem,
												coordinates:
													outsideDamagePointsCoordinates[
													keyVechicle
													][
													damageItem
														.status_report_element_id
													],
											})
										} else if (
											insideDamagePointsCoordinatesCar[
											damageItem
												.status_report_element_id
											]
										) {
											inside.push({
												...newItem,
												coordinates:
													insideDamagePointsCoordinatesCar[
													damageItem
														.status_report_element_id
													],
											})
										}
									}
								}
							)
						}
					})
				}

				if (child.full_childs) {
					findDefects(child.full_childs)
				}
			})
		}

		if (defects?.full_childs?.length) {
			findDefects(defects.full_childs)
		}

		return { inside, outside }
	}, [defects])

	const elDefectsModal = useMemo(() => {
		if (activePoint) {
			return (
				<Modal
					show={Boolean(activePoint)}
					onHide={() => setActivePoint(null)}
					dialogClassName="car-damage__modal"
				>
					<div className="mb-30px">
						<div>
							<span className="text-secondary">
								Тип повреждения:
							</span>{" "}
							{activePoint.defect_name}
						</div>
						<div>
							<span className="text-secondary">
								Место повреждения:
							</span>{" "}
							{activePoint.defect_place}
						</div>
					</div>
					<Slider
						className="car-damage__slider"
						photos={
							activePoint?.photos?.map((item) => {
								return (
									item?.thumbnails_urls?.small ||
									item?.file?.url
								)
							}) || []
						}
						onImageClick={(index) => {
							setGalleryPhotos(
								activePoint.photos.map((item) => {
									return {
										original:
											item.thumbnails_urls?.middle ||
											item.file.url,
										thumbnail: item?.thumbnails_urls?.small,
										description: `${activePoint.defect_place}, ${activePoint?.defect_name}`,
									}
								})
							)

							setStartIndex(index)
							setShowGallery(true)
						}}
					/>
				</Modal>
			)
		}
	}, [activePoint])

	const renderPoints = (points) => {
		if (points.length > 0) {
			return (
				<div className="car-damage__points">
					{points.map((point) => {
						return (
							<span
								key={`car-damage-point-${point.id}`}
								className="car-damage__point"
								style={{
									top: point.coordinates.top,
									left: point.coordinates.left,
								}}
								data-id={point.place_id}
								onClick={() => {
									setActivePoint(point)
								}}
							/>
						)
					})}
				</div>
			)
		}
	}

	const renderDefectsMap = (key) => {
		return (
			<>
				<div className="title">Повреждения</div>
				<div className="car-damage-photos-wrapper">
					<div className="car-damage-photos">
						<div className="car-damage__image-content">
							<div className="car-damage__image-name">Кузов</div>
							<div
								className={clsx(
									"car-damage__image-wrap",
									key === "car" &&
									"car-damage__image-wrap-car"
								)}
							>
								<img
									className="car-damage__image"
									src={IMAGE_CAR_OUTSIDE[key]}
									alt=""
								/>
								{renderPoints(allPoints.outside)}
							</div>
						</div>
						{key === "car" && (
							<div className="car-damage__image-content">
								<div className="car-damage__image-name">
									Салон
								</div>
								<div
									className={clsx(
										"car-damage__image-wrap",
										key === "car" &&
										"car-damage__image-wrap-car"
									)}
								>
									<img
										className="car-damage__image"
										src={IMAGE_CAR_INSIDE[key]}
										alt=""
									/>
									{renderPoints(allPoints.inside)}
								</div>
							</div>
						)}
					</div>
				</div>
				{elDefectsModal}
			</>
		)
	}

	const renderExtraDefects = () => {
		const extraDefects = defects?.imported_children?.filter(
			(item) => item.name === "Остальное"
		)

		return (
			<div className="car-damage">
				<div className="title">Остальное</div>
				<div className="mt-2">
					{extraDefects?.[0]?.imported_defects?.map((defectGroup) => {
						return (
							<div
								key={defectGroup.id}
								className="car-damage__wrapper"
							>
								<div
									onClick={() =>
										setActiveFilter((prev) =>
											prev === defectGroup.id
												? null
												: defectGroup.id
										)
									}
									className="d-flex align-items-start gap-15px"
								>
									<img
										className={clsx(
											"arrow-down cursor-pointer",
											{
												"rotate-180":
													activeFilter !==
													defectGroup.id,
											}
										)}
										src="/img/tabs/hide.svg"
										alt=""
									/>
									<div className="car-damage__wrapper-info-element-title-name">
										{defectGroup.element_name}
									</div>
								</div>
								<div
									className={
										activeFilter !== defectGroup.id
											? "d-none"
											: "car-damage__wrapper-info"
									}
								>
									<div className="car-damage__wrapper-info-elements-defect">
										<div className="car-damage__wrapper-info-elements-defect-name">
											{defectGroup?.defect}
										</div>
										<div className="d-flex flex-wrap gap-5px w-70">
											{defectGroup.photos?.map(
												(photo, index) => {
													return (
														<span
															className="car-damage__img-wrap"
															key={`photo-${index}`}
															onClick={() => {
																setGalleryPhotos(
																	defectGroup.photos.map(
																		(
																			item
																		) => {
																			return {
																				original:
																					item
																						.thumbnails_urls
																						?.middle ||
																					item
																						.file
																						?.url,
																				thumbnail:
																					item
																						.thumbnails_urls
																						?.small,
																				description: `${defectGroup.element_name}, ${defectGroup?.defect}`,
																			}
																		}
																	)
																)
																setStartIndex(
																	index
																)
																setShowGallery(
																	true
																)
															}}
														>
															<img
																src={
																	photo
																		.thumbnails_urls
																		?.small ||
																	photo?.file
																		?.url
																}
																alt=""
																className="car-damage__image-defect"
															/>
														</span>
													)
												}
											)}
										</div>
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}

	return (
		<div className="car-damage section" id="damage">
			{isImported ? (
				<>
					{newDefects?.map((defectGroup) => {
						return (
							<div key={defectGroup.id}>
								<div className="title">{defectGroup.name}</div>
								<div className="mt-2">
									{defectGroup.imported_children?.map(
										(defect, index) => {
											return (
												<div
													className="car-damage__wrapper"
													key={`defect-${index}`}
												>
													<div
														onClick={() =>
															setActiveFilter(
																(prev) =>
																	prev ===
																		defect.id
																		? null
																		: defect.id
															)
														}
														className="d-flex align-items-start gap-15px"
													>
														<img
															className={clsx(
																"arrow-down cursor-pointer",
																{
																	"rotate-180":
																		activeFilter !==
																		defect.id,
																}
															)}
															src="/img/tabs/hide.svg"
															alt=""
														/>
														<div className="car-damage__wrapper-info-element-title-name">
															{defect.name}
														</div>
													</div>
													<div
														className={
															activeFilter !==
																defect.id
																? "d-none"
																: "car-damage__wrapper-info"
														}
													>
														{defect?.imported_defects?.map(
															(
																element,
																index
															) => {
																return (
																	<div
																		className="car-damage__wrapper-info-elements"
																		key={`element-${index}`}
																	>
																		<div className="car-damage__wrapper-info-element-name">
																			{
																				element?.element_name
																			}
																			<span className="text-secondary fw-normal ml-5px">
																				{
																					element
																						.status_report_values
																						?.length
																				}
																			</span>
																		</div>
																		<div
																			className="car-damage__wrapper-info-elements-defect"
																			key={`report-value-${index}`}
																		>
																			<div className="car-damage__wrapper-info-elements-defect-name">
																				{
																					element.defect
																				}
																			</div>
																			<div className="d-flex flex-wrap gap-5px w-70">
																				{element.photos?.map(
																					(
																						photo,
																						index
																					) => {
																						return (
																							<span
																								className="car-damage__img-wrap"
																								key={`photo-${index}`}
																								onClick={() => {
																									setGalleryPhotos(
																										element.photos.map(
																											(
																												item
																											) => {
																												return {
																													original:
																														item
																															.file
																															?.url,
																													thumbnail:
																														item
																															.thumbnails_urls
																															?.small,
																													description: `${element?.element_name}, ${element.defect}`,
																												}
																											}
																										)
																									)
																									setStartIndex(
																										index
																									)
																									setShowGallery(
																										true
																									)
																								}}
																							>
																								<img
																									src={
																										photo
																											.thumbnails_urls
																											?.small ||
																										photo
																											?.file
																											?.url
																									}
																									alt=""
																									className="car-damage__image-defect"
																								/>
																							</span>
																						)
																					}
																				)}
																			</div>
																		</div>
																	</div>
																)
															}
														)}
													</div>
												</div>
											)
										}
									)}
								</div>
							</div>
						)
					})}
					{renderExtraDefects()}
				</>
			) : (
				renderDefectsMap(keyVechicle)
			)}
			{showGallery && (
				<Gallery
					startIndex={startIndex}
					showPlayButton={false}
					items={galleryPhotos}
					onClose={() => setShowGallery(false)}
					isWithOverlay={true}
					showFullscreenButton={true}
					showThumbnails={true}
				/>
			)}
		</div>
	)
}
