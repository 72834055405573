import React from 'react';
import clsx from 'clsx';

type TabButtonProps = {
	title: string
	active?: boolean
	onClick?(): void
}

const TabButton = ({ title, active, onClick }: TabButtonProps) => {

	return (
		<button
			onClick={onClick}
			className={clsx('tab-btn', active && 'tab-btn-active')}
			type="button"
		>
			{title}
		</button>
	);
};

export default TabButton;
