import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@components/button/button';
import { POST } from '@api/request';
import { Input } from '@components/input/input';
import { NO_AUTH_URL } from '@utils/common';

export const TemporaryRegister = () => {
  const { code } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {
      isValid,
      errors,
    },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
    },
  });

  const [typeFieldPassword, setTypeFieldPassword] = useState('password');
  const [classFieldPassword, setClassFieldPassword] = useState('hide-password-input');
  const [serverError, setServerError] = useState('');
  const [isShowFlash, setIsShowFlash] = useState(false);
  const [textFlash, setTextFlash] = useState('');
  const [valuesErrors, setValuesErrors] = useState({});
  const values = watch();
  const navigate = useNavigate();

  const hidePassword = () => {
    if (typeFieldPassword === 'text') {
      setClassFieldPassword('hide-password-input');
      setTypeFieldPassword('password');
    } else {
      setClassFieldPassword('hide-password-input-active');
      setTypeFieldPassword('text');
    }
  };

  const onClassNameChange = (field, error, serverError) => {
    if (field === 'email') {
      if (valuesErrors?.email) {
        return 'border-red';
      }

      if (values.email === '') {
        return '';
      }

      if (!values.email?.includes('@') || !values.email?.includes('.')) {
        return 'border-red';
      } if (values.email?.includes('@') && values.email?.includes('.')) {
        return 'border-green';
      }
    }

    if (error || serverError) {
      return 'border-red';
    } if (values[field] !== '') {
      return 'border-green';
    }
    return '';
  };

  const elInputStatus = (name, error, serverError) => {
    if (serverError) {
      return <span className="form-no-validate" />;
    }

    if (name === 'roles') {
      if (serverError?.roles) {
        return <span className="form-no-validate" />;
      }
      return '';
    }

    if (name === 'email') {
      if (serverError?.email) {
        return <span className="form-no-validate" />;
      }

      if (values.email === '') {
        return '';
      } if (!values.email?.includes('@') || !values.email?.includes('.')) {
        return <span className="form-no-validate" />;
      } if (values.email?.includes('@') && values.email?.includes('.')) {
        return <span className="form-validate" />;
      }
    }

    if (values[name] !== '') {
      return <span className="form-validate" />;
    } if (error) {
      return <span className="form-no-validate" />;
    }
    return '';
  };

  const onFormSubmit = (data) => {
    const convert = data;
    convert.phone = convert.phone.replace(/\D/g, '');

    POST(`auth/temporary-registration/${code}`, convert).then((data) => {
      setServerError('');
      setValuesErrors({});
      if (data.errors) {
        setValuesErrors(data.errors);
        setServerError('Что-то пошло не так!');

        setTimeout(() => {
          setValuesErrors({});
        }, 3000);
      } else if (data.message) {
        setServerError('Что-то пошло не так!');
        setIsShowFlash(false);
      } else {
        setTextFlash('Вы успешно прошли регистрацию!');
        setIsShowFlash(true);
        navigate(NO_AUTH_URL);
      }
    });
  };

  return (
    <div className="form-lk-wrapper form-signup">
      <div className="title">Регистрация</div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mb-3 position-relative">
          <Input
            className={onClassNameChange('last_name', errors.last_name, valuesErrors.last_name)}
            placeholder="Фамилия *"
            {...register('last_name', {
              required: true,
            })}
          />
          {elInputStatus('last_name', errors.last_name, valuesErrors.last_name)}
          {valuesErrors?.last_name && <span className="error-message">{valuesErrors.last_name}</span>}
          {errors?.last_name && <span className="error-message">Укажите фамилию</span>}
        </div>
        <div className="mb-3 position-relative">
          <Input
            className={onClassNameChange('first_name', errors.first_name, valuesErrors.first_name)}
            placeholder="Имя *"
            {...register('first_name', {
              required: true,
            })}
          />
          {elInputStatus('first_name', errors.first_name, valuesErrors.first_name)}
          {valuesErrors?.first_name && <span className="error-message">{valuesErrors.first_name}</span>}
          {errors?.first_name && <span className="error-message">Укажите имя</span>}
        </div>
        <div className="mb-3 position-relative">
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <InputMask
                  className={`input input_l ${onClassNameChange('phone', errors.phone, valuesErrors.first_name)}`}
                  mask="+7(999) 999-99-99"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  placeholder="Телефон*"
                  refs={{ ...register('phone', {
                    required: true,
                  }) }}
                />
              );
            }}
          />
          {elInputStatus('phone', errors.phone, valuesErrors.first_name)}
          {valuesErrors?.phone && <span className="error-message">{valuesErrors.phone}</span>}
          {errors?.phone && <span className="error-message">Укажите номер телефона</span>}
        </div>
        <div className="mb-3 position-relative">
          <Input
            className={onClassNameChange('email', errors.email, valuesErrors.first_name)}
            placeholder="E-mail *"
            autoComplete="new-password"
            {...register('email', {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
              },
            })}
          />
          {elInputStatus('email', errors.email, valuesErrors.email)}
          {valuesErrors?.email && <span className="error-message">{valuesErrors.email}</span>}
          {errors?.email && <span className="error-message">Укажите корректный E-mail</span>}
        </div>
        <div className="mb-3 position-relative">
          <Input
            type={typeFieldPassword}
            name="password"
            className={onClassNameChange('password', errors.password, valuesErrors.password)}
            placeholder="Пароль *"
            autoComplete="new-password"
            {...register('password', {
              required: true,
            })}
          />
          {elInputStatus('password', errors.password, valuesErrors.password)}
          {valuesErrors?.password && <span className="error-message">{valuesErrors.password}</span>}
          {errors?.password && <span className="error-message">Укажите пароль</span>}
          <span onClick={hidePassword} className={classFieldPassword} />
        </div>
        <div className="mb-3">
          <Button preset="primary" type="submit" disabled={!isValid} className="w-100">
            Зарегистрироваться
          </Button>
        </div>
      </form>
      {isShowFlash && <div className="alert alert-success">{textFlash}</div>}
      {serverError && <div className="alert alert-danger">{serverError}</div>}
    </div>
  );
};
