import { observer } from "mobx-react-lite"
import moment from "moment"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

import { getGroupUsersRequest, getUserGroupRequest } from "@api/user-groups"
import { Button } from "@components/button/button"
import { Loader } from "@components/loader/loader"
import { Pagination } from "@components/pagination/pagination"
import { Table } from "@components/table/table"
import { meStore } from "@mobx/me"

import { UsersGroupModal } from "./user-groups-modal/users-group-modal"

import "./user-group.scss"

const PER_PAGE = [25, 50, 100, 250, 500]

const columns = [
	{
		dataField: "first_name",
		text: "ФИО",
		sort: true,
		formatter: (cell, row) => (
			<Link to={`/user/${row.id}`}>
				{row.display_name}
			</Link>
		),
	},
	{
		dataField: "phone",
		text: "Телефон",
		sort: true,
	},
	{
		dataField: "company",
		text: "Компании",
		sort: true,
		formatter: (cell, row) => {
			const companies = row.companies
			return (
				<>
					{!!companies?.length ?
						companies.map(x =>
							<div key={x.id} className='mb-2'>
								<Link to={`/company/${x.id}`} title={x.display_name} className='users-company-name'>{x.display_name}</Link>
							</div>
						) :
						'-'
					}
				</>
			)
		},
	},
	{
		dataField: "created_at",
		text: "Дата создания",
		formatter: (cell, row) => moment(row.created_at).format("DD.MM.YYYY"),
		sort: true,
	},
]

export const UserGroup = observer(() => {
	const { id } = useParams()

	const [group, setGroup] = useState()
	const [loading, setLoading] = useState(true)
	const [users, setUsers] = useState()
	const [showModal, setShowModal] = useState(false)
	const [role, setRole] = useState('')

	const requestUsers = (params) => {
		const newParams = Object.assign({}, params, role && { 'roles[]': role })
		getGroupUsersRequest(id, newParams).then(setUsers)
	}

	useEffect(() => {
		getUserGroupRequest(id).then((resp) => {
			setGroup(resp)
			setRole(resp?.role?.name)
			setLoading(false)
			requestUsers({ page: 1, per_page: PER_PAGE[0], 'roles[]': resp?.role?.name, 'orders[created_at]': 'desc' })
		})
	}, [])

	if (loading) {
		return <Loader />
	}

	return (
		<div className="group-wrapper">
			<div className="d-flex justify-content-end">
				{meStore.hasPermission("groups.edit") && (
					<Link
						to={`/user-group/${id}/edit`}
						className="btn btn-primary"
					>
						Редактировать
					</Link>
				)}
			</div>
			<div className="company-info mt-25px">
				<div className="d-flex justify-content-between">
					<div className="name">
						<span>Название группы</span>
					</div>
					<div className="value">
						<span>{group.name}</span>
					</div>
				</div>
				<div className="d-flex justify-content-between">
					<div className="name">
						<span>Роль</span>
					</div>
					<div className="value">
						<span>{group.role?.description}</span>
					</div>
				</div>
			</div>
			<div className="d-flex align-items-center justify-content-between mt-4">
				<div className="title">Пользователи</div>
				{meStore.hasPermission("groups.assign") && (
					<Button onClick={() => setShowModal(true)}>Изменить</Button>
				)}
			</div>
			{users?.data?.length > 0 ? (
				<div className="mt-4">
					<Table data={users.data} columns={columns} />
					<Pagination
						data={users}
						onChange={requestUsers}
						perPageList={PER_PAGE}
					/>
				</div>
			) : (
				<div className="mt-4">Пользователи еще не добавлены</div>
			)}
			{showModal && (
				<UsersGroupModal
					groupUsers={users}
					groupId={group?.id}
					filteredRole={group?.role?.name}
					show={true}
					onHide={() => setShowModal(false)}
					requestUsers={requestUsers}
				/>
			)}
		</div>
	)
})
