import React from 'react';
import { Modal } from 'react-bootstrap';

import './transition-confirm-popup.scss';

const getTextPopup = (confirmationMessageCode, actionId, lotId) => {
  if (confirmationMessageCode === 'reserved_sale_moving_to_new_should_be_confirmed') {
    return (
      <div>Сейчас этот автомобиль торгуется в аукционе №
        <a target="_blank" href={`/auction/${actionId}`} rel="noreferrer">{actionId}</a>. Если вы смените его статус, лот № {lotId} будет закрыт со статусом «Отклонен». Все автомобили в лоте № {lotId} также будут переведены на статус «В подготовке».
        Это действие нельзя отменить.
      </div>
    );
  } if (confirmationMessageCode === 'lot_declining_should_be_confirmed') {
    return 'Сменить статус лота? Это действие нельзя отменить';
  }
  return 'Сменить статус? Это действие нельзя отменить';
};

export const TransitionConfirmPopup = ({ handleClose, transition, auctionId, lotId, changeStatusLot, isCar }) => {
  return (
    <Modal
      show={true}
      onHide={handleClose}
      className="transition-confirm-popup-wrapper d-flex align-items-center justify-content-center"
    >
      <div className="transition-confirm-popup-wrapper__header">Сменить статус {isCar ? 'авто' : 'лота'}?</div>
      <div className="transition-confirm-popup-wrapper__text">
        {getTextPopup(transition.confirmation_message_code, auctionId, lotId)}
      </div>
      <div className="transition-confirm-popup-wrapper__btn-wrap">
        <button
          type="button"
          className="btn transition-confirm-popup-wrapper__btn-wrap-confirm"
          onClick={() => changeStatusLot({ auctionId, lotId, status: transition?.status })}
        >
          Да, сменить статус
        </button>
        <button type="button" className="btn btn-secondary" onClick={handleClose}>Нет, вернуться</button>
      </div>
    </Modal>
  );
};
