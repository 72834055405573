import React from 'react';
import styles from './Badge.module.scss'
import clsx from 'clsx';

type BadgeProps = {
	text: string
	className?: string
	color?: 'gray'
}

const Badge = ({ text, className, color = 'gray' }: BadgeProps) => {
	return (
		<div className={clsx(className, styles.Badge, styles[color])}>
			{text}
		</div>
	);
};

export default Badge;
