import React, { ElementType, HTMLAttributes } from 'react';
import styles from './Title.module.scss'
import classNames from 'classnames';

interface TitleProps extends HTMLAttributes<HTMLDivElement | HTMLHeadingElement> {
	as?: ElementType
	className?: string
	label: string
}

const Title = ({ as: Tag = 'div', className, label }: TitleProps) => {
	return (
		<Tag className={classNames(className, styles.title)}>
			{label}
		</Tag>
	);
};

export default Title;
