import React, { ReactNode } from 'react';
import styles from './TabHeader.module.scss'
import clsx from 'clsx';

type TabHeaderProps = {
	children: ReactNode
}

const TabHeader = ({ children }: TabHeaderProps) => {
	return (
		<div className={clsx('tabs d-flex', styles.TabHeader)}>
			{children}
		</div>
	);
};

export default TabHeader;
