import React from 'react';
import { IconType } from './IconType';
import classNames from 'classnames';
import styles from './Icon.module.scss'

const IconPlus = ({ className, size = 16 }: IconType) => {
	return (
		<svg className={classNames(className, styles[`Icon${size}`])} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
			<g fill="currentColor">
				<path d="M8.74992 3.33325C8.74992 2.91904 8.41413 2.58325 7.99992 2.58325C7.58571 2.58325 7.24992 2.91904 7.24992 3.33325V7.24992H3.33325C2.91904 7.24992 2.58325 7.58571 2.58325 7.99992C2.58325 8.41413 2.91904 8.74992 3.33325 8.74992H7.24992V12.6666C7.24992 13.0808 7.58571 13.4166 7.99992 13.4166C8.41413 13.4166 8.74992 13.0808 8.74992 12.6666V8.74992H12.6666C13.0808 8.74992 13.4166 8.41413 13.4166 7.99992C13.4166 7.58571 13.0808 7.24992 12.6666 7.24992H8.74992V3.33325Z" />
			</g>
		</svg>
	);
};

export default IconPlus;
