import React from 'react';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import ReactTooltip from 'react-tooltip';

import { Select } from '@components/select/select';
import { ReactComponent as Good } from '@assets/white-check-in-green-circle.svg';
import { ReactComponent as Error } from '@assets/white-cross-in-red-circle.svg';

import './form-select.scss';

export const FormSelect = (props) => {
  const { label, id, options = [], className, wrapperClassName, placeholder = 'Выберите значение', error, success, isClearable, ...rest } = props;
  const selectId = id || uniqueId();

  return (
    <label
      htmlFor={selectId}
      className={clsx('d-flex align-items-center select-wrapper justify-content-between', wrapperClassName)}
    >
      {label && <span className="select-label">{label}</span>}
      <div className={clsx(className, { error, success })}>
        <Select
          options={options}
          placeholder={placeholder}
          isClearable={isClearable}
          {...rest}
        />
        <Error
          data-tip={error?.value || error}
          data-for={selectId}
          className={clsx('select-notice', { error__show: error })}
        />
        <Good className={clsx('select-notice', { success__show: success })} />
        <ReactTooltip id={selectId} type="error" effect="solid" />
      </div>
    </label>
  );
};
