import React, { useState } from 'react';
import clsx from 'clsx';

import { VIEW_HORIZONTAL, VIEW_TILE } from '@pages/current-trades/current-trades-filters/utils';

import './lot-change-view.scss';

export const LotChangeView = (props) => {
  const [view, setView] = useState(localStorage.getItem(props.nameView) || VIEW_HORIZONTAL);

  const onChange = (view) => {
    setView(view);
    props.setView(view);
    localStorage.setItem(props.nameView, view);
  };

  return (
    <div className="icon-view-wrapper">
      <div className="d-flex align-items-center gap-5px">
        <span className={clsx('icon-view', { 'icon-view_selected': view === VIEW_TILE })}>
          <img
            src="/img/show.svg"
            alt=""
            onClick={() => onChange(VIEW_TILE)}
          />
        </span>
        <span className={clsx('icon-view', { 'icon-view_selected': view === VIEW_HORIZONTAL })}>
          <img
            src="/img/icons/horizontal-icon.svg"
            alt=""
            onClick={() => onChange(VIEW_HORIZONTAL)}
          />
        </span>
      </div>
    </div>
  );
};
