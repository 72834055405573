import React, { useEffect, useState } from "react";

import { Table } from "@components/table/table";
import { COMPANIES_COLUMNS } from "@pages/auction/auction-edit-trades-result-modal/utils";
import { Pagination } from "@components/pagination/pagination";
import { getCompaniesRequest, getFilteredCompanies } from "@api/companies";
import { Input } from "@components/input/input";
import { Button } from "@components/button/button";

const PER_PAGE = [25, 50, 100, 250, 500];

const DEFAULT_FILTERS = {
	title: "",
	company_name: "",
	inn: ""
};

export const CompaniesTable = ({ onSelectCompany }) => {
	const [companies, setCompanies] = useState();
	const [filters, setFilters] = useState(DEFAULT_FILTERS);

	const requestCompanies = (params) => {
		getCompaniesRequest(params).then(setCompanies);
	};

	useEffect(() => {
		requestCompanies({ page: 1, per_page: PER_PAGE[0] });
	}, []);

	const getFilteredCompaniesList = () => {
		getFilteredCompanies(filters, { page: 1, per_page: companies?.per_page }).then(setCompanies);
	};

	const clearAllFilters = () => {
		setFilters(DEFAULT_FILTERS);
		requestCompanies({ page: 1, per_page: companies?.per_page });
	};

	const handleInputChange = (e) => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value
		});
	};

	return (
		<>
			<div className="companies-filters">
				<div className="d-flex align-items-center flex-wrap gap-10px">
					<Input
						size="s"
						placeholder="Название"
						name="title"
						value={filters.title}
						onChange={handleInputChange}
					/>
					<Input
						size="s"
						placeholder="Юр. название"
						name="company_name"
						value={filters.company_name}
						onChange={handleInputChange}
					/>
					<Input
						size="s"
						placeholder="ИНН"
						name="inn"
						value={filters.inn}
						onChange={handleInputChange}
					/>
				</div>
				<div className="d-flex mt-20px gap-15px">
					<Button onClick={getFilteredCompaniesList} size="s">
						Применить фильтры
					</Button>
					<Button onClick={clearAllFilters} className="d-flex align-items-center gap-5px" preset="secondary"
							size="s">
						<img src="/img/icons/filters-off-icon.svg" />
						Очистить
					</Button>
				</div>
			</div>
			{companies?.data?.length > 0 ? (
				<>
					<div className="mt-3 d-flex align-items-stretch flex-wrap gap-10px">
						<Table
							data={companies?.data}
							columns={COMPANIES_COLUMNS}
							rowEvents={{
								onClick: (e, row) => {
									onSelectCompany?.(row, "company");
								}
							}}
						/>
					</div>
					<div className="pagination-modal-wrapper">
						<Pagination
							data={companies}
							onChange={requestCompanies}
							perPageList={PER_PAGE}
						/>
					</div>
				</>
			) : (
				<p>Нет данных</p>
			)}
		</>
	);
};
