import { makeObservable, observable, computed, action, runInAction } from 'mobx';

import { GET } from '@api/request';

class ManagersStore {
  constructor() {
    this.items = new Map();
    this.noPermissions = false;

    makeObservable(this, {
      items: observable,
      managers: computed,
      fetch: action,
    });
  }

  fetch() {
    if (!this.noPermissions) {
      GET('users?filters[roles][0]=manager').then((resp) => {
        runInAction(() => {
          if (!resp.message && resp.data) {
            resp.data.forEach((item) => {
              this.items.set(item.id, item);
            });
          } else if (resp.status === 403) {
            this.noPermissions = true;
          }
        });
      });
    }
  }

  get managers() {
    return Array.from(this.items.values());
  }
}

export const managersStore = new ManagersStore();
