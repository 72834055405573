import clsx from "clsx";
import { ReactNode } from "react";
import cls from "./BtnText.module.scss";

interface BtnTextProps {
	className?: string;
	children?: ReactNode;
	iconBefore?: ReactNode;
	iconAfter?: ReactNode;
}

const BtnText = (props: BtnTextProps) => {
	const { className, children, iconAfter, iconBefore, ...otherProps } = props;
	return (
		<button className={clsx(cls.BtnText, className)} {...otherProps}>
			{iconBefore}
			<span>{children}</span>
			{iconAfter}
		</button>
	);
};

export default BtnText;
