import React, { useEffect, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { meStore } from "@mobx/me";
import { NO_PERMISSION_URL } from "@utils/common";
import { Pagination } from "@components/pagination/pagination";
import { getCompaniesRequest } from "@api/companies";
import { Table } from "@components/table/table";

import { COLUMNS } from "./utils";

import "./companies.scss";
import Filter from "@components/filters/Filter/Filter";
import { getRoles } from "@api/roles";
import { citiesStore } from "@mobx/cities";
import { getUsersList } from "@api/users";
import { dictionariesStore } from "@mobx/dictionaries";
import FilterButton from "@components/filters/FilterButton/FilterButton";
import Btn from "@components/btn/Btn/Btn";
import IconDownload from "@components/icons/IconDownload";

const PER_PAGE = [25, 50, 100, 250, 500];

export const Companies = observer(() => {

	let [searchParams, setSearchParams] = useSearchParams();
	const [isInitLoading, setIsInitLoading] = useState(false);

	const [filterData, setFilterData] = useState([
		{
			id: 0,
			name: "filters[title]",
			type: "text",
			placeholder: "Название"
		},
		{
			id: 1,
			name: "filters[requisite][company_name]",
			type: "text",
			placeholder: "Юр название"
		},
		{
			id: 2,
			name: "filters[created_at_gt]",
			type: "date",
			placeholder: "Дата создания компании/подразделения с"
		},
		{
			id: 3,
			name: "filters[created_at_ls]",
			type: "date",
			placeholder: "Дата создания компании/подразделения по"
		},
		{
			id: 4,
			name: "filters[requisite][inn]",
			type: "text",
			placeholder: "ИНН"
		},
		{
			id: 5,
			name: "filters[roles][]",
			type: "select",
			placeholder: "Роль",
			options: []
		},
		{
			id: 6,
			name: "filters[type_id]",
			type: "select",
			placeholder: "Тип клиента",
			options: []
		},
		{
			id: 7,
			name: "filters[city_ids][]",
			type: "select",
			isMulti: true,
			placeholder: "Город",
			options: []
		},
		{
			id: 8,
			name: "filters[responsible_ids][]",
			type: "select",
			isMulti: true,
			placeholder: "Ответственный менеджер",
			options: []
		}
	]);

	const [isReadyFilter, setIsReadyFilter] = useState(false);
	const [formData, setFormData] = useState({});
	const [filterShow, setFilterShow] = useState(false);

	const [companies, setCompanies] = useState();

	useEffect(() => {

		(async () => {
			let roles = await getRoles();
			if (!dictionariesStore.dictionaries?.company_types) await dictionariesStore.request("company_types", { filters: { entity: "company" } });
			if (!citiesStore.cities.length) await citiesStore.fetch();
			let { data } = await getUsersList({ "filters[roles][]": "manager" });

			setFilterData(filterData.map(x => {
				if (x.name === "filters[roles][]") x.options = roles.map(x => ({
					value: x.name,
					label: x.description
				}));
				if (x.name === "filters[type_id]") x.options = dictionariesStore.dictionaries?.company_types;
				if (x.name === "filters[city_ids][]") x.options = citiesStore.cities.map(x => ({
					value: x.id,
					label: x.name
				}));
				if (x.name === "filters[responsible_ids][]") x.options = data.map(x => ({
					value: x.id,
					label: x.full_name
				}));
				return x;
			}));

			setIsReadyFilter(true);
		})();

	}, []);

	const requestCompanies = (params) => {
		getCompaniesRequest(params).then(setCompanies);
	};

	if (meStore.data === null) {
		return null;
	}

	if (meStore.noPermission("admin.companies.view")) {
		return <Navigate to={NO_PERMISSION_URL} />;
	}

	const onPagination = (params) => {
		setSearchParams({ page: params.page });
		requestCompanies({ ...params, ...formData });
	};

	const onSubmit = (data) => {
		setFormData(data);
		isInitLoading && setSearchParams({});
		requestCompanies({
			page: !isInitLoading ? searchParams.get("page") : 1,
			per_page: companies?.per_page || PER_PAGE[0], ...data
		});
		setFilterShow(false);
	};

	const onCloseFilter = () => {
		setFilterShow(false);
	};

	return (
		<div className="companies-wrapper">

			{isReadyFilter &&
				<Filter
					data={filterData}
					typeFilter={"filter:companies"}
					typeFilterData={"filter:companies:data"}
					indent
					mobileShow={filterShow}
					onSubmit={onSubmit}
					onClose={onCloseFilter}
				/>
			}

			<div className="d-flex justify-content-between align-items-center mb-3">
				<div className="name">Компании</div>
				{meStore.hasPermission("admin.companies.edit") && (
					<div className="d-flex gap-2">
						<Btn href={process.env.REACT_APP_API_URL + "/v1/companies/report"} color={"white"} download>
							<IconDownload />
							Экспортировать в XLS
						</Btn>
						<Link to="/create-company" className="btn btn-primary">
							Добавить
						</Link>
					</div>
				)}
			</div>

			<FilterButton indentBottom onClick={() => setFilterShow(true)} />

			{companies?.data?.length > 0 ? (
				<>
					<Table
						data={companies.data}
						columns={COLUMNS}
					/>
					<Pagination
						data={companies}
						onChange={onPagination}
						perPageList={PER_PAGE}
					/>
				</>
			) : (
				<p>Нет компаний</p>
			)}
		</div>
	);
});
