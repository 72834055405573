import clsx from "clsx";
import { ReactNode } from "react";
import styles from "./ReportButtonSimple.module.scss";

type ReportButtonProps = {
	children: ReactNode;
	eventKey: string;
	onClick(): void
};

const ReportButtonSimple = (props: ReportButtonProps) => {
	const { children, onClick } = props;

	return (
		<button
			onClick={onClick}
			className={clsx("accordion-button", styles.button)}
		>
			{children}
		</button>
	);
};

export default ReportButtonSimple;
