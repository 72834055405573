import { useEffect, useState } from 'react'

import { SuggestionsList } from './suggestions-list'

import clsx from 'clsx'
import './suggestions.scss'

export const Suggestions = (props) => {
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		if (props.value && props.items.length) {
			setVisible(true)
		}
	}, [props.value, props.items])

	return (
		<div className={clsx('suggestions', props.className)}>
			{props.children}
			<div className="suggestions__list-wrap">
				{visible && (
					<SuggestionsList
						list={props.items}
						onSelectItem={props.onSelectItem}
						onClose={() => setVisible(false)}
					/>
				)}
			</div>
		</div>
	)
}
