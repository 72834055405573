import moment from "moment/moment"
import { Link } from "react-router-dom"

export const COLUMNS = [
	{
		dataField: "first_name",
		text: "ФИО",
		sort: true,
		formatter: (cell, row) => (
			<Link to={`/user/${row.id}`}>
				{row.full_name}
			</Link>
		)
	},
	{
		dataField: "phone",
		text: "Телефон",
		sort: true
	},
	{
		dataField: "companies",
		text: "Название компании",
		headerStyle: () => {
			return { width: '200px' }
		},
		formatter: (cell, row) => {
			return (
				<div>{row.companies?.length ?
					row.companies.map(x =>
						<div key={x.id} className='mb-2'>
							<Link to={`/company/${x.id}`} title={x.display_name} className='users-company-name'>{x.display_name}</Link>
						</div>
					)
					: '-'}
				</div>
			)
		}
	},
	{
		dataField: "status",
		text: "Статус",
		sort: true,
		formatter: (cell, row) => (row.status ? row.status.name : "")
	},
	{
		dataField: "created_at",
		text: "Дата создания",
		formatter: (cell, row) => moment(row.created_at).format("DD.MM.YYYY"),
		sort: true
	}
]
