import clsx from "clsx"
import map from "lodash/map"
import uniqBy from "lodash/uniqBy"
import { useEffect, useState } from "react"
import { Accordion, Modal } from "react-bootstrap"
import { useAccordionButton } from "react-bootstrap/AccordionButton"
import { useParams } from "react-router-dom"

import {
	deleteStatusReportDefect,
	getDefects,
	getStatusReportDefects,
	postCreateSaleDefect
} from "@api/reports"
import { Button } from "@components/button/button"
import { CarDefectModal } from "@components/car-defect-modal/car-defect-modal"
import { InputUploadPhoto } from "@components/input-upload-photo/input-upload-photo"
import { Input } from "@components/input/input"
import { Select } from "@components/select/select"
import QuestionTooltip from "@components/tooltip/tooltip-question"

import { DefectShort } from "./defect-short"

import "./state-accordion.scss"

const defaultFormValues = {
	description: "",
	defect: ""
}

export const StateAccordion = ({
	accordion,
	isMobile,
	keyVechicle,
	eventKey,
	isClosed
}) => {
	const { reportId } = useParams()

	const [reportDefects, setReportDefects] = useState()
	const [statusReportsElements, setStatusReportsElements] = useState()
	const [show, setShow] = useState(false)
	const [showReport, setShowReport] = useState(false)
	const [selectedReport, setSelectedReport] = useState(null)
	const [elementsWithoutDefects, setElementsWithoutDefects] = useState([])
	const [selectedReportRoot, setSelectedReportRoot] = useState(null)
	const [selectedState, setSelectedState] = useState(null)
	const [selectedImages, setSelectedImages] = useState([])
	const [form, setForm] = useState(defaultFormValues)

	const [selectedItems, setSelectedItems] = useState({})
	const [selectedItemsParent, setSelectedItemsParent] = useState({})

	const decoratedOnClick = useAccordionButton(eventKey)

	const requestStatusReportDefects = () => {
		getStatusReportDefects(reportId, accordion.id).then(
			setStatusReportsElements
		)
	}
	const hideModal = () => {
		setShow(false)
		setSelectedImages([])
		setForm(defaultFormValues)
	}

	const handleInputChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value
		})
	}

	const createSaleDefect = (reportId, data) => {
		return postCreateSaleDefect(reportId, data).then((resp) => {
			if (!resp.message) {
				hideModal()
			}
		})
	}

	const handleAddDefect = async () => {
		const fd = new FormData()

		if (form.description) {
			fd.append("defects[defects][description]", form.description)

			if (!fd.get("defects[defects][element_id]")) {
				fd.append(
					"defects[defects][element_id]",
					selectedState.additional.id
				)
			}
		}
		fd.append("defects[defects][defect_id]", form.defect)
		fd.append("defects[defects][element_id]", selectedState.additional.id)

		if (selectedImages?.length > 1) {
			selectedImages.forEach((img) => {
				fd.append("defects[defects][photos][]", img)
			})
		}

		if (selectedImages?.length === 1) {
			fd.append("defects[defects][photos][photos]", selectedImages[0])
		}

		await createSaleDefect(reportId, fd)

		await requestStatusReportDefects()

		setChecked((prev) => map(prev, (value) => false))
		hideModal()
	}

	const handleShow = async (additional, name, isDiagnostic) => {
		await getDefects(additional.defect_group_id).then(setReportDefects)
		setSelectedState({ additional, name, isDiagnostic })
		setShow(true)
	}

	const handleShowReport = (report, rootReport, rootName) => {
		setSelectedReport(report)
		setSelectedReportRoot({ ...rootReport, rootName })
		setShowReport(true)
	}

	const handleCloseAddDefect = () => {
		hideModal()
		setSelectedImages([])
	}

	const defects = reportDefects?.map((item) =>
		item.defects.map((defect) => defect)
	)

	const handleChangeFile = (e, isDiagnostic = false) => {
		const images = []
		const fileArray = []
		images.push(e.target.files)

		for (let i = 0; i < images[0].length; i += 1) {
			fileArray.push(images[0][i])
		}
		setSelectedImages((prev) =>
			uniqBy([...prev, ...fileArray], (file) => file.name)
		)
		e.target.value = ""

		if (isDiagnostic) {
			const defect = defects?.[0].find((i) => i.name === "Не ОК")
			setForm({ ...form, defect: defect.id })
		}
	}
	const removeImage = (index) => {
		const buffData = [...selectedImages]
		buffData.splice(index, 1)
		setSelectedImages(buffData)
	}

	const removeDefect = async () => {
		await deleteStatusReportDefect(selectedReport.status_report_id, {
			defects: [selectedReport.id]
		})

		await requestStatusReportDefects()

		setChecked((prev) => map(prev, (value) => false))

		setShowReport(false)
	}

	const removeDefectById = async (id) => {
		await deleteStatusReportDefect(reportId, { defects: [id] })

		await requestStatusReportDefects()
	}

	const handleMultiplyOk = async (allOtherOk = true) => {
		if (
			Object.values(checked).filter((i) => i).length ||
			elementsWithoutDefects.length
		) {
			const json = allOtherOk
				? uniqBy(elementsWithoutDefects, (el) => el).map((id) => ({
					defect_id: 1,
					element_id: id
				}))
				: Object.keys(checked)
					.filter((k) => checked[k])
					.map((id) => ({
						defect_id: 1,
						element_id: id
					}))

			await createSaleDefect(reportId, { defects: json })

			await requestStatusReportDefects()
			setChecked((prev) => map(prev, () => false))

			if (allOtherOk) {
				setElementsWithoutDefects([])
			}
		}
	}

	const [checked, setChecked] = useState({})
	const [countChecked, setCountChecked] = useState({})
	const [countCheckBoxes, setCountCheckedCheckBox] = useState({})

	const handleCheckBox = (e, parent) => {
		const { id } = e.target
		const { id: parentId, parent_id: rootId } = parent

		if (!countChecked[parentId]) {
			countChecked[parentId] = 0
		}
		if (!countChecked[rootId]) {
			countChecked[rootId] = 0
		}

		if (checked[id]) {
			setCountChecked((prev) => ({
				...prev,
				[parentId]: prev[parentId] - 1
			}))
			setCountChecked((prev) => ({
				...prev,
				[rootId]: prev[rootId] - 1
			}))
		} else {
			setCountChecked((prev) => ({
				...prev,
				[parentId]: prev[parentId] + 1
			}))
			setCountChecked((prev) => ({
				...prev,
				[rootId]: prev[rootId] + 1
			}))
		}

		setChecked((prev) => ({ ...prev, [id]: !prev[id] }))
	}

	useEffect(() => {
		if (statusReportsElements) {
			const counter = {}
			const parentCounter = {}

			statusReportsElements.full_childs.forEach(
				({ status_report_elements, full_childs, parent_id, id }) => {

					let defectsAll = 0

					if (full_childs?.length) {
						full_childs.forEach(
							({ id, status_report_elements, parent_id }) => {


								if (!parentCounter[parent_id]) {
									parentCounter[parent_id] = 0
								}
								let defectsArray = status_report_elements.filter(x => {
									if (x.status_report_values.length > 0) {
										if (x.status_report_values[0]?.defect_id !== 1) return x
									}
								})
								setSelectedItems(prev => ({
									...prev,
									[id]: defectsArray.length
								}))

								defectsAll += defectsArray.length

								counter[id] = status_report_elements.length
								parentCounter[parent_id] +=
									status_report_elements.length

								status_report_elements.forEach((item) => {
									if (
										item?.status_report_values?.length === 0
									) {
										setElementsWithoutDefects((prev) => [
											...prev,
											item.id
										])
									}
									setChecked((prev) => ({
										...prev,
										[item.id]: false
									}))
								})
							}
						)
					} else {
						if (!parentCounter[parent_id]) {
							parentCounter[parent_id] = 0
						}

						counter[id] = status_report_elements.length
						parentCounter[parent_id] +=
							status_report_elements.length

						let defectsArray = status_report_elements.filter(x => {
							if (x.status_report_values.length > 0) {
								if (x.status_report_values[0]?.defect_id !== 1) return x
							}
						})
						setSelectedItems(prev => ({
							...prev,
							[id]: defectsArray.length
						}))

						defectsAll += defectsArray.length

						status_report_elements.forEach((item) => {
							if (item?.status_report_values?.length === 0) {
								setElementsWithoutDefects((prev) => [
									...prev,
									item.id
								])
							}
							setChecked((prev) => ({
								...prev,
								[item.id]: false
							}))
						})
					}

					setSelectedItemsParent(prev => ({
						...prev,
						[id]: defectsAll
					}))
				}
			)
			setCountCheckedCheckBox({ ...parentCounter, ...counter })
		}
	}, [statusReportsElements])

	useEffect(() => {
		requestStatusReportDefects()
	}, [reportId, accordion.id])

	const somethingChecked = !!Object.keys(checked).filter((k) => checked[k])
		.length

	return (
		<div className="state-accordion-wrapper">
			<Accordion defaultActiveKey="0">
				{!isClosed &&
					<>
						<hr />
						<div
							className={clsx(
								"d-flex align-items-center justify-content-between w-100",
								{ "flex-column-reverse gap-2": isMobile }
							)}
						>
							<div
								className={clsx("d-flex align-items-center gap-3", {
									"w-100 justify-content-between": isMobile
								})}
							>
								<button
									className="confirm-btn cursor-pointer"
									type="button"
									onClick={() => handleMultiplyOk(true)}
								>
									Все остальное ОК
								</button>
							</div>
							<button
								className={clsx("btn btn-primary", {
									"w-100": isMobile
								})}
								type="button"
								onClick={decoratedOnClick}
							>
								Сохранить
							</button>
						</div>
					</>
				}
				{statusReportsElements?.full_childs.map((item) => (
					<Accordion.Item key={item.id + 4} eventKey={item.id + 4}>
						<Accordion.Header>
							<div className="open-accordion justify-content-center align-items-center rounded-circle">
								<div>
									<img
										className="svg-icon"
										src="/img/icons/plus-sign.svg"
										alt=""
									/>
								</div>
							</div>
							<div className="accordion-head">
								{item.name}
								<div className="accordion-small-text justify-content-end">
									Отмечено {selectedItemsParent[item.id] ?? 0} из{" "}
									{countCheckBoxes[item.id]}
								</div>
							</div>
						</Accordion.Header>
						<Accordion.Body>
							<Accordion defaultActiveKey={item.id}>
								{item?.full_childs.length
									? item.full_childs.map((el) => (
										<Accordion.Item
											key={el.id}
											eventKey={el.id + 14}
										>
											<Accordion.Header>
												<div
													className="open-accordion justify-content-center align-items-center rounded-circle">
													<div>
														<img
															className="svg-icon"
															src="/img/icons/plus-sign.svg"
															alt=""
														/>
													</div>
												</div>
												<div className="accordion-head">
													{el.name}{" "}
													<div className="accordion-small-text justify-content-end">
														Отмечено{" "}
														{selectedItems[el.id] ?? 0}{" "}
														из{" "}
														{
															countCheckBoxes[
															el.id
															]
														}
													</div>
												</div>
											</Accordion.Header>
											<Accordion.Body>
												{el.status_report_elements
													.length
													? el.status_report_elements.map(
														(
															additional, index
														) => (
															<div
																key={
																	additional.id
																}
																className={clsx(
																	additional.id ===
																	29 &&
																	keyVechicle ===
																	"truck" &&
																	"hidden"
																)}
															>
																<div
																	className="row"
																	key={
																		additional.id
																	}
																>
																	<div className="col-6 w-40">
																		<div className="col-name">
																			<div className="count">
																				<div className="round">{index + 1}</div>
																			</div>
																			<div>
																				{
																					additional.name
																				}
																			</div>
																		</div>
																	</div>
																	<div className="col-5 w-20 accordion-defects-group">
																		<DefectShort
																			defects={
																				additional
																			}
																			handleShowReport={
																				handleShowReport
																			}
																			rootName={
																				el.name
																			}
																			removeDefectById={
																				removeDefectById
																			}
																			isClosed={isClosed}
																		/>
																	</div>
																	<div className="col w-40 text-end">
																		{!additional
																			?.status_report_values
																			?.length && !isClosed && (
																				<button
																					onClick={() =>
																						handleShow(
																							additional,
																							el.name
																						)
																					}
																					type="button"
																					className="btn btn-outline-primary btn-defect"
																				>
																					<span className="btn-defect-icon" />
																				</button>
																			)}
																	</div>
																</div>
																<hr />
															</div>
														)
													)
													: ""}
											</Accordion.Body>
										</Accordion.Item>
									))
									: item.status_report_elements.map(
										(additional, index) => {
											return (
												<div key={additional.id}>
													<div
														className="row"
														key={additional.id}
													>
														<div className="col-6 w-40">
															<div className="col-name">
																<div className="count">
																	<div className="round">{index + 1}</div>
																</div>
																<div>
																	{
																		additional.name
																	}
																</div>
															</div>
														</div>
														<div className="col-5 w-20 accordion-defects-group">
															<DefectShort
																defects={
																	additional
																}
																handleShowReport={
																	handleShowReport
																}
																rootName={
																	item.name
																}
																removeDefectById={
																	removeDefectById
																}
																isClosed={isClosed}
															/>
														</div>
														<div className="col w-40 text-end">
															{!additional
																?.status_report_values
																?.length && !isClosed && (
																	<button
																		onClick={() =>
																			handleShow(
																				additional,
																				item.name,
																				true
																			)
																		}
																		type="button"
																		className="btn btn-outline-primary btn-defect"
																	>
																		<span className="btn-defect-icon" />
																	</button>
																)}
														</div>
													</div>
													<hr />
												</div>
											)
										}
									)}
							</Accordion>
						</Accordion.Body>
					</Accordion.Item>
				))}
			</Accordion>
			{selectedState && show ? (
				<Modal
					show={true}
					onHide={handleCloseAddDefect}
					className="defect-modal-wrapper"
				>
					<div className="d-flex justify-content-end">
						<img
							src="/img/icons/close-icon.svg"
							alt=""
							onClick={() => handleCloseAddDefect()}
							className="cursor-pointer"
						/>
					</div>
					<div className="defect-modal-wrapper__header">
						<div className="title">
							{selectedState.isDiagnostic
								? "Добавление диагностики"
								: "Добавление повреждения"}
						</div>
						<div className="defect-modal-wrapper__header-text">
							{selectedState.name} {"> "}{" "}
							{selectedState.additional.name}
						</div>
					</div>
					<div className="defect-modal-wrapper__form">
						<div className="defect-modal-wrapper__form-field d-flex align-items-center w-100">
							<div className="defect-modal-wrapper__form-field-name name">
								Фотография
							</div>
							<div className="defect-modal-wrapper__form-field-value">
								{selectedImages?.map((img, i) => (
									<div
										className="image-wrapper d-flex align-items-center justify-content-between mb-2"
										key={img.name}
									>
										<img
											src="/img/icons/img-icon.svg"
											alt=""
										/>
										<div>{img.name}</div>
										<img
											onClick={() => removeImage(i)}
											src="/img/icons/remove-icon.svg"
											alt=""
										/>
									</div>
								))}
							</div>
						</div>
						<InputUploadPhoto
							name="img"
							handleChangeFile={handleChangeFile}
							selectedState={selectedState}
						/>
						{!selectedState.isDiagnostic && (
							<div className="defect-modal-wrapper__form-field">
								<div className="defect-modal-wrapper__form-field-name name">
									Повреждение
								</div>
								<div className="defect-modal-wrapper__form-field-value">
									<Select
										options={defects?.[0]?.map((item) => ({
											value: item.id,
											label: item.name
										}))}
										valueId={form.defect}
										size="m"
										placeholder="Повреждение"
										onChange={({ value }) =>
											handleInputChange({
												target: {
													name: "defect",
													value
												}
											})
										}
									/>
									{!isMobile && (
										<QuestionTooltip text="Укажите тип повреждения" />
									)}
								</div>
							</div>
						)}
						{selectedState.isDiagnostic && (
							<>
								<div className="defect-modal-wrapper__form-field">
									<div className="defect-modal-wrapper__form-field-name name">
										Описание
									</div>
									<div className="defect-modal-wrapper__form-field-value">
										<Input
											name="description"
											placeholder="Описание"
											value={form.description}
											onChange={handleInputChange}
										/>
										{!isMobile && (
											<QuestionTooltip text="Укажите описание" />
										)}
									</div>
								</div>
								<div className="defect-modal-wrapper__form-field">
									<div className="defect-modal-wrapper__form-field-name name">
										Состояние
									</div>
									<div className="defect-modal-wrapper__form-field-value">
										<Select
											options={defects?.[0]?.map(
												(item) => ({
													value: item.id,
													label: item.name
												})
											)}
											valueId={form.defect}
											size="m"
											placeholder="Состояние"
											onChange={({ value }) =>
												handleInputChange({
													target: {
														name: "defect",
														value
													}
												})
											}
										/>
										{!isMobile && (
											<QuestionTooltip text="Укажите состояние" />
										)}
									</div>
								</div>
							</>
						)}
					</div>
					<div className="defect-modal-wrapper__buttons">
						<Button onClick={handleAddDefect} preset="primary">
							{selectedState.isDiagnostic
								? "Добавить диагностику"
								: "Добавить повреждение"}
						</Button>
						<Button onClick={handleCloseAddDefect} preset="ghost">
							Закрыть
						</Button>
					</div>
				</Modal>
			) : null}

			{selectedReport && showReport ? (
				<CarDefectModal
					handleClose={() => setShowReport(false)}
					selectedReport={selectedReport}
					selectedReportRoot={selectedReportRoot}
					removeDefect={removeDefect}
					isReport={true}
					isClosed={isClosed}
				/>
			) : null}
		</div>
	)
}
