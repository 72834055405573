import React, { useEffect, useState } from 'react';
import { uuid } from '@utils/uuid';
import { Roles } from '../../../types/Roles';
import { PermissionsGroup } from '../../../types/Permissions';
import Container from '../../Containers/Container/Container';
import styles from '../PermissionList/PermissionList.module.scss';
import Badge from '../../utils/Barge/Badge';

type PermissionListUserProps = {
	items: []
}

const PermissionListUser = ({ items }: PermissionListUserProps) => {

	const [group, setGroup] = useState<PermissionsGroup[]>([])
	const setSortGroup = (data: Roles[]) => {

		const uniqueKeys = [...new Set(data.map(x => x.group))];

		return uniqueKeys.map(key => ({
			id: uuid(),
			name: key,
			data: data.filter(x => x.group === key),
		}));
	}

	useEffect(() => {
		setGroup(setSortGroup(items))
	}, [items]);

	return (
		<Container>
			{group?.map(x =>
				<div key={x.id} className={styles.item}>
					<div className={styles.title}>
						{x.name}
					</div>
					<div className={styles.section}>
						{x.data.map(x =>
							<Badge key={x.id} text={x.description} />
						)}
					</div>
				</div>
			)}
		</Container>
	);
};

export default PermissionListUser;
