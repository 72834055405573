import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { getSaleModelsRequest } from '@api/dictionaries';
import { Input } from '@components/input/input';
import { citiesStore } from '@mobx/cities';
import { dictionariesStore } from '@mobx/dictionaries';
import { Select } from '@components/select/select';
import { carBrandsStore } from '@mobx/car-brands';
import { Button } from '@components/button/button';
import { CurrentTradesFiltersModal } from '@pages/current-trades/current-trades-filters/current-trades-filters-modal';
import { getAuctionsTypesRequest } from '@api/auctions';

const carType = 'car';

export const CurrentTradesFilters = observer(({ fieldsToHide, fieldsToAdd, onChange, isMobile, localStorageFilters }) => {
  const { car_locations } = dictionariesStore.dictionaries;
  const { cities } = citiesStore;
  const { brands } = carBrandsStore;
  const isFiltersDataRequested = useRef();
  const filtersCount = localStorageFilters && Object.keys(localStorageFilters).length;

  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('auctionsFilters')) || {});
  const [auctionTypes, setAuctionTypes] = useState();
  const [isShowCurrentTradesFiltersModal, setIsShowCurrentTradesFiltersModal] = useState(false);
  const [models, setModels] = useState([]);

  const applyFilters = () => onChange(filters, false);
  const clearAllFilters = () => {
    setFilters(null);
    onChange(null, true);
  };

  useEffect(() => {
    if (localStorageFilters) {
      setFilters((prev) => ({ ...prev, ...localStorageFilters }));

      if (localStorageFilters?.brand_id) {
        getSaleModelsRequest(localStorageFilters.brand_id, 'car').then(setModels);
      }
    }
  }, []);

  useEffect(() => {
    if ((showFilters || isShowCurrentTradesFiltersModal) && !isFiltersDataRequested.current) {
      isFiltersDataRequested.current = true;

      if (!brands.length) carBrandsStore.fetch();
      if (!citiesStore.cities.length) citiesStore.fetch();
      if (!car_locations?.length) dictionariesStore.request('car_locations');
      getAuctionsTypesRequest().then((resp) => {
        setAuctionTypes(() => resp?.filter((item) => item.key !== 'sequential_increase'));
      });
    }
  }, [showFilters, isShowCurrentTradesFiltersModal]);

  const handleFilterChange = (e) => {
    const newFilters = {
      ...filters,
      [e.target.name]: e.target.value,
    };

    setFilters(newFilters);
  };

  return (
    <>
      {isMobile ? (
        <button
          type="button"
          onClick={() => setIsShowCurrentTradesFiltersModal(true)}
          className="btn-filters"
        >
          <img src="/img/icons/filter-icon.svg" alt="" />
          Фильтры
          {filtersCount > 0 && <div className="filters-count">{filtersCount}</div>}
        </button>
      ) : (
        <div className="filters-wrapper">
          <div className="d-flex align-items-center position-relative">
            <div onClick={() => setShowFilters(!showFilters)} className="d-flex align-items-center">
              <div className="title">
                <span>Фильтры</span>
              </div>
              {filtersCount > 0 && <div className="filters-count">{filtersCount}</div>}
              <img
                className={clsx('arrow-down cursor-pointer', { 'rotate-180': !showFilters })}
                src="/img/tabs/hide.svg"
                alt=""
              />
            </div>
          </div>
          <div className={!showFilters ? 'd-none' : 'filter'}>
            <div className="d-flex align-items-center flex-wrap gap-10px">
              {fieldsToAdd?.includes('starts_at_gt') && (
                <Input
                  onChange={handleFilterChange}
                  name="starts_at_gt"
                  size="m"
                  value={filters?.starts_at_gt}
                  type="date"
                  placeholder="Дата начала от:"
                />
              )}
              {!fieldsToHide?.includes('start_at') && (
                <Input
                  onChange={handleFilterChange}
                  name="start_at"
                  size="m"
                  value={filters?.start_at}
                  type="date"
                  placeholder="Дата начала от:"
                />
              )}
              {!fieldsToHide?.includes('auction_type') && (
                <Select
                  options={auctionTypes?.map((auctionType) => ({ value: auctionType.key, label: auctionType.title }))}
                  valueId={filters?.auction_type}
                  size="m"
                  placeholder="Тип аукциона"
                  onChange={({ value }) => handleFilterChange({ target: { name: 'auction_type', value } })}
                />
              )}
              {!fieldsToHide?.includes('vin') && (
                <Input
                  size="m"
                  onChange={handleFilterChange}
                  value={filters?.vin || ''}
                  name="vin"
                  placeholder="VIN"
                />
              )}
              {!fieldsToHide?.includes('brand') && (
                <Select
                  options={brands}
                  valueId={filters?.brand_id}
                  size="m"
                  placeholder="Марка"
                  onChange={({ value }) => {
                    handleFilterChange({ target: { name: 'brand_id', value } });
                    getSaleModelsRequest(value, carType).then(setModels);
                  }}
                />
              )}
              {!fieldsToHide?.includes('model') && (
                <Select
                  options={models?.map((model) => ({ value: model.id, label: model.name }))}
                  valueId={filters?.model_id}
                  size="m"
                  placeholder="Модель"
                  onChange={({ value }) => {
                    handleFilterChange({ target: { name: 'model_id', value } });
                  }}
                />
              )}
              {!fieldsToHide?.includes('year') && (
                <Input
                  size="m"
                  onChange={handleFilterChange}
                  name="year_gt"
                  value={filters?.year_gt ?? ''}
                  placeholder="Год выпуска от"
                />
              )}
              {!fieldsToHide?.includes('year') && (
                <Input
                  size="m"
                  onChange={handleFilterChange}
                  name="year_ls"
                  value={filters?.year_ls ?? ''}
                  placeholder="Год выпуска до"
                />
              )}
              {!fieldsToHide?.includes('mileage') && (
                <Input
                  size="m"
                  onChange={handleFilterChange}
                  name="mileage_gt"
                  value={filters?.mileage_gt ?? ''}
                  placeholder="Пробег от"
                />
              )}
              {!fieldsToHide?.includes('mileage') && (
                <Input
                  size="m"
                  onChange={handleFilterChange}
                  name="mileage_ls"
                  value={filters?.mileage_ls ?? ''}
                  placeholder="Пробег до"
                />
              )}
              {!fieldsToHide?.includes('city_id') && (
                <Select
                  options={cities?.map((city) => ({ value: city.id, label: city.name }))}
                  valueId={filters?.city_id}
                  size="m"
                  placeholder="Город"
                  onChange={({ value }) => handleFilterChange({ target: { name: 'city_id', value } })}
                />
              )}
              {!fieldsToHide?.includes('location_id') && (
                <Select
                  options={car_locations}
                  valueId={filters?.location_id}
                  size="m"
                  placeholder="Местонахождение"
                  onChange={({ value }) => handleFilterChange({ target: { name: 'location_id', value } })}
                />
              )}
            </div>
            <div className="mt-20px d-flex gap-10px">
              <Button onClick={applyFilters} preset="primary">
                Применить фильтры
              </Button>
              <Button onClick={clearAllFilters} preset="ghost">
                Отменить все фильтры
              </Button>
            </div>
          </div>
        </div>
      )}
      {!showFilters && !isMobile ? <hr className="mt-4" /> : ''}
      {isShowCurrentTradesFiltersModal && (
        <CurrentTradesFiltersModal
          handleClose={() => setIsShowCurrentTradesFiltersModal(false)}
          applyFilters={applyFilters}
          clearAllFilters={clearAllFilters}
          handleFilterChange={handleFilterChange}
          filters={filters}
          fieldsToAdd={fieldsToAdd}
          fieldsToHide={fieldsToHide}
          brands={brands}
          auctionTypes={auctionTypes}
          cities={cities}
          car_locations={car_locations}
        />
      )}
    </>
  );
});
