import { DELETE, GET, POST } from "./request"

export const getDocumentsUser = (type: string, id: number) => {
	return GET(`${type}/${id}/documents`)
}

export const postDocumentsUser = (type: string, id: number, params: { description: string, files: any[] }[]) => {
	return POST(`${type}/${id}/documents`, { documents: params }, undefined, {
		headers: { "content-type": "multipart/form-data" }
	})
}

export const deleteDocumentsUser = (type: string, id: number, document_ids: number[]) => {
	return DELETE(`${type}/${id}/documents`, { document_ids: document_ids })
}