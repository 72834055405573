import React, { ReactNode } from 'react';
import styles from './PermissionsSection.module.scss'
import IconQuestion from '../../icons/IconQuestion';
import classNames from 'classnames';

type PermissionsProps = {
	name: string
	children: ReactNode
	info?: string
	hasInput?: boolean
}

const PermissionsSection = ({ name, children, info, hasInput }: PermissionsProps) => {

	return (
		<div className={classNames(styles.Permissions, hasInput && styles.hasInput)}>
			<div className={styles.col}>
				<div className={styles.title}>{name}</div>
			</div>
			<div className={styles.col}>
				{children}
			</div>
			{info &&
				<div className={styles.col}>
					<IconQuestion className={'cursor-pointer'} />
				</div>
			}
		</div>
	);
};

export default PermissionsSection;

