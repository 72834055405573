import React, { useEffect, useState } from 'react';

import { getCompaniesUsersRequest } from '@api/companies';
import { Select } from '@components/select/select';
import { Input } from '@components/input/input';
import { getUserById } from '@api/users';
import { postSetNewWinning } from '@api/auctions';
import { formatToNumberWithoutSpaces, formatValueWithSpaces } from '@utils/format';

export const EditWinningBet = ({ step, stepsNumber, handleClose, selectedWinner, lot }) => {
  const isCompanyWinner = selectedWinner?.winnerType === 'company';
  const isUserWinner = selectedWinner?.winnerType === 'user';
  const [listOfWinners, setListOfWinners] = useState();
  const [form, setForm] = useState({
    department_id: isCompanyWinner ? selectedWinner.id : undefined,
    user_id: isUserWinner ? selectedWinner.id : undefined,
    sum: formatValueWithSpaces(lot?.lastBet?.sum),
  });

  const getListOfWinners = () => {
    const request = isCompanyWinner ? getCompaniesUsersRequest(selectedWinner?.id) : getUserById(selectedWinner?.id);

    request.then((resp) => {
      setListOfWinners(isCompanyWinner ? resp.data : resp.companies);
    });
  };

  useEffect(() => {
    getListOfWinners();
  }, []);

  const onSubmit = () => {
    postSetNewWinning(lot?.auction?.id, lot?.id, { ...form, sum: formatToNumberWithoutSpaces(form.sum) }).then((resp) => {
      if (!resp.message) {
        handleClose(true);
      }
    });
  };

  return (
    <div className="mt-25px">
      <div className="edit-trades-result-wrapper__header-text-step">Шаг {step} из {stepsNumber}</div>
      <div className="edit-trades-result-wrapper__header-text">Выберите победителя</div>
      <div className="edit-winning-bet-wrapper">
        <div className="edit-winning-bet-wrapper__field">
          <div className="edit-winning-bet-wrapper__field-name">
            {isCompanyWinner ? 'Компания' : 'Пользователь'}
          </div>
          <div className="edit-winning-bet-wrapper__field-value">
            {isCompanyWinner ? selectedWinner?.display_name : selectedWinner.full_name}
          </div>
        </div>
        <div className="edit-winning-bet-wrapper__field">
          <div className="edit-winning-bet-wrapper__field-name">{isCompanyWinner ? 'Пользователь' : 'Компания'}</div>
          <div>
            <Select
              options={listOfWinners?.map((item) => ({ value: item.id, label: isCompanyWinner ? item.full_name : item.display_name }))}
              valueId={isCompanyWinner ? form?.user_id : form?.department_id}
              size="m"
              onChange={({ value }) => {
                setForm((prev) => ({ ...prev, [isCompanyWinner ? 'user_id' : 'department_id']: value }));
              }}
            />
          </div>
        </div>
        <div className="edit-winning-bet-wrapper__field">
          <div className="edit-winning-bet-wrapper__field-name">Значение выигрышной ставки, ₽</div>
          <div className="d-flex align-items-center w-70">
            <Input
              name="sum"
              autoComplete="off"
              onChange={(e) => setForm((prev) => ({ ...prev, sum: formatValueWithSpaces(e.target.value) }))}
              value={form.sum}
            />
          </div>
        </div>
      </div>
      <div className="edit-winning-bet-btns">
        <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>Отмена</button>
        <button type="button" className="btn btn-primary" onClick={onSubmit}>Сохранить изменения</button>
      </div>
    </div>
  );
};
