import React from 'react';
import { Modal } from 'react-bootstrap';

import { IconTick } from '@components/icons/tick';

import './car-info-components.scss';

export const MobileTabsModal = ({ options, onChange, handleClose, selectedTab, setSelectedTab }) => {
  return (
    <Modal
      show={true}
      onHide={handleClose}
      className="d-flex align-items-center justify-content-center mobile-tabs-modal"
    >
      <div className="mobile-tabs-modal-wrapper">
        <div className="mobile-tabs-modal-wrapper__header">Данные об автомобиле</div>
        {options.map((option) => (
          <div
            className="mobile-tabs-modal-wrapper__item"
            key={`${option.key}-${option.label}`}
            onClick={() => {
              handleClose();
              onChange(option.key);
              setSelectedTab(option.label);
            }}
          >
            {option.label}
            {option.label === selectedTab && <IconTick />}
          </div>
        ))}
      </div>
    </Modal>
  );
};
