import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { deleteAutoBet, getAutoBet, postAutoBet } from "@api/auctions";
import { Button } from "@components/button/button";
import { Input } from "@components/input/input";
import { AUTO_BET_SUCCESS } from "@components/notifications/utils";
import { notificationsStore } from "@mobx/notifications";
import { getCurrencySymbol } from "@utils/common";
import {
	formatToNumberWithoutSpaces,
	formatValueWithSpaces,
} from "@utils/format";

import "./auction-item.scss";

export const AutoBetModal = (props) => {
	const { handleClose, auctionId, lot, defaultValue, setShowAutoBetPoint } =
		props;
	const [error, setError] = useState();
	const [timer, setTimer] = useState();
	const [autoBet, setAutoBet] = useState("");

	const requestAutoBet = () => {
		getAutoBet(auctionId, lot.id).then((resp) => {
			setAutoBet(
				resp.max_sum
					? formatValueWithSpaces(resp.max_sum)
					: defaultValue
			);
		});
	};

	useEffect(() => {
		requestAutoBet();

		return () => {
			clearTimeout(timer);
		};
	}, []);

	const handleAutoBet = () => {
		const sentData = {
			step: lot.minimal_step,
			max_sum: formatToNumberWithoutSpaces(autoBet),
		};

		postAutoBet(auctionId, lot.id, sentData).then((res) => {
			let newTimer;

			if (!res.message) {
				setShowAutoBetPoint(true);
				handleClose();
				notificationsStore.addNotification({
					type: AUTO_BET_SUCCESS,
					auctionType: "parallel_increase",
					autoClose: 5000,
				});
			} else {
				setError(res.message);

				newTimer = setTimeout(() => {
					setError(null);
				}, 5000);
			}

			setTimer(newTimer);
		});
	};

	const onDeleteAutoBet = () => {
		deleteAutoBet(auctionId, lot.id).then((resp) => {
			setShowAutoBetPoint(false);
			setAutoBet("");
		});
	};

	return (
		<div>
			<Modal
				show={true}
				onHide={handleClose}
				className="auto-bet-modal-wrapper"
			>
				{error ? <div className="alert alert-danger">{error}</div> : ""}
				<div className="auto-bet-modal-wrapper__car-name">
					{lot?.sales[0]?.display_name} {lot?.sales[0]?.car?.vin}
				</div>
				<div className="head-text">
					{autoBet
						? "Редактирование автоставки"
						: "Задайте автоставку"}
				</div>
				<div className="auto-bet-modal-wrapper__text">
					Значение автоставки, {getCurrencySymbol()}
				</div>
				<Input
					name="sum"
					value={autoBet}
					color="white"
					onChange={(e) =>
						setAutoBet(formatValueWithSpaces(e.target.value))
					}
					className="w-100"
				/>
				<div className="auto-bet-modal-wrapper__btns">
					<Button
						onClick={handleAutoBet}
						preset="primary"
						className="d-flex align-items-center justify-content-center gap-5px"
					>
						<img src="/img/icons/tick-icon.svg" />
						Сохранить
					</Button>
					{autoBet && (
						<button
							type="submit"
							onClick={onDeleteAutoBet}
							className="btn btn-delete"
						>
							Удалить автоставку
						</button>
					)}
					<Button preset="secondary" onClick={handleClose}>
						Закрыть
					</Button>
				</div>
			</Modal>
		</div>
	);
};
