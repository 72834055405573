import React from 'react';
import { Modal } from 'react-bootstrap';

import { getCurrencySymbol } from '@utils/common';

import './car-checks.scss';

export const TermsOfCooperationModal = ({ handleClose, lot }) => {
  return (
    <Modal
      show={true}
      onHide={handleClose}
      className="terms-of-cooperation-modal-wrapper d-flex align-items-center justify-content-center"
    >
      <div className="head-text text-align-center">Условия и сборы</div>
      <div>
        Комиссия торговой площадки {lot?.commission}%, но не менее {lot?.minimal_commission} {getCurrencySymbol()} от стоимости выигранного лота.
      </div>
      <div>
        В случае необоснованного отказа от покупки выигранного лота, взимается штраф в размере {lot?.surcharge} {getCurrencySymbol()} и блокируется доступ к текущим торгам.
      </div>
    </Modal>
  );
};
