import { getOption } from "@utils/common";

export const getReportDefaultValues = (x) => {
	return {
		mileage: x.mileage,
		reg_number: x.reg_number,
		location_id: getOption(x.location),
		city_id: getOption(x.city),
		area_id: getOption(x.area),
		inspection_type_id: getOption(x.inspection_type)
	};
};
