import React from 'react';
import styles from './Info.module.scss'

type InfoProps = {
	title: string
	text: string
}

const Info = ({ title, text }: InfoProps ) => {
	return (
		<div className={styles.noRoles}>
			<div className={styles.title}>{title}</div>
			<div className={styles.text}>{text}</div>
		</div>
	);
};

export default Info;
