import React from "react";
import styles from "./DocumentsReport.module.scss";
import clsx from "clsx";
import { Documents } from "../../../car-form/car-form-components/documents";


type DocumentsReportProps = {
	className?: string
	id: number
	isClosed: boolean
	onClose(): void
}

const DocumentsReport = (props: DocumentsReportProps) => {

	return (
		<div className={clsx(styles.Documents)}>
			<Documents {...props} />
		</div>
	);
};

export default DocumentsReport;
