import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { deleteUserRequest, filteredUsers, getUsersList, setAccessUsersRequest } from "@api/users";
import { Input } from "@components/input/input";
import { Pagination } from "@components/pagination/pagination";
import { citiesStore } from "@mobx/cities";
import { Table } from "@components/table/table";
import { Select } from "@components/select/select";
import { Button } from "@components/button/button";

import { COLUMNS } from "./utils";

import "./access-user-modal.scss";

const DEFAULT_FIlTERS = {
	"filters[first_name]": "",
	"filters[middle_name]": "",
	"filters[last_name]": "",
	"filters[city_id][]": ""
};

const PER_PAGE = [25, 50, 100, 250, 500];

export const AccessUsers = observer(({ auctionId, onHide, onChange, selected, isAccess }) => {
	const { cities } = citiesStore;

	const [users, setUsers] = useState();
	const [error, setError] = useState();
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [usersToRemove, setUsersToRemove] = useState([]);
	const [selectedRows, setSelectedRows] = useState(selected);
	const [filters, setFilters] = useState(DEFAULT_FIlTERS);

	const selectRow = {
		mode: "checkbox",
		clickToSelect: true,
		classes: "selection-row",
		selected: selectedRows,
		onSelectAll: (isSelect, rows) => {
			if (isSelect) {
				setSelectedUsers(() => users.data);
				setSelectedRows(() => users.data.map((item) => item.id));
			} else {
				setUsersToRemove(() => [...selectedRows]);
				setSelectedUsers([]);
				setSelectedRows([]);
			}
		},
		onSelect: (row, isSelect, rowIndex) => {
			if (isSelect) {
				setSelectedUsers((prev) => [...prev, row]);
				setSelectedRows((prev) => [...prev, row.id]);
			} else {
				setSelectedUsers((prev) => prev.filter((item) => item.id !== row.id));
				setSelectedRows((prev) => prev.filter((item) => item !== row.id));
				setUsersToRemove((prev) => [...prev, row.id]);
			}
		}
	};

	const requestUsers = (params) => {
		getUsersList(params).then(setUsers);
	};

	useEffect(() => {
		requestUsers({ page: 1, per_page: PER_PAGE[0] });

		if (!citiesStore.cities.length) {
			citiesStore.fetch();
		}
	}, []);

	const saveUsers = async () => {
		if (selectedUsers.length) {
			const accesses = selectedUsers.map((item) => {
				return ({
					owner_type: "user",
					owner_id: item.id,
					has_permission: isAccess
				});
			});

			try {
				const saveUsersRequest = await setAccessUsersRequest({ accesses }, auctionId);

				if (saveUsersRequest.message) {
					setError(saveUsersRequest.errors || saveUsersRequest.message);

					setTimeout(() => setError(null), 5000);
				} else {
					onChange("Пользователи");
				}
			} catch (e) {
				setError("Возникла ошибка");

				setTimeout(() => setError(null), 5000);
			}
		}

		const dataForRemove = [];

		usersToRemove.forEach((userId) => {
			if (selected.includes(userId)) {
				dataForRemove.push({ owner_type: "user", owner_id: userId });
			}
		});

		if (dataForRemove.length) {
			try {
				const deleteUser = await deleteUserRequest({ accesses: dataForRemove }, auctionId);

				if (deleteUser.message) {
					setError(deleteUser.errors || deleteUser.message);

					setTimeout(() => setError(null), 5000);
				} else {
					onChange("Пользователи");
				}
			} catch (e) {
				setError("Возникла ошибка");

				setTimeout(() => setError(null), 5000);
			}
		}

		onHide();
	};

	const getFilteredUsersList = () => {
		let data = {};
		Object.entries(filters).forEach(([key, value]) => {
			if (value) data[key] = value;
		});
		filteredUsers(data, { page: 1, per_page: users?.per_page }).then(setUsers);
	};

	const clearAllFilters = () => {
		setFilters(DEFAULT_FIlTERS);
		requestUsers({ page: 1, per_page: users?.per_page });
	};

	const handleInputChange = (e) => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value
		});
	};

	return (
		<>
			<div className="user-access__filters">
				<div>
					<div className="d-flex align-items-center flex-wrap gap-15px">
						<Input
							size="s"
							placeholder="Фамилия"
							name="filters[last_name]"
							value={filters["filters[last_name]"]}
							onChange={handleInputChange}
						/>
						<Input
							size="s"
							placeholder="Имя"
							name="filters[first_name]"
							value={filters["filters[first_name]"]}
							onChange={handleInputChange}
						/>
						<Input
							size="s"
							placeholder="Отчество"
							name="filters[middle_name]"
							value={filters["filters[middle_name]"]}
							onChange={handleInputChange}
						/>
						<Select
							options={cities?.map((city) => ({ value: city.id, label: city.name }))}
							valueId={filters["filters[city_ids][]"]}
							size="s"
							name="filters[city_ids][]"
							placeholder="Город"
							onChange={({ value }) => handleInputChange({
								target: {
									name: "filters[city_ids][]",
									value
								}
							})}
						/>
					</div>
					<div className="d-flex mt-20px gap-15px">
						<Button onClick={getFilteredUsersList} size="s">
							Применить фильтры
						</Button>
						<Button onClick={clearAllFilters} className="d-flex align-items-center gap-5px"
								preset="secondary" size="s">
							<img src="/img/icons/filters-off-icon.svg" />
							Очистить
						</Button>
					</div>
				</div>
			</div>

			<div className="d-flex align-items-center justify-content-end header mb-3">
				<button onClick={saveUsers} className="btn btn-primary" type="button">
					Сохранить
				</button>
			</div>
			{error && (
				<div className="mt-2 mb-2 alert alert-danger">{JSON.stringify(error, null, " ")}</div>
			)}
			{users?.data?.length > 0 ? (
				<Table
					data={users.data}
					columns={COLUMNS}
					selectRow={selectRow}
				/>
			) : (
				<p>Нет данных</p>
			)}
			<Pagination
				data={users}
				onChange={(params) => filteredUsers(filters, params).then(setUsers)}
				perPageList={PER_PAGE}
			/>
		</>
	);
});
