import { useEffect, useRef, useState } from "react";
import ReactSelect, { SelectInstance } from "react-select";
import "./select.scss";

type SelectItemProps = {
	label: string | number;
	value: string | number;
};

type SelectProps = {
	reset?: boolean;
	name?: string;
	placeholder?: string;
	isMulty?: boolean;
	resetValue?: boolean;
	options?: any[];
	disabled?: boolean;
	сlearable?: boolean;
	defaultValue: SelectItemProps[];
	required?: boolean;
	onChange?: (x: SelectItemProps[]) => void;
};

const Select = ({
	reset,
	name,
	placeholder,
	isMulty,
	disabled,
	сlearable,
	options,
	resetValue,
	defaultValue,
	required,
	onChange,
	...rest
}: SelectProps) => {
	const selectRef = useRef<SelectInstance>(null);
	const [disabledFirstOnChange, setDisabledFirstOnChange] =
		useState<boolean>(false);

	const [value, setValue] = useState<SelectItemProps[]>([]);

	const clearValue = () => {
		if (selectRef.current) selectRef.current.clearValue();
	};

	useEffect(() => {
		setDisabledFirstOnChange(true);
		setValue(defaultValue);
	}, [defaultValue]);

	useEffect(() => {
		clearValue();
	}, [reset, resetValue]);

	const onChangeHandler = (e: any) => {
		if (!disabledFirstOnChange) return false;
		if (onChange) onChange(e || []);
		setValue(e);
	};

	return (
		<ReactSelect
			ref={selectRef}
			options={options}
			name={name}
			placeholder={placeholder}
			isMulti={isMulty}
			classNamePrefix={"select"}
			onChange={onChangeHandler}
			defaultValue={value}
			value={value}
			isDisabled={disabled}
			isClearable={сlearable}
			required={required}
			{...rest}
		/>
	);
};

export default Select;
