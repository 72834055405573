import clsx from 'clsx';
import React from 'react';

export const Step = (props) => {
  const { step, index, isActive, onStepClick = () => {} } = props;
  const { description } = step;

  const onClick = () => {
    onStepClick({ ...step, index });
  };

  return (
    <div className={clsx('step-item', { active: isActive })} onClick={onClick}>
      <div className="step-item__value">Шаг {index + 1}</div>
      <div className="step-item-indicator">
        <div className="step-item-indicator__cirle">
          <div className="step-item-indicator__cirle-inside" />
        </div>
        <div className="step-item-indicator__line" />
      </div>
      <div className="step-item-description">{description}</div>
    </div>
  );
};
