import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { getRoles } from '@api/roles'
import { changeUserGroupRequest, createUserGroupRequest, getUserGroupRequest } from '@api/user-groups'
import { Input } from '@components/input/input'
import { Loader } from '@components/loader/loader'
import { meStore } from '@mobx/me'
import { NO_PERMISSION_URL } from '@utils/common'
import Select from "../../components/Forms/Select/Select"
import './user-group-form.scss'
import { filterRoles } from './utils'

export const UserGroupForm = observer(({ isEdit }) => {
	const { id } = useParams()
	const navigate = useNavigate()

	const [loading, setLoading] = useState(Boolean(isEdit))
	const [error, setError] = useState()
	const [form, setForm] = useState({
		name: '',
	})
	const [roles, setRoles] = useState([])
	const [roleId, setRoleId] = useState(0)
	const [assignedRole, setAssignedRole] = useState('')

	useEffect(() => {
		if (isEdit) {
			getUserGroupRequest(id).then((resp) => {
				setLoading(false)
				setForm({ name: resp.name })
				setRoleId(resp.role_id)
				setAssignedRole(resp.role?.description)
			})
		} else {
			getRoles().then(data => setRoles(data.map(filterRoles)))
		}
	}, [])

	if (meStore.noPermission('groups.edit')) {
		return <Navigate to={NO_PERMISSION_URL} />
	}

	if (meStore.data === null || loading) {
		return <Loader />
	}

	const handleOnChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		})
	}

	const onChangeSelectFieldHandler = (data) => {
		setRoleId(data.value)
	}

	const saveGroup = () => {
		const data = { name: form.name, role_id: roleId }
		const request = isEdit ? changeUserGroupRequest(id, data) : createUserGroupRequest(data)

		request.then((resp) => {
			if (resp.message) {
				setError(resp.errors || resp.message)

				setTimeout(() => setError(''), 5000)
			} else {
				navigate(`/user-group/${resp.id}`)
			}
		})
	}

	return (
		<div className="add-user-group-wrapper">
			<div className="title">{isEdit ? 'Изменение' : 'Добавление'} группы пользователей</div>
			<div className="info-wrapper mt-4">
				<div className="d-flex gap-4 align-items-center justify-content-between filter">
					<div className="name">Название группы</div>
					<div className='w-50'>
						<Input
							name="name"
							onChange={handleOnChange}
							value={form.name}
							placeholder="Название"
						/>
					</div>
					{!isEdit &&
						<div className="w-50">
							<Select
								options={roles}
								placeholder={'Выберите роль'}
								onChange={onChangeSelectFieldHandler}
							/>
						</div>
					}
				</div>
				{isEdit &&
					<div className="d-flex gap-4 align-items-center justify-content-between filter mt-4">
						<div className="name">Роль</div>
						<div className="w-50">
							<div>{assignedRole}</div>
						</div>
					</div>
				}
			</div>
			<button
				disabled={!form.name}
				onClick={saveGroup}
				className="btn btn-primary mt-3 mb-2 w-100"
				type="button"
			>
				{isEdit ? 'Сохранить группу' : 'Создать группу'}
			</button>
			{error && <div className="mt-2 mb-2 alert alert-danger">{JSON.stringify(error, null, ' ')}</div>}
		</div>
	)
})
