import { meStore } from '@mobx/me';
import { postLogout } from '@api/auth';

export const logout = () => {
  postLogout();
  meStore.setData(null);
};

export const checkAuth = ({ onSuccess, onError }) => {
  if (meStore.data === null) {
    meStore.fetch().then((resp) => {
      if (resp.message === 'Unauthenticated.') {
        logout();
        onError?.();

        return 'error';
      }

      onSuccess?.(resp);
      return 'success';
    });
  } else {
    onSuccess?.(meStore.data);
    return 'success';
  }
};
