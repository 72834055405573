import React from 'react';

import { formatValueWithSpaces } from '@utils/format';

import { ExchangeLotSalesTable } from './exchange-lot-sales-table';

export const ExchangeLot = ({ lot, onEdit }) => {
  return (
    <div className="lots-wrapper mt-2">
      <div className="lot__buttons-top">
        <button
          onClick={() => onEdit(lot.id)}
          className="lot__button-top"
          type="button"
        >
          Изменить
        </button>
      </div>
      <div className="d-flex gap-50px">
        <div className="">
          <div className="name">№</div>
          <div className="">
            {lot.order}
          </div>
        </div>
        <div className="text-align-center">
          <div className="name">Стоимость</div>
          <div className="">{formatValueWithSpaces(lot.price)}</div>
        </div>
      </div>
      <div className="line-hr" />
      <ExchangeLotSalesTable sales={lot.sale ? [lot.sale] : []} />
    </div>
  );
};
