import { DELETE, GET, POST, PUT, Send, request } from './request';
import { types } from 'sass';
import Error = types.Error;

type SetRolesProps = {
	name: string
	permissions: string | number[]
}

type SetRolesAssignItem = {
	user_id: any
	role_id: any
}

type SetRolesCompanyAssignItem = {
	company_id: any
	role_id: any
}

type SetRolesAssign = {
	users?: SetRolesAssignItem[]
	companies?: SetRolesCompanyAssignItem[]
}

export const getRoles = <T>() => {
	return GET<T>('roles')
}

export const setRoles = <T>(data: SetRolesProps) => {
	return POST<T>('roles', data).then(({ data, status, error }) => {
		return {
			data,
			status,
			error: error?.response?.data
		}
	})
}

export const assignRoles = <T>(data: SetRolesAssign) => {
	return POST<T>('roles/assign', data).then(({ data }) => data)
}

export const removeRoles = <T>(data: SetRolesAssign) => {
	return POST<T>('roles/remove', data).then(({ data }) => data)
}

export const updateRoles = <T>(data: SetRolesProps, id: number | string) => {
	return PUT<T>('roles/' + id, data).then(({ data }) => data)
}

export const deleteRole = <T>(id: string | number | undefined) => {
	return DELETE<T>('roles/' + id).then(({ data }) => data)
}
