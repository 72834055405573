export const DEFECTS_SHORTHAND = {
  'ОК': 'ОК',
  'Вмятина': 'В',
  'Вспучивание': 'ВС',
  'Грязь': 'Г',
  'Деформация': 'ДФ',
  'Деформирован': 'ДФ',
  'Запотевание': 'З',
  'Красилось': 'К',
  'Не работает': 'НР',
  'Отклеен': 'КЛ',
  'Оторван': 'ПР',
  'Отсутствует': 'О',
  'Подтекание': 'ТЕ',
  'Порез': 'П',
  'Потертость': 'ПТ',
  'Прожжен': 'Ж',
  'Разбит': 'Б',
  'Ржавчина': 'Р',
  'Скол': 'С',
  'Сломан': 'Л',
  'Трещина': 'Т',
  'Царапина': 'Ц',
  'Не ОК': 'Не ОК',
};

export const DEFAULT_TIRES = [
  {
    axis: '1',
    dimensions: '',
    side: 1,
    tire_brand_id: '',
    position: 1,
    photos: [],
  },
  {
    axis: '1',
    dimensions: '',
    side: 2,
    tire_brand_id: '',
    position: 1,
    photos: [],
  },
  {
    axis: '2',
    dimensions: '',
    side: 1,
    tire_brand_id: '',
    position: 1,
    photos: [],
  },
  {
    axis: '2',
    dimensions: '',
    side: 2,
    tire_brand_id: '',
    position: 1,
    photos: [],
  },
];
