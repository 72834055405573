import Checkbox from "../../../../../components/form/Checkbox/Checkbox";
import styles from "./CheckboxGroups.module.scss";

type CheckboxGroupsProps = {
	name: string;
	options: { label: string; value: number }[];
	defaultValue: string;
	required: boolean;
};

const CheckboxGroups = (props: CheckboxGroupsProps) => {
	const { name, options, defaultValue, required } = props;

	return (
		<div className={styles.CheckboxGroups}>
			{options.map((option, index) => (
				<div key={index}>
					<Checkbox
						type={"radio"}
						name={name}
						label={option.label}
						defaultChecked={
							option.value ===
							(defaultValue && Number(JSON.parse(defaultValue)))
						}
						required={required}
						value={option.value}
					/>
				</div>
			))}
		</div>
	);
};

export default CheckboxGroups;
