import React from 'react';
import { Link } from 'react-router-dom';

import { meStore } from '@mobx/me';

export const getCompanies = (user) => {
  return user.companies?.map((item, index) => {
    return meStore.hasPermission('admin.companies.view') ? (
      <div key={`user-company-${item.id}`}>
        <Link to={`/company/${item.id}`}>{item.title}</Link>
        {(item.parent_id && item.root_department) ? <> (<Link to={`/company/${item.root_department.id}`}>{item.root_department.title}</Link>)</> : ''}
        {index !== (user.companies.length - 1) && ', '}
      </div>
    ) : (
      <div key={`user-company-${item.id}`}>
        <span>{item.title}</span>
        {(item.parent_id && item.root_department) ? <> (<span>{item.root_department.title}</span>)</> : ''}
        {index !== (user.companies.length - 1) && ', '}
      </div>
    );
  });
};
