import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { postCreateReport } from "@api/reports"

import "./car-form-components.scss"

export const Final = ({
	carData,
	edit,
	isNew,
	showConfirmPopup,
	onConfirmChangeStep,
}) => {
	const navigate = useNavigate()
	const carId = carData?.id
	const [openReport, setOpenReport] = useState()

	useEffect(() => {
		if (showConfirmPopup) {
			onConfirmChangeStep?.()
		}
	}, [showConfirmPopup])

	useEffect(() => {
		setOpenReport(carData?.status_reports?.find(x => x.status?.code === 'need_corrections' || x.status?.code === 'opened'))
	}, [carData])

	const goToCar = () => {
		navigate(`/car/${carId}`)
	}

	const handleCreateReportAndRedirect = async () => {
		postCreateReport(carId).then((resp) => {
			if (resp.id) {
				navigate(`/car/${carId}/report/${resp.id}`)
			}
		})
	}

	const handleOpenReportAndRedirect = () => {
		navigate(`/car/${carId}/report/${openReport.id}`)
	}

	return (
		<div>
			<div className="title mb-5">
				Автомобиль успешно {edit ? "изменен" : "добавлен"}
			</div>
			<div className='hint-car'>
				Для завершения создания карточки транспортного средства (ТС), Вам необходимо добавить Новый отчет или отредактировать уже созданный, заполнив в нем все обязательные поля!
			</div>
			<div className="info-car">
				Выберите дальнейшие действия с автомобилем
			</div>
			<div className="d-flex align-items-center justify-content-between gap-5 mob-padding">
				{carData?.metadata?.actions?.["statusReports.create"] && (
					<button
						type="button"
						onClick={handleCreateReportAndRedirect}
						className="btn btn-primary w-100 button"
					>
						Добавить отчет
					</button>
				)}
				{openReport &&
					<button
						type="button"
						onClick={handleOpenReportAndRedirect}
						className="btn btn-primary w-100 button"
					>
						Посмотреть отчет
					</button>
				}
				{!isNew && !!carData.status_reports?.length &&
					<button
						type="button"
						className="btn w-100 button btn-secondary"
						onClick={goToCar}
					>
						Просмотреть автомобиль
					</button>
				}
			</div>
		</div>
	)
}
