import { makeObservable, observable, action } from 'mobx';

import { MENU_WIDTH_CLOSED, MENU_WIDTH_OPENED } from '@utils/common';

class LayoutStore {
  constructor() {
    this.isSidebarOpened = true;

    makeObservable(this, {
      isSidebarOpened: observable,
      toggleSidebar: action,
    });
  }

  toggleSidebar() {
    this.isSidebarOpened = !this.isSidebarOpened;
    document.querySelector(':root').style.setProperty('--vars-left-menu-width', this.isSidebarOpened ? MENU_WIDTH_OPENED : MENU_WIDTH_CLOSED);
  }
}

export const layoutStore = new LayoutStore();
