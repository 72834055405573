import { GET, POST } from './request';

export const getReportsCategories = () => {
  return GET('reports/categories');
};

export const getReportsCategoriesTypes = (category) => {
  return GET(`reports/categories/${category}/types`);
};

export const getReports = (category, params) => {
  const newParams = {
    'expand[0]': 'status',
    'expand[2]': 'file',
    'expand[3]': 'seller',
    'expand[4]': 'createdBy',
    ...(params || {}),
  };
  return GET(`reports/categories/${category}/reports`, newParams);
};

export const postMakeReport = (params) => {
  return POST('reports/make', params);
};
