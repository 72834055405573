import cls from "./Separator.module.scss";
import clsx from "clsx";

interface SeparatorProps {
	className?: string;
	opacity: boolean;
}

const Separator = (props: SeparatorProps) => {
	const { className, opacity } = props;
	return (
		<div className={clsx(cls.Separator, opacity && cls.opacity)}></div>
	);
};

export default Separator;
