import classNames from 'classnames'
import styles from './Icon.module.scss'
import { IconType } from './IconType'

const IconInfo = ({ className, size = 16 }: IconType) => {
	return (
		<svg className={classNames(className, styles[`Icon${size}`])} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" fill="none">
			<g fill="currentColor">
				<path d="M896 128q209 0 385.5 103t279.5 279.5 103 385.5-103 385.5-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103zm128 1247v-190q0-14-9-23.5t-22-9.5h-192q-13 0-23 10t-10 23v190q0 13 10 23t23 10h192q13 0 22-9.5t9-23.5zm-2-344l18-621q0-12-10-18-10-8-24-8h-220q-14 0-24 8-10 6-10 18l17 621q0 10 10 17.5t24 7.5h185q14 0 23.5-7.5t10.5-17.5z" />
			</g>
		</svg>
	)
}

export default IconInfo
