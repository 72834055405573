import { RadioGroupField } from "@components/form/radio-group-field";

import { booleanOptions } from "../utils";

export const BooleanField = (props) => {
	const { field, value } = props;
	const { key, name, fieldName } = field;

	return (
		<div className="d-flex align-items-center justify-content-between">
			<div className="radio-title">{name}</div>
			<div className="radio-field">
				<RadioGroupField
					options={booleanOptions}
					name={fieldName}
					label={name || key}
					value={value}
					required={props.required}
					disabled={props.disabled}
				/>
			</div>
		</div>
	);
};
