import React from "react";
import clsx from "clsx";
import styles from "./ModalInfo.module.scss";
import { Modal } from "react-bootstrap";
import IconReload from "../../icons/IconReload";

type ModalInfoProps = {
	show: boolean
	title?: string
	className?: string
	onClick?(): void
}

const ModalInfo = (props: ModalInfoProps) => {
	const { show, title, className, onClick } = props;

	return (
		<Modal
			show={show}
			className={styles.ModalInfo}
		>
			<div className={clsx(className, styles.section)}>
				<div className={styles.reload}>
					<IconReload />
				</div>
				{title && <div className={styles.title}>{title}</div>}
				<div className={styles.footer}>
					<button
						onClick={onClick}
						className="btn btn-primary"
						type="button"
					>
						Обновить страницу
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default ModalInfo;
