import React, { useState } from 'react';

import { ExchangeLotForm } from './exchange-lot-form';
import { ExchangeLot } from './exchange-lot';

export const ExchangeLots = ({ exchangeId, isEdit, ...props }) => {
  const [lots, setLots] = useState(props?.lots || []);
  const [lotSuccess, setLotSuccess] = useState('');
  const [editId, setEditId] = useState();
  const [showLotForm, setShowLotForm] = useState(true);

  const hideNewLot = () => {
    setShowLotForm(false);
  };

  const onCreateLot = (newLots) => {
    setShowLotForm(false);
    setLots(newLots);
    setLotSuccess('Лот успешно добавлен');

    setTimeout(() => setLotSuccess(''), 5000);
  };

  const onSaveLot = (newLots) => {
    setEditId(null);
    setLots(newLots);
    setLotSuccess('Лот успешно изменен');

    setTimeout(() => setLotSuccess(''), 5000);
  };

  const onEditLot = (lotId) => {
    setEditId(lotId);
  };

  const onCancelLotEdit = () => {
    setEditId(null);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-5 mb-3">
        <div className="head-text">
          <span>Лоты аукциона</span>
        </div>
        {!showLotForm && (
          <button onClick={() => setShowLotForm(true)} className="btn btn-primary" type="button">
            Добавить лот
          </button>
        )}
      </div>
      {lots?.length > 0 && (
        <>
          <div className="name mb-2">Сохраненные лоты</div>
          {lots.map((lot) => {
            if (editId === lot.id) {
              return (
                <ExchangeLotForm
                  key={`edit-lot-${lot.id}`}
                  onClose={onCancelLotEdit}
                  onChange={onSaveLot}
                  exchangeId={exchangeId}
                  isEdit={true}
                  lot={lot}
                />
              );
            }

            return (
              <ExchangeLot
                key={`saved-lot-${lot.id}`}
                lot={lot}
                onEdit={onEditLot}
              />
            );
          })}
        </>
      )}
      {showLotForm && (
        <ExchangeLotForm
          onClose={hideNewLot}
          onChange={onCreateLot}
          exchangeId={exchangeId}
          hideClose={!lots?.length}
          order={lots.length}
        />
      )}
      {Boolean(lotSuccess) && (
        <div className="mt-2 mb-2 alert alert-success">{lotSuccess}</div>
      )}
    </div>
  );
};
