import React, { memo, useState } from 'react';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { getPhotoSrc } from '@utils/photo';

/* eslint-disable import/no-unresolved */
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
/* eslint-enable import/no-unresolved */

import './slider.scss';

export const Slider = memo(({ photos, className, mainSliderClassName, previewSliderClassName, imgAlt, onImageClick, isMobile }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className={`${className || ''} d-flex flex-column swiper-slider`}>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        spaceBetween={10}
        navigation
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className={`swiper-slider__main ${mainSliderClassName || ''}`}
      >
        {photos.map((img, index) => {
          const imgUrl = img?.original || img;

          return (
            <SwiperSlide key={`${imgUrl}-${index}`} onClick={onImageClick ? () => onImageClick(index) : undefined}>
              {onImageClick ? (
                <img src={getPhotoSrc(imgUrl)} alt={imgAlt} />
              ) : (
                <a href={img} target="_blank" rel="noreferrer">
                  <img src={getPhotoSrc(imgUrl)} alt={imgAlt} />
                </a>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode
        watchSlidesProgress
        modules={[FreeMode, Navigation, Thumbs]}
        className={`swiper-slider__thumbs ${previewSliderClassName || ''}`}
      >
        {!isMobile && photos.map((img, index) => {
          const imgUrl = img?.thumbnail || img?.original || img || undefined;

          return (
            <SwiperSlide key={`${imgUrl}-${index}`}>
              <img src={`${getPhotoSrc(imgUrl)}?${index}`} alt={imgAlt} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
});
