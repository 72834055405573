import React from 'react';
import styles from './FilterButton.module.scss';
import clsx from 'clsx';

type FilterButtonProps = {
	indent?: boolean
	indentBottom?: boolean
	onClick?:() => void;
}

const FilterButton = ({ indent, indentBottom, onClick }: FilterButtonProps) => {
	return (
		<button className={clsx(styles.FilterButton, indent && styles.indent, indentBottom && styles.indentBottom)} onClick={onClick}>
			<img src="/img/icons/filter-icon.svg" />
			Фильтры
		</button>
	);
};

export default FilterButton;
