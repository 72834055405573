import React from 'react';
import { IconType } from './IconType';
import classNames from 'classnames';
import styles from './Icon.module.scss'

const IconPlus = ({ className, size = 30 }: IconType) => {
	return (
		<svg className={classNames(className, styles[`Icon${size}`])} xmlns="http://www.w3.org/2000/svg"
		     viewBox="0 0 30 30" fill="none">
			<circle cx="15" cy="15" r="15" fill="#283445" />
			<path
				d="M18.8911 16.7776C19.1574 16.5113 19.1574 16.0796 18.8911 15.8133L15.482 12.4042C15.2158 12.138 14.7841 12.138 14.5178 12.4042L11.1087 15.8133C10.8424 16.0796 10.8424 16.5113 11.1087 16.7776C11.375 17.0438 11.8067 17.0438 12.0729 16.7776L14.9999 13.8506L17.9269 16.7776C18.1932 17.0438 18.6249 17.0438 18.8911 16.7776Z"
				fill="white" />
		</svg>
	);
};

export default IconPlus;
