import { useEffect, useState } from "react"

import { getStatusReportComments } from '@api/reports'
import "./specification.scss"

export const Specification = ({ carSaleInfo, currentStatusReport, id }) => {
	const { car } = carSaleInfo

	const [comment, setComment] = useState()
	const [reportDefault, setReportDefault] = useState()

	useEffect(() => {
		if (currentStatusReport?.id) {
			getStatusReportComments(currentStatusReport?.id).then(resp => {
				if (resp.data.length) {
					let x = resp.data.at(-1)
					setComment(x.body)
				}
			})
		}
	}, [currentStatusReport?.id])

	return (
		<div className="specification-wrapper section" id="description">
			<div className="title">Спецификация</div>
			<div className="info-car-wrapper">
				<div className="info-car-wrapper__columns">
					<div className="info-car">
						<div className="info-car__field">
							<div className="name">Год выпуска</div>
							<div className="value">{car?.year}</div>
						</div>
						<div className="info-car__field">
							<div className="name">Пробег</div>
							<div className="value">
								{currentStatusReport?.mileage ?? "-"}
							</div>
						</div>
						<div className="info-car__field">
							<div className="name">Кузов</div>
							<div className="value">
								{car?.type?.name ?? "-"}
							</div>
						</div>
					</div>
					<div className="info-car">
						<div className="info-car__field">
							<div className="name">Цвет</div>
							<div className="value">
								{carSaleInfo?.color?.name ?? "-"}
							</div>
						</div>
						<div className="info-car__field">
							<div className="name">Двигатель</div>
							<div className="value">
								{car?.engine_volume} / {car?.power} /{" "}
								{car?.engine?.name}
							</div>
						</div>
						<div className="info-car__field">
							<div className="name">КПП</div>
							<div className="value">
								{car?.transmission?.name ?? "-"}
							</div>
						</div>
					</div>
					<div className="info-car">
						<div className="info-car__field">
							<div className="name">Привод</div>
							<div className="value">{car?.drive?.name}</div>
						</div>
						<div className="info-car__field">
							<div className="name">VIN</div>
							<div className="value">{car?.vin ?? "-"}</div>
						</div>
						<div className="info-car__field">
							<div className="name">Гос номер</div>
							<div className="value">
								{currentStatusReport?.reg_number ?? "-"}
							</div>
						</div>
					</div>
				</div>
			</div>
			{comment && (
				<div className="info-car-wrapper__comment">
					<div className="name">Комментарий</div>
					<div className="value">
						{comment ?? "-"}
					</div>
				</div>
			)}
		</div>
	)
}
