import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { citiesStore } from '../../../../../mobx/cities'
import ReportList from '../../../ReportList/ReportList'
import ReportModal from '../../../ReportModal/ReportModal'
import ReportSelected from '../../../ReportSelected/ReportSelected'

interface LotSelectedCarProps {
	className?: string
	selectedReports: any[]
	id: number
	lotId: number
	name: string
	vin: string
	withCreateSale: boolean
	isNewLot: boolean
	isSavedLot: boolean
	isEnableDelectingReport: boolean
	statusReports: any[]
	hasReports: boolean
	onRemove(id: number): void
	onRemoveStatusReport(id: number): void
	onSelectReport(obj: any): void
}

const LotSelectedCar = (props: LotSelectedCarProps) => {
	const {
		className,
		selectedReports,
		id,
		lotId,
		name,
		vin,
		withCreateSale,
		isNewLot,
		isSavedLot,
		isEnableDelectingReport = true,
		statusReports,
		hasReports,
		onRemove,
		onRemoveStatusReport,
		onSelectReport
	} = props

	const { cities } = citiesStore

	const [city, setCity] = useState('')
	const [report, setReport] = useState<any>()
	const [reports, setReports] = useState<any[]>([])
	const [showReport, setShowReport] = useState(false)
	const [isEnableDeleting, setIsEnableDeleting] = useState(isEnableDelectingReport)

	useEffect(() => {
		if (hasReports) {
			if (selectedReports.length) {
				setReport(selectedReports.find(x => x.sale_id === id))
			}
		}
	}, [selectedReports])

	useEffect(() => {
		if (!hasReports) {
			let statusReportsInit = statusReports.filter(x => x.lot_id === null)

			if (statusReportsInit.length === 1) {
				let report = {
					sale_id: statusReportsInit[0].sale_id,
					status_report_id: statusReportsInit[0].id,
					closed_report: statusReportsInit[0].closed_by?.display_name,
					closed_at: moment(statusReportsInit[0].closed_at).format("DD.MM.YYYY HH:mm"),
					cityId: statusReportsInit[0].city_id
				}
				onSelectReportHandler(report)
			}
		}
	}, [])

	const onRemoveHandler = () => {
		onRemove(id)
	}

	const onRemoveStatusReportHandler = (id: number) => {
		setReport(null)
		onRemoveStatusReport(id)
	}

	const onShowReports = (reports: any) => {
		let _reports: any[] = []
		let _currentReports
		if (lotId) {
			_currentReports = reports.find((x: any) => x.lot_id === lotId)
			_reports = [_currentReports]
		}
		_reports = [..._reports, ...reports.filter((x: any) => x.lot_id === null)].sort((a, b) => a.id - b.id)
		setReports(_reports)
		setShowReport(true)
	}

	const onSelectReportHandler = (obj: any) => {
		setReport(obj)
		setShowReport(false)
		onSelectReport(obj)
	}

	useEffect(() => {
		if (!citiesStore.cities.length) {
			citiesStore.fetch()
		}
	}, [])

	useEffect(() => {
		if (report?.cityId) {
			let findedCity = cities.find(x => x.id === report?.cityId)
			setCity(findedCity?.name)
		} else setCity('')
	}, [report])

	return (
		<>
			<div className="lot__table-item-table">
				<div
					className="lot__table-item-row"
				>
					<div className="lot__table-item-tr">
						<a
							href={`/car/${id}`}
							className="lot__table-item-tr-car-link"
							target="_blank"
							rel="noreferrer"
						>
							{name}
						</a>
					</div>
					<div className="lot__table-item-tr">
						{vin}
					</div>
					<div className="lot__table-item-tr">
						{city}
					</div>
					{withCreateSale && isNewLot && (
						<img
							src="/img/icons/delete-icon.svg"
							alt=""
							onClick={onRemoveHandler}
							className="cursor-pointer"
						/>
					)}
				</div>
				{!isSavedLot &&
					<div className="report-info">

						{report ? (
							<ReportSelected report={report} onRemove={onRemoveStatusReportHandler} isDeleting={isEnableDeleting} />
						)
							:
							<button
								className={"add-report"}
								onClick={() => onShowReports(statusReports)}>
								<span>Прикрепить отчет</span> *
							</button>}
					</div>
				}
			</div>
			<ReportModal show={showReport} onHide={() => setShowReport(false)}>
				<ReportList items={reports} onSelect={onSelectReportHandler} />
			</ReportModal>
		</>
	)
}

export default LotSelectedCar