import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { FindLots } from '@pages/sell/confirm-popup/find-lots';
import { ChooseLots } from '@pages/sell/confirm-popup/choose-lots';

import './confirm-popup.scss';

const STEPS_NUMBER = 2;

export const ConfirmPopup = ({ isConfirmLot, handleClose, auctionRequest }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [inputSearch, setInputSearch] = useState(null);

  const onStepChange = useCallback((isBack) => {
    setActiveStep((prev) => (isBack ? (prev - 1) : (prev + 1)));
  }, []);

  return (
    <Modal
      show={true}
      onHide={handleClose}
      className="d-flex justify-content-center lots-confirm-popup mt-30px"
    >
      {activeStep === 1
        && (
        <FindLots
          isConfirmLot={isConfirmLot}
          onStepChange={onStepChange}
          step={1}
          stepsNumber={STEPS_NUMBER}
          handleClose={handleClose}
          setInputSearch={setInputSearch}
        />
        )}
      {activeStep === 2
        && (
        <ChooseLots
          isConfirmLot={isConfirmLot}
          onStepChange={onStepChange}
          step={2}
          stepsNumber={STEPS_NUMBER}
          handleClose={handleClose}
          vinList={inputSearch}
          auctionRequest={auctionRequest}
        />
        )}
    </Modal>
  );
};
