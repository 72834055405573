export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const API_URL_V1 = process.env.REACT_APP_API_URL
	? `${process.env.REACT_APP_API_URL}/v1/`
	: "/api/v1/";
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || "";
export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
export const WS_HOST = process.env.REACT_APP_WS_HOST || "";
export const WS_PORT = process.env.REACT_APP_WS_PORT
	? Number(process.env.REACT_APP_WS_PORT)
	: 6002;
export const WSS_PORT = process.env.REACT_APP_WSS_PORT
	? Number(process.env.REACT_APP_WSS_PORT)
	: 6002;
export const FORCE_TLS = process.env.REACT_APP_FORCE_TLS
	? process.env.REACT_APP_FORCE_TLS === "true"
	: true;
export const COUNTRY = process.env.REACT_APP_COUNTRY || "ru";
