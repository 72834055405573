import uniqBy from "lodash/uniqBy";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import InputMask from "react-input-mask";
import clsx from "clsx";

import { getPhotoSrc } from "@utils/photo";
import { InputUploadPhoto } from "@components/input-upload-photo/input-upload-photo";
import { Select } from "@components/select/select";

const SIDES = {
	1: "Левая",
	2: "Правая"
};

export const WheelRow = ({ field, complectForm, fieldId, complectIdx, tireBrands, isMobile, isClosed }) => {
	const [show, setShow] = useState(false);
	const [selectedImages, setSelectedImages] = useState([]);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const ParentWrapper = (isMobile) ? "div" : "tr";
	const ChildWrapper = (isMobile) ? "div" : "td";

	const openImage = (url) => {
		window.open(url, "_blank");
	};

	const removeImage = (index) => {
		const buffData = [...selectedImages];
		buffData.splice(index, 1);
		setSelectedImages(buffData);
	};

	const handleChangeFile = (e) => {
		const images = [];
		const fileArray = [];
		images.push(e.target.files);

		for (let i = 0; i < images[0].length; i += 1) {
			fileArray.push(images[0][i]);
		}
		setSelectedImages((prev) => uniqBy([...prev, ...fileArray], (file) => file.name));
		e.target.value = "";
	};

	const addWheelPhotos = () => {
		handleClose();
		const fd = new FormData();
		selectedImages.forEach((img) => {
			fd.append(`${complectIdx}[tires][photos][photos][]`, img);
		});
		// eslint-disable-next-line no-param-reassign
		field.photos = uniqBy(
			[...field.photos, ...selectedImages],
			(img) => img.name || img?.file?.original_name
		);
	};

	return (
		<ParentWrapper className={clsx({ "mob-wheel__wrapper mob-wheel__gap": isMobile })}>

			{isMobile && (
				<div className="w-50 mob-border-bottom mob-bold">
					Ось
				</div>
			)}
			<ChildWrapper className={clsx({ "w-50 mob-border-bottom": isMobile })}>
				{field.axis}
			</ChildWrapper>

			{isMobile && (
				<div className="w-50 mob-border-bottom mob-bold">
					Сторона
				</div>
			)}
			<ChildWrapper className={clsx({ "w-50 mob-border-bottom": isMobile })}>
				{SIDES[field.side]}
			</ChildWrapper>

			{isMobile && (
				<div className="w-100 mob-bold">
					Марка
				</div>
			)}
			<ChildWrapper className={clsx({ "w-100 mob-border-bottom": isMobile })}>
				<Select
					options={tireBrands?.map((brand) => ({ value: brand.value, label: brand.label }))}
					valueId={complectForm.getValues().wheels[complectIdx].tires[fieldId].tire_brand_id}
					size="l"
					placeholder="Марка"
					onChange={({ value }) => complectForm.setValue(`wheels[${complectIdx}].tires[${fieldId}].tire_brand_id`, value)}
					disabled={isClosed}
				/>
			</ChildWrapper>

			{isMobile && (
				<div className="w-100 mob-bold">
					Размерность
				</div>
			)}
			<ChildWrapper className={clsx({ "w-100 mob-border-bottom": isMobile })}>
				<InputMask
					mask="999/99R99"
					alwaysShowMask
					type="text"
					value={field.dimensions}
					className={clsx("input input_l", isClosed && "opacity-50 input-disabled")}
					{...complectForm.register(`wheels[${complectIdx}].tires[${fieldId}].dimensions`, {
						required: true
					})}
				/>
			</ChildWrapper>

			{isMobile && (
				<div className="w-100 mob-bold">
					Фото
				</div>
			)}
			<ChildWrapper className={clsx({ "mob-wheel__photos": isMobile })}>
				<div className="d-flex align-items-center justify-content-between">
					<div className="accordion-images-preview">
						{field?.photos?.length
							? field.photos.map((img, i) => (!img?.file ? (
								<img
									key={img.name}
									className="wheel-image"
									src={URL.createObjectURL(img)}
									onClick={() => {
										openImage(URL.createObjectURL(img));
									}}
									alt=""
								/>
							) : (
								<img
									key={img?.file.url}
									className="wheel-image"
									src={getPhotoSrc(img?.file?.url)}
									alt=""
									onClick={() => {
										openImage(getPhotoSrc(img?.file?.url));
									}}
								/>
							)))
							: ""}
					</div>

					{isMobile && !isClosed && (
						<div className="flex flex-row w-100">
							<button onClick={handleShow} className="w-100 mob-wheel__button" type="button">
								<div className="mob-bold mob-btn__caption">Добавить фотографию</div>
								<div onClick={handleShow} className="add-photo-btn mob-btn__icon rounded-circle"
									 type="button">
									<img className="svg-icon" src="/img/icons/plus-sign.svg" alt="" />
								</div>
							</button>
						</div>
					)}

					{!isMobile && !isClosed && (
						<button onClick={handleShow} className="add-photo-btn" type="button">
							<img className="svg-icon" src="/img/icons/plus-sign.svg" alt="" />
						</button>
					)}
				</div>
			</ChildWrapper>

			<Modal size="xl" show={show} centered onHide={handleClose}>
				<Modal.Header>
					<div className="modal-wrapper__header">
						<div className="title">Добавление фото колес</div>
						<div className="modal-small-text">
							{field.axis} - {SIDES[field.side]}
						</div>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form d-flex align-items-center">
						<div className="name w-25">Фотография</div>
						<div className="w-75">
							{selectedImages && selectedImages.map((img, i) => (
								<div
									className="image-wrapper d-flex align-items-center justify-content-between mb-2"
									key={img.name}
								>
									<img src="/img/icons/img-icon.svg" alt="" />
									<div>{img.name}</div>
									<img onClick={() => removeImage(i)} src="/img/icons/remove-icon.svg" alt="" />
								</div>
							))}
						</div>
						<InputUploadPhoto name="img" handleChangeFile={handleChangeFile} />
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-primary" onClick={addWheelPhotos} type="button">
						Добавить фото
					</button>
					<button className="save-btn" onClick={handleClose} type="button">
						Закрыть
					</button>
				</Modal.Footer>
			</Modal>
		</ParentWrapper>
	);
};
