import React from 'react';
import { IconType } from './IconType';
import classNames from 'classnames';
import styles from './Icon.module.scss'

const IconTrash = ({ className, size = 16 }: IconType) => {
	return (
		<svg className={classNames(className, styles[`Icon${size}`])} xmlns="http://www.w3.org/2000/svg"
		     viewBox="0 0 16 16" fill="none">
			<g fill="currentColor">
				<path d="M6.75002 4.41667V3.25H9.25002V4.41667H6.75002ZM5.25002 4.41667V3.16667C5.25002 2.79094 5.39928 2.43061 5.66495 2.16493C5.93063 1.89926 6.29096 1.75 6.66669 1.75H9.33335C9.70908 1.75 10.0694 1.89926 10.3351 2.16493C10.6008 2.43061 10.75 2.79094 10.75 3.16667V4.41667H12.6579H12.6738H13.3334C13.7476 4.41667 14.0834 4.75245 14.0834 5.16667C14.0834 5.57303 13.7602 5.90391 13.3568 5.91631L12.7497 13.2013C12.7408 13.7413 12.5224 14.2573 12.1398 14.6398C11.7491 15.0305 11.2192 15.25 10.6667 15.25H5.33335C4.78082 15.25 4.25091 15.0305 3.86021 14.6398C3.47769 14.2573 3.25928 13.7413 3.25031 13.2013L2.64322 5.91631C2.23987 5.90391 1.91669 5.57303 1.91669 5.16667C1.91669 4.75245 2.25247 4.41667 2.66669 4.41667H3.32626H3.34212H5.25002ZM10 5.91667H6.00002H4.14845L4.74743 13.1044C4.74916 13.1251 4.75002 13.1459 4.75002 13.1667C4.75002 13.3214 4.81148 13.4697 4.92087 13.5791C5.03027 13.6885 5.17864 13.75 5.33335 13.75H10.6667C10.8214 13.75 10.9698 13.6885 11.0792 13.5791C11.1886 13.4697 11.25 13.3214 11.25 13.1667C11.25 13.1459 11.2509 13.1251 11.2526 13.1044L11.8516 5.91667H10ZM6.66669 7.08333C7.0809 7.08333 7.41669 7.41912 7.41669 7.83333V11.8333C7.41669 12.2475 7.0809 12.5833 6.66669 12.5833C6.25247 12.5833 5.91669 12.2475 5.91669 11.8333V7.83333C5.91669 7.41912 6.25247 7.08333 6.66669 7.08333ZM10.0834 7.83333C10.0834 7.41912 9.74757 7.08333 9.33335 7.08333C8.91914 7.08333 8.58335 7.41912 8.58335 7.83333V11.8333C8.58335 12.2475 8.91914 12.5833 9.33335 12.5833C9.74757 12.5833 10.0834 12.2475 10.0834 11.8333V7.83333Z" />
			</g>
		</svg>
	);
};

export default IconTrash;
